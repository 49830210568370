import {
  Box,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { FormCheckBox, FormOutlineField, FormSelectField } from "components";
import { ClinicLocationApiStateContext, ClinicLocationStateContext } from "context";
import React, { forwardRef, useCallback, useContext, useEffect, useImperativeHandle, useRef, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { NOTIFICATION_MEDIUM } from "utils";
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from "@mui/icons-material/Delete";
import { IconButton } from '@mui/material';
const ClinicLocationNotification = forwardRef(({ formId, formData }, parentRef) => {
  const formRef = useRef(null);
  const { isClinicLocationEdit } = useContext(ClinicLocationStateContext);
  const { clinicLocationData } = useContext(ClinicLocationApiStateContext);
  const { control, handleSubmit, setValue, getValues, trigger } = useForm({
    defaultValues: {
      isToBillClinicForPrescription: "clinic",
      defaultShippingOption: "clinic",
      isToNotifyWhenOrderOnHold: true,
      isToNotifyWhenOrderInProcess: false,
      isToNotifyWhenOrderIsCompleted: false,
    },
  });

  const watchBillingOption = useWatch({ control, name: "isToBillClinicForPrescription" });
  const watchShippingOption = useWatch({ control, name: "defaultShippingOption" });

  const [emailFields, setEmailFields] = useState([{ id: 1, email: '' }]);
  const [emailFields2, setEmailFields2] = useState([{ id: 1, email: '' }]);
  const [emailFields3, setEmailFields3] = useState([{ id: 1, email: '' }]);

  const handleAddField = () => {
    setEmailFields(prevFields => [...prevFields, { id: prevFields.length + 1, email: '' }]);
  };
  const handleAddField2 = () => {
    setEmailFields2(prevFields => [...prevFields, { id: prevFields.length + 1, email: '' }]);
  };
  const handleAddField3 = () => {
    setEmailFields3(prevFields => [...prevFields, { id: prevFields.length + 1, email: '' }]);
  };

  const handleRemoveField = useCallback((id) => {
    setEmailFields(prevFields => {
       if (prevFields.length === 1) {
        return prevFields;  
      }
      const updatedFields = prevFields.filter(field => field.id !== id);
       
      setValue("notificationOrderOnHold", updatedFields.map(field => field.email));
      return updatedFields;
    });
  }, [setEmailFields, setValue]);

  const handleRemoveField2 = useCallback((id) => {
    setEmailFields2(prevFields => { 
      const updatedFields = prevFields.filter(field => field.id !== id);
       
      setValue("notificationOrderInProcess", updatedFields.map(field => field.email));
      return updatedFields;
    });
  }, [setEmailFields2, setValue]);
  const handleRemoveField3 = useCallback((id) => {
    setEmailFields3(prevFields => {
      const updatedFields = prevFields.filter(field => field.id !== id);
       
      setValue("notificationOrderIsCompleted", updatedFields.map(field => field.email));
      return updatedFields;
    });
  }, [setEmailFields3, setValue]);

  const handleChange = useCallback((id, value) => {
    setEmailFields(prevFields => {
      const updatedFields = prevFields.map(field => field.id === id ? { ...field, email: value } : field);
      setValue("notificationOrderOnHold", updatedFields.map(field => field.email));
      return updatedFields;
    });
  }, [setEmailFields, setValue]);
  const handleChange2 = useCallback((id, value) => {
    setEmailFields2(prevFields => {
      const updatedFields = prevFields.map(field => field.id === id ? { ...field, email: value } : field);
      setValue("notificationOrderInProcess", updatedFields.map(field => field.email));
      return updatedFields;
    });
  }, [setEmailFields2, setValue]);
  const handleChange3 = useCallback((id, value) => {
    setEmailFields3(prevFields => {
      const updatedFields = prevFields.map(field => field.id === id ? { ...field, email: value } : field);
      setValue("notificationOrderIsCompleted", updatedFields.map(field => field.email));
      return updatedFields;
    });
  }, [setEmailFields3, setValue]);
  
  useEffect(() => {
    setValue("notificationOrderInProcess", []);
    setValue("notificationOrderIsCompleted", []);
    if (isClinicLocationEdit && clinicLocationData) {
      const formFields = getValues();
      for (const field in formFields) {
        let fieldValue = clinicLocationData?.[field] ?? "";
        if (field === "isToBillClinicForPrescription") {
          fieldValue = fieldValue ? "clinic" : "patient";
        } else if (field === "defaultShippingOption") {
          fieldValue = clinicLocationData?.isToShipToClinic ? "clinic" : "patient";
        }
        setValue(field, fieldValue);
      }
      if (clinicLocationData?.notificationOrderOnHold?.length > 0) {
        setEmailFields(clinicLocationData?.notificationOrderOnHold.map((email, index) => ({ id: index + 1, email })));
      }
      if (clinicLocationData?.notificationOrderInProcess?.length > 0) {
        setEmailFields2(clinicLocationData?.notificationOrderInProcess.map((email, index) => ({ id: index + 1, email })));
      }
      if (clinicLocationData?.notificationOrderIsCompleted?.length > 0) {
        setEmailFields3(clinicLocationData?.notificationOrderIsCompleted.map((email, index) => ({ id: index + 1, email })));
      }
      submitAction(getValues(), null, false);
    }
  }, [isClinicLocationEdit, clinicLocationData]);

  const checkBoxBtn = useCallback((name, index) => {
    setValue(name, index);
  }, []);

  const radioBtnClick = useCallback(
    fieldName => async event => {
      setValue(fieldName, event.target.value);
    },
    [setValue],
  );

  const submitAction = useCallback(
    (
      { defaultShippingOption, isToBillClinicForPrescription, ...restData },
      progressBarIndex = null,
      allowNavigation = true,
    ) => {
      const info = {
        ...restData,
        isToShipToClinic: defaultShippingOption === "clinic",
        isToShipToPatient: defaultShippingOption === "patient",
        isToBillClinicForPrescription: isToBillClinicForPrescription === "clinic",
        isToBillPatientForPrescription: isToBillClinicForPrescription === "patient",
      };
      formData?.({ info }, formId, progressBarIndex, allowNavigation);
    },
    [formData, formId],
  );

  const canNavigate = useCallback(
    progressBarIndex => {
      handleSubmit(data => submitAction(data, progressBarIndex))();
    },
    [handleSubmit, submitAction],
  );

  const checkValidation = useCallback(async () => {
    const formValidated = await trigger();
    return formValidated;
  }, []);

  const requestFormSubmit = useCallback(() => formRef.current?.requestSubmit?.(), []);

  useImperativeHandle(parentRef, () => ({
    submitForm: requestFormSubmit,
    canNavigate: canNavigate,
    checkFilledForm: checkValidation,
  }));

  return (
    <Box id={formId} ref={formRef} component={"form"} onSubmit={handleSubmit(data => submitAction(data, null))}>
      <Grid container columnSpacing={3} rowSpacing={1}>
        <Grid container item marginY={2} columnSpacing={3} rowSpacing={1}>
          <Grid item xs={12} marginBottom={2}>
            <Typography variant="h5" fontWeight={"500"}>
              OUR CLINIC WOULD LIKE THE PRESCRIPTIONS TO BE MARKED AS BELOW
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <FormControl>
              <FormLabel>
                <Typography variant="h6" color="primary">
                  1. Billing for prescriptions
                </Typography>
              </FormLabel>
              <RadioGroup row name="isToBillClinicForPrescription" value={watchBillingOption}>
                <FormControlLabel
                  value="clinic"
                  control={<Radio style={{ fontSize: 20 }} onClick={radioBtnClick("isToBillClinicForPrescription")} />}
                  label="Bill to Clinic"
                />
                <FormControlLabel
                  value="patient"
                  control={<Radio style={{ fontSize: 20 }} onClick={radioBtnClick("isToBillClinicForPrescription")} />}
                  label="Bill to Patient"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl>
              <FormLabel>
                <Typography variant="h6" color="primary">
                  2. Shipping for prescriptions
                </Typography>
              </FormLabel>
              <RadioGroup row name="defaultShippingOption" value={watchShippingOption}>
                <FormControlLabel
                  value="clinic"
                  control={<Radio style={{ fontSize: 20 }} onClick={radioBtnClick("defaultShippingOption")} />}
                  label="Ship to Clinic"
                />
                <FormControlLabel
                  value="patient"
                  control={<Radio style={{ fontSize: 20 }} onClick={radioBtnClick("defaultShippingOption")} />}
                  label="Ship to Patient"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container item marginY={2} columnSpacing={3} rowSpacing={1}>
          <Grid item xs={12} marginBottom={2}>
            <Typography variant="h5" fontWeight={"500"}>
              What email address and contact number would you like to receive notifications on?
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormOutlineField
              name="notificationEmail"
              control={control}
              rules={{
                pattern: { value: /\S+@\S+\.\S+/, message: "Invalid email address" },
              }}
              MuiFieldProps={{ label: "End of Day Email", type: "email", placeholder: "abc@abc.com" }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormOutlineField
              name="notificationContactNumber"
              control={control}
              rules={{
                pattern: { value: /^(\d{10})?$/, message: "Invalid Phone" },
              }}
              MuiInputProps={{ format: "(###) ###-####" }}
              MuiFieldProps={{ label: "Notification Contact", placeholder: "(123) 456-7890", isMasked: true }}
            />
          </Grid>
          
          <Grid item xs={12} sm={6}>
            <p className="semiBold medText my-2">Order on Hold</p>
            <>
              {emailFields.map((field) => (
                <div key={field.id} style={{ display: 'flex', alignItems: 'center' }}>
                  <FormOutlineField
                    name="notificationOrderOnHold"
                    control={control}
                    rules={{
                      required: "Order on Hold Email is required.",
                      pattern: { value: /\S+@\S+\.\S+/, message: "Invalid email address" },
                    }}
                    MuiFieldProps={{
                      label: field.id === 1 ? 'Hold Email 1' : `Hold Email ${field.id}`,
                      type: "email",
                      placeholder: "abc@abc.com",
                      value: field.email, // Display the email value from the field object
                      onChange: (e) => handleChange(field.id, e.target.value) // Call handleChange function on change
                    }}
                  />
                  <IconButton onClick={() => handleRemoveField(field.id)} aria-label={`remove email field ${field.id}`} style={{ marginLeft: '10px', marginTop: '-15px' }}>
                    <DeleteIcon color="error" style={{ width: 30, height: 30 }} />
                  </IconButton>
                </div>
              ))}
              <IconButton onClick={handleAddField} aria-label="add email field">
                <AddIcon color="primary" style={{ width: 25, height: 25 }} />
              </IconButton>
            </>
          </Grid>
          <Grid item xs={12} sm={6}>
            <p className="semiBold medText my-2">Order in Process</p>
            <>
              {emailFields2.map((field) => (
                <div key={field.id} style={{ display: 'flex', alignItems: 'center' }}>
                  <FormOutlineField
                    name="notificationOrderInProcess"
                    control={control}
                    rules={{
                      pattern: { value: /\S+@\S+\.\S+/, message: "Invalid email address" },
                    }}
                    MuiFieldProps={{
                      label: field.id === 1 ? 'In Process Email 1' : `In Process Email ${field.id}`,
                      type: "email",
                      placeholder: "abc@abc.com",
                      value: field.email, // Display the email value from the field object
                      onChange: (e) => handleChange2(field.id, e.target.value) // Call handleChange function on change
                    }}
                  />
                  <IconButton onClick={() => handleRemoveField2(field.id)} aria-label={`remove email field ${field.id}`} style={{ marginLeft: '10px', marginTop: '-15px' }}>
                    <DeleteIcon color="error" style={{ width: 30, height: 30 }} />
                  </IconButton>
                </div>
              ))}
              <IconButton onClick={handleAddField2} aria-label="add email field">
                <AddIcon color="primary" style={{ width: 25, height: 25 }} />
              </IconButton>
            </>
          </Grid>
          <Grid item xs={12} sm={6}>
            <p className="semiBold medText my-2">Order Completed</p>
            <>
              {emailFields3.map((field) => (
                <div key={field.id} style={{ display: 'flex', alignItems: 'center' }}>
                  <FormOutlineField
                    name="notificationOrderIsCompleted"
                    control={control}
                    rules={{
                      pattern: { value: /\S+@\S+\.\S+/, message: "Invalid email address" },
                    }}
                    MuiFieldProps={{
                      label: field.id === 1 ? 'Completed Email 1' : `Completed Email ${field.id}`,
                      type: "email",
                      placeholder: "abc@abc.com",
                      value: field.email, // Display the email value from the field object
                      onChange: (e) => handleChange3(field.id, e.target.value) // Call handleChange function on change
                    }}
                  />
                  <IconButton onClick={() => handleRemoveField3(field.id)} aria-label={`remove email field ${field.id}`} style={{ marginLeft: '10px', marginTop: '-15px' }}>
                    <DeleteIcon color="error" style={{ width: 30, height: 30 }} />
                  </IconButton>
                </div>
              ))}
              <IconButton onClick={handleAddField3} aria-label="add email field">
                <AddIcon color="primary" style={{ width: 25, height: 25 }} />
              </IconButton>
            </>
          </Grid>
        </Grid>
        <Grid container item marginY={2}>
          <FormControl fullWidth component="fieldset" variant="standard">
            <FormLabel component="legend" className="my-3">
              <Typography variant="h5" fontWeight={"500"}>
                Preferred Notifications
              </Typography>
            </FormLabel>
            <Grid item xs={12} sm={6} md={4} paddingRight={2}>
              <FormSelectField
                name={"notificationPreferences"}
                control={control}
                MuiFieldProps={{ label: "Notification Method", optionArray: NOTIFICATION_MEDIUM }}
              />
            </Grid>
            <FormGroup row>
              <FormCheckBox
                control={control}
                name="isToNotifyWhenOrderOnHold"
                checkBoxBtn={checkBoxBtn}
                MuiFieldProps={{ label: "Order on Hold" }}
                forceChecked={true} // This will force the checkbox to be checked
              />
              <FormCheckBox
                control={control}
                name="isToNotifyWhenOrderInProcess"
                checkBoxBtn={checkBoxBtn}
                MuiFieldProps={{ label: "Order in Process" }}
              />
              <FormCheckBox
                control={control}
                name="isToNotifyWhenOrderIsCompleted"
                checkBoxBtn={checkBoxBtn}
                MuiFieldProps={{ label: "Order Completed" }}
              />
            </FormGroup>
          </FormControl>
        </Grid>
      </Grid>
    </Box>
  );
});

export default React.memo(ClinicLocationNotification);
