import styled from "@emotion/styled";
import {
  Accordion,
  Autocomplete,
  Breadcrumbs,
  Select,
  StepConnector,
  StepLabel,
  Table,
  autocompleteClasses,
  breadcrumbsClasses,
  buttonClasses,
  selectClasses,
  stepConnectorClasses,
  stepLabelClasses,
  tableCellClasses,
  Button,
  tableClasses,
} from "@mui/material";
import { grey } from "@mui/material/colors";

const CustomStepConnector = styled(StepConnector)({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 23,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: "rgba(63, 108, 166,0.9)",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: "rgb(44, 75, 118)",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor: "#eaeaf0",
    borderRadius: 1,
  },
});

const CustomStepLabel = styled(StepLabel)(({ theme }) => ({
  [`& .${stepLabelClasses.label}`]: {
    [`&.${stepLabelClasses.completed}`]: {
      color: theme.typography.allVariants.color,
    },
  },
}));

const CustomStepIconStyled = styled("div")(({ ownerState }) => ({
  backgroundColor: "rgb(180,180,180)",
  zIndex: 1,
  color: "#fff",
  width: 50,
  height: 50,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    // backgroundColor: "rgba(63, 108, 166,0.9)",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.5)",
  }),
  ...(ownerState.completed && {
    backgroundColor: "rgb(44, 75, 118)",
  }),
}));

const ProfileImageStyled = styled("img")({
  width: 150,
  height: 150,
  borderRadius: 75,
  objectFit: "cover",
});

const SelectStyled = styled(Select)({
  [`& .${selectClasses.iconOutlined}`]: {
    fontSize: 22,
  },
  [`& .${selectClasses.iconStandard}`]: {
    fontSize: 22,
  },
});

const AutoCompleteStyled = styled(Autocomplete)({
  [`& .${autocompleteClasses.popupIndicator}`]: {
    fontSize: 22,
  },
});

const TableStyled = styled(Table)({
  [`&.${tableClasses.root}`]: {
    border: 1,
    borderColor: grey["400"],
    borderStyle: "solid",
  },
  [`&.${tableClasses.root} .${tableCellClasses.root}`]: {
    borderWidth: 1,
    borderColor: grey["400"],
  },
  [`&.${tableClasses.root} .${tableCellClasses.head}`]: {
    fontWeight: "bold",
  },
});

const DrawerHeaderStyled = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "space-between",
}));

const AccordionStyled = styled(props => <Accordion disableGutters elevation={0} square {...props} />)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&::before": {
    display: "none",
  },
}));

const BreadcrumbsStyled = styled(Breadcrumbs)({
  [`& .${breadcrumbsClasses.separator}`]: {
    marginLeft: 0,
    marginRight: 0,
  },
});

const LinkButtonStyled = styled(Button)({
  [`&.${buttonClasses.text}`]: {
    textTransform: "capitalize",
    minWidth: 0,
    margin: 0,
    padding: 0,
    fontWeight:'normal',
    ":hover": { textDecorationLine: "underline" },
  },
});
export {
  AccordionStyled,
  AutoCompleteStyled,
  CustomStepConnector,
  CustomStepIconStyled,
  CustomStepLabel,
  DrawerHeaderStyled,
  ProfileImageStyled,
  SelectStyled,
  TableStyled,
  BreadcrumbsStyled,
  LinkButtonStyled,
};
