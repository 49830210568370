import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import VPICompoundingLogo from "assets/vpi-images/vpi-logo.png";
import classNames from "classnames";
import { AlertMessage } from "components";
import { usePrintPage } from "components/Hooks";
import { TableStyled } from "components/StyledComponent";
import Spinner from "components/spinner";
import dayjs from "dayjs";
import { filter, find, flatten, map } from "lodash";
import React, { useCallback, useMemo } from "react";
import { useGetClinicPrescriptionDetailQuery } from "rtk";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);
dayjs.extend(timezone);

function OrderPackingSlipModal({ prescriptionId = "", handleClose }) {
  const { componentRef, printLoading, printModal } = usePrintPage(prescriptionId);
  const {
    data: invoiceData,
    isFetching,
    error,
  } = useGetClinicPrescriptionDetailQuery({ prescriptionId }, { skip: !prescriptionId });

  const errorMessage = useMemo(() => error?.data?.message || "", [error?.data?.message]);

  const isBulkInvoice = useMemo(
    () => invoiceData?.subPrescriptions?.length > 0,
    [invoiceData?.subPrescriptions?.length],
  );

  const closeModal = useCallback(() => {
    handleClose?.();
  }, [handleClose]);

  const renderSlipHeaderItem = useCallback(
    (title = "", value = "", hPosition = "left", vPosition = "top", rowCol = 4, rightCol = 4, leftCol = 8) => (
      <Grid
        container
        item
        sm={rowCol}
        padding={2}
        border={1}
        borderColor={grey["400"]}
        sx={theme => ({
          [theme.breakpoints.down("sm")]: {
            borderRight: 1,
            borderTop: hPosition === "center" ? 0 : 1,
            borderBottom: hPosition === "center" ? 0 : 1,
            borderColor: grey["400"],
          },
          [theme.breakpoints.up("sm")]: {
            borderRight: hPosition === "right" || vPosition === "middle" ? 1 : 0,
            borderTop: vPosition === "middle" ? 0 : 1,
            borderBottom: vPosition === "middle" ? 0 : 1,
            borderColor: grey["400"],
          },
        })}>
        <Grid item xs={rightCol}>
          <strong>{`${title}:`} </strong>
        </Grid>
        <Grid item xs={leftCol}>
          {value}
        </Grid>
      </Grid>
    ),
    [],
  );

  const renderDeliveryInfo = useMemo(() => {
    const shippingInfo = invoiceData?.shippingInfo;
    const isPickup = shippingInfo?.shippingMethod.toLowerCase().includes("pickup");
    return renderSlipHeaderItem(
      "Delivery Address",
      isPickup ? (
        "Pickup"
      ) : (
        <>
          <i>Ship To:</i> {shippingInfo?.shipTo}
          <br />
          {shippingInfo?.shippingAddress?.addressLine1}
          <br />
          {shippingInfo?.shippingAddress?.addressLine2 ?? "-"}
          <br />
          {shippingInfo?.shippingAddress?.city}, {shippingInfo?.shippingAddress?.state}
          <br />
          {shippingInfo?.shippingAddress?.zipcode}
        </>
      ),
      "left",
      "middle",
      12,
      1.25,
      0,
    );
  }, [invoiceData?.shippingInfo, renderSlipHeaderItem]);

  const prescriptionTableColumns = useMemo(
    () => (
      <TableHead>
        <TableRow>
          <TableCell style={{ minWidth: "105px", width: "105px" }}>Date</TableCell>
          <TableCell>RX #</TableCell>
          <TableCell>Refills</TableCell>
          <TableCell>Medication</TableCell>
          <TableCell>QTY</TableCell>
        </TableRow>
      </TableHead>
    ),
    [],
  );

  const renderInvoiceTable = useCallback(
    (invoiceTableData, invoiceRxPadProducts = [], invoiceDetail) => (
      <Grid item xs={12}>
        <TableContainer>
          <TableStyled size="small">
            {prescriptionTableColumns}
            <TableBody>
              {map(invoiceTableData, tableProduct => {
                const actualProduct = find(invoiceDetail?.products, { productId: tableProduct?.productId });
                return (
                  <TableRow key={`${actualProduct?._id}`}>
                    <TableCell style={{ textAlign: "center" }}>
                      {invoiceDetail?.prescriptionDate
                        ? `${dayjs(invoiceDetail?.prescriptionDate)
                            .tz("America/Denver")
                            .format("YYYY-MM-DD hh:mm:ss A")} [MST]`
                        : "-"}
                    </TableCell>
                    <TableCell>{actualProduct?.rxNumber ?? "-"}</TableCell>
                    <TableCell>{actualProduct?.refills ?? "-"}</TableCell>
                    <TableCell>
                      {actualProduct?.name ?? "-"}
                      <Typography>
                        <b>Sig:</b> {actualProduct?.sig?.toUpperCase() ?? "-"}
                      </Typography>
                    </TableCell>
                    <TableCell>{actualProduct?.quantity ?? "-"}</TableCell>
                  </TableRow>
                );
              })}
              {invoiceRxPadProducts?.map(customProduct => {
                return (
                  <TableRow key={`${customProduct?._id}`}>
                    <TableCell>
                      {invoiceDetail?.prescriptionDate
                        ? `${dayjs(invoiceDetail?.prescriptionDate)
                            .tz("America/Denver")
                            .format("YYYY-MM-DD hh:mm:ss A")} [MST]`
                        : "-"}
                    </TableCell>
                    <TableCell>{customProduct?.rxNumber ?? "-"}</TableCell>
                    <TableCell>{customProduct?.refills ?? "-"}</TableCell>
                    <TableCell>
                      {customProduct?.name ?? "-"}
                      <Typography>
                        <b>Sig:</b> {customProduct?.sig?.toUpperCase() ?? "-"}
                      </Typography>
                    </TableCell>
                    <TableCell>{customProduct?.quantity ?? "-"}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </TableStyled>
        </TableContainer>
      </Grid>
    ),
    [prescriptionTableColumns],
  );

  const renderInvoiceView = useCallback(
    invoiceDetail => {
      const patientIds = invoiceDetail?.patientIds ?? [];
      let invoiceProducts = invoiceDetail?.products;
      let invoiceRxPadProducts = invoiceDetail?.rxPadProducts || [];

      return map(patientIds, patientId => {
        // Following condition for find the product detail according to patientProducts.
        if (invoiceDetail?.patientsProducts.length > 0) {
          const filteredPatient = filter(invoiceDetail?.patientsProducts, { patientId });
          invoiceProducts = flatten(
            map(filteredPatient, filteredPatient =>
              map(filteredPatient?.products, patientProduct =>
                find(invoiceDetail.products, { productId: patientProduct.productId }),
              ),
            ),
          );
        }
        const patientList = invoiceDetail?.patients ?? invoiceDetail?.patientsDetail ?? [];
        const patientInfo = find(patientList, { id: patientId });
        return (
          <div key={patientId} className="page-break">
            <Card sx={{ boxShadow: 5 }} className={classNames({ "my-3": isBulkInvoice || patientId.length > 1 })}>
              <CardContent>
                <Grid container justifyContent={"space-between"} alignItems={"center"} className="mb-5">
                  <Grid item>
                    <img src={VPICompoundingLogo} alt="logo" height="40" />
                  </Grid>
                  <Grid item>
                    <Typography variant="h5">Packing Slip for {invoiceData?.clinicName}</Typography>
                  </Grid>
                </Grid>
                <Grid container>
                  {renderSlipHeaderItem(
                    "Patient Name",
                    `${patientInfo?.firstName ?? ""} ${patientInfo?.lastName ?? ""}`,
                  )}
                  {renderSlipHeaderItem("Clinic Name", invoiceDetail?.clinicLocationDetails?.locationName, "center")}
                  {renderSlipHeaderItem(
                    "Prescriber Name",
                    `${invoiceDetail?.providerDetails?.firstName ?? ""} ${
                      invoiceDetail?.providerDetails?.lastName ?? ""
                    }`,
                    "right",
                  )}
                </Grid>
                <Grid container>{renderDeliveryInfo}</Grid>
                <Grid container>{renderInvoiceTable(invoiceProducts, invoiceRxPadProducts, invoiceDetail)}</Grid>
              </CardContent>
            </Card>
          </div>
        );
      });
    },
    [invoiceData?.clinicName, isBulkInvoice, renderDeliveryInfo, renderInvoiceTable, renderSlipHeaderItem],
  );

  const renderInvoice = useMemo(() => {
    if (isBulkInvoice) {
      return map(invoiceData?.subPrescriptionList, subPrescription => (
        <React.Fragment key={subPrescription.id}>{renderInvoiceView(subPrescription)}</React.Fragment>
      ));
    }
    return renderInvoiceView(invoiceData);
  }, [invoiceData, isBulkInvoice, renderInvoiceView]);

  return (
    <Dialog open={Boolean(prescriptionId)} onClose={closeModal} scroll="paper" fullWidth maxWidth="xl">
      {printLoading && <Spinner />}
      <DialogTitle variant="h5">
        {"Packing Slip"}
        <IconButton
          aria-label="close"
          onClick={closeModal}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: theme => theme.palette.grey[500],
          }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        {isFetching ? (
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress />
          </Box>
        ) : (
          <Box ref={componentRef}>
            <AlertMessage isError msg={errorMessage} />
            {renderInvoice}
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button disabled={isFetching} variant="contained" className="pinkButton" onClick={printModal}>
          Print
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default React.memo(OrderPackingSlipModal);
