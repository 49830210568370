/* eslint-disable react-hooks/exhaustive-deps */
import { Box, FormControl, FormControlLabel, Grid, Radio, RadioGroup, Typography } from "@mui/material";
import { FormOutlineField, FormRadioField, FormSelectField } from "components";
import ValidAddressModal from "components/validAddressModal";
import { PatientApiActionContext, PatientApiStateContext, PatientStateContext } from "context";
import React, {
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { useForm, useWatch } from "react-hook-form";
import { USA_STATES, SHIPPING_METHOD } from "utils";

const PatientShipping = forwardRef(
  ({ formId, formData, setCustomError, patientDefaultAddress = {}, clinicLocationId = "" }, parentRef) => {
    const formRef = useRef(null);
    const [fedexShippingAddress, setFedexShippingAddress] = useState(null);
    const { clinicAddressRefetch, getShippingMethodOption, findShippingMethod, validateShippingAddress } =
      useContext(PatientApiActionContext);
    const { isPatientEdit } = useContext(PatientStateContext);
    const { patientData } = useContext(PatientApiStateContext);
    const { control, handleSubmit, setValue, getValues, trigger } = useForm({
      defaultValues: {
        defaultShippingOption: "",
        shippingMethod: "fedex",
      },
    });
    const showValidatedAddress = useMemo(() => fedexShippingAddress !== null, [fedexShippingAddress]);
    const watchShippingOption = useWatch({ control, name: "defaultShippingOption" });
    const shippingMethod = useWatch({ control, name: "shippingMethod" });
    const watchShippingPreference = useWatch({ control, name: "shippingPreference" });
    const disabledForm = useMemo(() => shippingMethod === "pickup", [shippingMethod]);
    const step=3;

    const shippingMethodOption = useMemo(
      () => getShippingMethodOption(shippingMethod),
      [getShippingMethodOption, shippingMethod],
    );

    const submitAction = useCallback(
      ({ shippingMethod: _, defaultShippingOption = "", ...data }, progressBarIndex = null, allowNavigation = true) => {
        const info = { ...data, isShipToClinic: defaultShippingOption === "isShipToClinic" };
        formData?.({ info }, formId, progressBarIndex, allowNavigation);
      },
      [formData, formId],
    );

    useEffect(() => {
      if (shippingMethodOption?.length > 0 && !watchShippingPreference) {
        setValue("shippingPreference", shippingMethodOption?.[0]?.value);
      }
    }, [shippingMethodOption, watchShippingPreference]);

    useEffect(() => {
      if (patientData?.id && isPatientEdit) {
        const formFields = getValues();
        for (const field in formFields) {
          if (field !== "shippingMethod") {
            let fieldValue = patientData?.[field] ?? "";
            if (field === "shippingPreference" && fieldValue) {
              const methodName = findShippingMethod(fieldValue);
              setValue("shippingMethod", methodName);
            }
            setValue(field, fieldValue);
          }
        }
        submitAction(getValues(), null, false);
      }
    }, [findShippingMethod, patientData?.id, isPatientEdit]);

    const getTypedAddress = useCallback(() => {
      const formValues = getValues();
      return {
        shippingAddressLine1: formValues?.shippingAddressLine1 ?? "",
        shippingAddressLine2: formValues?.shippingAddressLine2 ? formValues?.shippingAddressLine2 : "-",
        shippingCity: formValues?.shippingCity,
        shippingState: formValues?.shippingState,
        shippingZipcode: formValues?.shippingZipcode,
      };
    }, []);

    const getValidatedAddress = useCallback(async () => {
      const formValues = getValues();

      const body = {
        isUSPS: formValues?.shippingMethod === "usps",
        address: {
          streetLines: {
            addressLine1: formValues?.shippingAddressLine1 ?? "",
            addressLine2: formValues?.shippingAddressLine2 ? formValues?.shippingAddressLine2 : "-",
          },
          city: formValues?.shippingCity,
          state: formValues?.shippingState,
          postalCode: formValues?.shippingZipcode,
        },
      };
      try {
        const fedexShipping = await validateShippingAddress(body, true).unwrap();
        setFedexShippingAddress({ ...fedexShipping, zipcode: fedexShipping.postalCode });
      } catch (e) {
        setCustomError(e.data?.error);
      }
    }, []);

    const canNavigate = useCallback(
      progressBarIndex => {
        handleSubmit(data => submitAction(data, progressBarIndex))();
      },
      [handleSubmit, submitAction],
    );

    const requestFormSubmit = useCallback(async () => {
      // on submitting button call validate and on closing modal submit form.
      const formValidated = await trigger();
      if (disabledForm) {
        handleSubmit(data => submitAction(data, step))();
      } else if (formValidated) {
        getValidatedAddress();
      }
    }, [disabledForm]);

    const checkValidation = useCallback(async () => {
      const formValidated = await trigger();
      return formValidated;
    }, []);

    useImperativeHandle(parentRef, () => ({
      submitForm: requestFormSubmit,
      canNavigate: canNavigate,
      checkFilledForm: checkValidation,
    }));

    const resetShippingPreference = useCallback(() => setValue("shippingPreference", ""), []);

    const setShippingAddress = useCallback((patientAddress = {}) => {
      setValue("shippingAddressLine1", patientAddress?.addressLine1 ?? "");
      setValue("shippingAddressLine2", patientAddress?.addressLine2 ?? "");
      setValue("shippingCity", patientAddress?.city ?? "");
      setValue("shippingState", patientAddress?.state ?? "");
      setValue("shippingZipcode", patientAddress?.zipcode ?? "");
    }, []);

    const getClinicAddress = useCallback(async () => {
      try {
        const response = await clinicAddressRefetch({ locationId: clinicLocationId }, true).unwrap();
        if (response) {
          setShippingAddress(response);
        }
      } catch {}
    }, [clinicAddressRefetch, clinicLocationId, setShippingAddress]);

    const fillSameAddress = useCallback(
      (optionName = "") => {
        if (optionName === "sameAsDefaultPA") {
          setShippingAddress(patientDefaultAddress);
        } else if (optionName === "isShipToClinic") {
          getClinicAddress();
        }
      },
      [getClinicAddress, patientDefaultAddress, setShippingAddress],
    );

    const handleClick = useCallback(
      async event => {
        if (event.target.value === watchShippingOption) {
          setValue("defaultShippingOption", "");
        } else {
          setValue("defaultShippingOption", event.target.value);
          fillSameAddress(event.target.value);
        }
      },
      [fillSameAddress, setValue, watchShippingOption],
    );

    const closeValidateAddress = useCallback(() => {
      setFedexShippingAddress(null);
      setTimeout(() => {
        formRef.current?.requestSubmit?.();
      }, 350);
    }, []);

    const onClickValidate = useCallback(() => {
      setShippingAddress(fedexShippingAddress);
      closeValidateAddress();
    }, [fedexShippingAddress]);

    return (
      <>
        <Box id={formId} ref={formRef} component={"form"} onSubmit={handleSubmit(data => submitAction(data, null))}>
          <Grid container item marginY={2}>
            <FormControl>
              <RadioGroup name="defaultShippingOption" value={watchShippingOption}>
                {/* <FormControlLabel
                  disabled={disabledForm}
                  value="isShipToClinic"
                  control={<Radio style={{ fontSize: 20 }} onClick={handleClick} />}
                  label="Ship to Clinic"
                /> */}
                <FormControlLabel
                  disabled={disabledForm}
                  value="sameAsDefaultPA"
                  control={<Radio style={{ fontSize: 20 }} onClick={handleClick} />}
                  label="Same as default patient address"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid container columnSpacing={3} rowSpacing={1}>
            <Grid item xs={12} sm={6}>
              <FormOutlineField
                name={"shippingAddressLine1"}
                control={control}
                rules={{ required: disabledForm ? false : "Shipping Address is required." }}
                MuiFieldProps={{ label: "Shipping Address line 1 ", multiline: true, rows: 2, disabled: disabledForm }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormOutlineField
                name={"shippingAddressLine2"}
                control={control}
                MuiFieldProps={{ label: "Shipping Address line 2", multiline: true, rows: 2, disabled: disabledForm }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormOutlineField
                name={"shippingCity"}
                control={control}
                rules={{ required: disabledForm ? false : "City is required." }}
                MuiFieldProps={{ label: "City", disabled: disabledForm }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormSelectField
                name={"shippingState"}
                control={control}
                rules={{ required: disabledForm ? false : "State is required." }}
                MuiFieldProps={{ label: "State", optionArray: USA_STATES, disabled: disabledForm }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormOutlineField
                name={"shippingZipcode"}
                control={control}
                rules={{
                  required: disabledForm ? false : "Zip Code is required.",
                  pattern: { value: /^\d{5}(-\d{4})?$/, message: "Invalid ZipCode" },
                }}
                MuiFieldProps={{ label: "Zip Code", placeholder: "12345, 12345-1234", disabled: disabledForm }}
              />
            </Grid>
          </Grid>
          <Grid container marginY={2} columnSpacing={3} rowSpacing={1}>
            <Grid item xs={12} marginBottom={3}>
              <Typography variant="h5" fontWeight={"500"}>
                Shipping Method
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <FormRadioField
                control={control}
                name={"shippingMethod"}
                radioOptions={SHIPPING_METHOD}
                dependentChange={resetShippingPreference}
              />
            </Grid>
            <Grid item xs={12} sm={6} marginY={2}>
              <FormSelectField
                name={"shippingPreference"}
                control={control}
                rules={{ required: "Shipping preference is required." }}
                MuiFieldProps={{ label: "Shipping Preference", optionArray: shippingMethodOption }}
              />
            </Grid>
          </Grid>
        </Box>
        <ValidAddressModal
          isUSPS={shippingMethod === "usps"}
          showValidAddressModal={showValidatedAddress}
          fedexShippingAddress={fedexShippingAddress}
          patientShippingAddress={getTypedAddress()}
          onClickNotValidate={closeValidateAddress}
          onClickValidate={onClickValidate}
          closeModal={closeValidateAddress}
        />
      </>
    );
  },
);

export default React.memo(PatientShipping);
