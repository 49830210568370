import { ProductApi } from "../../rtkApi";
import { API_METHODS, CACHE_TAGS, PRODUCT_END_POINTS } from "../../rtkConstant";

const ProductMutation = ProductApi.injectEndpoints({
  endpoints: builder => ({
    toggleProductFavorite: builder.mutation({
      query: body => ({
        url: PRODUCT_END_POINTS.TOGGLE_FAVORITE_PRODUCT,
        method: API_METHODS.POST,
        body: body,
      }),
      invalidatesTags: (result, error, { productId }) => [{ type: CACHE_TAGS.PRODUCT, id: productId }],
    }),
  }),
});
export const { useToggleProductFavoriteMutation } = ProductMutation;
