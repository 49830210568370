import RefreshIcon from "@mui/icons-material/Refresh";
import {
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Tooltip,
  Typography,
} from "@mui/material";
import { flexRender, getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { ListingSearch, MuiAlertMessage, MuiTableCollapse, PrescriptionOrderButton } from "components";
import { filter } from "lodash";
import React, { forwardRef, useCallback, useImperativeHandle, useMemo } from "react";
import { useLazyGetPatientsListQuery } from "rtk";
import HeaderInvoiceCheckBox from "screens/common/Invoices/InvoiceList/HeaderInvoiceCheckBox";
import InvoiceCheckBox from "screens/common/Invoices/InvoiceList/InvoiceCheckBox";
import { usePatientListing } from "screens/common/Patients/Hook";
import { PATIENT_FILTER_OPTIONS, ROWS_LIMIT, columnHelper } from "utils";
import Spinner from "../../spinner";

function PatientListingTableView(
  {
    tableHeaderButton,
    tableHeaders,
    isLoading = false,
    error = "",
    success = "",
    prescriptionClinic = "",
    enableRowSelection = false,
  },
  ref,
) {
  const {
    clinicId,
    sorting,
    rowLimit,
    tablePage,
    showSpinner,
    rowSelection,
    errorMessage,
    totalRecords,
    isClinicSide,
    patientListing,
    selectedFilter,
    searchKeyword,
    isPrescription,
    selectedPatient,
    setSorting,
    applyFilter,
    clearFilter,
    refetchList,
    updateKeyword,
    changeRowLimit,
    setRowSelection,
    updateFilterType,
    setSelectedPatient,
    handleChangePage,
  } = usePatientListing({
    listingQuery: useLazyGetPatientsListQuery,
    propsClinicId: prescriptionClinic,
  });

  const combineLoading = useMemo(() => showSpinner || isLoading, [isLoading, showSpinner]);
  const combinedErrorMessage = useMemo(() => errorMessage || error || "", [error, errorMessage]);

  const tableHeaderView = useMemo(() => {
    if (!prescriptionClinic && (clinicId || isClinicSide)) {
      return (
        <Grid container className={`px-3 my-4  justify-content-between`}>
          <Grid item xs={4}>
            <PrescriptionOrderButton
              clinicId={clinicId}
              showView={isPrescription || clinicId || isClinicSide}
              selectedPatients={selectedPatient}
            />
          </Grid>
          <Grid item className="justify-content-end">
            {tableHeaderButton}
          </Grid>
        </Grid>
      );
    }
  }, [prescriptionClinic, clinicId, isClinicSide, isPrescription, selectedPatient, tableHeaderButton]);

  const renderHeaderCell = useCallback(header => {
    const columnSortDirection = header.column.getIsSorted();
    const isCheckBoxCell = header.id === "checkbox";

    const cellView = (
      <Typography key={`label-${header.id}`} variant="tableHeader" noWrap>
        {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
      </Typography>
    );
    let headerCellView = cellView;
    if (!isCheckBoxCell) {
      headerCellView = (
        <TableSortLabel
          key={`Sort-${header.id}`}
          disabled={!header.column.getCanSort()}
          hideSortIcon={!header.column.getCanSort()}
          active={Boolean(columnSortDirection)}
          direction={columnSortDirection || "asc"}
          onClick={() => header.column.toggleSorting(columnSortDirection !== "desc")}>
          {cellView}
        </TableSortLabel>
      );
    }

    return (
      <TableCell
        key={header.id}
        style={header.column.columnDef.headerStyle ?? { minWidth: 150 }}
        sortDirection={header.column.getIsSorted()}>
        {headerCellView}
      </TableCell>
    );
  }, []);

  const onRowSelection = useCallback(
    (rowData, isSelected) =>
      setSelectedPatient(prev => (isSelected ? filter(prev, x => x.id !== rowData.id) : [...prev, rowData])),
    [],
  );

  const onAllRowSelection = useCallback(
    isSelected => setSelectedPatient(isSelected ? [] : patientListing),
    [patientListing],
  );

  const getSelectedPatientList = useCallback(() => selectedPatient, [selectedPatient]);
  const setSelectedPatientList = useCallback(
    (patientList = []) => {
      setSelectedPatient(patientList);
      const rowSelectionObj = patientList.reduce((prevObj, currObj) => {
        prevObj[currObj?.id] = true;
        return prevObj;
      }, {});
      setRowSelection(rowSelectionObj);
    },
    [setSelectedPatient],
  );

  const checkBox = useMemo(() => {
    if (isClinicSide || isPrescription || prescriptionClinic) {
      return columnHelper.accessor("checkbox", {
        headerStyle: { width: 40, paddingLeft: 0, paddingRight: 0 },
        enableSorting: false,
        header: ({ table }) => (
          <HeaderInvoiceCheckBox tableRow={table} onAllRowSelection={onAllRowSelection} isSelectableAll />
        ),
        cell: ({ row }) => <InvoiceCheckBox rowData={row.original} tableRow={row} onRowSelection={onRowSelection} />,
      });
    }
    return null;
  }, [isClinicSide, isPrescription, prescriptionClinic, onAllRowSelection, onRowSelection]);

  const patientTableHeader = useMemo(() => [checkBox, ...tableHeaders].filter(Boolean), [checkBox, tableHeaders]);

  const reactTable = useReactTable({
    data: patientListing,
    columns: patientTableHeader,
    getCoreRowModel: getCoreRowModel(),
    manualSorting: true,
    getRowId: row => row.id,
    onSortingChange: setSorting,
    onRowSelectionChange: setRowSelection,
    enableRowSelection: enableRowSelection && Boolean(patientListing.length),
    manualPagination: true,
    pageCount: totalRecords ?? -1,
    state: {
      sorting,
      rowSelection,
    },
  });

  useImperativeHandle(ref, () => ({
    setSelectedPatientList,
    getSelectedPatientList,
  }));

  return (
    <>
      {combineLoading && <Spinner />}
      <MuiAlertMessage message={combinedErrorMessage || success} isError={combinedErrorMessage} />
      {tableHeaderView}
      <ListingSearch
        searchKeyword={searchKeyword}
        selectedFilter={selectedFilter}
        applySearch={applyFilter}
        clearFilter={clearFilter}
        updateKeyword={updateKeyword}
        updateFilterType={updateFilterType}
        filterOptions={PATIENT_FILTER_OPTIONS}
      />
      <Grid container justifyContent={"flex-end"} style={{ marginRight: 15 }} className="mb-1">
        <Tooltip title="Refresh">
          <Button variant="contained" onClick={refetchList}>
            <RefreshIcon style={{ width: 20, height: 20 }} />
          </Button>
        </Tooltip>
      </Grid>
      <TableContainer style={{ maxHeight: "70vh" }}>
        <Table size="small" stickyHeader>
          <TableHead>
            {reactTable.getHeaderGroups().map(headerGroup => (
              <TableRow key={headerGroup.id}>{headerGroup.headers.map(header => renderHeaderCell(header))}</TableRow>
            ))}
          </TableHead>
          <TableBody>
            {reactTable.getRowModel().rows.map(row => (
              <MuiTableCollapse
                key={row.id}
                showNested={false}
                readOnly={true}
                row={row}
                showColoredRows={false}
                showMarkAllBtn={!row.getCanSelect()}
                isChecked={row.getIsSelected()} // This is important for re-rendering of row to represent checkbox changes
                tableHeader={patientTableHeader.length} // This is import for re-rendering of row to represent checkbox in first column when conditional checkbox header added
              />
            ))}
          </TableBody>
        </Table>
        {patientListing?.length === 0 && !combineLoading ? (
          <p className="w-100 text-center m-5">No patients data found!</p>
        ) : null}
      </TableContainer>
      <TablePagination
        component="div"
        showFirstButton
        showLastButton
        page={tablePage}
        count={totalRecords}
        rowsPerPage={rowLimit}
        rowsPerPageOptions={ROWS_LIMIT}
        onPageChange={handleChangePage}
        onRowsPerPageChange={changeRowLimit}
        style={{ alignItems: "center", marginBottom: 0 }}
      />
    </>
  );
}
export default React.memo(forwardRef(PatientListingTableView));
