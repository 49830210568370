import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DeleteIcon from "@mui/icons-material/Delete";
import { Box, Button, IconButton, Paper, Tooltip } from "@mui/material";
import { PatientListingTableView } from "components";
import NavBar from "components/NavBar";
import { includes } from "lodash";
import React, { useCallback, useMemo, useRef } from "react";
import MetaTags from "react-meta-tags";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { Container } from "reactstrap";
import { useDeletePatientMutation } from "rtk";
import { userInfoSelector, userRoleSelector } from "selectors";
import { ADMIN_PATIENT_COLUMNS, ADMIN_PATIENT_PRESCRIPTION_COLUMNS, USER_ROLES, columnHelper } from "utils";
import { usePatientAction } from "./Hook";

import { useLocation, useParams } from "react-router-dom";

function PatientsList() {
  const history = useHistory();
  const uploadButtonRef = useRef(null);
  const userInfo = useSelector(userInfoSelector);
  const userRole = useSelector(userRoleSelector);
  const { clinicId = "" } = useParams();
  const isClinicSide = useMemo(() => userInfo?.clinicId ?? "", [userInfo?.clinicId]);
  const { state: { isPrescription = false, clinicLocationId: routeClinicLocationId = "" } = {} } = useLocation();
  const clinicLocationId = useMemo(() => {
    let locationId = routeClinicLocationId;
    if (userRole === USER_ROLES.CLINIC_SUPER_USER || userInfo?.isSuperUserSameAsProvider) {
      locationId = userInfo?.clinicLocationIds?.[0];
    }
    return locationId;
  }, [routeClinicLocationId, userInfo?.clinicLocationIds, userInfo?.isSuperUserSameAsProvider, userRole]);

  const { mutateError, mutateLoading, successMessage, fileUploading, exportPatientList, toggleConfirmationModal } =
    usePatientAction({
      listingName: "PatientListing",
      listingMutation: useDeletePatientMutation,
    });

  const deletionAllowed = useMemo(
    () =>
      includes(
        [
          USER_ROLES.ADMIN_SUPER_USER,
          USER_ROLES.PHARMACIST,
          USER_ROLES.RX_TECH,
          USER_ROLES.CLINIC_SUPER_USER,
          USER_ROLES.CLINIC_SUPPORT,
          USER_ROLES.CLINIC_PROVIDER,
        ],
        userRole,
      ),
    [userRole],
  );

  const actonButtonCell = useCallback(
    rowData => {
      return (
        <Box component={"span"} className="d-flex justify-content-end">
          {isClinicSide && userRole !== USER_ROLES.CLINIC_SUPPORT && rowData.row.original?.lastPrescriptionId && (
            <Link
              to={{
                pathname: `/reorder-prescription/` + rowData.row.original?.lastPrescriptionId,
                state: { id: rowData.row.original?.id, isReview: true },
              }}
              className="yellowButton text-nowrap">
              Reorder Prescription
            </Link>
          )}
          <Link to={"/patient-profile/" + rowData.row.original.id} className="pinkButton mx-2">
            Profile
          </Link>
          {!isPrescription && (
            <>
              <Tooltip title="Edit">
                <IconButton
                  onClick={() =>
                    history.push({
                      pathname: `/edit-patient/${rowData.row.original.id}`,
                      state: { clinicId, clinicLocationId },
                    })
                  }
                  style={{ width: 30, height: 30 }}>
                  <i className="fas fa-edit medText" aria-hidden="true" style={{ fontSize: 15 }} />
                </IconButton>
              </Tooltip>
              {deletionAllowed && (
                <Tooltip title="Delete">
                  <IconButton
                    onClick={() => toggleConfirmationModal(rowData.row.original.id)}
                    style={{ width: 30, height: 30 }}>
                    <DeleteIcon color="error" style={{ width: 25, height: 25 }} />
                  </IconButton>
                </Tooltip>
              )}
            </>
          )}
        </Box>
      );
    },
    [isClinicSide, userRole, isPrescription, deletionAllowed, history, toggleConfirmationModal],
  );

  const columnAction = useMemo(
    () =>
      columnHelper.accessor("actions", {
        header: "Actions",
        enableSorting: false,
        headerStyle: { minWidth: "180px", textAlign: "center" },
        cell: actonButtonCell,
      }),
    [actonButtonCell],
  );

  const clinicPatientTableHeader = useMemo(
    () => [...ADMIN_PATIENT_COLUMNS, ...ADMIN_PATIENT_PRESCRIPTION_COLUMNS, columnAction].filter(Boolean),
    [columnAction],
  );

  const tableHeaderButton = useMemo(() => {
    const buttonArray = [];
    if (isClinicSide) {
      buttonArray.push(
        <button
          key={"upload_btn"}
          className="pinkButton"
          onClick={e => {
            e.preventDefault();
            uploadButtonRef.current.click();
          }}>
          Upload File
        </button>,
      );
      buttonArray.push(
        <input
          key={"upload_input"}
          ref={uploadButtonRef}
          accept=".csv"
          onChange={fileUploading}
          name="patient_file"
          className="d-none "
          type="file"
          id="patientFile"
        />,
      );

      buttonArray.push(
        <button key={"export_btn"} className="pinkButton m-1" onClick={exportPatientList}>
          Export CSV
        </button>,
      );
    }
    if (!isPrescription) {
      buttonArray.push(
        <Link
          key={"link_add_btn"}
          to={{
            pathname: `/add-patient`,
            state: {
              clinicId,
              clinicLocationId,
            },
          }}
          className="pinkButton">
          Add patient
        </Link>,
      );
    }
    return buttonArray;
  }, [clinicId, clinicLocationId, exportPatientList, fileUploading, isClinicSide, isPrescription]);

  return (
    <React.Fragment>
      <NavBar />
      <div className="page-content">
        <MetaTags>
          <title>Patients | VPI Compounding</title>
        </MetaTags>
        <Container fluid>
          <Button color="info" onClick={() => history.goBack()} startIcon={<ArrowBackIcon />}>
            Back
          </Button>
          <h4 className="p-3 text-muted">PATIENTS</h4>
          <Paper className="p-3 mt-2 mx-1" elevation={1}>
            <PatientListingTableView
              enableRowSelection
              isLoading={mutateLoading}
              success={successMessage}
              error={mutateError}
              tableHeaderButton={tableHeaderButton}
              tableHeaders={clinicPatientTableHeader}
            />
          </Paper>
        </Container>
      </div>
    </React.Fragment>
  );
}

export default PatientsList;
