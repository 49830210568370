import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from "@mui/material";
import React, { useCallback, useRef } from "react";
import { ClinicLocationCard } from "screens";
import { STEPPER_FORMS } from "utils";

function PrescriptionBillingCardModal({ showModal = false, handleClose, setNewCreditCard }) {
  const modalFormRef = useRef(null);

  const closeModal = useCallback(() => {
    handleClose?.();
  }, [handleClose]);

  const updateAction = useCallback(e => {
    e.preventDefault();
    modalFormRef.current?.submitForm?.();
  }, []);

  return (
    <Dialog open={showModal} onClose={closeModal} scroll="paper" fullWidth maxWidth="xl">
      <DialogTitle variant="h5">
        Add New Credit Card
        <IconButton
          aria-label="close"
          onClick={closeModal}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: theme => theme.palette.grey[500],
          }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Box className="px-5 mt-4">
          <ClinicLocationCard
            singleCardOnly
            ref={modalFormRef}
            formId={STEPPER_FORMS.CREDIT_INFO}
            formData={setNewCreditCard}
          />
          ;
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" className={"mx-2"} onClick={updateAction}>
          {"Add Credit Card"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default React.memo(PrescriptionBillingCardModal);
