import { filter, forEach, set } from "lodash";
import { PatientApi } from "../../rtkApi";
import { API_METHODS, CACHE_TAGS, PAGE_TO_ENDPOINT, PATIENT_END_POINT, UPLOADS_END_POINT } from "../../rtkConstant";

const multiFilesKeyNames = ["labWork", "otherDocuments", "physicals", "soapNotes"];
const singleFileKeyName = "driverLicenseFileUrl";

const PatientMutation = PatientApi.injectEndpoints({
  endpoints: builder => ({
    insertPatient: builder.mutation({
      queryFn: async (apiArgs, { dispatch, getState }, _, baseQuery) => {
        try {
          const user = getState().AuthSlice || {};
          if (user?.userData) {
            const clinicId = apiArgs?.clinicId || user?.userData?.clinicId;
            const organizedBody = { ...apiArgs, clinicId, userId: user?.userData?.id };
            const url = apiArgs?.patientId ? PATIENT_END_POINT.EDIT_PATIENT : PATIENT_END_POINT.ADD_PATIENT;
            // Get the file that has to be uploaded
            const fileFormData = new FormData();
            for (const keyName of multiFilesKeyNames) {
              const fileArray = organizedBody?.patient?.[keyName];
              const filteredDoc = filter(fileArray, doc => {
                if (doc instanceof File) {
                  fileFormData.append(keyName, doc);
                  return false;
                }
                return true;
              });
              set(organizedBody, ["patient", keyName], filteredDoc);
            }

            // Uploaded multi Docs
            const fileUploadedResult = await dispatch(
              PatientApi.endpoints.uploadPatientDocuments.initiate(fileFormData),
            ).unwrap();

            if (fileUploadedResult.response) {
              forEach(fileUploadedResult.response, (value, key) => {
                set(organizedBody, ["patient", key], [...(organizedBody.patient?.[key] ?? []), ...value]);
              });
            }

            // Upload the driverLicense File
            if (organizedBody.patient?.[singleFileKeyName] instanceof File) {
              const licenseFormData = new FormData();
              licenseFormData.append("file", organizedBody.patient?.[singleFileKeyName]);
              const fileUploadedResult = await dispatch(
                PatientApi.endpoints.uploadPatientProfile.initiate(licenseFormData),
              ).unwrap();
              if (fileUploadedResult?.accessUrl) {
                set(organizedBody, ["patient", singleFileKeyName], fileUploadedResult?.accessUrl);
              }
            }

            const response = await baseQuery({
              url,
              method: API_METHODS.POST,
              body: organizedBody,
            });
            return response;
          } else {
            return {
              error: {
                data: { message: "Unauthenticated user" },
              },
            };
          }
        } catch (error) {
          return {
            error: {
              data: { message: error?.data?.message ?? "Unknown error occur" },
            },
          };
        }
      },
      async onQueryStarted(apiArgs, { queryFulfilled, dispatch }) {
        try {
          await queryFulfilled;
          if (apiArgs?.patientId) {
            dispatch(PatientApi.util.invalidateTags([{ type: CACHE_TAGS.PATIENT, id: apiArgs?.patientId }]));
          } else {
            dispatch(PatientApi.util.invalidateTags([CACHE_TAGS.PATIENT]));
          }
        } catch {}
      },
    }),
    deletePatient: builder.mutation({
      queryFn: async ({ pageName, ...apiArgs }, queryApi, _, baseQuery) => {
        try {
          const user = queryApi.getState().AuthSlice || {};
          if (user?.userData) {
            const organizedBody = { ...apiArgs, userId: user?.userData?.id };
            const response = await baseQuery({
              url: PATIENT_END_POINT.DELETE_PATIENT,
              method: API_METHODS.DELETE,
              body: organizedBody,
            });
            return response;
          } else {
            return {
              error: {
                data: { message: "Unauthenticated user" },
              },
            };
          }
        } catch {}
      },
      async onQueryStarted({ pageName = "", patientId = "" }, { queryFulfilled, dispatch, getState }) {
        try {
          if (pageName) {
            await queryFulfilled;
            const listingEndpoint = PAGE_TO_ENDPOINT[pageName];
            let queryCache = PatientApi.util.selectInvalidatedBy(getState(), [
              { type: CACHE_TAGS.PATIENT, id: patientId },
            ]);
            if (queryCache.length > 1) {
              queryCache = queryCache.find(query => query.endpointName === listingEndpoint);
            } else {
              queryCache = queryCache[0];
            }

            dispatch(
              PatientApi.util.updateQueryData(listingEndpoint, queryCache.originalArgs, listData => {
                return {
                  ...listData,
                  patients: listData.patients?.filter(record => record?.id !== patientId),
                };
              }),
            );
          }
        } catch {}
      },
    }),
    uploadPatientFile: builder.mutation({
      query: fileData => ({
        url: PATIENT_END_POINT.CLINIC_PATIENT_UPLOAD,
        method: "POST",
        credentials: "include",
        body: fileData,
        Headers: {
          "content-type": "multipart/form-data",
        },
      }),
    }),
    uploadPatientProfile: builder.mutation({
      query: fileData => ({
        url: UPLOADS_END_POINT.UPLOAD_FILE,
        method: "POST",
        credentials: "include",
        body: fileData,
        Headers: {
          "content-type": "multipart/form-data",
        },
      }),
    }),
    uploadPatientDocuments: builder.mutation({
      query: filesData => ({
        url: UPLOADS_END_POINT.MULTIPLE_FILES,
        method: "POST",
        credentials: "include",
        body: filesData,
        Headers: {
          "content-type": "multipart/form-data",
        },
      }),
    }),
  }),
});

export const {
  useUploadPatientFileMutation,
  useDeletePatientMutation,
  useUploadPatientProfileMutation,
  useUploadPatientDocumentsMutation,
  useInsertPatientMutation,
} = PatientMutation;
