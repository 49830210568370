/* eslint-disable react-hooks/exhaustive-deps */
import { HorizontalRule } from "@mui/icons-material";
import { Box, Grid, TextField, Typography } from "@mui/material";
import { FormCheckBox, FormOutlineField } from "components";
import { EXOSTAR_ENABLED_CLINICS, EXOSTAR_LICENSE_KEY } from "components/Exostar/ExostarConstants";
import { GoogleAuthToggle } from "components/GoogleAuthModal";
import { StaffApiStateContext, StaffStateContext } from "context";
import React, { forwardRef, useCallback, useContext, useEffect, useImperativeHandle, useMemo, useRef } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useSelector } from "react-redux";
import { Button } from "reactstrap";
import { userInfoSelector } from "selectors";

const StaffPortalInfo = forwardRef(({ formId, formData, clinicId }, parentRef) => {
  const formRef = useRef(null);
  const userInfo = useSelector(userInfoSelector);
  const { isStaffEdit } = useContext(StaffStateContext);
  const { staffData } = useContext(StaffApiStateContext);
  const { control, handleSubmit, getValues, setValue, reset, trigger } = useForm({
    defaultValues: {
      isToGivePortalAccess: true,
      allowExostar: false,
    },
  });
  const clinicSide = userInfo.clinicId;
  const watchAllowExostar = useWatch({ control, name: "allowExostar" });
  const ownUser = useMemo(() => staffData?.id === userInfo?.id, [userInfo?.id, staffData?.id]);

  const submitAction = useCallback(
    (data, progressBarIndex = null, allowNavigation = true) => {
      // Conditionally include exostar_netsuit_key
      const updatedData = { ...data };
      if (!watchAllowExostar) {
        delete updatedData.exostar_netsuit_key;
      }
      formData?.({ info: updatedData }, formId, progressBarIndex, allowNavigation);
    },
    [formData, formId, watchAllowExostar],
  );

  useEffect(() => {
    if (isStaffEdit && staffData?.id) {
      const formFields = getValues();
      for (const field in formFields) {
        let fieldValue = staffData?.[field] ?? "";
        setValue(field, fieldValue);
      }
      submitAction(getValues(), null, false);
    }
  }, [isStaffEdit, staffData]);

  const canNavigate = useCallback(
    progressBarIndex => {
      handleSubmit(data => submitAction(data, progressBarIndex))();
    },
    [handleSubmit, submitAction],
  );

  const checkValidation = useCallback(async () => {
    const formValidated = await trigger();
    return formValidated;
  }, []);

  const requestFormSubmit = useCallback(() => formRef.current?.requestSubmit?.(), []);

  useImperativeHandle(parentRef, () => ({
    submitForm: requestFormSubmit,
    canNavigate: canNavigate,
    checkFilledForm: checkValidation,
  }));

  const checkBoxBtn = (name, index) => {
    setValue(name, index);
  };

  const handlePurchase = () => {
    window.open(
      EXOSTAR_LICENSE_KEY,
      "_blank",
    );
  };
  
  return (
    <Box id={formId} ref={formRef} component={"form"} onSubmit={handleSubmit(data => submitAction(data, null))}>
      <Grid container marginY={2} columnSpacing={3} rowSpacing={1}>
        <Grid item xs={12}>
          <FormCheckBox
            control={control}
            checkBoxBtn={checkBoxBtn}
            name="isToGivePortalAccess"
            MuiFieldProps={{ label: "Portal Access" }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <FormOutlineField
            name="password"
            control={control}
            MuiFieldProps={{ isPassword: true, label: "Password", type: "password", autoComplete: "new-password" }}
          />
        </Grid>
        {!clinicSide && EXOSTAR_ENABLED_CLINICS.includes(clinicId) && (
          <>
            <Grid item xs={12}>
              <Button className="blueButton" onClick={handlePurchase}>
                Purchase License
              </Button>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <FormCheckBox
                control={control}
                checkBoxBtn={checkBoxBtn}
                name="allowExostar"
                MuiFieldProps={{ label: "Exostar Verification" }}
              />
            </Grid>
            {watchAllowExostar && (
              <Grid item xs={12} sm={6} md={4}>
                <FormOutlineField
                  control={control}
                  name="exostar_netsuit_key"
                  MuiFieldProps={{ label: "License Key", required: watchAllowExostar }}
                />
              </Grid>
            )}
          </>
        )}
      </Grid>
    </Box>
  );
});

export default React.memo(StaffPortalInfo);
