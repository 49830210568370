import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { flexRender, getCoreRowModel, useReactTable } from "@tanstack/react-table";
import classNames from "classnames";
import Spinner from "components/spinner";
import { flatten, has, includes, some } from "lodash";
import React, { useCallback, useEffect, useMemo } from "react";
import { useChangeRxStatusMutation } from "rtk";
import { RX_STATUS_COMPLETED } from "rtk/rtkConstant";
import { PrescriptionOrderInput } from "screens/admin/Prescriptions";
import {
  ADMIN_APPROVED_LISTING,
  LISTING_NAME,
  NESTED_BULK_INVOICE_TABLE_HEADER,
  NESTED_BULK_TABLE_HEADER,
  NESTED_TABLE_HEADER,
} from "../../utils";
import { RenderRxInput } from "../RenderRxInput";
import { PrescriptionStatusDD } from "../dropdowns";

function MuiNestedTable({
  originalRow = {},
  nestedEditable,
  showMarkAllBtn,
  pageName,
  readOnly,
  filterRxStatus,
  setCustomError,
  toggleUpdateModal,
  incompleteRx = false,
}) {
  const {
    rxPadProducts,
    products,
    patientsDetail,
    patientsProducts,
    paymentStatus,
    subPrescriptions = [],
    subPrescriptionList = [],
  } = originalRow;
  //Adding Patient Ids check
  const isBulkOrder = originalRow?.patientIds?.length > 1;
  const [rxStatusMutate, { isLoading: statusLoading, error: statusError }] = useChangeRxStatusMutation();
  const statusErrorMessage = useMemo(() => statusError?.data?.message || "", [statusError?.data?.message]);

  useEffect(() => {
    if (statusErrorMessage) {
      setCustomError(statusErrorMessage);
    }
  }, [statusErrorMessage, setCustomError]);

  const markAllReceived = useCallback(() => {
    rxStatusMutate({
      isToReceivedAll: true,
      rxStatus: RX_STATUS_COMPLETED[0],
      bulkPresId: originalRow?.id,
      prescriptionId: originalRow?.id,
      pageName,
    });
  }, [originalRow?.id, pageName, rxStatusMutate]);

  const mainPrescriptionId = useMemo(() => originalRow?.id, [originalRow?.id]);

  const prescriptionListingData = useCallback(
    (
      item,
      customItems,
      rxStatusPatient = "",
      rxNumberPatient = "",
      patientObj = {},
      invoice = {},
      subPrescriptionId = mainPrescriptionId,
    ) => {
      const tableRowData = [];
      const { patientId = "", patientName = "", ...restPatient } = patientObj;
      const { showInvoice = false, invoiceNumber = "" } = invoice;
      item.forEach(data => {
        const rxStatus = rxStatusPatient || data?.rxStatus;
        const rxNumber = rxNumberPatient || data?.rxNumber;
        const dataObj = {
          id: data?._id,
          subPrescriptionId,
          name: data.name,
          rxStatus,
          rxNumber,
          family: data?.family,
          subCategory1: data?.subCategory1,
          ...restPatient,
        };
        if (showInvoice) {
          dataObj["invoiceNumber"] = invoiceNumber;
        }
        if (patientId) {
          dataObj["patientName"] = patientName;
          dataObj["patientId"] = patientId;
        }
        tableRowData.push(dataObj);
      });
      customItems?.forEach(product => {
        const rxStatus = rxStatusPatient || product?.rxStatus;
        const rxNumber = rxNumberPatient || product.rxNumber;
        const dataObj = { family: product?.family, name: product.name, subPrescriptionId, rxStatus, rxNumber };
        dataObj["isCustomOrder"] = true;
        if (showInvoice) {
          dataObj["invoiceNumber"] = invoiceNumber;
        }
        if (patientId) {
          dataObj["patientName"] = patientName;
          dataObj["patientId"] = patientId;
        }
        tableRowData.push(dataObj);
      });
      return tableRowData;
    },
    [mainPrescriptionId],
  );

  const prescriptionBulkProduct = useCallback(
    (
      isBulkOrder,
      patientsProducts,
      patientsDetail,
      customItem,
      item = [],
      invoice = undefined,
      subPrescriptionId = mainPrescriptionId,
    ) => {
      let productTableData = [];
      if (isBulkOrder) {
        if (patientsProducts?.length > 0) {
          productTableData = patientsProducts.map(patientProduct => {
            const patientObj = {
              patientId: patientProduct?.patientId,
              patientName: patientProduct?.patientName,
            };
            if (has(patientProduct, "rxOrderInput") && pageName === LISTING_NAME.ADMIN_RX_WORKFLOW) {
              patientObj["rxOrderInput"] = patientProduct.rxOrderInput;
            }
            const productInfo = patientProduct.products?.[0];
            const filterProduct = item.filter(itemRow => itemRow.productId === productInfo?.productId);
            const rxStatusPatient = productInfo?.rxStatus || filterProduct?.rxStatus;
            const rxNumberPatient = productInfo?.rxNumber || filterProduct?.rxNumber;
            return prescriptionListingData(
              filterProduct,
              customItem,
              rxStatusPatient,
              rxNumberPatient,
              patientObj,
              invoice,
              subPrescriptionId,
            );
          });
        } else if (patientsDetail?.length) {
          productTableData = patientsDetail.map(patient => {
            const patientObj = {
              patientId: patient?.id,
              patientName: (patient?.firstName || "") + " " + (patient?.lastName || ""),
            };

            return prescriptionListingData(item, customItem, "", "", patientObj, invoice, subPrescriptionId);
          });
        }
      } else {
        const patient = patientsDetail?.[0] ?? {};
        const patientObj = {
          patientId: patient?.id ?? "-",
          patientName: (patient?.firstName || "") + " " + (patient?.lastName || ""),
        };
        productTableData = prescriptionListingData(item, customItem, "", "", patientObj, invoice, subPrescriptionId);
      }
      return flatten(productTableData);
    },
    [mainPrescriptionId, prescriptionListingData],
  );

  const nestedTableData = useMemo(() => {
    let bulkTableData = [];
    //First it should check if it is combined invoice or ot
    /*If it is combined invoice then it traverse the list and then checks bulkOrder (multiple patients)
    If it is bulkOrder then it should retrieve RxNumber and statuses from patientDetails products
    Else it should retrieve directly from products List.*/
    // For not combined invoices , it first checks it is multiple patients then do the.... else do that..
    if (subPrescriptionList?.length > 0) {
      bulkTableData = subPrescriptions.map((subPrescription, i) => {
        const item1 = subPrescriptionList?.filter(subPres => subPres.id === subPrescription)?.[0];
        const isMultiplePatientSubPrescription = item1?.patientIds?.length > 1;
        const customItems = item1?.rxPadProducts;
        const invoice = { showInvoice: true, invoiceNumber: item1?.invoiceNumber };
        return prescriptionBulkProduct(
          isMultiplePatientSubPrescription,
          item1?.patientsProducts,
          item1?.patientsDetail,
          customItems,
          item1?.products,
          invoice,
          subPrescription, // Sub Prescription Id
        );
      });
    } else {
      if (isBulkOrder) {
        if (patientsDetail?.length > 0) {
          bulkTableData = prescriptionBulkProduct(
            isBulkOrder,
            patientsProducts,
            patientsDetail,
            rxPadProducts,
            products,
          );
        }
      } else {
        bulkTableData = prescriptionListingData(products, rxPadProducts);
      }
    }
    return flatten(bulkTableData);
  }, [
    isBulkOrder,
    patientsDetail,
    patientsProducts,
    prescriptionBulkProduct,
    prescriptionListingData,
    products,
    rxPadProducts,
    subPrescriptionList,
    subPrescriptions,
  ]);

  const showNestedRxOrderInput = useMemo(
    () => isBulkOrder && subPrescriptionList.length < 1 && patientsDetail?.length > 0,
    [isBulkOrder, patientsDetail?.length, subPrescriptionList.length],
  );

  const nestedColumn =
    subPrescriptions?.length > 0
      ? NESTED_BULK_INVOICE_TABLE_HEADER
      : isBulkOrder
      ? NESTED_BULK_TABLE_HEADER
      : NESTED_TABLE_HEADER;

  const renderStatus = useMemo(() => {
    if (pageName !== LISTING_NAME.CLINIC_PENDING_NEEDED)
      return {
        accessorKey: "rxStatus",
        headerStyle: { width: "240px" },
        header: () => (
          <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} marginRight={"20px"}>
            <span>Status</span>
            {showMarkAllBtn && (
              <Tooltip title="Mark All Received">
                <IconButton onClick={markAllReceived} style={{ width: 30, height: 30 }}>
                  <PlaylistAddCheckIcon color="primary" style={{ width: 30, height: 30 }} />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        ),
        cell: props => {
          if (readOnly) {
            return props?.getValue() ?? "-";
          }
          const dataRow = props.row.original;
          return (
            <PrescriptionStatusDD
              bulkPresId={mainPrescriptionId}
              presId={dataRow?.subPrescriptionId}
              rowId={dataRow?.id}
              pageName={pageName}
              patientId={dataRow?.patientId}
              rxStatus={props.getValue()}
              readOnly={dataRow?.isCustomOrder}
              setCustomError={setCustomError}
              paymentStatus={paymentStatus}
              customError={statusErrorMessage}
            />
          );
        },
      };
  }, [
    markAllReceived,
    statusErrorMessage,
    mainPrescriptionId,
    pageName,
    paymentStatus,
    readOnly,
    setCustomError,
    showMarkAllBtn,
  ]);

  const renderRxInput = useMemo(() => {
    if (includes(ADMIN_APPROVED_LISTING, pageName)) {
      return {
        header: "RX Number",
        accessorKey: "rxNumber",
        headerStyle: { minWidth: "200px" },
        cell: props => {
          if (readOnly) {
            return props?.getValue?.() ?? "-";
          }
          const dataRow = props.row.original;
          return dataRow.family?.toUpperCase() === "MEDICAL ACCESSORIES" ||
            dataRow?.subCategory1?.toUpperCase() === "MEDICAL ACCESSORIES" ? (
            "MA"
          ) : (
            <RenderRxInput
              bulkPresId={mainPrescriptionId}
              patientId={dataRow?.patientId}
              setCustomError={setCustomError}
              rxNumber={dataRow.rxNumber}
              productId={dataRow?.id}
              pageName={pageName}
              presId={dataRow?.subPrescriptionId}
            />
          );
        },
      };
    }
  }, [mainPrescriptionId, pageName, readOnly, setCustomError]);

  const renderRxOrderSwitch = useMemo(() => {
    if (pageName === LISTING_NAME.ADMIN_RX_WORKFLOW && showNestedRxOrderInput && !readOnly) {
      return {
        header: "RX Order",
        accessorKey: "rxOrderInput",
        headerStyle: { width: "50px" },
        cell: props => {
          const dataRow = props.row.original;
          return (
            <PrescriptionOrderInput
              id={dataRow?.subPrescriptionId}
              patientId={dataRow?.patientId}
              rxOrderInput={dataRow?.rxOrderInput}
              setCustomError={setCustomError}
            />
          );
        },
      };
    }
  }, [pageName, readOnly, setCustomError, showNestedRxOrderInput]);

  const extraCell = useMemo(
    () => ({
      header: "",
      accessorKey: "extraCell",
    }),
    [],
  );

  const reactTable = useReactTable({
    data: nestedTableData,
    columns: [...nestedColumn, renderRxOrderSwitch, renderStatus, renderRxInput, extraCell].filter(Boolean),
    manualPagination: true,
    getCoreRowModel: getCoreRowModel(),
  });

  const editMedication = useMemo(() => {
    if (
      nestedEditable &&
      !["Approved", "Ready To Pack", "Received", "Cancelled"].includes(originalRow?.prescriptionStatus)
    ) {
      return (
        <Tooltip title="Edit Medication" key={`tooltip`}>
          <IconButton
            onClick={() => {
              toggleUpdateModal?.(originalRow?.id, "Product");
            }}
            style={{ width: 25, height: 25, marginRight: 20 }}>
            <i className="fas fa-edit medText" aria-hidden="true" style={{ fontSize: 15 }} />
          </IconButton>
        </Tooltip>
      );
    }
  }, [nestedEditable, originalRow?.id, toggleUpdateModal]);

  return (
    <Box sx={{ margin: 1 }}>
      {statusLoading && <Spinner />}
      <TableContainer>
        <Table size="medium">
          <TableHead>
            {reactTable.getHeaderGroups().map(headerGroup => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map(header => {
                  const isMedicationColumn = header.column.columnDef.header === "Medication";
                  let cellBox = (
                    <Typography variant="tableHeader" noWrap>
                      {flexRender(header.column.columnDef.header, header.getContext())}
                    </Typography>
                  );
                  if (nestedEditable && isMedicationColumn) {
                    cellBox = (
                      <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                        {cellBox}
                        {editMedication}
                      </Box>
                    );
                  }
                  return (
                    <TableCell key={header.id} style={header.column.columnDef.headerStyle ?? { minWidth: 150 }}>
                      {cellBox}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableHead>
          <TableBody>
            {reactTable.getRowModel().rows.map((row, index) => {
              const disableRow = Boolean(filterRxStatus) && row?.original.rxStatus !== filterRxStatus;
              return (
                <TableRow key={`${row.id}_${index}`} className={classNames({ "disable-row": disableRow })}>
                  {row.getVisibleCells?.().map(cell => {
                    const isMedicationColumn = cell.column.columnDef.header === "Medication";
                    return (
                      <TableCell
                        key={cell.id}
                        // width should be same as we mention in Table Columns
                        style={
                          isMedicationColumn ? { overflow: "auto", maxWidth: 350 } : cell.column.columnDef.cellStyle
                        }>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default React.memo(MuiNestedTable);
