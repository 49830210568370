import { Card, CardActionArea, CardContent, CardMedia, Grid, Typography } from "@mui/material";
import { get } from "lodash";
import React, { useCallback } from "react";
import ProductImageMapping from "./ProductImageMapping";
import VpiCompounding from "assets/vpi-images/vpi logo for products.jpg";

function ProductCategoryBox({ itemTitle, onSelect }) {
  const onSelectCallback = useCallback(() => {
    onSelect(itemTitle);
  }, []);
  return (
    <Grid item xs={3} md={4} lg={3} display={"flex"}>
      <Card sx={{ width: "100%" }}>
        <CardActionArea onClick={onSelectCallback}>
          <CardMedia
            component="img"
            height={100}
            image={get(ProductImageMapping, itemTitle, VpiCompounding)}
            alt="Product Image"
          />
          <CardContent sx={{ p: 1 }}>
            <Typography textAlign={"center"} variant="body2" color={"secondary"}>
              {itemTitle}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </Grid>
  );
}
export default React.memo(ProductCategoryBox);
