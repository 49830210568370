import SearchIcon from "@mui/icons-material/Search";
import { Autocomplete, Box, IconButton, InputBase, MenuItem } from "@mui/material";
import { SelectStyled } from "components/StyledComponent";
import { throttle } from "lodash";
import React, { useCallback, useEffect, useMemo } from "react";
import { useLazyGetSuggestionSearchProductsQuery } from "rtk";

function AutoCompleteSearch({
  searchKeyword = "",
  selectedFilter = "",
  filterOptions = [],
  applySearch,
  updateKeyword,
  clearFilter,
  updateFilterType,
}) {
  const [fetchList, { data = [], isFetching }] = useLazyGetSuggestionSearchProductsQuery();

  const onKeyDown = useCallback(
    e => {
      if (e.code === "Enter") {
        e?.preventDefault();
        applySearch?.(e);
      }
    },
    [applySearch],
  );

  const apiCall = useMemo(
    () =>
      throttle(keyword => {
        fetchList({ keyword: keyword, filter: [selectedFilter] });
      }, 500),
    [selectedFilter],
  );

  useEffect(() => {
    if (searchKeyword) {
      apiCall(searchKeyword);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchKeyword]);

  const filterDropdown = useMemo(() => {
    if (filterOptions?.length) {
      return (
        <SelectStyled
          displayEmpty
          value={selectedFilter}
          onChange={updateFilterType}
          inputProps={{ "aria-label": "Without label" }}
          variant="standard"
          sx={{ height: 40, backgroundColor: "rgba(0,0,0,0.1)", paddingLeft: 1, flexGrow: 0.1 }}>
          {filterOptions.map(option => (
            <MenuItem key={option?.value} value={option?.value}>
              {option?.label}
            </MenuItem>
          ))}
        </SelectStyled>
      );
    }
  }, [filterOptions, selectedFilter, updateFilterType]);

  return (
    <Autocomplete
      freeSolo
      id="search-product"
      autoComplete
      includeInputInList
      disableClearable={false}
      noOptionsText="No suggestion"
      options={data}
      value={searchKeyword}
      loading={isFetching}
      getOptionLabel={option => option}
      onInputChange={(_, newInputValue) => updateKeyword(newInputValue)}
      onKeyDown={onKeyDown}
      filterOptions={x => x}
      renderInput={params => (
        <Box
          ref={params.InputProps.ref}
          border={0.5}
          borderRadius={1}
          sx={{ display: "flex", padding: 0, alignItems: "center" }}>
          {filterDropdown}
          <InputBase
            id={params.id}
            disabled={params.disabled}
            fullWidth={params.fullWidth}
            size={params.size}
            sx={{ ml: 1, flex: 1 }}
            inputProps={params.inputProps}
            type="text"
            placeholder="Search Product"
          />
          {searchKeyword?.length ? (
            <button className="fas fa-times redText align-self-center" onClick={clearFilter} />
          ) : null}
          <IconButton
            type="button"
            aria-label="search"
            onClick={applySearch}
            style={{ background: "#1769aa", color: "#fff", marginLeft: "5px", borderRadius: 4 }}>
            <SearchIcon style={{ fontSize: 22 }} />
          </IconButton>
        </Box>
      )}
    />
  );
}

export default React.memo(AutoCompleteSearch);
