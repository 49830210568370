import { PrescriptionApiStateContext, PrescriptionFormSateContext } from "context/PrescriptionContext";
import dayjs from "dayjs";
import { find, get, map, startsWith, unset } from "lodash";
import { useCallback, useContext, useMemo, useRef, useState } from "react";
import {
  useLazyGetMedicalAccessoriesListQuery,
  useLazyGetProductDetailByIdQuery,
  useLazyGetProductDiscountByIdsQuery,
  useToggleProductFavoriteMutation,
} from "rtk";

function useProductCartModal({ getValues, setValue, setError, reset }) {
  // addCartProductCallbackRef is used to store the reference of the function used to add the product to the productCartModal.
  const addCartProductCallbackRef = useRef(null);
  const [activeTab, setActiveTab] = useState("1");
  const [openProductCartDialog, setOpenProductCartDialog] = useState(false);
  const [productCartDialogData, setProductCartDialogData] = useState({});
  const { patientFormData } = useContext(PrescriptionFormSateContext);
  const { filterClinicId } = useContext(PrescriptionApiStateContext);

  const [fetchProductDetail, { data: productData, isFetching: productDetailFetching, error: productDetailError }] =
    useLazyGetProductDetailByIdQuery();

  const [
    fetchProductDiscount,
    { data: discountData, isFetching: productDiscountFetching, error: productDiscountError },
  ] = useLazyGetProductDiscountByIdsQuery();

  const [
    fetchMedicalAccessoryProductDiscount,
    {
      data: medicalAccessoryDiscountDetail,
      isFetching: medicalAccessoryDiscountFetching,
      error: medicalAccessoryDiscountError,
    },
  ] = useLazyGetProductDiscountByIdsQuery();

  const [
    fetchMedicalAccessory,
    { data: medicalAccessoryData, isFetching: medicalAccessoryFetching, error: medicalAccessoryError },
  ] = useLazyGetMedicalAccessoriesListQuery();

  const [toggleFavoriteMutate, { isLoading: favLoading, error: favError, reset: resetMutate }] =
    useToggleProductFavoriteMutation();

  const isRxPad = useMemo(
    () => productCartDialogData?.productType === "rxPad" || !productCartDialogData?.productId,
    [productCartDialogData?.productId, productCartDialogData?.productType],
  );

  const discountDetail = useMemo(() => {
    if (!isRxPad) {
      return discountData;
    }
  }, [discountData, isRxPad]);

  const productDetail = useMemo(() => {
    if (isRxPad) {
      return productCartDialogData;
    }
    return productData;
  }, [isRxPad, productData, productCartDialogData]);

  const showLoading = useMemo(
    () =>
      productDetailFetching || productDiscountFetching || medicalAccessoryFetching || medicalAccessoryDiscountFetching,
    [medicalAccessoryDiscountFetching, medicalAccessoryFetching, productDetailFetching, productDiscountFetching],
  );
  const errorMessage = useMemo(
    () =>
      productDetailError?.data?.message ||
      productDiscountError?.data?.message ||
      favError?.data?.message ||
      medicalAccessoryError?.data?.message ||
      medicalAccessoryDiscountError?.data?.message ||
      "",
    [
      favError?.data?.message,
      medicalAccessoryDiscountError?.data?.message,
      medicalAccessoryError?.data?.message,
      productDetailError?.data?.message,
      productDiscountError?.data?.message,
    ],
  );

  const infoMessage = useMemo(() => {
    let message = "";
    if (!(productDetail?.isAvailable ?? true)) {
      message =
        "This product is awaiting one or more active ingredients. VPI will ship the product when available again. Please advise.";
    }
    return message;
  }, [productDetail?.isAvailable]);

  const medicalAccessoryOption = useMemo(
    () =>
      map(medicalAccessoryData?.products, accessory => ({
        label: accessory?.name,
        value: accessory?.id,
      })),
    [medicalAccessoryData],
  );

  const unitPrice = useMemo(
    () => discountDetail?.[0]?.unitPrice || productCartDialogData?.unitPrice || "-",
    [discountDetail, productCartDialogData?.unitPrice],
  );

  const handleTabChange = (_, newValue) => {
    setActiveTab(newValue);
  };

  const fillFormValue = useCallback(
    selectedProduct => {
      setValue("_id", selectedProduct?._id ?? Date.now());
      setValue("name", selectedProduct?.name);
      setValue("quantity", selectedProduct?.quantity ?? 1);
      setValue("strength", selectedProduct?.strength);
      setValue("dispenseType", selectedProduct?.dispenseType);
      setValue("refills", selectedProduct?.refills);
      setValue("daySupply", selectedProduct?.daySupply);
      setValue(
        "last_office_visit_timestamp",
        selectedProduct?.last_office_visit_timestamp
          ? dayjs(selectedProduct?.last_office_visit_timestamp, "DD/MM/YYYY").format("YYYY-MM-DD")
          : "",
      );
      setValue("reasonForCompoundedMedication", selectedProduct?.reasonForCompoundedMedication ?? "");
      setValue("patientDiagnosis", selectedProduct?.patientDiagnosis);
      setValue("sig", selectedProduct?.sig ?? "");
      // productType
      let productType = "categorized";
      if (selectedProduct?.productType) {
        productType = selectedProduct?.productType;
      } else if (!selectedProduct?.productId || startsWith(selectedProduct?.rxStatus, "CUSTOM")) {
        productType = "rxPad";
      }
      setValue("productType", productType);
      if (selectedProduct?.medicalAccessories === "1") {
        fetchMedicalAccessory();
        setValue("medicalAccessory", "");
      }
    },
    [fetchMedicalAccessory, setValue],
  );

  const showProductCartDialog = useCallback(
    (selectedProduct, productCallback) => {
      addCartProductCallbackRef.current = productCallback;
      setOpenProductCartDialog(true);
      // if selectedProduct is not coming in argument then this is RX pad flow
      fillFormValue(selectedProduct);
      if (
        selectedProduct &&
        selectedProduct?.productType !== "rxPad" &&
        !startsWith(selectedProduct?.rxStatus, "CUSTOM")
      ) {
        fetchProductDetail(selectedProduct?.productId, true);
        fetchProductDiscount({ clinicId: filterClinicId, productIds: [selectedProduct?.productId] }, true);
      } else {
        setActiveTab("2");
      }
      setProductCartDialogData(selectedProduct);
    },
    [fetchProductDetail, fetchProductDiscount, fillFormValue, filterClinicId],
  );

  const closeProductCartDialog = useCallback(() => {
    setOpenProductCartDialog(false);
    setTimeout(() => {
      setProductCartDialogData({});
      setActiveTab("1");
      reset();
      resetMutate();
    }, 500);
  }, []);

  const favoriteCheckbox = useCallback(
    event => {
      toggleFavoriteMutate({
        productId: productDetail?.id,
        name: productDetail?.name,
        status: event.target.checked,
      });
    },
    [productDetail?.productId, productDetail?.name, toggleFavoriteMutate],
  );

  const onMedicalAccessoryDependentChange = useCallback(
    e => {
      const selectedMedicalAccessory = getValues("medicalAccessory");
      // true => No api call when selecting the same option multiple time.
      fetchMedicalAccessoryProductDiscount({ clinicId: filterClinicId, productIds: [selectedMedicalAccessory] }, true);
    },
    [fetchMedicalAccessoryProductDiscount, filterClinicId, getValues],
  );

  const getFormattedProductObj = useCallback((productDetail, formData, discountDetail) => {
    const organizedProductObj = {
      ...productDetail,
      ...formData,
      ...discountDetail,
      productId: productDetail?.id ?? "",
      sig: formData?.sig?.toUpperCase(),
      displayedGeneratedSig: formData?.sig?.toUpperCase(),
      last_office_visit_timestamp: formData?.last_office_visit_timestamp
        ? dayjs(formData?.last_office_visit_timestamp, "YYYY-MM-DD").format("MM/DD/YYYY")
        : "",
    };
    unset(organizedProductObj, "sigOptions");
    unset(organizedProductObj, "created");
    unset(organizedProductObj, "updated");
    unset(organizedProductObj, "medicalAccessory");
    unset(organizedProductObj, "medicalAccessoryQuantity");

    return organizedProductObj;
  }, []);

  const submitAction = useCallback(
    data => {
      // validation
      // 1. Sig should be selected
      // 2. In case of Multiple patient (Bulk) only one product can selected
      // 3. Medical accessory should not be selected in Rx pad and Multiple Patient
      // 4. Same name Product can not be selected (Duplicate Products)
      // 5.
      if (!data?.sig) {
        setError("sig", {
          types: {
            required: "Instruction is missing",
          },
        });
      } else {
        const selectedProductArray = [getFormattedProductObj(productDetail, data, discountDetail?.[0] ?? {})];
        if (get(data, "medicalAccessory")) {
          const medicalAccessoryProductDetail = find(medicalAccessoryData?.products, {
            id: get(data, "medicalAccessory"),
          });
          const medicalFormData = {
            ...data,
            ...medicalAccessoryProductDetail,
            quantity: data?.medicalAccessoryQuantity,
          };
          selectedProductArray.push(
            getFormattedProductObj(
              medicalAccessoryProductDetail,
              medicalFormData,
              medicalAccessoryDiscountDetail?.[0] ?? {},
            ),
          );
        }
        addCartProductCallbackRef.current?.(selectedProductArray);
        closeProductCartDialog();
      }
    },
    [
      closeProductCartDialog,
      discountDetail,
      getFormattedProductObj,
      medicalAccessoryData?.products,
      medicalAccessoryDiscountDetail,
      productDetail,
      setError,
    ],
  );

  return {
    isRxPad,
    unitPrice,
    activeTab,
    infoMessage,
    favLoading,
    errorMessage,
    showLoading,
    productDetail,
    discountDetail,
    patientFormData,
    openProductCartDialog,
    medicalAccessoryOption,
    medicalAccessoryDiscountDetail,
    submitAction,
    handleTabChange,
    favoriteCheckbox,
    showProductCartDialog,
    closeProductCartDialog,
    onMedicalAccessoryDependentChange,
  };
}

export default useProductCartModal;
