import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
} from "@mui/material";
import Spinner from "components/spinner";
import { find, has, map } from "lodash";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { FormGroup, Label } from "reactstrap";
import {
  useChangeShippingAddressMutation,
  useEditClinicPrescriptionMutation,
  useGetClinicPrescriptionDetailQuery,
  useLazyClinicDetailQuery,
  useLazyGetShippingRateQuery,
} from "rtk";
import { ChangeShippingInfo } from "screens";
import AlertMessage from "screens/admin/Prescriptions/components/alert_message";
import PrescriptionPatient from "screens/common/EditPrescription/PrescriptionPatient";
import AddShippingInfo from "screens/common/add-shipping-info";
import CartContainer from "screens/common/cartContainer";
import SelectProducts from "screens/common/select-products";
import { LISTING_NAME } from "utils";
import { RUSH_ORDER_INFO } from "utils/StatusConstant";

function PrescriptionUpdateModal({ modalTitle = "", prescriptionId = "", listingName = "adminPending", handleClose }) {
  const changeShippingRef = useRef(null);
  const [selectedPatient, setSelectedPatient] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [customShippingRate, setCustomShippingRate] = useState(0);
  const [creditApplied, setCreditApplied] = useState(0);
  const [creditRequested, setCreditRequested] = useState(0);
  const [creditUsed, setCreditUsed] = useState(false);
  const [shippingAddress, setShippingAddress] = useState({});
  const [shipToClinic, setShipToClinic] = useState(true);
  const [shippingMethod, setShippingMethod] = useState("");
  const [customError, setCustomError] = useState("");
  const [rushOrder, setRushOrder] = useState(false);
  const [rushOrderMethod, setRushOrderMethod] = useState();
  const [isNewAddressUsed, setIsNewAddressUsed] = useState(false);
  const [rushOrderCost, setRushOrderCost] = useState(0);
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedProductToEdit, setSelectedProductToEdit] = useState("");
  const [recipientName, setRecipientName] = useState("");
  const [recipientNumber, setRecipientNumber] = useState("");

  const openDialog = useMemo(() => Boolean(prescriptionId) && Boolean(modalTitle), [modalTitle, prescriptionId]);
  const {
    data: prescriptionData,
    isFetching,
    error: prescriptionError,
  } = useGetClinicPrescriptionDetailQuery({ prescriptionId }, { skip: !openDialog });
  const [editMutate, { isLoading: editLoading, isSuccess: editSuccess, error: editError }] =
    useEditClinicPrescriptionMutation();
  const [
    changeShippingMutate,
    { isLoading: changeShippingLoading, isSuccess: changeShippingSuccess, error: changeShippingError },
  ] = useChangeShippingAddressMutation();
  const [fetchClinicData, { data: clinicData, error: clinicError }] = useLazyClinicDetailQuery();
  const [rateMutate, { data: shippingRateData, isFetching: shippingRateLoading, error: shippingRateError }] =
    useLazyGetShippingRateQuery();

  const selectedPatientIds = useMemo(() => map(selectedPatient, "id"), [selectedPatient]);
  const showLoading = useMemo(
    () => editLoading || shippingRateLoading || changeShippingLoading,
    [editLoading, changeShippingLoading, shippingRateLoading],
  );
  const isBulkOrder = useMemo(
    () =>
      prescriptionData?.subPrescriptions?.length > 0 ? prescriptionData?.isBulkOrder : selectedPatient?.length > 1,
    [selectedPatient?.length],
  );
  const isNet30 = useMemo(() => clinicData?.paymentTermDays > 0, [clinicData?.paymentTermDays]);
  const isCompleteOrder = useMemo(
    () => prescriptionData?.prescriptionStatus === "Ready To Pack",
    [prescriptionData?.prescriptionStatus],
  );

  const shippingRate = useMemo(() => {
    if (shippingMethod === "CustomRate") {
      return customShippingRate?.toString();
    } else if (shippingRateData) {
      return shippingRateData?.shippingCost?.toString();
    } else if (isCompleteOrder && prescriptionData?.shippingInfo?.shippingMethod === "CustomRate") {
      return prescriptionData?.shippingInfo?.customRate?.toString();
    }
  }, [
    customShippingRate,
    isCompleteOrder,
    prescriptionData?.shippingInfo?.customRate,
    prescriptionData?.shippingInfo?.shippingMethod,
    shippingMethod,
    shippingRateData,
  ]);

  const disableActionBtn = useMemo(() => {
    if (modalTitle === "Product") {
      return selectedProducts.length < 1;
    } else if (modalTitle === "Patient") {
      return (
        (!prescriptionData?.patientIds?.length > 1 && isBulkOrder && selectedProducts.length > 1) ||
        selectedPatient.length < 1
      );
    }
  }, [isBulkOrder, modalTitle, prescriptionData?.patientIds?.length, selectedPatient.length, selectedProducts.length]);

  const isEmptyCart = useMemo(() => selectedProducts.length < 1, [selectedProducts.length]);
  const credit = useMemo(
    () => clinicData?.clinicLocations.filter(v => v.id === prescriptionData?.clinicLocationId)[0]?.credit,
    [clinicData?.clinicLocations, prescriptionData?.clinicLocationId],
  );
  const errorMessage = useMemo(
    () =>
      changeShippingError?.data?.message ||
      prescriptionError?.data?.message ||
      editError?.data?.message ||
      clinicError?.data?.message ||
      shippingRateError?.data?.message ||
      customError,
    [
      changeShippingError?.data?.message,
      clinicError?.data?.message,
      customError,
      editError?.data?.message,
      prescriptionError?.data?.message,
      shippingRateError?.data?.message,
    ],
  );

  useEffect(() => {
    if (openDialog && prescriptionData?.id) {
      if (prescriptionData?.clinicId) {
        fetchClinicData({ clinicId: prescriptionData?.clinicId });
      }
      if (prescriptionData?.shippingInfo?.recipientPhone) {
        setSelectedPatient(prescriptionData?.subPrescriptionList[0]?.patients); //we did this for bulk orders in which we get patient ids inside subprescriptions
      } else {
        setSelectedPatient(prescriptionData?.patients);
      }
      setSelectedProducts(prescriptionData?.products.concat(prescriptionData?.rxPadProducts));
      setShippingAddress(prescriptionData?.shippingInfo.shippingAddress);
      setShippingMethod(prescriptionData?.shippingInfo.shippingMethod);
      setCustomShippingRate(prescriptionData?.shippingInfo?.customRate ?? 0);
      setCreditApplied(prescriptionData?.creditRequested);
      setCreditRequested(prescriptionData?.creditRequested);
      setIsNewAddressUsed(prescriptionData?.shippingInfo.isNewAddressUsed);
      setRushOrder(prescriptionData?.shippingInfo.isRushOrder);
      setRecipientName(prescriptionData?.shippingInfo?.recipientName);
      setRecipientNumber(prescriptionData?.shippingInfo?.recipientPhone);
      setShipToClinic(
        prescriptionData?.patientIds.length > 1 ? true : prescriptionData?.shippingInfo?.shipTo === "clinic",
      );
    }
  }, [
    openDialog,
    prescriptionData?.clinicId,
    prescriptionData?.creditRequested,
    prescriptionData?.id,
    prescriptionData?.patientIds.length,
    prescriptionData?.patients,
    prescriptionData?.products,
    prescriptionData?.rxPadProducts,
    prescriptionData?.shippingInfo.isNewAddressUsed,
    prescriptionData?.shippingInfo.isRushOrder,
    prescriptionData?.shippingInfo?.shipTo,
    prescriptionData?.shippingInfo.shippingAddress,
    prescriptionData?.shippingInfo.shippingMethod,
    prescriptionData?.shippingInfo?.recipientName,
    prescriptionData?.shippingInfo?.recipientPhone,
  ]);

  const handleNameChange = e => {
    setRecipientName(e.target.value);
  };

  const handleNumberChange = e => {
    setRecipientNumber(e.target.value);
  };
  const handleInput = e => {
    e.target.value = e.target.value.replace(/[^0-9]/g, "");
  };

  useEffect(() => {
    if (editSuccess || changeShippingSuccess) {
      closeModal?.();
    }
  }, [editSuccess, changeShippingSuccess]);

  const clearStates = useCallback(() => {
    setSelectedPatient([]);
    setSelectedProducts([]);
    setShippingAddress({});
    setCreditApplied(0);
    setCreditRequested(0);
    setIsNewAddressUsed(false);
    setRushOrder(false);
    setCreditUsed(false);
    setShipToClinic(true);
    setCustomError("");
    setRushOrderMethod();
    setRushOrderCost(0);
    setShowEditModal(false);
    setSelectedProductToEdit("");
    setShippingMethod("");
    setCustomShippingRate(0);
  }, []);

  const closeModal = useCallback(() => {
    clearStates?.();
    handleClose?.();
  }, []);

  const resetCreditApplied = useCallback(() => {
    setCreditApplied(0);
  }, []);

  const updateShippingCost = useCallback(
    async (address = shippingAddress, method = shippingMethod) => {
      const findProduct = find(selectedProducts, product => Boolean(product?.unitPrice));
      const body = {
        recipientAddress: {
          streetLines: {
            addressLine1: address?.addressLine1,
            addressLine2: address?.addressLine2,
          },
          city: address?.city,
          state: address?.state,
          postalCode: address?.zipcode,
          residential: method === "GROUND_HOME_DELIVERY",
        },
        shippingMethod: method,
      };
      if (findProduct) {
        if (body?.recipientAddress?.streetLines?.addressLine1 !== "") {
          if (body?.shippingMethod !== "CustomRate") {
            const shippingApiParam = changeShippingRef.current?.getShippingInfo();
            rateMutate({ ...body, ...shippingApiParam });
          }
        } else {
          setCustomError("Shipping Address Line 1 is invalid");
        }
      }
    },
    [rateMutate, selectedProducts, shippingAddress, shippingMethod],
  );

  const updateShippingMethod = useCallback(
    shippingMethod => {
      setShippingMethod(shippingMethod);
      if (shippingMethod !== "CustomRate") {
        updateShippingCost(shippingAddress, shippingMethod);
      }
    },
    [shippingAddress, updateShippingCost],
  );

  const updateShippingAddress = useCallback(
    (address, newAddressCheck) => {
      setShippingAddress(address);
      setIsNewAddressUsed(newAddressCheck);
      updateShippingCost(address, shippingMethod);
    },
    [shippingMethod, updateShippingCost],
  );

  const updateRushOrder = useCallback((check, method) => {
    setRushOrder(check);
    setRushOrderMethod(method);
  }, []);

  const updateCreditRequested = useCallback(am => {
    setCreditRequested(parseFloat(am));
  }, []);

  const editProduct = useCallback(product => {
    setShowEditModal(true);
    setSelectedProductToEdit(product);
  }, []);

  const removeProduct = useCallback(product => {
    setSelectedProducts(prev =>
      prev.filter((e, i) => {
        return (
          e.productId?.toString() !== product?.productId?.toString() &&
          e.name?.toString() !== product?.productName?.toString()
        );
      }),
    );
  }, []);

  const getPrescriptionObject = useCallback(() => {
    const serialize = require("form-serialize");
    const form = document.getElementById("newPrescriptionForm");
    const obj = serialize(form, {
      hash: true,
      empty: true,
    });

    const rxPadProd = [];
    const storedProducts = [];

    selectedProducts.forEach(prod => {
      if (prod?.productType === "custom" || prod?.rxStatus?.includes("CUSTOM")) {
        rxPadProd.push({
          name: prod.name,
          quantity: prod.quantity,
          family: prod.family,
          strength: prod.strength,
          refills: prod.refills,
          sig: prod.sig,
          reasonForCompoundedMedication: prod.reasonForCompoundedMedication,
        });
      } else {
        storedProducts.push(prod);
      }
    });

    const prescription = {
      prescriptionId: prescriptionData?.id,
      clinicId: prescriptionData?.clinicId,
      clinicLocationId: obj.locationId ?? prescriptionData?.clinicLocationId,
      providerId: obj.providerId ?? prescriptionData?.providerId,
      patientIds: selectedPatientIds,
      products: storedProducts,
      rxPadProducts: rxPadProd,
      //Credit
      creditRequested: creditRequested,
      shippingInfo: {
        //SHIP TO
        shipTo: isBulkOrder ? "clinic" : shipToClinic ? "clinic" : "patient",
        isNewAddressUsed: isNewAddressUsed,
        shippingAddress:
          // Following condition to check if multiple patient selection in Modal Patient only editing
          prescriptionData.patientIds.length === 1 && isBulkOrder
            ? {
                addressLine1: prescriptionData?.clinicLocationDetails?.addressLine1 ?? "",
                addressLine2: prescriptionData?.clinicLocationDetails?.addressLine2 ?? "",
                city: prescriptionData?.clinicLocationDetails?.city ?? "",
                state: prescriptionData?.clinicLocationDetails?.state ?? "",
                zipcode: prescriptionData?.clinicLocationDetails?.zipcode ?? "",
              }
            : shippingAddress,
        shippingMethod: shippingMethod,
        isPoBox: has(obj, "POBox") ? obj.POBox === "on" : prescriptionData?.shippingInfo?.isPoBox,
        orderNotes: has(obj, "orderNotes") ? obj.orderNotes : prescriptionData?.shippingInfo?.orderNotes,
        isSignatureRequired: has(obj, "signatureRequired")
          ? obj.signatureRequired === "on"
          : prescriptionData?.shippingInfo?.isSignatureRequired,
        isRushOrder: has(obj, "isRushOrder") ? obj.isRushOrder === "on" : prescriptionData?.shippingInfo?.isRushOrder,
        rushOrderMethod: rushOrder ? RUSH_ORDER_INFO.name : "",
        rushOrderCost: rushOrderCost,
      },
      encryptedBillingInfo: prescriptionData?.encryptedBillingInfo,
      signatureFileUrl: prescriptionData?.signatureFileUrl,
    };

    return prescription;
  }, [
    creditRequested,
    isBulkOrder,
    isNewAddressUsed,
    prescriptionData?.clinicId,
    prescriptionData?.clinicLocationId,
    prescriptionData?.encryptedBillingInfo,
    prescriptionData?.id,
    prescriptionData?.providerId,
    prescriptionData?.shippingInfo?.isPoBox,
    prescriptionData?.shippingInfo?.isRushOrder,
    prescriptionData?.shippingInfo?.isSignatureRequired,
    prescriptionData?.shippingInfo?.orderNotes,
    prescriptionData?.signatureFileUrl,
    rushOrder,
    rushOrderCost,
    selectedPatientIds,
    selectedProducts,
    shipToClinic,
    shippingAddress,
    shippingMethod,
  ]);

  const getChangedShippingInfo = useCallback(async () => {
    const getShippingValue = await changeShippingRef.current?.getShippingData();
    if (getShippingValue) {
      const changeShippingData = {
        prescriptionId: prescriptionData?.id,
        packagingType: getShippingValue?.packagingType,
        weight: getShippingValue.weight,
        dimensions: getShippingValue.dimensions,
        shippingInfo: {
          shipTo: getShippingValue.shipTo,
          isNewAddressUsed: isNewAddressUsed,
          shippingMethod: shippingMethod,
          shippingAddress: shippingAddress,
          recipientName: recipientName,
          recipientPhone: recipientNumber,
        },
      };
      if (shippingMethod === "CustomRate") {
        changeShippingData["customRate"] = customShippingRate;
      }
      return changeShippingData;
    } else {
      setCustomError("Something went wrong.");
      return null;
    }
  }, [
    customShippingRate,
    isNewAddressUsed,
    prescriptionData?.id,
    shippingAddress,
    shippingMethod,
    recipientName,
    recipientNumber,
  ]);

  const savePrescription = useCallback(
    async e => {
      e.preventDefault();
      if (!editLoading) {
        if (isCompleteOrder && modalTitle === "Delivery Address") {
          const updatedShippingInfo = await getChangedShippingInfo();
          if (updatedShippingInfo) {
            changeShippingMutate(updatedShippingInfo);
          }
        } else {
          const updatedData = getPrescriptionObject();
          editMutate({
            isReview: listingName === LISTING_NAME.CLINIC_PENDING_APPROVAL,
            side: listingName,
            ...updatedData,
            // prescriptionStatus,
            //   adminClinicSide: paramClinicId,
            //   reviewCheck,
          });
        }
      }
    },
    [
      changeShippingMutate,
      editLoading,
      editMutate,
      getChangedShippingInfo,
      getPrescriptionObject,
      isCompleteOrder,
      listingName,
      modalTitle,
    ],
  );

  const renderShippingInfo = useMemo(() => {
    if (isCompleteOrder) {
      return (
        <>
          <ChangeShippingInfo
            ref={changeShippingRef}
            isBulkOrder={isBulkOrder}
            shipToClinic={shipToClinic}
            shippingMethod={shippingMethod}
            selectedPatients={selectedPatientIds}
            setCustomShippingRate={setCustomShippingRate}
            clinicLocationId={prescriptionData?.clinicLocationId}
            defaultShippingInfo={prescriptionData?.shippingInfo}
            updateShippingCost={updateShippingCost}
            setShippingAddress={updateShippingAddress}
            setShippingMethod={updateShippingMethod}
            isCustomShippingLabel={prescriptionData?.shippingInfo?.recipientPhone}
          />
          {prescriptionData?.shippingInfo?.recipientPhone && (
            <div>
              <h3>Custom Information</h3>
              <FormGroup className="mb-4">
                <Label htmlFor="businessName" className="form-label">
                  Recipient Names <span className="requiredStar">*</span>
                </Label>
                <TextField
                  id="recipientName"
                  name="recipientName"
                  className="form-control"
                  type="text"
                  placeholder="patient1,patient2"
                  variant="outlined"
                  // fullWidth
                  value={recipientName}
                  onChange={handleNameChange}
                />
                <Label htmlFor="businessName" className="form-label">
                  Recipient Phone
                </Label>
                <TextField
                  id="recipientPhone"
                  name="recipientPhone"
                  className="form-control"
                  type="tel"
                  inputProps={{
                    maxLength: 10,
                  }}
                  placeholder="1234567890"
                  variant="outlined"
                  value={recipientNumber}
                  onChange={handleNumberChange}
                  onInput={handleInput}
                />
              </FormGroup>
            </div>
          )}
        </>
      );
    }
    return (
      <AddShippingInfo
        modalView={true}
        editFlow={true}
        resetCreditApplied={resetCreditApplied}
        credit={credit}
        setCreditUsed={setCreditUsed}
        shipToClinicFunc={setShipToClinic}
        updateShippingCost={updateShippingCost}
        shippingMethod={shippingMethod}
        setShippingMethod={updateShippingMethod}
        defaultShippingInfo={prescriptionData?.shippingInfo}
        setShippingAddress={updateShippingAddress}
        selectedPatients={selectedPatientIds}
        setRushOrder={updateRushOrder}
        isBulkOrder={isBulkOrder}
        clinicLocationId={prescriptionData?.clinicLocationId}
        step={openDialog ? 4 : 0}
        shipToClinic={shipToClinic}
        clinicId={prescriptionData?.clinicId}
        isNet30={isNet30}
      />
    );
  }, [
    credit,
    isBulkOrder,
    isCompleteOrder,
    isNet30,
    openDialog,
    prescriptionData?.clinicId,
    prescriptionData?.clinicLocationId,
    prescriptionData?.shippingInfo,
    resetCreditApplied,
    selectedPatientIds,
    shipToClinic,
    shippingMethod,
    updateRushOrder,
    updateShippingAddress,
    updateShippingCost,
    updateShippingMethod,
    recipientName,
    recipientNumber,
    prescriptionData?.shippingInfo?.recipientPhone,
  ]);

  const renderView = useMemo(() => {
    switch (modalTitle) {
      case "Patient":
        return (
          <PrescriptionPatient
            editPrescription={true}
            selectedPatients={selectedPatientIds}
            updateSelectedPatients={setSelectedPatient}
            defaultLocationId={prescriptionData?.clinicLocationId}
            clinicId={prescriptionData?.clinicId}
            selectedProducts={selectedProducts}
          />
        );

      case "Delivery Address":
        return (
          <Grid container spacing={2}>
            <Grid item xs={8}>
              {renderShippingInfo}
            </Grid>
            <Grid item xs={4} className="pt-5">
              <CartContainer
                showProducts={false}
                showPatients={false}
                readOnly={isCompleteOrder}
                setCreditRequested={updateCreditRequested}
                credit={credit}
                creditRequested={creditRequested}
                creditUsed={creditUsed}
                creditApplied={creditApplied}
                setRushOrderCost={setRushOrderCost}
                shippingCost={selectedPatientIds ? shippingRate : "0"}
                shippingMethod={shippingMethod}
                rushOrder={rushOrder}
                rushOrderMethod={rushOrderMethod}
                selectedPatients={selectedPatient}
                selectedProducts={selectedProducts}
                clinicId={prescriptionData?.clinicId}
              />
            </Grid>
          </Grid>
        );
      case "Product":
        return (
          <Grid container spacing={2}>
            <Grid item xs={8}>
              <SelectProducts
                selectedProductToEdit={selectedProductToEdit}
                isEditModal={setShowEditModal}
                showEditModal={showEditModal}
                isBulkOrder={selectedPatient?.length > 1}
                selectedProducts={selectedProducts}
                updateSelectedProducts={setSelectedProducts}
                selectedPatients={selectedPatientIds}
                clinicId={prescriptionData?.clinicId}
                clinicLocationId={prescriptionData?.clinicLocationId}
              />
            </Grid>
            <Grid item xs={4} className="pt-5">
              <CartContainer
                showPatients={false}
                getShippingRate={updateShippingCost}
                setCreditRequested={updateCreditRequested}
                credit={credit}
                creditRequested={creditRequested}
                creditUsed={creditUsed}
                creditApplied={creditApplied}
                setRushOrderCost={setRushOrderCost}
                shippingCost={selectedPatientIds ? shippingRate : "0"}
                shippingMethod={shippingMethod}
                rushOrder={rushOrder}
                rushOrderMethod={rushOrderMethod}
                selectedPatients={selectedPatient}
                selectedProducts={selectedProducts}
                clinicId={prescriptionData?.clinicId}
                editProduct={editProduct}
                showEditModal={showEditModal}
                emptyCart={isEmptyCart}
                removeProduct={removeProduct}
              />
            </Grid>
          </Grid>
        );

      default:
        return null;
    }
  }, [
    credit,
    creditApplied,
    creditRequested,
    creditUsed,
    editProduct,
    isCompleteOrder,
    isEmptyCart,
    modalTitle,
    prescriptionData?.clinicId,
    prescriptionData?.clinicLocationId,
    removeProduct,
    renderShippingInfo,
    rushOrder,
    rushOrderMethod,
    selectedPatient,
    selectedPatientIds,
    selectedProductToEdit,
    selectedProducts,
    shippingMethod,
    shippingRate,
    showEditModal,
    updateCreditRequested,
    updateShippingCost,
  ]);

  return (
    <Dialog open={openDialog} scroll="paper" fullWidth maxWidth="xl">
      <DialogTitle variant="h5">
        {`Edit ${modalTitle}`}
        <IconButton
          aria-label="close"
          onClick={closeModal}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: theme => theme.palette.grey[500],
          }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        {showLoading && <Spinner />}
        {isFetching ? (
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress />
          </Box>
        ) : (
          <Box component={"form"} id="newPrescriptionForm" onSubmit={savePrescription} className="mt-1">
            {errorMessage ? <AlertMessage isError msg={errorMessage} /> : null}
            {renderView}
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          disabled={disableActionBtn || showLoading}
          variant="contained"
          className={"mx-2"}
          type="submit"
          form="newPrescriptionForm">
          {"Update"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default React.memo(PrescriptionUpdateModal);
