import { PrescriptionProvider } from "context/PrescriptionContext";
import React from "react";
import InsertPrescription from "./InsertPrescription";

function ManagePrescription() {
  return (
    <PrescriptionProvider>
      <InsertPrescription />
    </PrescriptionProvider>
  );
}

export default React.memo(ManagePrescription);
