import { Alert, alertClasses, Fade } from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";

function MuiAlertMessage({ message = "", isError = false, isInfo = false, isWarning = false, onClose }) {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(Boolean(message));
  }, [message]);

  const toggleAlert = useCallback(() => {
    setOpen(false);
    onClose?.();
  }, []);

  const severityAlert = useMemo(() => {
    let alertColor = "success";
    if (isError) {
      alertColor = "error";
    } else if (isInfo) {
      alertColor = "info";
    } else if (isWarning) {
      alertColor = "warning";
    }
    return alertColor;
  }, [isError, isInfo, isWarning]);

  return (
    <Fade in={open}>
      <Alert
        severity={severityAlert}
        onClose={toggleAlert}
        sx={{
          mb: 2,
          position: "relative",
          paddingX: 5,
          justifyContent: "center",
          [`& .${alertClasses.action}`]: {
            position: "absolute",
            right: 8,
            marginLeft: 0,
            marginRight: 0,
          },
        }}>
        {message}
      </Alert>
    </Fade>
  );
}

export default React.memo(MuiAlertMessage);
