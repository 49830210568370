import SearchIcon from "@mui/icons-material/Search";
import { Box, IconButton, InputBase, MenuItem } from "@mui/material";
import { SelectStyled } from "components/StyledComponent";
import React, { useCallback, useMemo } from "react";
import { Row } from "reactstrap";

function ListingSearch({
  searchKeyword = "",
  selectedFilter = "",
  filterOptions = [],
  applySearch,
  updateKeyword,
  clearFilter,
  updateFilterType,
  leftChildren,
}) {
  const showFilterDropdown = useMemo(() => filterOptions.length, [filterOptions.length]);
  const onKeyDown = useCallback(
    e => {
      if (e.code === "Enter") {
        applySearch?.(e);
      }
    },
    [applySearch],
  );

  const clearKeyword = useCallback(e => clearFilter(e, "keyword"), [clearFilter]);

  const filterDropdown = useMemo(() => {
    if (filterOptions.length) {
      return (
        <SelectStyled
          displayEmpty
          value={selectedFilter || "all"}
          onChange={updateFilterType}
          inputProps={{ "aria-label": "Without label" }}
          variant="standard"
          sx={{ height: 40, backgroundColor: "rgba(0,0,0,0.1)", paddingLeft: 1 }}>
          <MenuItem value="all">All</MenuItem>
          {filterOptions.map(option => (
            <MenuItem key={option?.value} value={option?.value}>
              {option?.label}
            </MenuItem>
          ))}
        </SelectStyled>
      );
    }
  }, [filterOptions, selectedFilter, updateFilterType]);

  return (
    <Row className="justify-content-between px-3 my-3 ">
      <Box
        border={0.5}
        borderRadius={1}
        sx={{
          display: "flex",
          padding: 0,
          alignItems: "center",
          width: showFilterDropdown ? { xs: 300, sm: 350, md: 400 } : 270,
        }}>
        {filterDropdown}
        <InputBase
          sx={{ ml: 1, flex: 1 }}
          type="text"
          placeholder="Search table"
          value={searchKeyword}
          onKeyDown={onKeyDown}
          onChange={updateKeyword}
        />
        {searchKeyword.length ? (
          <button className="fas fa-times redText align-self-center" onClick={clearKeyword} />
        ) : null}
        <IconButton
          type="button"
          aria-label="search"
          onClick={applySearch}
          style={{ background: "#1769aa", color: "#fff", marginLeft: "5px", borderRadius: 4 }}>
          <SearchIcon style={{ fontSize: 22 }} />
        </IconButton>
      </Box>
      {leftChildren}
    </Row>
  );
}

export default React.memo(ListingSearch);
