import { Grid } from "@mui/material";
import { PatientListingTableView } from "components";
import { PrescriptionApiStateContext, PrescriptionStateContext } from "context/PrescriptionContext";
import { delay, map } from "lodash";
import React, { forwardRef, useCallback, useContext, useEffect, useImperativeHandle, useRef, useState } from "react";
import { ADMIN_PATIENT_COLUMNS } from "utils";

const PrescriptionPatientInfo = forwardRef(({ formId, formData }, parentRef) => {
  const patientListingTableRef = useRef(null);
  const [patientFormError, setPatientFormError] = useState("");
  const { prescriptionData, filterClinicId } = useContext(PrescriptionApiStateContext);
  const { isPrescriptionEdit, preSelectedPatients } = useContext(PrescriptionStateContext);

  const submitAction = useCallback(
    (data, progressBarIndex = null, allowNavigation = true) => {
      formData?.({ info: data }, formId, progressBarIndex, allowNavigation);
    },
    [formData, formId],
  );

  useEffect(() => {
    if (isPrescriptionEdit && prescriptionData?.id && patientListingTableRef.current) {
      const selectedPatientList = map(
        prescriptionData?.patients,
        ({ addresses, ...restPatientData }) => restPatientData,
      );
      patientListingTableRef.current?.setSelectedPatientList(selectedPatientList);
      submitAction(selectedPatientList, null, false);
    }
  }, [isPrescriptionEdit, prescriptionData?.id, prescriptionData?.patients]);

  useEffect(() => {
    // Handle the pre selected Patient. When start ordering from patient listing
    const selectedPatientList = patientListingTableRef.current?.getSelectedPatientList();
    if (
      !isPrescriptionEdit &&
      patientListingTableRef.current &&
      preSelectedPatients.length > 0 &&
      selectedPatientList.length === 0
    ) {
      patientListingTableRef.current?.setSelectedPatientList(preSelectedPatients);
      submitAction(preSelectedPatients, null, true);
    }
  }, [isPrescriptionEdit, preSelectedPatients, submitAction]);

  const canNavigate = useCallback(progressBarIndex => {
    setPatientFormError("");
    const selectedPatient = patientListingTableRef.current?.getSelectedPatientList();
    if (selectedPatient.length) {
      submitAction(selectedPatient, progressBarIndex);
    } else {
      delay(() => setPatientFormError("Please select one or more patients"), 350);
      window.scrollTo(0, 0);
    }
  }, []);

  const requestFormSubmit = useCallback(() => {
    canNavigate(null);
  }, []);

  const checkValidation = useCallback(async () => {
    const selectedPatient = patientListingTableRef.current?.getSelectedPatientList();
    return Boolean(selectedPatient?.length);
  }, []);

  useImperativeHandle(parentRef, () => ({
    submitForm: requestFormSubmit,
    canNavigate: canNavigate,
    checkFilledForm: checkValidation,
  }));

  return (
    <Grid container justifyContent={"center"}>
      <Grid item xs={12} md={11}>
        <PatientListingTableView
          enableRowSelection
          error={patientFormError}
          ref={patientListingTableRef}
          prescriptionClinic={filterClinicId}
          tableHeaders={ADMIN_PATIENT_COLUMNS}
        />
      </Grid>
    </Grid>
  );
});
export default React.memo(PrescriptionPatientInfo);
