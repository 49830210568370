import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL, CACHE_TAGS } from "../rtkConstant";
import { baseQuery } from "./BaseApi";

const ExostarApi = createApi({
  reducerPath: "ExostarApi",
  tagTypes: [CACHE_TAGS.EXOSTAR],
  baseQuery: baseQuery,
  // baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  endpoints: () => ({}),
});

export default ExostarApi;
