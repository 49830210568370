import {
  PrescriptionActionContext,
  PrescriptionApiStateContext,
  PrescriptionFormSateContext,
  PrescriptionStateContext,
} from "context/PrescriptionContext";
import { decrypt } from "cryptofunc";
import { forEach, includes, trim } from "lodash";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";

function usePrescriptionBilling({ setValue, clearErrors }) {
  const [creditError, setCreditError] = useState("");
  const [creditAmount, setCreditAmount] = useState(0);
  const [newCardModal, setNewCardModal] = useState(false);
  const [addNewCard, setAddNewCard] = useState(null);
  const { creditRequestAmount, prescriptionSubTotal, providerFormData } = useContext(PrescriptionFormSateContext);
  const { addUsedCredit } = useContext(PrescriptionActionContext);
  const { prescriptionData } = useContext(PrescriptionApiStateContext);
  const { isPrescriptionEdit } = useContext(PrescriptionStateContext);

  const selectedClinicLocation = providerFormData?.current?.clinicLocation ?? {};

  const cardOptions = useMemo(() => {
    if ((selectedClinicLocation?.encryptedCreditCards?.length ?? 0) === 0) {
      return [
        {
          value: "",
          label: "No providers found!",
          disabled: true,
        },
      ];
    }
    return selectedClinicLocation?.encryptedCreditCards?.map(card => {
      const cardInfo = decrypt(card);
      const label = `${cardInfo?.cardHolderName ?? ""} - **** **** **** ${
        cardInfo?.creditCardNumber?.substr(-4) ?? ""
      }`;

      if (cardInfo.isCardDefault) {
        setValue("creditCard", label);
      }
      return {
        value: label,
        label: label,
      };
    });
  }, [selectedClinicLocation?.encryptedCreditCards, setValue]);

  const billingMethods = useMemo(
    () => [
      {
        id: 0,
        label: "Clinic",
        value: "clinic",
      },
    ],
    [],
  );

  const creditErrormessage = useMemo(() => trim(creditError), [creditError]);

  const remainingCredit = useMemo(
    () => selectedClinicLocation?.credit - Number(creditRequestAmount),
    [selectedClinicLocation?.credit, creditRequestAmount],
  );

  useEffect(() => {
    if (isPrescriptionEdit && prescriptionData?.id) {
      const decryptedBillingInfo = decrypt(prescriptionData?.encryptedBillingInfo);
      setValue("creditCardOwner", decryptedBillingInfo?.creditCardOwner);
      setValue("isNewCardUsed", decryptedBillingInfo?.isNewCardUsed);
      setCreditAmount(prescriptionData?.creditRequested);
      addUsedCredit(prescriptionData?.creditRequested);
      if (includes(decryptedBillingInfo?.creditCard?.id, "card ending")) {
        setValue(
          "creditCard",
          `${decryptedBillingInfo?.creditCard?.cardHolderName ?? ""} - **** **** **** ${
            decryptedBillingInfo?.creditCard?.creditCardNumber?.substr(-4) ?? ""
          }`,
        );
      } else {
        setValue("creditCard", decryptedBillingInfo?.creditCard?.id);
      }
    }
  }, [
    isPrescriptionEdit,
    prescriptionData?.creditRequested,
    prescriptionData?.encryptedBillingInfo,
    prescriptionData?.id,
    setValue,
  ]);

  const getCardInfo = useCallback(
    (cardId = "") => {
      let cardInfo = "";
      forEach(selectedClinicLocation?.encryptedCreditCards, encryptCard => {
        const decryptCardInfo = decrypt(encryptCard);
        const label = `${decryptCardInfo?.cardHolderName ?? ""} - **** **** **** ${
          decryptCardInfo?.creditCardNumber?.substr(-4) ?? ""
        }`;
        if (label === cardId) {
          cardInfo = { id: label, ...decryptCardInfo };
          return false;
        }
      });
      return cardInfo;
    },
    [selectedClinicLocation?.encryptedCreditCards],
  );

  const updateCredit = useCallback(() => {
    setCreditError("");
    if (Number(creditAmount) > Number(selectedClinicLocation?.credit)) {
      setCreditError(`Credit Amount can't be greater than $${selectedClinicLocation?.credit}`);
    } else if (Number(creditAmount) > Number(prescriptionSubTotal)) {
      //  TO DO: Check credit Amount should not be greater than sub total Amount
      setCreditError(`Credit Amount can't be greater than $${prescriptionSubTotal}`);
    } else if (Number(creditAmount) < 0) {
      setCreditError("Credit Amount can't negative");
    } else {
      addUsedCredit(Number(creditAmount));
    }
  }, [addUsedCredit, selectedClinicLocation?.credit, creditAmount, prescriptionSubTotal]);

  const updateCreditAmount = useCallback(
    e => {
      if (!creditRequestAmount) {
        setCreditAmount(e.target.value);
      }
    },
    [creditRequestAmount],
  );
  const closeModal = useCallback(() => {
    setNewCardModal(false);
  }, []);

  const removeNewCard = useCallback(() => {
    setAddNewCard(null);
    setValue("isNewCardUsed", false);
  }, []);

  const setNewCreditCard = useCallback(
    data => {
      clearErrors("creditCard");
      setValue("isNewCardUsed", true);
      setAddNewCard(decrypt(data?.info?.encryptedCreditCards));
      closeModal();
    },
    [clearErrors, closeModal, setValue],
  );

  return {
    addNewCard,
    creditRequestAmount,
    cardOptions,
    creditAmount,
    newCardModal,
    billingMethods,
    remainingCredit,
    creditErrormessage,
    selectedClinicLocation,
    closeModal,
    getCardInfo,
    updateCredit,
    removeNewCard,
    setNewCreditCard,
    setNewCardModal,
    updateCreditAmount,
  };
}

export default usePrescriptionBilling;
