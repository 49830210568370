import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton
} from "@mui/material";
import BAAForm from "components/BAAForm";
import Spinner from "components/spinner";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import html2pdf from "html2pdf.js";
import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Col } from "reactstrap";
import { useUploadFileMutation } from "rtk";

dayjs.extend(utc);
dayjs.extend(timezone);

function BAAFormModal({
  vpiInfo,
  setVpiInfo,
  setCompanyInfo,
  companyInfo,
  showBaaForm,
  closeModal,
  isCompanyInfoReadOnly,
  approveIt,
  disapproveIt,
  setBaaFormPDFUrl,
}) {
  console.log("IS COMPANY READ: ", isCompanyInfoReadOnly);
  const [uploadMutate, { error: uploadError, isError: isUploadError, isLoading: isUploadLoading }] =
    useUploadFileMutation();
  const history = useHistory();
  const [showSpinner, setShowSpinner] = useState(false);

  async function upload(e, name, signPad = "") {
    const reader = new FileReader();
    if (e) {
    } else {
      if (signPad) {
        switch (name) {
          case "vpiInfoSignatureImageUrl":
            setVpiInfo(prevState => ({ ...prevState, signatureUrl: signPad }));
            break;

          case "companyInfoSignatureImageUrl":
            setCompanyInfo(prevState => ({ ...prevState, signatureUrl: signPad }));
            break;
          default:
            break;
        }
      }
    }
  }

  const onClickSave = (person = "signatureImageUrl", sig) => {
    upload("", person, sig);
  };

  async function handleApprove(e) {
    e.preventDefault();
    setShowSpinner(true);
    let element = document.getElementById("content-to-convert");
    var elementToRemove = element.querySelector("span.pointer.form-control");

    // Ensure the element is found before attempting to remove it
    if (elementToRemove) {
      // Remove the element from its parent node
      var parentElement = elementToRemove.parentNode;
      parentElement.removeChild(elementToRemove);

      // Re-select the element to update its value
      element = document.getElementById("content-to-convert");
      element.innerHTML = element.innerHTML.replace("300px", "auto");
      // Now `element` contains the modified content after removing the specified <span> element
    } else {
      console.log("Element not found");
    }

    console.log("ELEMENT: ", element);
    const opt = {
      margin: 1,
      filename: "baa_form.pdf",
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
    };
    const pdf = await html2pdf().from(element).set(opt).toPdf().output("blob");

    const file = new File([pdf], "baa_form.pdf", { type: "application/pdf" });
    const formData = new FormData();

    formData.append("file", file);
    console.log("PDF: ", pdf);
    let res = await uploadMutate(formData);
    if (res?.data) {
      setBaaFormPDFUrl(res?.data?.accessUrl);
      approveIt(e, res?.data?.accessUrl,companyInfo,vpiInfo);

      setShowSpinner(false);
    }
  }

  return (
    <Dialog open={Boolean(showBaaForm)} onClose={closeModal} scroll="paper" fullWidth maxWidth="xl">
      <DialogTitle variant="h5">
        <IconButton
          aria-label="close"
          onClick={closeModal}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: theme => theme.palette.grey[500],
          }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <form onSubmit={handleApprove}>
      {showSpinner ? <Spinner /> : null}
        <DialogContent dividers>
          <div id="content-to-convert">
            {/* <div className="header">
            <img src={BaaImages[1]} style={{ width: "100%" }} />
          </div> */}
            <BAAForm
              vpiInfo={vpiInfo}
              setVpiInfo={setVpiInfo}
              setCompanyInfo={setCompanyInfo}
              companyInfo={companyInfo}
              isCompanyInfoReadOnly={isCompanyInfoReadOnly}
              onClickSave={onClickSave}
              step={3}
            />
          </div>
        </DialogContent>

        <DialogActions className="justify-content-center">
          <Box>
            <Col className="flexJCAC pt-2">
              <Link to="/clinics" onClick={disapproveIt} className="pinkButton text-center  m-2 w-100">
                Disapprove
              </Link>
              <button type="submit" className="greenButton text-center  m-2 w-100">
                Approve
              </button>
            </Col>
          </Box>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default React.memo(BAAFormModal);
