import CloseIcon from "@mui/icons-material/Close";
import { Input } from "reactstrap";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputBase,
  TextField,
} from "@mui/material";
import Spinner from "components/spinner";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useAdminPrescriptionNoteMutation } from "rtk";
import AlertMessage from "screens/admin/Prescriptions/components/alert_message";
import { dateFormatterMountainTime } from "utils/constants";

function PrescriptionNoteModal({ modalData, pageName, handleClose }) {
  const [note, setNote] = useState("");
  const [disableSave, setDisableSave] = useState(false);

  const [updateNotes, { error: notesError, isSuccess: notesSuccess, isLoading: notesLoading, reset: resetMutate }] =
    useAdminPrescriptionNoteMutation();

  const openDialog = useMemo(() => Boolean(modalData?.prescriptionId), [modalData?.prescriptionId]);
  const errorMessage = useMemo(() => notesError?.data?.message || "", [notesError?.data?.message]);

  useEffect(() => {
    setNote(modalData?.noteMessage);
  }, [modalData?.noteMessage]);

  useEffect(() => {
    if (notesSuccess) {
      handleClose();
      setDisableSave(false);
    }
  }, [errorMessage, handleClose, notesSuccess]);

  const checkInput = useCallback(() => setDisableSave(note !== modalData?.noteMessage), [note, modalData?.noteMessage]);
  const closeModal = useCallback(() => {
    handleClose();
  }, []);

  const savePrescription = useCallback(() => {
    resetMutate();
    updateNotes({ prescriptionId: modalData?.prescriptionId, message: note, pageName });
  }, [modalData?.prescriptionId, note, pageName, resetMutate, updateNotes]);

  return (
    <Dialog open={openDialog} scroll="paper" fullWidth maxWidth="md">
      <DialogTitle variant="h5">
        {"Notes"}
        <IconButton
          aria-label="close"
          onClick={closeModal}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: theme => theme.palette.grey[500],
          }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        {notesLoading && <Spinner />}
        <Box className="mt-1">
          {errorMessage ? <AlertMessage isError msg={errorMessage} /> : null}
          <TextField
            style={{ fontSize: "14px" }}
            fullWidth
            multiline
            rows={4}
            maxRows={7}
            type="text"
            onBlur={checkInput}
            value={note}
            onChange={e => {
              setNote(e.target.value);
              if (!disableSave) {
                setDisableSave(true);
              }
            }}
          />
          <Grid container justifyContent={"flex-end"} className="mt-2">
            <Grid item>
              <strong>Created by:</strong> {modalData?.createdBy}
              <br />
              <strong>On: </strong>
              {dateFormatterMountainTime(modalData?.createdOn)}
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={!disableSave || notesLoading}
          variant="contained"
          className={"mx-2"}
          onClick={savePrescription}>
          {"Save"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default React.memo(PrescriptionNoteModal);
