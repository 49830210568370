import Allergy from "assets/images/familyPhotos/Allergy.jpg";
import Analgesic from "assets/images/familyPhotos/Analgesic Photo.jpg";
import Anesthetic from "assets/images/familyPhotos/Anesthetic.jpg";
import Antacid from "assets/images/familyPhotos/Antacid.jpg";
import AntiFugal from "assets/images/familyPhotos/Anti Fungal.jpg";
import Anti from "assets/images/familyPhotos/Anti Inflammatory.jpg";
import Antibiotic from "assets/images/familyPhotos/Antibiotic.jpg";
import Anxiolytic from "assets/images/familyPhotos/Anxiolytic.jpg";
import BAC from "assets/images/familyPhotos/BAC.jpg";
import Dermatology from "assets/images/familyPhotos/Dermatology.jpg";
import HRT from "assets/images/familyPhotos/HRT.jpg";
import HairRestoreTreatment from "assets/images/familyPhotos/Hair Restore Treatment.jpg";
import HairRestore from "assets/images/familyPhotos/Hair Restore.jpg";
import Hypertensive from "assets/images/familyPhotos/Hypertensive.jpg";
import IvTherapy from "assets/images/familyPhotos/IV Therapy.jpg";
import Medical from "assets/images/familyPhotos/Medical Accessory.jpg";
import Nutritional from "assets/images/familyPhotos/Nutritional Support.jpg";
import Ophthalmic from "assets/images/familyPhotos/Opthalmic.jpg";
import Oral from "assets/images/familyPhotos/Oral Therapy.jpg";
import Parasitic from "assets/images/familyPhotos/Parasitic.jpg";
import Peptide from "assets/images/familyPhotos/Peptide Therapy.jpg";
import Recovery from "assets/images/familyPhotos/Recovery.jpg";
import Rush from "assets/images/familyPhotos/Rush.jpg";
import SQTheraphy from "assets/images/familyPhotos/SQ Therapy.jpg";
import SexualHealth from "assets/images/familyPhotos/Sexual Health.jpg";
import Sleep from "assets/images/familyPhotos/Sleep Aid.jpg";
import WeightLoss from "assets/images/familyPhotos/Weightloss.jpg";
import Hormone from "assets/images/familyPhotos/hormone.jpg";
import Injectable from "assets/images/familyPhotos/injectable.jpg";
import Other from "assets/images/familyPhotos/other.jpg";
import Pain from "assets/images/familyPhotos/pain.jpg";
import Sexual from "assets/images/familyPhotos/sexual.jpg";
import Thyroid from "assets/images/familyPhotos/thyroid.jpg";
import Weight from "assets/images/familyPhotos/weight.jpg";
import Wellness from "assets/images/familyPhotos/wellness.jpg";

// import cat1 from "assets/images/categoryPhotos/Capsules.jpg";
// import cat2 from "assets/images/categoryPhotos/Cream.jpg";
// import cat3 from "assets/images/categoryPhotos/Foam Wash.jpg";
// import cat4 from "assets/images/categoryPhotos/Gel.jpg";
// import cat5 from "assets/images/categoryPhotos/Ointment.jpg";
// import cat6 from "assets/images/categoryPhotos/Solution.jpg";
// import cat7 from "assets/images/categoryPhotos/Capsules.jpg";
// import cat8 from "assets/images/categoryPhotos/Cream.jpg";
// import cat9 from "assets/images/categoryPhotos/Foam Wash.jpg";
// import cat10 from "assets/images/categoryPhotos/Gel.jpg";
// import cat11 from "assets/images/categoryPhotos/Ointment.jpg";

const ProductImageMapping = {
  Allergy: Allergy,
  Analgesic: Analgesic,
  Anesthetic: Anesthetic,
  Antacid: Antacid,
  "Anti-Inflammatory": Anti,
  "Anti-Parasitic": Parasitic,
  Antibiotic: Antibiotic,
  "Anti-Fungal": AntiFugal,
  AntiHypertensive: Hypertensive,
  Anxiolytic: Anxiolytic,
  "BAC Water": BAC,
  Dermatology: Dermatology,
  HRT: HRT,
  "Hair Restore": HairRestore,
  "Hair Restore Treatment": HairRestoreTreatment,
  "Oral Therapy": Oral,
  "IV Therapy": IvTherapy,
  "SQ/IM Therapy": SQTheraphy,
  "Medical Accessories": Medical,
  "Nutritional Support": Nutritional,
  "Peptide Therapy": Peptide,
  Recovery: Recovery,
  Rush: Rush,
  "Sexual Health": SexualHealth,
  Injectable: Injectable,
  "Weight Loss Therapy": WeightLoss,
  "Sleep Aid": Sleep,
  Ophthalmic: Ophthalmic,
  Thyroid: Thyroid,
  "Hormone Restoration": Hormone,
  Injectables: Injectable,
  Other: Other,
  "Pain Management": Pain,
  "Sexual Well-Being": Sexual,
  "Weight Management": Weight,
  Wellness: Wellness,
};
export default ProductImageMapping;
