import { CometChat } from "@cometchat-pro/chat";
import { includes } from "lodash";
import { useContext, useEffect, useMemo, useState } from "react";
import { Nav, NavDropdown, Navbar } from "react-bootstrap";
import { useSelector } from "react-redux";
import { LinkContainer } from "react-router-bootstrap";
import { userInfoSelector, userRoleSelector } from "selectors";
import VPICompoundingLogo from "../assets/vpi-images/vpi-logo-white.png";
import "../assets/vpi-styles/loggedInNavbar.css";
import { SHOW_INVOICE_TAB, USER_ROLES } from "../utils";
import { useLocation } from "react-router-dom";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { CartContext } from "context/Cart";

const ClinicNavbar = () => {
  const [messageCount, setMessageCount] = useState("");
  const userRole = useSelector(userRoleSelector);
  const userData = useSelector(userInfoSelector);
  const { toggleCartDrawer } = useContext(CartContext);
  const { pathname } = useLocation();

  const showCartTab = useMemo(() => {
    const pathArray = pathname?.split("/");
    return !(
      pathArray.includes("new-prescription") ||
      pathArray.includes("product-search") ||
      pathArray.includes("edit-prescription") ||
      pathArray.includes("new-prescription-flow")
    );
  }, [pathname]);

  const showInvoiceTab = useMemo(
    () => includes(SHOW_INVOICE_TAB, userRole) || userData?.isSuperUserSameAsProvider,
    [userRole],
  );

  useEffect(() => {
    CometChat.getUnreadMessageCount().then(
      array => {
        setMessageCount(Object.keys(array.users).length ? Object.keys(array.users).length : "");
      },
      error => {},
    );
  }, []);

  return (
    <Navbar collapseOnSelect expand="md" variant="dark" className="navbar">
      <Navbar.Brand id="navbarBrand" href="/" className="navbarBrand">
        <img src={VPICompoundingLogo} alt="logo" className="navLogo" />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="navbar-content" className="navbarToggle">
        <i className="navbarToggleIcon fa fa-fw fa-bars" />
      </Navbar.Toggle>
      <Navbar.Collapse id="navbar-content" className="navbarCollapse">
        <Nav className="ml-auto navbarContent mx-3">
          <LinkContainer
            className="navLink"
            to="/"
            activeClassName={window.location.pathname === "/" ? "activeLink" : ""}>
            <Nav.Link>Home</Nav.Link>
          </LinkContainer>
          <LinkContainer className="navLink" to="/patients" activeClassName="activeLink">
            <Nav.Link>Patients</Nav.Link>
          </LinkContainer>
          <LinkContainer
            className="navLink"
            to={{ pathname: "/new-prescription-flow", state: { productSearch: true } }}>
            <Nav.Link>Products</Nav.Link>
          </LinkContainer>
          <NavDropdown
            title={
              <p className="m-0">
                Prescriptions <i className="mx-1 fas fa-angle-down"></i>
              </p>
            }
            id="nav-dropdown"
            className="navDD navLink">
            <LinkContainer className="navLink navDDLink" to="/pending-prescriptions" activeClassName="activeLinkDark">
              <Nav.Link>Pending Prescriptions</Nav.Link>
            </LinkContainer>
            <NavDropdown.Divider />
            <LinkContainer className="navLink navDDLink" to="/prescription-history" activeClassName="activeLinkDark">
              <Nav.Link>Order Status</Nav.Link>
            </LinkContainer>
          </NavDropdown>
          {showInvoiceTab && (
            <LinkContainer className="navLink" to="/invoices" activeClassName="activeLink">
              <Nav.Link>Invoices</Nav.Link>
            </LinkContainer>
          )}
          <LinkContainer className="navLink" to="/messages" activeClassName="activeLink" style={{ margin: 0 }}>
            <Nav.Link>
              <i className="fas fa-envelope fa-lg" style={{ lineHeight: "20px" }} />
              <span
                style={{
                  color: "red",
                  fontSize: "12px",
                  fontWeight: "800px",
                  marginTop: "-5px",
                  padding: "0px",
                  position: "absolute",
                }}>
                <strong>{messageCount}</strong>
              </span>
            </Nav.Link>
          </LinkContainer>
          {showCartTab && (
            <Nav.Link onClick={toggleCartDrawer}>
              <ShoppingCartIcon style={{ fontSize: 20 }} />
            </Nav.Link>
          )}
          <NavDropdown
            title={
              <p className="m-0">
                <i className="navUserCircle fas fa-user-circle"></i> {`${userData?.firstName} ${userData?.lastName}`}{" "}
                <i className="mx-1 fas fa-angle-down"></i>
              </p>
            }
            id="nav-dropdown"
            className="navDD navLink profileDD">
            <LinkContainer className="navLink navDDLink" to="/profile" activeClassName="activeLinkDark">
              <Nav.Link>Profile</Nav.Link>
            </LinkContainer>
            {userRole?.toString() === USER_ROLES.CLINIC_SUPER_USER || userData?.isSuperUserSameAsProvider ? (
              <>
                <NavDropdown.Divider />
                <LinkContainer className="navLink navDDLink" to="/clinic-profile" activeClassName="activeLinkDark">
                  <Nav.Link>Clinic Information</Nav.Link>
                </LinkContainer>
                <NavDropdown.Divider />
                <LinkContainer className="navLink navDDLink" to="/clinic-locations" activeClassName="activeLinkDark">
                  <Nav.Link>Clinic Locations</Nav.Link>
                </LinkContainer>
                <NavDropdown.Divider />
                <LinkContainer className="navLink navDDLink" to="/clinic-staff" activeClassName="activeLinkDark">
                  <Nav.Link>Clinic Staff</Nav.Link>
                </LinkContainer>
              </>
            ) : null}
            <NavDropdown.Divider />

            <LinkContainer className="navLink navDDLink" to="/logout" activeClassName="activeLink">
              <Nav.Link>
                <i className="mx-1 logoutIcon fas fa-sign-out-alt"></i> Logout
              </Nav.Link>
            </LinkContainer>
          </NavDropdown>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};
export default ClinicNavbar;
