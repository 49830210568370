import { DialogContext } from "context";
import { filter, find, findIndex, forEach, has, isNull, map } from "lodash";
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import {
  ProductCartAction,
  useEditClinicPrescriptionMutation,
  useLazyGetShippingRateQuery,
  useSaveNewPrescriptionMutation,
} from "rtk";
import { LISTING_NAME, PRESCRIPTION_ORDER_STEPPER, STEPPER_FORMS } from "utils";
import { RUSH_ORDER_INFO } from "utils/StatusConstant";

function usePrescriptionProvider({ getEditApiParam, isPrescriptionEdit, filterClinicId, productCartDialogRef }) {
  const dispatch = useDispatch();
  const prescriptionSideCartRef = useRef(null);
  const formRef = useRef([]);
  const inputData = useRef(null);
  const patientData = useRef([]);
  const productData = useRef([]);
  const providerData = useRef(null);
  const shippingData = useRef(null);
  const billingData = useRef(null);
  const [customError, setCustomError] = useState("");
  const [activeStep, setActiveStep] = useState(0);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [selectedClinicLocation, setSelectedClinicLocation] = useState({});
  const [customShippingRate, setCustomShippingRate] = useState(0);
  const [shippingMethod, setShippingMethod] = useState("");
  const [isRushOrder, setIsRushOrder] = useState(null);
  const [usedCredit, setUsedCredit] = useState(0);
  const [subTotalAmount, setSubTotalAmount] = useState(0);
  const [allowReview, setAllowReview] = useState(false);
  const history = useHistory();
  const { showDialog, closeDialog } = useContext(DialogContext);
  const { state: { clinicId: adminClinicId = "", fromRxWorkFlow = false, productSearch = false } = {} } = useLocation();

  const [completedStep, setCompletedStep] = useState(() =>
    isPrescriptionEdit
      ? { 0: true, 1: true, 2: true, 3: true, 4: true }
      : productSearch
      ? { 0: false, 1: true }
      : { 0: true },
  );

  const [
    rxSaveMutate,
    {
      data: saveResponseData,
      isLoading: saveLoading,
      error: saveError,
      isSuccess: isSaveSuccess,
      reset: resetSaveMutation,
    },
  ] = useSaveNewPrescriptionMutation();

  const [
    editMutate,
    { isLoading: editLoading, originalArgs, isSuccess: editSuccess, error: editError, reset: resetEditMutation },
  ] = useEditClinicPrescriptionMutation();

  const isLastForm = useMemo(() => formRef.current?.length - 1 === activeStep, [activeStep, formRef]);

  const mutateLoading = useMemo(() => saveLoading || editLoading, [editLoading, saveLoading]);
  const mutateError = useMemo(
    () => saveError?.data?.message || editError?.data?.message || "",
    [saveError?.data?.message, editError?.data?.message],
  );

  useEffect(() => {
    if (productSearch) {
      setActiveStep(1);
    }
  }, [productSearch]);

  const goBack = useCallback(
    e => {
      e?.preventDefault();
      setActiveStep(prev => {
        if (prev === 2) {
          dispatch(ProductCartAction.removeLastProductSummary());
        }
        if (prev > 0) {
          if (productSearch) {
            setCompletedStep(prevStep => ({ ...prevStep, [prev - 1]: true }));
          }
          return prev - 1;
        }
        return prev;
      });
    },
    [productSearch],
  );

  const goNext = useCallback(
    e => {
      e.preventDefault();
      formRef.current[activeStep]?.submitForm?.();
    },
    [activeStep],
  );

  const reviewButton = useCallback(
    e => {
      e.preventDefault();
      setAllowReview(true);
      formRef.current[activeStep]?.submitForm?.();
    },
    [activeStep],
  );

  const progressBarAction = useCallback((pageIndex, presentPage) => {
    if (pageIndex < presentPage) {
      setActiveStep(pageIndex);
      if (pageIndex <= 1 && presentPage > 1) {
        dispatch(ProductCartAction.removeLastProductSummary());
      }
    } else {
      // WHen User wants to go Next Form
      // TODO: Logic to check the Validation for current form and move to next
      formRef?.current?.[presentPage]?.canNavigate?.(pageIndex);
    }
  }, []);

  const navigateForm = useCallback(
    (nextIndex = null) => {
      if (productSearch && !completedStep[activeStep - 1] && activeStep > 0) {
        setCompletedStep(prev => ({ ...prev, [activeStep - 1]: true }));
        setActiveStep(activeStep - 1);
      } else {
        const nextPage = isNull(nextIndex) ? activeStep + 1 : nextIndex;
        if (nextPage <= PRESCRIPTION_ORDER_STEPPER.length - 1) {
          setCompletedStep(prev => ({ ...prev, [nextPage]: true }));
          setActiveStep(nextPage);
        }
      }
    },
    [activeStep, completedStep, productSearch],
  );

  const checkFormsValidation = useCallback(async () => {
    for (let i = 0; i < formRef.current.length - 1; i++) {
      const passed = await formRef?.current?.[i]?.checkFilledForm?.();
      if (!passed) {
        return { pageToFill: i };
      }
    }
  }, []);

  const backNavigation = useCallback(() => {
    closeDialog();
    resetSaveMutation();
    resetEditMutation();
    history?.goBack();
  }, [closeDialog, history, resetEditMutation, resetSaveMutation]);

  const editBackNavigation = useCallback(() => {
    resetSaveMutation();
    resetEditMutation();
    history.replace({
      pathname: "/pending-prescriptions",
      state: originalArgs?.side === LISTING_NAME.CLINIC_PENDING_NEEDED ? "2" : "1",
    });
    closeDialog();
  }, [closeDialog, history, originalArgs?.side, resetEditMutation, resetSaveMutation]);

  const navigateToPending = useCallback(() => {
    resetSaveMutation();
    resetEditMutation();
    history.replace({ pathname: "/pending-prescriptions", state: "2" });
    closeDialog();
  }, [closeDialog, history, resetEditMutation, resetSaveMutation]);

  const navigateToReview = useCallback(() => {
    closeDialog();
    resetSaveMutation();
    resetEditMutation();
    history.replace({
      pathname: `/review-prescription/${adminClinicId ? adminClinicId + "/" : ""}${originalArgs?.prescriptionId}`,
      state: {
        isReview: true,
        clinicId: filterClinicId,
        shipTo: originalArgs?.shippingInfo?.shipTo,
        flow: "EDIT",
        side: originalArgs?.side,
      },
    });
  }, [
    adminClinicId,
    closeDialog,
    filterClinicId,
    history,
    originalArgs?.prescriptionId,
    originalArgs?.shippingInfo?.shipTo,
    originalArgs?.side,
    resetEditMutation,
    resetSaveMutation,
  ]);

  const navigateToRxWorkflow = useCallback(() => {
    closeDialog();
    resetSaveMutation();
    resetEditMutation();
    history.replace({
      pathname: "/prescription-history",
      state: {
        state: "2",
      },
    });
  }, [closeDialog, history, resetEditMutation, resetSaveMutation]);

  useEffect(() => {
    if (editSuccess && originalArgs) {
      showDialog({
        question: "Your prescription has been saved successfully!",
        actionText: fromRxWorkFlow ? "RX Workflow" : "Review Prescription",
        cancelText: "Back",
        closeAble: false,
        closeModal: editBackNavigation,
        onClickNo: editBackNavigation,
        onClickYes: fromRxWorkFlow ? navigateToRxWorkflow : navigateToReview,
      });
    }
  }, [
    editBackNavigation,
    editSuccess,
    fromRxWorkFlow,
    navigateToReview,
    navigateToRxWorkflow,
    originalArgs,
    showDialog,
  ]);

  useEffect(() => {
    if (isSaveSuccess && saveResponseData) {
      if (allowReview) {
        history.replace({
          pathname: `/review-prescription/${adminClinicId ? adminClinicId + "/" : ""}${
            saveResponseData?.prescriptionId
          }`,
          state: { shipTo: saveResponseData?.shippingInfo?.shipTo, side: "newRx" },
        });
      } else {
        showDialog({
          question: "Your prescription has been saved successfully!",
          actionText: "Saved Prescription",
          cancelText: "Back",
          closeAble: false,
          closeModal: backNavigation,
          onClickNo: backNavigation,
          onClickYes: navigateToPending,
        });
      }
    }
  }, [
    adminClinicId,
    allowReview,
    backNavigation,
    filterClinicId,
    history,
    isSaveSuccess,
    navigateToPending,
    saveResponseData,
    showDialog,
  ]);

  const savePrescriptionOrder = useCallback(async () => {
    const editApiParam = getEditApiParam();
    let products = [],
      rxPadProducts = [];
    forEach(selectedProducts, product => {
      if (product.productType === "rxPad") {
        rxPadProducts.push({
          name: product.name,
          quantity: product.quantity,
          family: product.family,
          strength: product.strength,
          refills: product.refills,
          sig: product.sig,
          reasonForCompoundedMedication: product.reasonForCompoundedMedication,
        });
      } else {
        products.push(product);
      }
    });
    const patientIds = map(inputData?.current?.patients, "id");
    const providerInfo = inputData?.current?.provider;

    const totalProductAmount = parseFloat(
      selectedProducts.reduce((total, product) => total + product.quantity * product.discountedPrice, 0) *
        (patientIds.length || 1),
    );
    const rushOrderCost = isRushOrder
      ? parseFloat(
          parseFloat(RUSH_ORDER_INFO.value) + (parseFloat(RUSH_ORDER_INFO.percentOfOrder) / 100) * totalProductAmount,
        )
      : 0;

    const organizedPayload = {
      patientIds,
      clinicLocationId: providerInfo?.clinicLocationId,
      providerId: providerInfo?.id,
      products,
      rxPadProducts,
      shippingInfo: {
        ...inputData?.current?.shipping,
        rushOrderCost,
        rushOrderMethod: isRushOrder ? RUSH_ORDER_INFO.name : "",
      },
      ...inputData?.current?.billing,
      ...editApiParam,
    };
    if (editApiParam?.prescriptionId) {
      editMutate({ ...editApiParam, ...organizedPayload });
    } else {
      rxSaveMutate({ prescription: organizedPayload });
    }
  }, [editMutate, getEditApiParam, isRushOrder, rxSaveMutate, selectedProducts]);

  const checkAllFormValidation = useCallback(async () => {
    const formValidation = await checkFormsValidation();
    if (has(formValidation, "pageToFill")) {
      setActiveStep(formValidation.pageToFill);
    } else {
      savePrescriptionOrder();
    }
  }, [savePrescriptionOrder, checkFormsValidation]);

  const getSingleFormData = useCallback(formType => {
    let formData = {};
    switch (formType) {
      case STEPPER_FORMS.PATIENT_INFO:
        formData = patientData.current;
        break;
      case STEPPER_FORMS.PRODUCT_INFO:
        formData = productData.current;
        break;
      case STEPPER_FORMS.PROVIDER_INFO:
        formData = providerData.current;
        break;
      case STEPPER_FORMS.SHIPPING_INFO:
        formData = shippingData.current;
        break;
      case STEPPER_FORMS.BILLING_INFO:
        formData = billingData.current;
        break;
      default:
        formData = {};
    }
    return formData;
  }, []);

  const formData = useCallback(
    (data, formType = "", progressBarIndex = null, allowNavigation = true) => {
      inputData.current = null;
      switch (formType) {
        case STEPPER_FORMS.PATIENT_INFO:
          patientData.current = [...data.info];
          break;
        case STEPPER_FORMS.PRODUCT_INFO:
          productData.current = [...data.info];
          break;
        case STEPPER_FORMS.PROVIDER_INFO:
          providerData.current = { ...data.info };
          setSelectedClinicLocation(data.info?.clinicLocation);
          break;
        case STEPPER_FORMS.SHIPPING_INFO:
          shippingData.current = { ...data.info };
          break;
        case STEPPER_FORMS.BILLING_INFO:
          billingData.current = { ...data.info };
          break;
        default:
          patientData.current = [];
          productData.current = [];
          providerData.current = null;
          shippingData.current = null;
          billingData.current = null;
          break;
      }

      inputData.current = {
        ...inputData.current,
        patients: [...patientData.current],
        products: [...productData.current],
        provider: { ...providerData.current },
        shipping: { ...shippingData.current },
        billing: { ...billingData.current },
      };

      if (allowNavigation) {
        if (isLastForm) {
          checkAllFormValidation();
        } else {
          navigateForm(progressBarIndex);
        }
      }
    },
    [checkAllFormValidation, isLastForm, navigateForm],
  );

  const prescriptionSubTotal = useMemo(() => subTotalAmount, [subTotalAmount]);

  // Sub Total
  const subTotalCalculation = useCallback(
    updatedProducts => {
      const totalProductAmount = parseFloat(
        (updatedProducts ?? selectedProducts).reduce(
          (total, product) => total + product.quantity * product.discountedPrice,
          0,
        ) * (patientData?.current?.length || 1),
      );
      const rushOrderCharges = isRushOrder
        ? parseFloat(
            parseFloat(RUSH_ORDER_INFO.value) + (parseFloat(RUSH_ORDER_INFO.percentOfOrder) / 100) * totalProductAmount,
          )
        : 0;
      setSubTotalAmount(totalProductAmount + rushOrderCharges);
    },
    [isRushOrder, selectedProducts],
  );

  //  in Form Selected Product
  const allowToAddProduct = useMemo(
    () => !(patientData?.current?.length > 1 && selectedProducts.length > 0),
    [patientData?.current?.length, selectedProducts.length],
  );

  const handleCustomProductError = useCallback((errorMessage = "") => {
    setCustomError(errorMessage);
  }, []);

  const handleSelectedProduct = useCallback(
    (selectProduct, replaceProducts = false) => {
      if (replaceProducts) {
        setSelectedProducts(selectProduct);
        subTotalCalculation();
      } else {
        setSelectedProducts(prev => {
          const newState = filter(prev, x => findIndex(selectProduct, { _id: x._id }) === -1);
          newState.push(...selectProduct);
          subTotalCalculation(newState);
          return newState;
        });
      }
    },
    [subTotalCalculation],
  );

  const removeSelectedProduct = useCallback(
    productId => {
      setSelectedProducts(prev => {
        const filteredProducts = filter(prev, productObj => productObj._id !== productId);
        productData.current = filteredProducts;
        inputData.current = {
          ...inputData.current,
          products: filteredProducts,
        };
        subTotalCalculation(filteredProducts);
        return filteredProducts;
      });
    },
    [subTotalCalculation],
  );

  const addToSidebarSummary = useCallback(() => {
    if (selectedProducts.length > 0 && patientData.current.length > 0) {
      dispatch(
        ProductCartAction.addNewProductSummary({
          clinicId: adminClinicId,
          clinicLocationId: patientData.current?.[0]?.clinicLocationId,
          product: selectedProducts,
          patient: patientData.current,
        }),
      );
    }
  }, [dispatch, adminClinicId, selectedProducts, patientData.current]);

  // Add Product to Cart Modal Action
  const showProductCartDialog = useCallback((showObj, callback) => {
    productCartDialogRef.current?.showProductCartDialog?.(showObj, callback);
  }, []);

  const addProductToCartAction = useCallback(
    (product, productId) => () => {
      if (allowToAddProduct || findIndex(selectedProducts, { _id: productId }) !== -1) {
        handleCustomProductError();
        if (productId) {
          const selectedProduct = find(selectedProducts, { _id: productId });
          showProductCartDialog(selectedProduct, handleSelectedProduct);
        } else {
          showProductCartDialog(product, handleSelectedProduct);
        }
      } else {
        handleCustomProductError("You can not select more than one product for Bulk Orders!");
        window.scroll(0, 0);
      }
    },
    [allowToAddProduct, handleCustomProductError, selectedProducts, showProductCartDialog, handleSelectedProduct],
  );

  // Shipping
  const [rateMutate, { data: shippingRateData, isFetching: shippingRateLoading, error: shippingRateError }] =
    useLazyGetShippingRateQuery();

  const shippingRate = useMemo(() => {
    if (shippingMethod === "CustomRate") {
      return customShippingRate?.toString();
    } else if (shippingMethod === "PickUp") {
      return "";
    } else if (shippingRateData) {
      return shippingRateData?.shippingCost?.toString();
    }
  }, [customShippingRate, shippingMethod, shippingRateData]);

  const toggleRushOrder = useCallback((flag = false) => {
    setIsRushOrder(flag);
  }, []);

  const rushOrderCheck = useMemo(() => isRushOrder, [isRushOrder]);

  // Billing

  const creditRequestAmount = useMemo(() => usedCredit, [usedCredit]);

  const addUsedCredit = useCallback(creditAmount => {
    setUsedCredit(prev => {
      if (prev > 0) {
        return 0;
      }
      return creditAmount;
    });
  }, []);

  return {
    formRef,
    activeStep,
    customError,
    patientData,
    productData,
    mutateError,
    mutateLoading,
    shippingRate,
    providerData,
    completedStep,
    rushOrderCheck,
    shippingMethod,
    prescriptionSubTotal,
    creditRequestAmount,
    selectedProducts,
    shippingRateError,
    shippingRateLoading,
    selectedClinicLocation,
    prescriptionSideCartRef,
    formData,
    goBack,
    goNext,
    rateMutate,
    reviewButton,
    setShippingMethod,
    getSingleFormData,
    progressBarAction,
    addToSidebarSummary,
    addUsedCredit,
    toggleRushOrder,
    subTotalCalculation,
    handleSelectedProduct,
    removeSelectedProduct,
    setCustomShippingRate,
    addProductToCartAction,
    handleCustomProductError,
  };
}

export default usePrescriptionProvider;
