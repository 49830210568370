import AddIcon from "@mui/icons-material/Add";
import AddCardIcon from "@mui/icons-material/AddCard";
import CancelIcon from "@mui/icons-material/Cancel";
import RemoveIcon from "@mui/icons-material/Remove";
import {
  Box,
  Button,
  Divider,
  FormHelperText,
  Grid,
  IconButton,
  InputBase,
  List,
  ListItem,
  ListItemText,
  TextField,
  Typography,
} from "@mui/material";
import { FormRadioField, FormSelectField, PrescriptionBillingCardModal } from "components";
import Spinner from "components/spinner";
import { PrescriptionApiStateContext } from "context/PrescriptionContext";
import { encrypt } from "cryptofunc";
import { isNull, map } from "lodash";
import React, { forwardRef, useCallback, useContext, useImperativeHandle, useMemo, useRef } from "react";
import { useForm } from "react-hook-form";
import { COMMON_TERMS, NET30_TERMS } from "utils";
import { usePrescriptionBilling } from "../Hook";

function PrescriptionBilling({ formId, formData }, parentRef) {
  const formRef = useRef();

  const { isNet30 } = useContext(PrescriptionApiStateContext);
  const { control, handleSubmit, clearErrors, setValue, trigger } = useForm({
    defaultValues: {
      creditCardOwner: "clinic",
      isNewCardUsed: false,
    },
  });
  const {
    addNewCard,
    cardOptions,
    newCardModal,
    creditAmount,
    billingMethods,
    remainingCredit,
    creditRequestAmount,
    creditErrormessage,
    selectedClinicLocation,
    closeModal,
    getCardInfo,
    updateCredit,
    removeNewCard,
    setNewCardModal,
    setNewCreditCard,
    updateCreditAmount,
  } = usePrescriptionBilling({
    setValue,
    clearErrors,
  });

  const submitAction = useCallback(
    async (data, progressBarIndex = null, allowNavigation = true) => {
      let encryptedCard = addNewCard;
      if (!data?.isNewCardUsed) {
        encryptedCard = getCardInfo(data?.creditCard);
      }
      const encryptedBillingInfo = encrypt({
        ...data,
        creditCard: encryptedCard,
      });
      const finalOrganizedData = {
        creditRequested: creditRequestAmount,
        encryptedBillingInfo,
      };
      formData?.({ info: finalOrganizedData }, formId, progressBarIndex, allowNavigation);
    },
    [addNewCard, formData, formId, getCardInfo, creditRequestAmount],
  );

  const canNavigate = useCallback(
    progressBarIndex => {
      handleSubmit(data => submitAction(data, progressBarIndex))();
    },
    [handleSubmit, submitAction],
  );

  const checkValidation = useCallback(async () => {
    const formValidated = await trigger();
    return formValidated;
  }, [trigger]);

  const requestFormSubmit = useCallback(() => formRef.current?.requestSubmit?.(), []);

  useImperativeHandle(parentRef, () => ({
    submitForm: requestFormSubmit,
    canNavigate: canNavigate,
    checkFilledForm: checkValidation,
  }));

  const termsView = useMemo(() => {
    let termsArray = COMMON_TERMS;
    if (isNet30) {
      termsArray = NET30_TERMS;
    }
    return map(termsArray?.list, terms => (
      <ListItem key={terms.id}>
        <ListItemText
          className="my-0"
          style={{ display: "list-item" }}
          primary={terms.text}
          primaryTypographyProps={{
            textAlign: "justify",
          }}
        />
      </ListItem>
    ));
  }, [isNet30]);

  const renderNewCardView = useMemo(() => {
    if (isNull(addNewCard)) {
      return (
        <Grid item xs={12} textAlign={"center"}>
          <Button
            color={"primary"}
            size="large"
            variant="contained"
            className="pinkButton"
            startIcon={<AddCardIcon />}
            onClick={e => {
              e.preventDefault();
              setNewCardModal(true);
            }}>
            Add New Card
          </Button>
        </Grid>
      );
    } else {
      const newCreditCardInfo = `${addNewCard?.cardHolderName ?? ""} - **** **** **** ${
        addNewCard?.creditCardNumber?.substr(-4) ?? ""
      }`;
      return (
        <Grid container item xs={12} justifyContent={"space-between"} alignItems={"center"}>
          <Grid item xs={10}>
            <TextField
              id="outlined-basic"
              label="New Credit Card"
              variant="outlined"
              size="small"
              fullWidth
              contentEditable={false}
              value={newCreditCardInfo}
            />
          </Grid>
          <IconButton aria-label="delete" size="small" style={{ width: 25, height: 25 }} onClick={removeNewCard}>
            <CancelIcon color="error" style={{ width: 20, height: 20 }} />
          </IconButton>
        </Grid>
      );
    }
  }, [addNewCard, removeNewCard]);

  return (
    <>
      <Box id={formId} ref={formRef} component={"form"} onSubmit={handleSubmit(data => submitAction(data, null))}>
        <Grid container justifyContent={"center"}>
          <Spinner showSpinner={false} />
          <Grid container justifyContent={"space-around"}>
            <Grid item md={3} xl={2} display={{ xs: "none", md: "flex" }} />
            <Grid item container xs={12} md={9} lg={9} xl={9} px={2} rowGap={2}>
              <Typography variant="h6" color={"primary"}>
                Select Credit Card
              </Typography>
              <Grid item xs={12}>
                <FormRadioField
                  control={control}
                  name={"creditCardOwner"}
                  MuiFieldProps={{ label: "Bill To", disabled: !isNull(addNewCard) }}
                  radioOptions={billingMethods}
                />
              </Grid>
              <Grid container rowSpacing={2}>
                <Grid item xs={12}>
                  <FormSelectField
                    name="creditCard"
                    control={control}
                    rules={{ required: !isNull(addNewCard) ? false : "Credit Card is required." }}
                    MuiFieldProps={{
                      label: "Credit Card",
                      disabled: !isNull(addNewCard),
                      optionArray: cardOptions,
                    }}
                  />
                </Grid>
                {renderNewCardView}
              </Grid>
              <Divider my={2} />
              <Grid item container xs={12} rowSpacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h6" color={"primary"}>
                    Apply Credit
                  </Typography>
                </Grid>
                <Grid item xs={12} lg={8}>
                  <Typography>
                    Total Credit on {selectedClinicLocation?.locationName ?? "selected Clinic Location"}:
                    <b>{` $${selectedClinicLocation?.credit}`}</b>
                  </Typography>
                  <Typography>How much credit would you like to apply to this payment?</Typography>

                  <Box
                    border={0.5}
                    borderRadius={creditErrormessage ? 2 : 1}
                    my={1}
                    sx={{
                      display: "flex",
                      padding: 0,
                      alignItems: "center",
                      borderColor: creditErrormessage ? "error.main" : undefined,
                    }}>
                    <InputBase
                      sx={{ ml: 1, flex: 1 }}
                      type="number"
                      placeholder="Add Credit"
                      inputProps={{ min: 0 }}
                      disabled={Boolean(creditRequestAmount)}
                      value={creditAmount}
                      onChange={updateCreditAmount}
                    />
                    <Button
                      color={creditRequestAmount ? "error" : "primary"}
                      size="small"
                      variant="contained"
                      onClick={updateCredit}>
                      {creditRequestAmount ? (
                        <RemoveIcon style={{ fontSize: 22 }} />
                      ) : (
                        <AddIcon style={{ fontSize: 22 }} />
                      )}
                    </Button>
                  </Box>
                  {Boolean(creditErrormessage) && (
                    <FormHelperText error={Boolean(creditErrormessage)} variant="outlined" style={{ fontSize: 10 }}>
                      {creditErrormessage}
                    </FormHelperText>
                  )}
                  <Typography>
                    Total Credit remaining after this Payment: <b>${remainingCredit}</b>
                  </Typography>
                </Grid>
              </Grid>
              <Divider my={2} />
              <Grid item container xs={12} rowSpacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h6" color={"primary"}>
                    Terms and Conditions
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography color={"success.main"}>Your clinic is currently under credit card terms</Typography>
                </Grid>
                <Grid item xs={12}>
                  <List component={"ol"} sx={{ listStyle: "auto" }} dense>
                    {termsView}
                  </List>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <PrescriptionBillingCardModal
        showModal={newCardModal}
        handleClose={closeModal}
        setNewCreditCard={setNewCreditCard}
      />
    </>
  );
}

export default React.memo(forwardRef(PrescriptionBilling));
