import { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { useGetClinicLocationsQuery, useGetStaffQuery } from "rtk";
import { userInfoSelector } from "selectors";

function useStaffApi() {
  const { pathname, state: { clinicId: clinicParam } = {} } = useLocation();
  const { staffId = "" } = useParams();
  const userInfo = useSelector(userInfoSelector);
  const staffUserId = useMemo(() => staffId || userInfo?.id, [staffId, userInfo?.id]);
  const isEditProfile = useMemo(() => pathname.includes("edit-profile"), [pathname]);
  const isStaffEdit = useMemo(() => pathname.includes("edit-staff") || pathname.includes("edit-profile"), [pathname]);

  const {
    data: staffData,
    error: staffError,
    isFetching: staffFetching,
  } = useGetStaffQuery({ staffId: staffUserId }, { skip: !isStaffEdit });

  const filterClinicId = useMemo(
    () => clinicParam || staffData?.clinicId || userInfo?.clinicId || "",
    [clinicParam, staffData?.clinicId, userInfo?.clinicId],
  );

  const {
    data: clinicLocationData,
    isFetching: clinicFetching,
    error: clinicError,
  } = useGetClinicLocationsQuery({ clinicId: filterClinicId }, { skip: !filterClinicId });

  const clinicLocationOption = useMemo(() => {
    if (clinicFetching) {
      return [
        {
          value: "",
          label: "Loading",
          disabled: true,
        },
      ];
    }
    return clinicLocationData?.clinicLocations?.map(location => ({
      value: location?.id,
      label: location?.locationName ?? "",
    }));
  }, [clinicFetching, clinicLocationData?.clinicLocations]);

  const apiLoading = useMemo(() => staffFetching, [staffFetching]);

  const apiError = useMemo(
    () => clinicError?.data?.message || staffError?.data?.message || "",
    [clinicError?.data?.message, staffError?.data?.message],
  );

  const getEditApiParam = useCallback(() => {
    const apiParam = { clinicId: filterClinicId || "" };
    if (isEditProfile) {
      apiParam["isEditProfile"] = true;
    }
    if (staffData?.id) {
      apiParam["staffId"] = staffData?.id;
    }
    return apiParam;
  }, [filterClinicId, isEditProfile, staffData?.id]);

  return {
    apiError,
    apiLoading,
    staffData,
    isStaffEdit,
    isEditProfile,
    clinicFetching,
    clinicLocationOption,
    getEditApiParam,
    filterClinicId
  };
}

export default useStaffApi;
