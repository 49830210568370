/* eslint-disable react-hooks/exhaustive-deps */
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import axios from "axios";
import { FormCheckBox, FormFileField, FormOutlineField, FormSelectField } from "components";
import { ProfileImageStyled } from "components/StyledComponent";
import Spinner from "components/spinner";
import { PatientActionContext, PatientApiActionContext, PatientApiStateContext, PatientStateContext } from "context";
import dayjs from "dayjs";
import { filter, includes } from "lodash";
import React, {
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { useForm, useWatch } from "react-hook-form";
import { useSelector } from "react-redux";
import { Alert, Col } from "reactstrap";
import { useExostarRegisterMutation } from "rtk/rtkEndpoints/Exostar";
import { userInfoSelector, userRoleSelector } from "selectors";
import Cookies from "universal-cookie";
import { ETHNICITY, HIDE_PATIENT_LOCATION, MARTIAL_STATUS, RACE, SEX, USA_STATES, getFileNames } from "utils";

const ExostarRegisterModal = props => {
  const [passExostar, { isFetching }] = useExostarRegisterMutation();
  const { showModal, closeModal } = props;
  const formRef = useRef(null);
  const { control, handleSubmit, setValue, getValues, trigger } = useForm({
    defaultValues: {
      givenName: "",
      middleName: "",
      familyName: "",
      streetAddress1: "",
      regionCode: "",
      locality: "",
      postalCode: "",
      country: "US",
      emailAddress: "",
    },
  });
  const [showSpinner, setShowSpinner] = useState(false);
  const [error, setError] = useState("");

  function convertObjectValuesToUpperCase(obj) {
    return Object.keys(obj).reduce((result, key) => {
      result[key] = typeof obj[key] === "string" ? obj[key].toUpperCase() : obj[key];
      return result;
    }, {});
  }
  async function submitAction() {
    setShowSpinner(true);
    let bodyVal = convertObjectValuesToUpperCase(getValues());

    try {
      const resp = await passExostar(bodyVal);
      if (resp?.data) {
        setError("");
        window.location.href = resp.data.redirectUrl;
      }
      else{
        setShowSpinner(false);
        setError(resp?.error?.data?.message);
      }
    } catch (error) {
      setShowSpinner(false);
      setError(error?.response?.data?.message);
      console.error("An error occurred:", error?.response?.data?.message);
      // Handle error, maybe show a message to the user
      // this.setState({
      //   error: "An error occurred while trying to fetch admins list!",
      // });
    }
  }

  return (
    <Dialog open={Boolean(showModal)} onClose={closeModal} scroll="paper" fullWidth maxWidth="xl">
      <DialogTitle variant="h3">
        <p>Exostar Two Factor Authentication</p>
        <IconButton
          aria-label="close"
          onClick={closeModal}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: theme => theme.palette.grey[500],
          }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Box
        id={"exostarRegisterForm"}
        ref={formRef}
        component={"form"}
        onSubmit={handleSubmit(data => submitAction(data, null))}>
        {error ? (
          <Alert color="danger" className="text-center">
            {error}
          </Alert>
        ) : null}

        {showSpinner ? <Spinner /> : null}
        <DialogContent dividers>
          <Grid container columnSpacing={3} rowSpacing={1}>
            <Grid item xs={12} sm={6} md={4}>
              <FormOutlineField
                name="givenName"
                control={control}
                rules={{ required: "First name is required." }}
                MuiFieldProps={{ label: "First name" }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormOutlineField name="middleName" control={control} MuiFieldProps={{ label: "Middle name" }}  rules={{ required: "Middle name is required." }} />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormOutlineField
                name="familyName"
                control={control}
                rules={{ required: "Last name is required." }}
                MuiFieldProps={{ label: "Last name" }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormOutlineField
                name="emailAddress"
                control={control}
                rules={{ required: "Email Address is required." }}
                MuiFieldProps={{ label: "Email Address" }}
              />
            </Grid>      
          </Grid>
        </DialogContent>

        <DialogActions className="justify-content-center">
          <Box>
            <Col className="flexJCAC pt-2">
              <button type="submit" className="pinkButton text-center  m-2 w-100">
                Register
              </button>
            </Col>
          </Box>
        </DialogActions>
      </Box>
    </Dialog>
  );
};
export default ExostarRegisterModal;
