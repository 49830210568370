import CancelIcon from "@mui/icons-material/Cancel";
import { Box, Divider, Grid, IconButton, List, ListItem, ListItemText, Paper, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { map } from "lodash";
import React, { useCallback, useMemo } from "react";
import { RUSH_ORDER_INFO } from "utils/StatusConstant";

function ProductSelectedContainer({
  showProducts = true,
  showPatients = true,
  readOnly = false,
  rushOrder = false,
  creditRequested = 0,
  selectedPatients = [],
  selectedProducts = [],
  addToCartAction,
  shippingCost = "",
  removeSelectedProduct,
}) {
  const removeProduct = useCallback(
    productId => () => {
      removeSelectedProduct(productId);
    },
    [removeSelectedProduct],
  );
  const emptyView = useMemo(() => {
    if (selectedProducts.length === 0) {
      return (
        <Typography variant="body1" textAlign={"center"} my={2}>
          No products selected
        </Typography>
      );
    }
  }, [selectedProducts.length]);

  const containerPriceRow = useCallback(
    (title, value = "-") => (
      <Grid item container justifyContent={"space-between"}>
        <Grid item>
          <Typography variant="body1" fontWeight={"500"}>
            {title}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="body1" fontWeight={"500"}>
            {value}
          </Typography>
        </Grid>
      </Grid>
    ),
    [],
  );

  const renderPatientNames = useMemo(() => {
    if (!showPatients) {
      return null;
    } else {
      const patientNames =
        selectedPatients.length < 1 ? (
          <ListItemText className="my-0" primary={"No Patient Selected"} primaryTypographyProps={{ color: "grey" }} />
        ) : (
          selectedPatients?.map(patient => (
            <ListItem key={patient.id}>
              <ListItemText
                className="my-0"
                style={{ display: "list-item" }}
                primary={patient?.patientName || `${patient?.firstName} ${patient?.lastName}`}
                primaryTypographyProps={{
                  noWrap: true, // this will truncate the text wil ellipses in the end
                }}
              />
            </ListItem>
          ))
        );
      return (
        <>
          <Typography variant="subtitle1" fontWeight={"500"}>{`Patient ${
            selectedPatients?.length > 1 ? "Names" : "Name:"
          }`}</Typography>
          <List
            sx={{
              listStyle: "decimal",
            }}
            dense={true}>
            {patientNames}
          </List>
        </>
      );
    }
  }, [selectedPatients, showPatients]);

  const containerProductAction = useCallback(
    id => {
      if (showProducts && !readOnly) {
        return (
          <Box>
            <IconButton
              aria-label="close"
              onClick={addToCartAction(null, id)}
              sx={{
                color: theme => theme.palette.grey[600],
              }}
              size="small">
              <i className="fas fa-edit" aria-hidden="true" style={{ color: grey[600], fontSize: 12 }} />
            </IconButton>
            <IconButton
              aria-label="close"
              onClick={removeProduct(id)}
              sx={{
                color: theme => theme.palette.grey[600],
              }}
              size="small">
              <CancelIcon fontSize="small" />
            </IconButton>
          </Box>
        );
      }
    },
    [addToCartAction, readOnly, removeProduct, showProducts],
  );

  const renderProductNames = useMemo(() => {
    if (selectedProducts.length > 0) {
      return map(selectedProducts, product => (
        <Grid key={product._id} item container xs={12} my={1} columnSpacing={1} justifyContent={"space-between"}>
          <Grid item container xs={10}>
            {containerProductAction(product?._id)}
            <Grid item xs={9} sm={10} md={9} lg={8} xl={9} sx={{ overflowWrap: "break-word" }}>
              <Typography component={"span"} variant="body1">
                {product?.name}
              </Typography>
              <Typography component={"p"} variant="caption">
                <Typography component={"span"} variant="caption" color={"primary"}>
                  Unit Quantity:
                </Typography>
                {product?.quantity}
              </Typography>
              <Typography component={"p"} variant="caption">
                <Typography component={"span"} variant="caption" color={"primary"}>
                  Day Supply:
                </Typography>
                {product?.daySupply}
              </Typography>
              <Typography component={"p"} variant="caption">
                <Typography component={"span"} variant="caption" color={"primary"}>
                  Sig per Med:
                </Typography>
                {product?.sig}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={2} textAlign={"end"}>
            <Typography component={"span"} variant="body1" fontWeight={"500"}>
              {product?.discountedPrice ?? "-"}
            </Typography>
          </Grid>
        </Grid>
      ));
    }
  }, [containerProductAction, selectedProducts]);

  const containerProducts = useMemo(() => {
    if (selectedProducts.length > 0) {
      return (
        <>
          {renderPatientNames}
          <Divider variant="fullWidth" className="mb-3" />
          <Grid container item justifyContent={"space-between"}>
            <Grid item>
              <Typography variant="subtitle1" fontWeight={"500"}>
                Product Name
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="subtitle1" fontWeight={"500"}>
                Price ($)
              </Typography>
            </Grid>
          </Grid>
          {renderProductNames}
        </>
      );
    }
  }, [renderPatientNames, renderProductNames, selectedProducts.length]);

  const containerPrice = useMemo(() => {
    if (selectedProducts.length > 0) {
      const totalProductAmount = parseFloat(
        selectedProducts.reduce((total, product) => total + product.quantity * (product.discountedPrice ?? 0), 0) *
          (selectedPatients.length || 1),
      );
      const rushOrderCharges = rushOrder
        ? parseFloat(
            parseFloat(RUSH_ORDER_INFO.value) + (parseFloat(RUSH_ORDER_INFO.percentOfOrder) / 100) * totalProductAmount,
          )
        : 0;
      const subTotal = totalProductAmount + rushOrderCharges - parseFloat(creditRequested);
      const totalAmount = subTotal + parseFloat(isNaN(shippingCost) ? "0" : shippingCost || "0");

      return (
        <Grid container rowSpacing={1}>
          <Divider variant="fullWidth" sx={{ my: 2 }} />
          {containerPriceRow("Total Product Amount", totalProductAmount?.toFixed(2))}
          {rushOrder && containerPriceRow("Priority Order Fee", rushOrderCharges?.toFixed(2))}
          {containerPriceRow("Credit Used", creditRequested)}
          {containerPriceRow("Sub Total", subTotal?.toFixed(2))}
          {containerPriceRow("Estimated Shipping", shippingCost || "-")}
          <Divider sx={{ my: 2 }} />
          {containerPriceRow("Total Amount", totalAmount?.toFixed(2))}
        </Grid>
      );
    }
  }, [containerPriceRow, creditRequested, rushOrder, selectedPatients?.length, selectedProducts, shippingCost]);

  return (
    <>
      <Typography variant="h6" fontWeight={"600"} color={"primary"}>
        Selected Products
      </Typography>
      <Typography variant="body2" mt={3}>
        Total Selected Products: {selectedProducts.length}
      </Typography>
      <Paper sx={{ p: 2, borderRadius: 2, my: 2 }} elevation={3}>
        {emptyView}
        {containerProducts}
        {containerPrice}
      </Paper>
    </>
  );
}

export default React.memo(ProductSelectedContainer);
