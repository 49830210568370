import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Button, Card, CardActions, CardContent, Container, Grid } from "@mui/material";
import { CartDrawer, FormStepper, MuiAlertMessage, ProductSelectedContainer } from "components";
import NavBar from "components/NavBar";
import Spinner from "components/spinner";
import {
  PrescriptionActionContext,
  PrescriptionFormActionContext,
  PrescriptionFormSateContext,
  PrescriptionStateContext,
} from "context/PrescriptionContext";
import React, { useCallback, useContext, useMemo } from "react";
import { MetaTags } from "react-meta-tags";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { TabContent, TabPane } from "reactstrap";
import { userRoleSelector } from "selectors";
import { PRESCRIPTION_ORDER_STEPPER, STEPPER_FORMS, USER_ROLES } from "utils";
import {
  PrescriptionBilling,
  PrescriptionLocationProviderSelection,
  PrescriptionPatientInfo,
  PrescriptionProductSelection,
  PrescriptionShipping,
} from "../PrescriptionInfo";

function InsertPrescription(params) {
  const history = useHistory();
  const userRole = useSelector(userRoleSelector);
  const { mutateError, isPrescriptionEdit, showSpinner } = useContext(PrescriptionStateContext);
  const {
    formRef,
    completedStep,
    activeStep,
    prescriptionSideCartRef,
    patientFormData,
    shippingRate,
    rushOrderCheck,
    selectedProducts,
    creditRequestAmount,
    shippingRateLoading,
  } = useContext(PrescriptionFormSateContext);
  const { goNext, reviewButton, goBack, formData, progressBarAction, getCartProduct } =
    useContext(PrescriptionFormActionContext);
  const { addProductToCartAction, removeSelectedProduct } = useContext(PrescriptionActionContext);

  const goBrowserBack = useCallback(e => {
    e?.preventDefault();
    history.goBack();
  }, []);

  const isLastForm = useMemo(() => formRef.current?.length - 1 === activeStep, [activeStep, formRef]);
  const buttonText = useMemo(() => {
    let text = "Next";
    if (isLastForm) {
      text = "Save";
    }
    return text;
  }, [isLastForm]);

  const backButtonView = useMemo(() => {
    if (activeStep > 0) {
      return (
        <Button
          variant="outlined"
          size="large"
          className="muiLightButton"
          sx={{ mx: 2, width: 1 / 5 }}
          onClick={goBack}>
          Back
        </Button>
      );
    }
  }, [activeStep, goBack]);

  const reviewButtonView = useMemo(() => {
    if (isLastForm && !isPrescriptionEdit) {
      return (
        <Button
          color="secondary"
          variant="contained"
          size="large"
          sx={{ mx: 2, width: 1 / 5 }}
          onClick={reviewButton}
          disabled={userRole === USER_ROLES.CLINIC_SUPPORT}>
          Review & Submit
        </Button>
      );
    }
  }, [isLastForm, isPrescriptionEdit, reviewButton, userRole]);

  const productSelectedView = useMemo(() => {
    if (activeStep > 0) {
      return (
        <Grid item xs={12} md={4} lg={3}>
          <ProductSelectedContainer
            rushOrder={rushOrderCheck}
            shippingCost={shippingRateLoading ? "......" : shippingRate}
            creditRequested={creditRequestAmount}
            addToCartAction={addProductToCartAction}
            selectedProducts={selectedProducts}
            selectedPatients={patientFormData?.current ?? []}
            removeSelectedProduct={removeSelectedProduct}
          />
        </Grid>
      );
    }
  }, [
    activeStep,
    shippingRateLoading,
    addProductToCartAction,
    creditRequestAmount,
    patientFormData,
    removeSelectedProduct,
    rushOrderCheck,
    selectedProducts,
    shippingRate,
  ]);

  return (
    <Grid container>
      <Grid item xs={12} lg={10} style={{ position: "relative" }}>
        <NavBar />
        <div className="page-content">
          <MetaTags>
            <title>{`${isPrescriptionEdit ? "Edit Prescription" : "New Prescription"} | VPI Compounding`}</title>
          </MetaTags>
          <Container maxWidth={false} disableGutters>
            <Button color="info" onClick={goBrowserBack} startIcon={<ArrowBackIcon />}>
              Back
            </Button>
            <h4 className="p-3 text-muted">{isPrescriptionEdit ? "EDIT PRESCRIPTION" : "NEW PRESCRIPTION"}</h4>
            {/* {successMessage} */}
            <Card elevation={1}>
              <Spinner showSpinner={showSpinner} />
              <CardContent>
                <FormStepper
                  stepper={PRESCRIPTION_ORDER_STEPPER}
                  completedStep={completedStep}
                  activeStep={activeStep}
                  clickAction={progressBarAction}
                />
                <MuiAlertMessage message={mutateError} isError />
                <Grid item container xs={12}>
                  <Grid item xs={12} md={activeStep ? 8 : 12} lg={activeStep ? 9 : 12}>
                    <TabContent activeTab={activeStep}>
                      <TabPane tabId={0}>
                        <PrescriptionPatientInfo
                          ref={el => (formRef.current[0] = el)}
                          formId={STEPPER_FORMS.PATIENT_INFO}
                          formData={formData}
                        />
                      </TabPane>
                      <TabPane tabId={1}>
                        <PrescriptionProductSelection
                          ref={el => (formRef.current[1] = el)}
                          formId={STEPPER_FORMS.PRODUCT_INFO}
                          formData={formData}
                        />
                      </TabPane>
                      <TabPane tabId={2}>
                        <PrescriptionLocationProviderSelection
                          ref={el => (formRef.current[2] = el)}
                          formId={STEPPER_FORMS.PROVIDER_INFO}
                          formData={formData}
                        />
                      </TabPane>
                      <TabPane tabId={3}>
                        <PrescriptionShipping
                          ref={el => (formRef.current[3] = el)}
                          formId={STEPPER_FORMS.SHIPPING_INFO}
                          formData={formData}
                        />
                      </TabPane>
                      <TabPane tabId={4}>
                        <PrescriptionBilling
                          ref={el => (formRef.current[4] = el)}
                          formId={STEPPER_FORMS.BILLING_INFO}
                          formData={formData}
                        />
                      </TabPane>
                    </TabContent>
                  </Grid>

                  {productSelectedView}
                </Grid>
              </CardContent>
            </Card>
            <CardActions
              className="justify-content-center"
              sx={{
                position: "fixed",
                bottom: 0,
                left: 0,
                right: 0,
                bgcolor: "grey.100",
                p: 3,
                boxShadow: 5,
                zIndex: 1,
              }}>
              {backButtonView}
              <Button
                color={isLastForm ? "warning" : "secondary"}
                variant="contained"
                size="large"
                sx={{ mx: 2, width: 1 / 5 }}
                onClick={goNext}>
                {buttonText}
              </Button>
              {reviewButtonView}
            </CardActions>
          </Container>
        </div>
      </Grid>
      <Grid
        item
        xs={0}
        lg={2}
        sx={{
          display: { xs: "none", lg: "flex" },
        }}>
        <CartDrawer
          ref={prescriptionSideCartRef}
          drawerVariant={"persistent"}
          getCartProduct={getCartProduct}
          hideSelect={activeStep > 1}
        />
      </Grid>
    </Grid>
  );
}

export default React.memo(InsertPrescription);
