import { map } from "lodash";
import React from "react";
import ProductCommonNameListing from "./ProductCommonNameListing";

function ProductSubcategoryListing({ productData, addToCartAction }) {
  return map(productData, commonProduct => (
    <ProductCommonNameListing
      key={commonProduct?.commonName}
      commonProduct={commonProduct}
      addToCartAction={addToCartAction}
    />
  ));
}

export default React.memo(ProductSubcategoryListing);
