import Spinner from "components/spinner";
import moment from "moment";
import React, { useState } from "react";
import { Col, Row } from "reactstrap";
import { useUploadFileMutation } from "rtk";
import "./BAAForm.scss";
import { SignaturePad } from "./SignaturePad";

function BAAgreementText() {
  return (
    <div
      className="baa-form"
      style={{ height: "300px", overflowY: "scroll", textAlign: "justify", marginBottom: "30px" }}>
      <p className="mt-2 m-1">
        This Business Associate Agreement ("BAA"), dated the date it is fully signed ("Effective Date"), is entered into
        by and between Valescent Pharmaceuticals Inc. ("VPI") and the customer set forth below in the signature block
        ("Company") (each a "Party" and collectively the "Parties").
      </p>
      <p className="mt-2 m-1">The Parties hereby agree as follows:</p>
      <p className="mt-2 m-1">
        <strong>
          <u>1. DEFINITIONS</u>
        </strong>
      </p>
      <div>
        <p className="mx-1">
          {" "}
          Unless otherwise specified in this BAA, all capitalized terms used in this BAA not otherwise defined have the
          meanings established for purposes of the Health Insurance Portability and Accountability Act of 1996 and its
          implementing regulations, as amended and supplemented by HITECH, as each is amended from time to time
          (collectively, "HIPAA").
        </p>

        <ol type="a" class="bold-letters">
          <li data-letter="a">
            "Breach": means the acquisition, access, use or disclosure of PHI in a manner not permitted by the Privacy
            Rule that compromises the security or privacy of the PHI as defined, and subject to the exclusions set
            forth, in 45 C.F.R. § 164.402.
          </li>
          <li data-letter="b">“Business Associate”: has the meaning provided at 45 C.F.R. § 160.103.</li>
          <li data-letter="c">“Covered Entity”: has the meaning provided at 45 C.F.R. § 160.103.</li>
          <li data-letter="d">"ePHI": means PHI that is transmitted or maintained in Electronic Media.</li>
          <li data-letter="e">
            "HITECH": means Subtitle D of the Health Information Technology for Economic and Clinical Health Act
            provisions of the American Recovery and Reinvestment Act of 2009, 42 U.S.C. §§ 17921-17954, and all
            associated existing and future implementing regulations, when and as each is effective.
          </li>
          <li data-letter="f">
            "PHI": means Protected Health Information, as defined in 45 C.F.R. § 160.103, and is limited to the
            Protected Health Information received, maintained, created or transmitted on behalf of, Company by VPI in
            performance of the Services.
          </li>
          <li data-letter="g">
            "Privacy Rule": means the federal privacy regulations, as amended from time to time, issued pursuant to
            HIPAA and codified at 45 C.F.R. Parts 160 and 164 (Subparts A & E).
          </li>
          <li data-letter="h">
            "Security Rule": means the federal security regulations, as amended from time to time, issued pursuant to
            HIPAA and codified at 45 C.F.R. Parts 160 and 164 (Subparts A & C).
          </li>
          <li data-letter="i">
            "Services": means, to the extent and only to the extent they involve the receipt, creation, maintenance,
            transmission, use or disclosure of PHI, the services provided by VPI to Company as set forth in the
            Agreement as may be amended by written agreement of the Parties.
          </li>
        </ol>
      </div>
      <p className="mt-2 m-1">
        <strong>
          <u>2. RESPONSIBILITIES OF VPI</u>
        </strong>
      </p>
      <div>
        <p className="mx-1">With regard to its use and/or disclosure of PHI, VPI agrees to:</p>
        <ol type="a" class="bold-letters">
          <li data-letter="a">
            Not use and/or further disclose PHI except as necessary to provide the Services, as permitted or required by
            this BAA, and in compliance with each applicable requirement of 45 C.F.R. § 164.504(e), or as otherwise
            required by Law; provided that, to the extent VPI is to carry out Company's obligations under the Privacy
            Rule, VPI will comply with the requirements that apply to the Company in the performance of those
            obligations.
            <li data-letter="b">
              Implement and use appropriate administrative, physical and technical safeguards and comply with applicable
              Security Rule requirements with respect to ePHI, to prevent use or disclosure of PHI other than as
              provided for by this BAA.
            </li>
            <li data-letter="c">
              Promptly report to Company any: (i) Breach of Unsecured PHI in accordance with 45 C.F.R. § 164.410 (ii)
              use or disclosure of PHI not provided for by this BAA of which it becomes aware in accordance with 45
              C.F.R. § 164.504(e); and (iii) any Security Incident of which VPI becomes aware in accordance with 45
              C.F.R. § 164.314(a). (Promptly meaning: without unreasonable delay, and in no case later than 60 calendar
              days after discovery of such Breach.)
            </li>
            <li data-letter="d">
              Ensure that any subcontractors of VPI that create, receive, maintain or transmit PHI on behalf of VPI
              agree, in writing, to restrictions and conditions no less stringent than those that apply to VPI under
              this BAA with respect to that PHI, including complying with the applicable Security Rule requirements with
              respect to ePHI.
            </li>
            <li data-letter="e">
              Make available its internal practices, books and records relating to the use and disclosure of PHI to the
              Secretary for purposes of determining compliance with the Privacy Rule.
            </li>
            <li data-letter="f">
              Promptly document and, after receiving a written request from Company, make available to Company,
              information necessary for Company to make an accounting of disclosures of PHI about an Individual, in
              accordance with 45 C.F.R. § 164.528.
            </li>
            <li data-letter="g">
              To the extent VPI maintains a Designated Record Set on behalf of Company, VPI shall: (i) provide access
              promptly after receiving a written request from Company, to PHI in a Designated Record Set about an
              Individual, to Company, sufficient to allow Company to comply with the requirements of 45 C.F.R. §
              164.524; and (ii) make available promptly after a written request by Company, PHI for amendment and
              incorporate any amendments to the PHI as requested by Company, all in accordance with 45 C.F.R. § 164.526.
            </li>
          </li>
        </ol>
      </div>
      <p className="mt-2 m-1">
        <strong>
          <u>3. RESPONSIBILITIES OF COVERED ENTITY</u>
        </strong>
      </p>
      <div>
        <p className="mx-1">
          In addition to any other obligations set forth in the Agreement, including this BAA, Company shall:
        </p>
        <ol type="a" class="bold-letters">
          <li data-letter="a">Provide to VPI only the minimum PHI necessary to accomplish the Services.</li>
          <li data-letter="b">
            In the event that the Company (or, if Company is a Business Associate to a third-party Covered Entity, that
            Covered Entity) honors a request to restrict the use or disclosure of PHI pursuant to 45 C.F.R. § 164.522(a)
            or makes revisions to its notice of privacy practices in accordance with 45 C.F.R. § 164.520 that increase
            the limitations on uses or disclosures of PHI or agrees to a request by an Individual for confidential
            communications under 45 C.F.R. § 164.522(b), not provide VPI any PHI that is subject to any of those
            restrictions or limitations to the extent any may limit VPI’s ability to use and/or disclose PHI as
            permitted or required under this BAA unless Company notifies VPI of the restriction or limitation and VPI
            agrees in writing to honor the restriction or limitation. In addition, if those limitations or revisions
            materially increase VPI’s cost of providing services under the Agreement including this BAA, Company shall
            reimburse VPI for such increase in cost.
          </li>
          <li data-letter="c">
            Be responsible for using administrative, physical and technical safeguards at all times to maintain and
            ensure the confidentiality, privacy and security of PHI transmitted to VPI pursuant to the Agreement
            including this BAA, in accordance with the standards and requirements of HIPAA, before and during the
            transmission of such PHI to VPI.
          </li>
          <li data-letter="d">
            Obtain any consent or authorization that may be required by applicable federal or state laws and regulations
            prior to furnishing VPI the PHI for use and disclosure in accordance with this BAA. If Company is a Business
            Associate to a third-party Covered Entity with respect to the PHI that Company provides to VPI, Company
            shall comply with all standards and requirements of HIPAA in its role as a Business Associate to that
            Covered Entity.
          </li>
        </ol>
      </div>

      <p className="mt-2 m-1">
        <strong>
          <u>4. PERMITTED USES AND DISCLOSURES OF PHI</u>
        </strong>
      </p>

      <div>
        <ol type="a" className="bold-letters">
          <li data-letter="a">
            Make any and all uses and disclosures of PHI necessary to provide the Services to the Company.
          </li>
          <li data-letter="b">
            Use and disclose PHI, if necessary, for proper management and administration of VPI or to carry out the
            legal responsibilities of VPI, provided that the disclosures are required by Law or any third party to which
            VPI discloses PHI for those purposes provides written assurances in advance that: (i) the information will
            be held confidentially and used or further disclosed only for the purpose for which it was disclosed to the
            third party or as required by Law; and (ii) the third party promptly will notify VPI of any instances of
            which it becomes aware of the confidentiality of the information has been breached.
          </li>
        </ol>
      </div>

      <p className="mt-2 m-1">
        <strong>
          <u>5. TERM AND TERMINATION</u>
        </strong>
      </p>
      <div>
        <ol type="a" className="bold-letters">
          <li>
            <strong>Term.</strong> The term of this BAA shall commence as of the Effective Date, and shall terminate
            without any further action upon the termination or expiration of the Agreement, unless earlier terminated in
            accordance with Section 5.2 of this BAA.
          </li>
          <li>
            <strong>Termination.</strong> If either Party knows of a pattern of activity or practice of the other Party
            that constitutes a material breach or violation of this BAA then the non-breaching Party shall provide
            written notice of the breach or violation to the other Party that specifies the nature of the breach or
            violation. The breaching Party must cure the breach or end the violation on or before thirty (30) days after
            receipt of the written notice. In the absence of a cure reasonably satisfactory to the non-breaching Party
            within the specified timeframe or in the event the breach is reasonably incapable of cure, then the
            non-breaching Party may terminate this BAA.
          </li>
        </ol>
      </div>
      <p className="mt-2 m-1">
        <strong>
          <u>6. MISCELLANEOUS</u>
        </strong>
      </p>

      <div>
        <ol type="a" className="bold-letters">
          <li>
            <strong>Construction of Terms.</strong> The terms of this BAA to the extent they are unclear shall be
            construed to allow for compliance by Company and VPI with HIPAA.
          </li>
          <li>
            <strong>Survival.</strong> Sections 5.3, 6.1, 6.2, 6.3, 6.4 and 6.5 shall survive the expiration or
            termination for any reason of the Agreement and/or this BAA.
          </li>
          <li>
            <strong>Indemnification.</strong> Each Party ("Indemnifying Party") will indemnify and hold harmless the
            other Party ("Other Party") against any third party claims, including losses, liabilities, penalties, fines,
            costs, damages and expenses the Other Party incurs, including reasonable attorneys’ fees, which arise out
            of: (i) the Indemnifying Party’s or the Indemnifying Party’s vendors’ gross negligence or willful misconduct
            in the performance of the Indemnifying Party’s or the Indemnifying Party’s vendors’, subcontractors’ or
            representatives’ obligations under this BAA; or (ii) the Indemnifying Party’s material breach of this BAA,
            all as determined by a court or other tribunal having jurisdiction over the matter. Each Party shall provide
            the other with prompt notice of any claim that may trigger the foregoing indemnification requirements. Upon
            demand by the Other Party, the Indemnifying Party shall defend any investigation, claim, litigation or other
            proceeding brought or threatened against the Indemnified Party, at the Indemnifying Party's expense, by
            counsel acceptable to the Indemnified Party. Neither Party shall enter into any settlement without the
            written consent of the other Party.
          </li>
          <li>
            <strong>No Third Party Beneficiaries.</strong> Nothing in this BAA shall confer upon any person other than
            the Parties and their respective successors or assigns, any rights, remedies, obligations or liabilities
            whatsoever.
          </li>
          <li>
            <strong>Governing Law.</strong> This BAA will be governed by and constructed in accordance with the laws of
            the State of Colorado (excluding its choice of law rules).
          </li>
          <li>
            <strong>Counterparts.</strong> This BAA may be executed in counterparts, each of which will constitute an
            original and all of which will be one and the same document.
          </li>
          <li>
            <strong>Independent Contractor.</strong> VPI and Company are and shall remain independent contractors
            throughout the term. Nothing in this BAA or otherwise in the Agreement shall be construed to constitute VPI
            and Company as partners, joint venturers, agents or anything other than independent contractors.
          </li>
        </ol>
      </div>
    </div>
  );
}
function BAAInputFields({
  vpiInfo,
  setVpiInfo,
  companyInfo,
  setCompanyInfo,
  onClickSave,
  isCompanyInfoReadOnly,
  step,
}) {
  const [vpiSignModal, setVpiSignModal] = useState(false);
  const [companySignModal, setCompanySignModal] = useState(false);
  const closeSignatureModal = () => {
    setCompanySignModal(false);
    setVpiSignModal(false);
  };

  return (
    <div className="mb-5" id="formInfo">
      {/* Form */}
      {/* Company section */}
      <p style={{ fontWeight: "bold", textDecoration: "underline" }}>Company</p>
      <div>
        <Row>
          <Col md={6}>
            <label htmlFor="companyName" className="mt-2 mr-2">
              Company Name:
            </label>{" "}
          </Col>{" "}
          <Col md={6}>
            <input
              required={step === 3}
              id="companyName"
              name="companyName"
              className="mt-2 form-control"
              type="text"
              placeholder="Company Name"
              value={companyInfo?.companyName}
              onChange={e => setCompanyInfo(prevState => ({ ...prevState, companyName: e.target.value }))}
              disabled={isCompanyInfoReadOnly}
            />
          </Col>
        </Row>
        <div>
          <Row>
            <Col md={6}>
              <span className="mt-2 mr-2">Signature:</span>{" "}
            </Col>{" "}
            <Col md={6}>
              {companyInfo?.signatureUrl ? (
                <img style={{ width: "150px", height: "auto" }} src={companyInfo?.signatureUrl} alt="" />
              ) : null}
              {!isCompanyInfoReadOnly && (
                <span
                  className="mt-2 pointer form-control"
                  onClick={() => {
                    setVpiSignModal(false);
                    setCompanySignModal(true);
                  }}>
                  <i class="fas fa-pen mx-2"></i>Signature Pad
                </span>
              )}
            </Col>
          </Row>
        </div>
        <Row>
          <Col md={6}>
            <label htmlFor="name" className="mt-2 mr-2">
              Name:
            </label>{" "}
          </Col>
          <Col md={6}>
            <input
              required={step === 3}
              id="company_person_name"
              name="company_person_name"
              className="mt-2 form-control"
              type="text"
              placeholder="Name"
              value={companyInfo?.name}
              onChange={e => setCompanyInfo(prevState => ({ ...prevState, name: e.target.value }))}
              disabled={isCompanyInfoReadOnly}
            />
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <label htmlFor="title" className="mt-2 mr-2">
              Title:
            </label>{" "}
          </Col>{" "}
          <Col md={6}>
            <input
              required={step === 3}
              id="company_title"
              name="company_title"
              className="mt-2 form-control"
              type="text"
              placeholder="Title"
              value={companyInfo?.title}
              onChange={e => setCompanyInfo(prevState => ({ ...prevState, title: e.target.value }))}
              disabled={isCompanyInfoReadOnly}
            />
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <label htmlFor="date" className="mt-2 mr-2">
              Date:
            </label>{" "}
          </Col>{" "}
          <Col md={6}>
            <input
              required={step === 3}
              id="company_date"
              name="company_date"
              className="mt-2 form-control"
              type="date"
              placeholder="Date"
              value={companyInfo?.date ? moment(companyInfo?.date)?.format("YYYY-MM-DD") : ""}
              onChange={e =>
                setCompanyInfo(prevState => ({ ...prevState, date: moment(e.target.value)?.format("YYYY-MM-DD") }))
              }
              disabled={isCompanyInfoReadOnly}
            />
          </Col>
        </Row>
      </div>
      {/* VPI section */}
      <p style={{ fontWeight: "bold", textDecoration: "underline" }}>VPI</p>
      <div className="mt-2 mt-2 mb-1">
        {/* Input fields for VPI info */}
        <div>
          <Row>
            <Col md={6}>
              <span className="mt-2 mr-2">Signature:</span>
            </Col>
            <Col md={6}>
              {vpiInfo?.signatureUrl ? (
                <img style={{ width: "150px", height: "auto" }} src={vpiInfo?.signatureUrl} alt="" />
              ) : null}
              {isCompanyInfoReadOnly ? (
                <span
                  className="mt-2 pointer form-control"
                  onClick={() => {
                    setCompanySignModal(false);
                    setVpiSignModal(true);
                  }}>
                  <i class="fas fa-pen mx-2"></i>Signature Pad
                </span>
              ) : null}
            </Col>
          </Row>
        </div>
        <Row>
          <Col md={6}>
            <label htmlFor="vpiName" className="mt-2 mr-2">
              Name:
            </label>{" "}
          </Col>{" "}
          <Col md={6}>
            <input
              required={step === 3 && isCompanyInfoReadOnly}
              id="vpi_name"
              name="vpi_name"
              className="mt-2 form-control"
              type="text"
              placeholder="Name"
              value={vpiInfo?.name}
              onChange={e => setVpiInfo(prevState => ({ ...prevState, name: e.target.value }))}
              disabled={!isCompanyInfoReadOnly}
            />
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <label htmlFor="vpiTitle" className="mt-2 mr-2">
              Title:
            </label>{" "}
          </Col>{" "}
          <Col md={6}>
            <input
              required={step === 3 && isCompanyInfoReadOnly}
              id="vpiTitle"
              name="vpiTitle"
              className="mt-2 form-control"
              type="text"
              placeholder="Title"
              value={vpiInfo?.title}
              disabled={!isCompanyInfoReadOnly}
              onChange={e => setVpiInfo(prevState => ({ ...prevState, title: e.target.value }))}
            />
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <label htmlFor="vpiDate" className="mt-2 mr-2">
              Date:
            </label>
          </Col>
          <Col md={6}>
            <input
              required={step === 3 && isCompanyInfoReadOnly}
              id="vpiDate"
              name="vpiDate"
              className="mt-2 form-control"
              type="date"
              placeholder="Date"
              value={vpiInfo?.date ? moment(vpiInfo?.date)?.format("YYYY-MM-DD") : ""}
              disabled={!isCompanyInfoReadOnly}
              onChange={e =>
                setVpiInfo(prevState => ({ ...prevState, date: moment(e.target.value)?.format("YYYY-MM-DD") }))
              }
            />
          </Col>
        </Row>
      </div>
      <SignaturePad
        actionText="Save"
        person={"vpiInfoSignatureImageUrl"}
        onClickSave={onClickSave}
        closeModal={closeSignatureModal}
        showModal={vpiSignModal}
      />

      <SignaturePad
        actionText="Save"
        person={"companyInfoSignatureImageUrl"}
        onClickSave={onClickSave}
        closeModal={closeSignatureModal}
        showModal={companySignModal}
      />
    </div>
  );
}

function BAAForm({
  vpiInfo,
  setVpiInfo,
  companyInfo,
  setCompanyInfo,
  handleSubmit,
  companyInforequired,
  vpiInforequired,
  adminSide = false,
  onClickSave,
  setBaaFormPDFUrl,
  isCompanyInfoReadOnly,
  step,
}) {
  const [uploadMutate, { error: uploadError, isError: isUploadError, isLoading: isUploadLoading }] =
    useUploadFileMutation();
  async function uploadForm(e, setBaaFormPDFUrl) {
    const formData = new FormData();
    const file = e.target.files[0];
    formData.append("file", file);
    let res = await uploadMutate(formData, false);
    if (res?.data) setBaaFormPDFUrl(res.data.accessUrl);
  }
  return (
    <div>
      {adminSide ? (
        <div>
          {isUploadLoading ? <Spinner /> : null}
          <p className="mx-2">
            Copy of BAA <span className="requiredStar">*</span>
          </p>
          <input
            required={step === 3}
            className="mt-2 form-control m-2"
            accept=".pdf"
            onChange={e => {
              uploadForm(e, setBaaFormPDFUrl);
            }}
            name="baa_form"
            type="file"
            id="baa_form"
          />{" "}
          <p className="redText mx-2">Only pdf files are allowed</p>{" "}
        </div>
      ) : (
        <div>
          <p className="text-center">
            <u>
              <b>{"VPI BUSINESS ASSOCIATE AGREEMENT"}</b>
            </u>
          </p>
          <BAAgreementText />
          <BAAInputFields
            vpiInfo={vpiInfo}
            setVpiInfo={setVpiInfo}
            companyInfo={companyInfo}
            setCompanyInfo={setCompanyInfo}
            handleSubmit={handleSubmit}
            companyInforequired={companyInforequired}
            vpiInforequired={vpiInforequired}
            onClickSave={onClickSave}
            isCompanyInfoReadOnly={isCompanyInfoReadOnly}
            step={step}
          />
        </div>
      )}
    </div>
  );
}

export default React.memo(BAAForm);
