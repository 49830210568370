import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import classNames from "classnames";
import { AlertMessage } from "components";
import { usePrintPage } from "components/Hooks";
import { SignaturePad } from "components/SignaturePad";
import Spinner from "components/spinner";
import { DialogContext } from "context";
import { decrypt } from "cryptofunc";
import { capitalize, filter, find, flatten, includes, map, set, some } from "lodash";
import moment from "moment";
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  useAdminAcknowledgePrescriptionMutation,
  useClinicUploadRxSignatureMutation,
  useGetClinicPrescriptionDetailQuery,
  useSubmitClinicPrescriptionMutation,
} from "rtk";
import { userInfoSelector, userRoleSelector } from "selectors";
import {
  ALLOWED_ACTION_USERS,
  LISTING_NAME,
  SUBMITTED_PRESCRIPTION_DIALOG,
  USERS_EDIT_PRESCRIPTION,
  USER_ROLES,
  base64toFile,
  formatDaySupply,
} from "utils";
import { dateFormatterMountainTime } from "utils/constants";
import { useLocation } from "react-router-dom";
import ExostarSign from "components/Exostar/ExostarSign";
import { EXOSTAR_ENABLED_CLINICS } from "components/Exostar/ExostarConstants";

function OrderViewModal({
  showAcknowledge = false,
  isReview = false,
  patientProductRefill = false,
  prescriptionId = "",
  mainPrescriptionId = "",
  listingName = "adminPending",
  handleClose,
}) {
  const { pathname } = useLocation();
  const history = useHistory();
  const pinCodeRef = useRef(null);
  const acknowledgeRef = useRef();
  const [customError, setCustomError] = useState("");
  const userInfo = useSelector(userInfoSelector);
  const { showDialog, closeDialog } = useContext(DialogContext);
  const [signatureModal, setSignatureModal] = useState(false);
  const userRole = useSelector(userRoleSelector);
  const [acknowledgeMutate, { isSuccess: acknowledgeSuccess, isLoading: acknowledgeLoading, error: acknowledgeError }] =
    useAdminAcknowledgePrescriptionMutation();
  const [uploadMutate, { data: uploadData, isLoading: isUploading, error: uploadError, reset: resetUploadMutate }] =
    useClinicUploadRxSignatureMutation();
  const [rxSubmitMutate, { isLoading: submitLoading, error: submitError, isSuccess: isSubmitSuccess }] =
    useSubmitClinicPrescriptionMutation();
  /* Exostar */
  const [enableSubmit, setEnableSubmit] = useState('');
  const [showExostarSign, setShowExostarSign] = useState(false);
  /* */
  const {
    data: invoiceData,
    isFetching,
    error,
  } = useGetClinicPrescriptionDetailQuery({ prescriptionId }, { skip: !prescriptionId });

  const { componentRef, printLoading, printModal } = usePrintPage(invoiceData?.invoiceNumber);
  const isBulkInvoice = useMemo(
    () => invoiceData?.subPrescriptions?.length > 0,
    [invoiceData?.subPrescriptions?.length],
  );

  const decryptedBillingInfo = useMemo(() => {
    let info = {};
    if (invoiceData?.encryptedBillingInfo) {
      info = decrypt(invoiceData?.encryptedBillingInfo);
    }
    return info;
  }, [invoiceData?.encryptedBillingInfo]);
  const canSubmitPrescription = useMemo(() => includes(SUBMITTED_PRESCRIPTION_DIALOG, listingName), [listingName]);
  const noRefill = useMemo(() => some(invoiceData?.products, { refills: 0 }), [invoiceData?.products]);
  const allowedAcknowledge = useMemo(() => includes(ALLOWED_ACTION_USERS, userRole), [userRole]);
  const isAllowedEditingPrescription = useMemo(
    () => some(USERS_EDIT_PRESCRIPTION, role => role === userRole),
    [userRole],
  );
  const shippingCost = useMemo(() => invoiceData?.shippingCost, [invoiceData?.shippingCost]);
  const showLoading = useMemo(
    () => acknowledgeLoading || printLoading || submitLoading || isUploading,
    [submitLoading, acknowledgeLoading, printLoading, isUploading],
  );
  const errorMessage = useMemo(
    () =>
      customError ||
      acknowledgeError?.data?.message ||
      error?.data?.message ||
      uploadError?.data?.message ||
      submitError?.data?.message,
    [
      acknowledgeError?.data?.message,
      customError,
      error?.data?.message,
      submitError?.data?.message,
      uploadError?.data?.message,
    ],
  );
  const showActionModal = useMemo(() => allowedAcknowledge && showAcknowledge, [allowedAcknowledge, showAcknowledge]);
  const showActionButton = useMemo(() => {
    if (isReview) {
      return isAllowedEditingPrescription;
    } else {
      return true;
    }
  }, [isAllowedEditingPrescription, isReview]);
  const disableActions = useMemo(
    () =>
      isFetching ||
      showLoading ||
      printLoading ||
      isUploading ||
      submitLoading ||
      !(invoiceData?.signatureFileUrl || uploadData?.accessUrl),
    [
      isFetching,
      showLoading,
      printLoading,
      isUploading,
      submitLoading,
      invoiceData?.signatureFileUrl,
      uploadData?.accessUrl,
    ],
  );

  const signatureFileUrl = useMemo(
    () => uploadData?.accessUrl || invoiceData?.signatureFileUrl || "",
    [invoiceData?.signatureFileUrl, uploadData?.accessUrl],
  );

  const showPIN = useMemo(
    () => userInfo?.google_2f_enabled && listingName === LISTING_NAME.CLINIC_PENDING_NEEDED,
    [listingName, userInfo?.google_2f_enabled],
  );

  const prescriptionTableColumns = useMemo(
    () => (
      <TableHead>
        <TableRow>
          <TableCell style={{ paddingRight: "4px", paddingLeft: "4px" }}>Medication (w/ instructions) </TableCell>
          <TableCell style={{ paddingRight: "4px", paddingLeft: "4px" }}>QTY</TableCell>
          <TableCell style={{ paddingRight: "4px", paddingLeft: "4px" }}>Refills</TableCell>
          <TableCell style={{ paddingRight: "4px", paddingLeft: "4px" }}>Day Supply</TableCell>
          <TableCell style={{ paddingRight: "4px", paddingLeft: "4px" }}>Reason for Medication</TableCell>
          <TableCell style={{ paddingRight: isReview ? "4px" : "0px", paddingLeft: "4px" }}>Last Visit</TableCell>
          {isReview && (
            <>
              <TableCell style={{ paddingRight: "4px", paddingLeft: "4px" }}>Diagnosis</TableCell>
              <TableCell style={{ paddingRight: "4px", paddingLeft: "4px" }}>Weight</TableCell>
              <TableCell style={{ paddingRight: "4px", paddingLeft: "4px" }}>Unit Price($)</TableCell>
              <TableCell style={{ paddingRight: "4px", paddingLeft: "4px" }}>Discounted Price($)</TableCell>
              <TableCell style={{ paddingRight: "0px", paddingLeft: "4px" }}>Total Price($)</TableCell>
            </>
          )}
        </TableRow>
      </TableHead>
    ),
    [isReview],
  );

  const closeModal = useCallback(() => {
    handleClose?.();
    setCustomError("");
    setTimeout(() => {
      resetUploadMutate();
    }, 300);
  }, [handleClose, resetUploadMutate]);

  useEffect(() => {
    if (acknowledgeSuccess || isSubmitSuccess) {
      toggleConfirmationModal?.();
      closeModal();
    }
  }, [acknowledgeSuccess, isSubmitSuccess]);

  //Exostar
  useEffect(() => {
    if (invoiceData?.products?.length > 0) {
      const selectedProducts = invoiceData?.products;
      const isControlledSubstance = selectedProducts.some(product => product.controlledSubstance === "1");
      
      // Currently we are enabling Exostar for one clinic and all its products and not controlled substances so we are removing    if(isControlledSubstance)
      if(EXOSTAR_ENABLED_CLINICS.includes(invoiceData?.clinicId)) {
        setShowExostarSign(true)
      }
      else {
        setShowExostarSign(false);
      }
    }
  }, [invoiceData]);
  //

  const closeSignatureModal = () => {
    setSignatureModal(false);
  };

  const uploadSignature = useCallback((e, signPad = "") => {
    e?.preventDefault?.();
    let file = "";
    const formData = new FormData();
    if (signPad) {
      file = base64toFile(signPad);
      formData.append("file", file);
    } else {
      file = e.target.files[0];
      formData.append("file", file);
    }
    uploadMutate(formData);
  }, []);

  const prescriptionAcknowledgeAction = useCallback(() => {
    acknowledgeMutate({ action: acknowledgeRef.current, prescriptionId, pageName: "AdminPendingPrescription" });
  }, [acknowledgeMutate, prescriptionId]);

  const toggleConfirmationModal = useCallback(
    (acknowledge = "") => {
      acknowledgeRef.current = acknowledge;
      if (acknowledge) {
        showDialog({
          question: `Are you sure you want to ${acknowledge?.toLowerCase()} this prescription?`,
          actionText: acknowledge,
          closeModal: toggleConfirmationModal,
          onClickNo: closeDialog,
          onClickYes: prescriptionAcknowledgeAction,
        });
      } else {
        closeDialog?.();
      }
    },
    [closeDialog, prescriptionAcknowledgeAction, showDialog],
  );

  const actionClick = useCallback(() => {
    if (isReview) {
      history.push({
        // pathname: "/edit-prescription/" + prescriptionId,
        pathname: "/edit-prescription-flow/" + prescriptionId,
        state: {
          isRefillPrescription: patientProductRefill ? mainPrescriptionId : "",
          isReview: true,
          side: listingName,
          prescriptionStatus: invoiceData?.prescriptionStatus,
          shippingCost1: shippingCost,
        },
      });
    } else {
      printModal();
    }
  }, [
    history,
    invoiceData?.prescriptionStatus,
    isReview,
    listingName,
    mainPrescriptionId,
    patientProductRefill,
    prescriptionId,
    printModal,
    shippingCost,
  ]);

  const approveAction = useCallback(
    e => {
      e.preventDefault();
      toggleConfirmationModal("Approve");
    },
    [toggleConfirmationModal],
  );

  const disapproveAction = useCallback(
    e => {
      e.preventDefault();
      toggleConfirmationModal("Disapprove");
    },
    [toggleConfirmationModal],
  );

  const submitPrescription = useCallback(
    e => {
      e.preventDefault();
      const pinCode = parseInt(pinCodeRef?.current?.value);
      if (isNaN(pinCode) && showPIN) {
        setCustomError("Invalid Pin Code");
        return;
      }
      const organizedData = {
        ...invoiceData,
        prescriptionId: invoiceData?.id,
        shippingInfo: {
          ...invoiceData?.shippingInfo,
          rushOrderCost: invoiceData?.shippingInfo.rushOrderCost,
          shipTo: invoiceData?.shippingInfo?.shipTo ?? (invoiceData?.patientIds?.length > 1 ? "clinic" : "patient"),
        },
        signatureFileUrl: signatureFileUrl,
      };
      if (showPIN) {
        set(organizedData, "otpToken", pinCode);
      } else if (patientProductRefill) {
        organizedData.isRefillRequest = true;
        organizedData.refillFromPrescriptionId = mainPrescriptionId;
      }

      rxSubmitMutate({
        isReview: true,
        prescription: organizedData,
        side: listingName,
      });
    },
    [showPIN, invoiceData, signatureFileUrl, patientProductRefill, rxSubmitMutate, listingName, mainPrescriptionId],
  );

  const renderColumnItem = useCallback(
    (title = "", value = "", rowCol = 4, rightCol = 4, leftCol = 8) => (
      <Grid container item xs={rowCol}>
        <Grid item xs={rightCol}>
          <strong>{`${title}:`} </strong>
        </Grid>
        <Grid item xs={leftCol}>
          {value}
        </Grid>
      </Grid>
    ),
    [],
  );

  const getClinicAddress = useCallback(
    invoiceDetail => (
      <>
        {invoiceDetail?.clinicLocationDetails?.locationName}
        <br />
        {invoiceDetail?.clinicLocationDetails?.addressLine1 + ","}
        <br />
        {invoiceDetail?.clinicLocationDetails?.addressLine2}
        <br />
        {`${invoiceDetail?.clinicLocationDetails?.city}, ${invoiceDetail?.clinicLocationDetails?.state}`},
        <br />
        {invoiceDetail?.clinicLocationDetails?.zipcode}
      </>
    ),
    [],
  );

  const clinicInfo = useCallback(
    invoiceDetail => (
      <>
        <Divider>Clinic & Prescriber Information</Divider>
        <Grid container spacing={1}>
          {renderColumnItem("Clinic Name", invoiceDetail?.clinicDetails?.businessName, 8, 2, 0)}
          {renderColumnItem("Clinic Address", getClinicAddress(invoiceDetail), 8, 2, 0)}
          <Grid container item>
            {renderColumnItem(
              "Clinic Phone",
              invoiceDetail?.clinicLocationDetails?.officeContactPhone ||
                invoiceDetail?.clinicLocationDetails?.contactNumber,
              8,
              2,
              0,
            )}
            {renderColumnItem("Clinic Fax", invoiceDetail?.clinicLocationDetails?.officeContactFax, 4, 3, 0)}
          </Grid>
          {renderColumnItem(
            "Prescriber Name",
            `${invoiceDetail?.providerDetails?.firstName ?? ""} ${invoiceDetail?.providerDetails?.lastName ?? ""}`,
            8,
            2,
            0,
          )}
          <Grid container item>
            {renderColumnItem("NPI #", invoiceDetail?.providerDetails?.npi, 8, 2, 0)}
            {renderColumnItem(
              "DEA #",
              invoiceDetail?.providerDetails?.deaInfo?.filter(
                a => a.clinicLocationId === invoiceDetail?.clinicLocationDetails?.id,
              )[0]?.dea ??
                invoiceDetail?.providerDetails?.dea ??
                "-",
              4,
              3,
              0,
            )}
          </Grid>
          <Grid container item>
            {renderColumnItem("State License #", invoiceDetail?.providerDetails?.medicalLicenseNumber ?? "-", 8, 2, 0)}
            {renderColumnItem(
              "License State",
              invoiceDetail?.providerDetails?.medicalLicenseStatus ??
                invoiceDetail?.providerDetails?.providerLicensingState ??
                "-",
              4,
              3,
              0,
            )}
          </Grid>
        </Grid>
      </>
    ),
    [getClinicAddress, renderColumnItem],
  );

  const billingInfo = useCallback(() => {
    if (!isReview) {
      return null;
    }
    return (
      <>
        <Divider>Billing Information</Divider>
        <Grid container spacing={1}>
          <Grid container item>
            {renderColumnItem("Bill To", invoiceData?.billTo, 8, 2, 0)}
            {renderColumnItem("Card holder", decryptedBillingInfo?.creditCard?.cardHolderName ?? "", 4, 3, 0)}
          </Grid>

          <Grid container item>
            {renderColumnItem(
              "Credit Card #",
              `**** **** **** ${decryptedBillingInfo?.creditCard?.creditCardNumber?.substring(
                decryptedBillingInfo?.creditCard?.creditCardNumber.length - 4,
              )}`,
              8,
              2,
              0,
            )}
            {renderColumnItem("Expiration Date", decryptedBillingInfo?.creditCard?.cardExpirationDate, 4, 3, 0)}
          </Grid>
          {renderColumnItem("NET", capitalize(invoiceData?.paymentTermDays?.toString()) ?? "-", 8, 2, 0)}
        </Grid>
      </>
    );
  }, [
    decryptedBillingInfo?.creditCard?.cardExpirationDate,
    decryptedBillingInfo?.creditCard?.cardHolderName,
    decryptedBillingInfo?.creditCard?.creditCardNumber,
    invoiceData?.billTo,
    invoiceData?.paymentTermDays,
    isReview,
    renderColumnItem,
  ]);

  const patientInfo = useCallback(
    (patientId, invoiceDetail) => {
      // patients => for single invoice ,   patientsDetail => for bulk invoice
      const patientList = invoiceDetail?.patients ?? invoiceDetail?.patientsDetail ?? [];
      const patientInfo = find(patientList, { id: patientId });
      const patientAddress = patientInfo?.addresses?.[0];

      return (
        <>
          <Divider className="my-3">Patient Information</Divider>
          <Grid container spacing={1}>
            <Grid container item>
              {renderColumnItem(
                "Patient Name",
                `${patientInfo?.firstName ?? ""} ${patientInfo?.lastName ?? ""}`,
                8,
                2,
                0,
              )}
              {renderColumnItem("DOB", patientInfo?.dateOfBirth ?? "-", 4, 3, 0)}
            </Grid>
            <Grid container item>
              {renderColumnItem(
                "Patient Address",
                `${patientAddress?.addressLine1 ?? ""} ${patientAddress?.addressLine2 ?? "-"}, ${
                  patientAddress?.city ?? "-"
                }, ${patientAddress?.state ?? "-"}, ${patientAddress?.zipcode ?? "-"}`,
                8,
                2,
                0,
              )}
              {renderColumnItem("Gender", patientInfo?.sex ?? "-", 4, 3, 0)}
            </Grid>
            <Grid container item>
              {renderColumnItem("Phone Number", patientInfo?.phoneNumber || patientInfo?.cellPhone || "-", 8, 2, 0)}
              {renderColumnItem("Email", patientInfo?.email ?? "-", 4, 3, 0)}
            </Grid>
          </Grid>
        </>
      );
    },
    [renderColumnItem],
  );

  const renderDeliveryInfo = useCallback(() => {
    const shippingInfo = invoiceData?.shippingInfo;
    const isPickup = shippingInfo?.shippingMethod.toLowerCase().includes("pickup");
    return (
      <>
        {renderColumnItem("Delivery Method", shippingInfo?.shippingMethod, 12, 1.35, 0)}
        {!isPickup &&
          renderColumnItem(
            "Delivery Address",
            <>
              <i>Ship To:</i> {shippingInfo?.shipTo}
              <br />
              {shippingInfo?.shippingAddress?.addressLine1}
              <br />
              {shippingInfo?.shippingAddress?.addressLine2 ?? "-"}
              <br />
              {shippingInfo?.shippingAddress?.city}, {shippingInfo?.shippingAddress?.state}
              <br />
              {shippingInfo?.shippingAddress?.zipcode}
            </>,
            12,
            1.35,
            0,
          )}
      </>
    );
  }, [invoiceData?.shippingInfo, renderColumnItem]);

  const renderInvoiceBillingInfo = useCallback(
    invoiceDetail => {
      if (!isReview) {
        return null;
      }
      return (
        <>
          <Grid container className="mt-3" rowSpacing={1}>
            {Boolean(invoiceDetail?.creditRequested) && (
              <>
                <Grid item xs={3} md={2} />
                <Grid item xs={3} md={2} className="text-start" />
                <Grid item md={4} className="d-none d-md-block" />

                <Grid item xs={3} md={2} className="text-end">
                  Credit Used
                </Grid>
                <Grid item xs={3} md={2} className="text-end">
                  <strong>$ {parseFloat(invoiceDetail?.creditRequested).toFixed(2)}</strong>
                </Grid>
              </>
            )}

            <Grid item xs={3} md={2}>
              Priority Order
            </Grid>
            <Grid item xs={3} md={2} className="text-start">
              <strong>{invoiceDetail?.shippingInfo?.isRushOrder ? "Yes" : "No"}</strong>
            </Grid>
            <Grid item md={4} className="d-none d-md-block" />

            <Grid item xs={3} md={2} className="text-end">
              Sub Total
            </Grid>
            <Grid item xs={3} md={2} className="text-end">
              <strong>
                ${" "}
                {isBulkInvoice
                  ? parseFloat(invoiceDetail?.subTotal).toFixed(2)
                  : parseFloat(
                      invoiceDetail?.totalAmount -
                        (invoiceDetail?.shippingCost ?? 0) -
                        (invoiceDetail?.shippingInfo?.rushOrderCost ?? 0),
                    ).toFixed(2)}
              </strong>
            </Grid>
          </Grid>
          {!isBulkInvoice && (
            <Grid container className="my-2">
              <Grid item xs={3} md={2}>
                Shipping Method
              </Grid>
              <Grid item xs={3} md={2} className="text-start">
                <strong>{invoiceDetail?.shippingInfo?.shippingMethod}</strong>
              </Grid>
              <Grid item md={4} className="d-none d-md-block" />

              <Grid item xs={3} md={2} className="text-end">
                Shipping
              </Grid>
              <Grid item xs={3} md={2} className="text-end">
                <strong>${invoiceDetail?.shippingCost ?? "0"}</strong>
              </Grid>
            </Grid>
          )}
          {invoiceDetail?.shippingInfo?.isRushOrder ? (
            <>
              <Grid container>
                <Grid item xs={3} md={2}>
                  Signature Required
                </Grid>
                <Grid item xs={3} md={2} className="text-start">
                  <strong>{invoiceDetail?.shippingInfo?.isSignatureRequired ? "Yes" : "No"}</strong>
                </Grid>
                <Grid item md={4} className="d-none d-md-block" />
                <Grid item xs={3} md={2} className="text-end">
                  Priority Order Fee
                </Grid>
                <Grid item xs={3} md={2} className="text-end">
                  <strong>$ {invoiceDetail?.shippingInfo?.rushOrderCost}</strong>
                </Grid>
              </Grid>
              {!isBulkInvoice && (
                <Grid container className="mt-2" justifyContent={"flex-end"}>
                  <Grid item xs={3} md={2} className="text-end">
                    <strong>Total</strong>
                  </Grid>
                  <Grid item xs={3} md={2} className="text-end">
                    <strong>$ {parseFloat(invoiceDetail?.totalAmount).toFixed(2)}</strong>
                  </Grid>
                </Grid>
              )}
            </>
          ) : (
            <Grid container>
              <Grid item xs={3} md={2}>
                Signature Required
              </Grid>
              <Grid item xs={3} md={2} className="text-start">
                <strong>{invoiceDetail?.shippingInfo?.isSignatureRequired ? "Yes" : "No"}</strong>
              </Grid>
              {!isBulkInvoice && (
                <>
                  <Grid item md={4} className="d-none d-md-block" />
                  <Grid item xs={3} md={2} className="text-end">
                    <strong>Total</strong>
                  </Grid>
                  <Grid item xs={3} md={2} className="text-end">
                    <strong>$ {parseFloat(invoiceDetail?.totalAmount).toFixed(2)}</strong>
                  </Grid>
                </>
              )}
            </Grid>
          )}
        </>
      );
    },
    [isReview, isBulkInvoice],
  );

  const renderInvoiceTable = useCallback(
    (invoiceTableData, invoiceRxPadProducts = [], invoiceDetail) => {
      return (
        <>
          <Grid item xs={12}>
            <TableContainer>
              <Table size="small">
                {prescriptionTableColumns}
                <TableBody>
                  {map(invoiceTableData, tableProduct => {
                    const actualProduct = find(invoiceDetail?.products, { productId: tableProduct?.productId });
                    return (
                      <TableRow key={`${actualProduct?._id}`}>
                        <TableCell style={{ paddingRight: "4px", paddingLeft: "4px" }}>
                          {actualProduct?.name ?? "-"}
                          <Typography>
                            <b>Sig:</b> {actualProduct?.sig?.toUpperCase() ?? "-"}
                          </Typography>
                        </TableCell>
                        <TableCell style={{ paddingRight: "4px", paddingLeft: "4px" }}>
                          {actualProduct?.quantity ?? "-"}
                        </TableCell>
                        <TableCell style={{ paddingRight: "4px", paddingLeft: "4px" }}>
                          {actualProduct?.refills ?? "-"}
                        </TableCell>
                        <TableCell style={{ paddingRight: "4px", paddingLeft: "4px" }}>
                          {formatDaySupply(actualProduct?.daySupply, "fs")}
                        </TableCell>
                        <TableCell style={{ paddingRight: "4px", paddingLeft: "4px" }}>
                          {actualProduct?.reasonForCompoundedMedication ?? "-"}
                        </TableCell>
                        <TableCell style={{ paddingRight: "4px", paddingLeft: "4px" }}>
                          {actualProduct?.last_office_visit_timestamp
                            ? moment(actualProduct.last_office_visit_timestamp, "YYYY-MM-DD").format("MM/DD/YYYY")
                            : "-"}
                        </TableCell>
                        {isReview && (
                          <>
                            <TableCell style={{ paddingRight: "4px", paddingLeft: "4px" }}>
                              {actualProduct?.patientDiagnosis ?? "-"}
                            </TableCell>
                            <TableCell style={{ paddingRight: "4px", paddingLeft: "4px" }}>
                              {actualProduct?.weight ?? "-"}
                            </TableCell>
                            <TableCell style={{ paddingRight: "4px", paddingLeft: "4px" }}>
                              {actualProduct?.unitPrice ?? "-"}
                            </TableCell>
                            <TableCell style={{ paddingRight: "4px", paddingLeft: "4px" }}>
                              {actualProduct?.discountedPrice ?? "-"}
                            </TableCell>
                            <TableCell style={{ paddingRight: "0px", paddingLeft: "4px" }}>
                              {parseFloat((actualProduct?.discountedPrice ?? 0) * actualProduct?.quantity).toFixed(2)}
                            </TableCell>
                          </>
                        )}
                      </TableRow>
                    );
                  })}
                  {invoiceRxPadProducts?.map(customProduct => {
                    return (
                      <TableRow key={`${customProduct?._id}`}>
                        <TableCell style={{ paddingRight: "4px", paddingLeft: "4px" }}>
                          {customProduct?.name ?? "-"}
                          <Typography>
                            <b>Sig:</b> {customProduct?.sig?.toUpperCase() ?? "-"}
                          </Typography>
                        </TableCell>
                        <TableCell style={{ paddingRight: "4px", paddingLeft: "4px" }}>
                          {customProduct?.quantity ?? "-"}
                        </TableCell>
                        <TableCell style={{ paddingRight: "4px", paddingLeft: "4px" }}>
                          {customProduct?.refills ?? "-"}
                        </TableCell>
                        <TableCell style={{ paddingRight: "4px", paddingLeft: "4px" }}>
                          {formatDaySupply(customProduct?.daySupply, "is")}
                        </TableCell>
                        <TableCell style={{ paddingRight: "4px", paddingLeft: "4px" }}>
                          {customProduct?.reasonForCompoundedMedication ?? "-"}
                        </TableCell>
                        <TableCell style={{ paddingRight: "4px", paddingLeft: "4px" }}>
                          {customProduct?.last_office_visit_timestamp
                            ? moment(customProduct.last_office_visit_timestamp, "YYYY-MM-DD").format("MM/DD/YYYY")
                            : "-"}
                        </TableCell>
                        {isReview && (
                          <>
                            <TableCell style={{ paddingRight: "4px", paddingLeft: "4px" }}>
                              {customProduct?.patientDiagnosis ?? "-"}
                            </TableCell>
                            <TableCell style={{ paddingRight: "4px", paddingLeft: "4px" }}>
                              {customProduct?.weight ?? "-"}
                            </TableCell>
                            <TableCell style={{ paddingRight: "4px", paddingLeft: "4px" }}>
                              {customProduct?.unitPrice ?? "-"}
                            </TableCell>
                            <TableCell style={{ paddingRight: "4px", paddingLeft: "4px" }}>
                              {customProduct?.discountedPrice ?? "-"}
                            </TableCell>
                            <TableCell style={{ paddingRight: "0px", paddingLeft: "4px" }}>
                              {customProduct?.discountedPrice
                                ? parseFloat(customProduct?.discountedPrice * customProduct?.quantity).toFixed(2)
                                : "-"}
                            </TableCell>
                          </>
                        )}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          {renderInvoiceBillingInfo(invoiceDetail)}
          <Grid item xs={12} className="mt-5">
            <strong> Order Notes</strong>
          </Grid>
          <Divider variant="fullWidth" className="my-1" />
          <Typography>{invoiceData?.shippingInfo?.orderNotes ?? "-"}</Typography>
          <Grid container item xs={12} justifyContent={"space-between"} alignItems={"flex-end"}>
            <Grid item>
              {!isReview && (
                <>
                  <strong>Priority Order Fee: </strong> &nbsp; {invoiceDetail?.shippingInfo?.isRushOrder ? "Yes" : "No"}
                  <Divider />
                </>
              )}
              {showExostarSign && <ExostarSign setSuccess={setEnableSubmit} prescriptionData={invoiceData} />}
            </Grid>
            <Grid item>
              {signatureFileUrl ? (
                <>
                  <img src={signatureFileUrl} alt="Signature" width={180} />
                  <p>
                    <strong>Signed by:</strong>
                    {` ${invoiceDetail?.providerDetails?.firstName ?? ""} ${
                      invoiceDetail?.providerDetails?.lastName ?? ""
                    } `}
                    <br />
                    <strong>On: </strong> {dateFormatterMountainTime(invoiceDetail?.prescriptionDate)}
                  </p>
                </>
              ) : (
                <>
                  <Typography variant="h6" color={"red"} fontWeight={600}>
                    Prescriber's Signature Missing
                  </Typography>
                  <Typography className="text-muted">Please sign it here, Thanks</Typography>
                </>
              )}

              <Divider />
              {canSubmitPrescription && (
                <Box flexDirection={"row"} display={"flex"}>
                  <span
                    className="pointer form-control mt-1 mx-2"
                    style={{ width: "250px" }}
                    onClick={() => setSignatureModal(true)}>
                    <i class="fas fa-pen mx-2"></i>Signature Pad
                  </span>
                </Box>
              )}
            </Grid>
          </Grid>
        </>
      );
    },
    [
      prescriptionTableColumns,
      renderInvoiceBillingInfo,
      invoiceData?.shippingInfo?.orderNotes,
      isReview,
      showPIN,
      signatureFileUrl,
      canSubmitPrescription,
      uploadSignature,
      showExostarSign
    ],
  );

  const renderInvoiceView = useCallback(
    invoiceDetail => {
      const patientIds = invoiceDetail?.patientIds ?? [];
      let invoiceProducts = invoiceDetail?.products;
      let invoiceRxPadProducts = invoiceDetail?.rxPadProducts || [];
      const clinicView = clinicInfo(invoiceDetail);
      const billingView = billingInfo(invoiceDetail);
      const deliveryView = renderDeliveryInfo();

      return map(patientIds, patientId => {
        // Following condition for find the product detail according to patientProducts.
        if (invoiceDetail?.patientsProducts.length > 0) {
          const filteredPatient = filter(invoiceDetail?.patientsProducts, { patientId });
          invoiceProducts = flatten(
            map(filteredPatient, filteredPatient =>
              map(filteredPatient?.products, patientProduct =>
                find(invoiceDetail.products, { productId: patientProduct.productId }),
              ),
            ),
          );
        }
        return (
          <div key={patientId} className="page-break">
            <Card sx={{ boxShadow: 5 }} className={classNames({ "my-3": isBulkInvoice || patientId.length > 1 })}>
              <CardContent>
                <Grid container rowSpacing={2}>
                  <Grid item xs={12}>
                    {/* <img src={VPICompoundingLogo} alt="logo" height="40" /> */}
                  </Grid>
                  {clinicView}
                  {billingView}
                  {patientInfo(patientId, invoiceDetail)}
                  <Divider className="my-3">Prescription Order Information</Divider>
                  {isReview && (
                    <Grid container spacing={1}>
                      {deliveryView}
                    </Grid>
                  )}
                  {renderInvoiceTable(invoiceProducts, invoiceRxPadProducts, invoiceDetail)}
                </Grid>
                <Divider variant="fullWidth" className="my-3" />
              </CardContent>
            </Card>
          </div>
        );
      });
    },
    [billingInfo, clinicInfo, isBulkInvoice, patientInfo, renderDeliveryInfo, renderInvoiceTable],
  );

  const renderInvoice = useMemo(() => {
    if (isBulkInvoice) {
      return map(invoiceData?.subPrescriptionList, subPrescription => (
        <React.Fragment key={subPrescription.id}>{renderInvoiceView(subPrescription)}</React.Fragment>
      ));
    }
    return renderInvoiceView(invoiceData);
  }, [invoiceData, isBulkInvoice, renderInvoiceView]);

  const renderActionButton = useMemo(() => {
    if (showActionModal) {
      return (
        <>
          <Button
            disabled={disableActions}
            variant="contained"
            className="greenButton"
            style={{ marginRight: 5 }}
            onClick={approveAction}>
            Approve Prescription
          </Button>
          <Button disabled={disableActions} variant="contained" className="redButton" onClick={disapproveAction}>
            Disapprove Prescription
          </Button>
        </>
      );
    } else if (canSubmitPrescription) {
      let buttonText = "Submit";
      if (listingName === LISTING_NAME.PATIENT_SUMMARY && !patientProductRefill) {
        buttonText = noRefill ? "Reorder" : "Refill";
      } else if (listingName === LISTING_NAME.CLINIC_PENDING_APPROVAL) {
        buttonText = "Resubmit";
      }
      return (
        <Button
          disabled={
            disableActions ||
            (userRole === USER_ROLES.CLINIC_SUPPORT && !window?.location?.pathname.includes("patient-profile")) ||
            (enableSubmit === '' && showExostarSign)
          }
          variant="contained"
          className="blueButton"
          style={{ marginRight: 5 }}
          onClick={submitPrescription}>
          {`${buttonText} Prescription`}
        </Button>
      );
    }
  }, [
    showActionModal,
    canSubmitPrescription,
    disableActions,
    approveAction,
    disapproveAction,
    listingName,
    patientProductRefill,
    submitPrescription,
    noRefill,
    showExostarSign
  ]);

  return (
    <Dialog open={Boolean(prescriptionId)} onClose={closeModal} scroll="paper" fullWidth maxWidth="xl">
      {showLoading && <Spinner />}
      <DialogTitle variant="h5">
        {isReview ? "Review Prescription" : "Rx Order"}
        <IconButton
          aria-label="close"
          onClick={closeModal}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: theme => theme.palette.grey[500],
          }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        {isFetching ? (
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress />
          </Box>
        ) : (
          <Box ref={componentRef}>
            <AlertMessage isError msg={errorMessage} />
            {renderInvoice}
          </Box>
        )}
      </DialogContent>
      <DialogActions className="justify-content-between">
        <Box>{renderActionButton}</Box>
        {showActionButton && (
          <Button disabled={disableActions} variant="contained" className="pinkButton" onClick={actionClick}>
            {isReview ? "Edit" : "Print"}
          </Button>
        )}
      </DialogActions>
      <SignaturePad
        actionText="Save"
        onClickSave={uploadSignature}
        closeModal={closeSignatureModal}
        showModal={signatureModal}
      />
    </Dialog>
  );
}

export default React.memo(OrderViewModal);
