import { filter, find, findKey, includes, map } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useGetClinicLocationAddressQuery, useGetPatientAddressQuery, useShippingMethodQuery } from "rtk";
import { EXCLUDE_SHIP_METHOD_FOR_RUSH_ORDER, SHIP_METHODS } from "utils/StatusConstant";

function usePrescriptionShippingInfo({ setValue, isBulkOrder = false, clinicLocationId = "", selectedPatients = [] }) {
  const [patientAddressState, setPatientAddressState] = useState([]);
  const {
    data: clinicAddressData,
    error: clinicAddressError,
    isFetching: clinicAddressFetching,
  } = useGetClinicLocationAddressQuery({ locationId: clinicLocationId }, { skip: !clinicLocationId });
  const {
    data: patientAddressData,
    error: patientAddressError,
    isFetching: patientAddressFetching,
  } = useGetPatientAddressQuery(
    { patientId: selectedPatients[0] },
    { skip: selectedPatients?.length === 0 || isBulkOrder },
  );

  const { data: shippingMethods, error: shippingError, isFetching: shippingFetching } = useShippingMethodQuery();

  useEffect(() => {
    if (patientAddressData?.addresses) {
      setPatientAddressState(patientAddressData?.addresses);
    }
  }, [patientAddressData?.addresses]);

  const apiLoading = useMemo(
    () => patientAddressFetching || clinicAddressFetching,
    [clinicAddressFetching, patientAddressFetching],
  );

  const apiError = useMemo(
    () => clinicAddressError?.data?.message || patientAddressError?.data?.message || shippingError?.data?.message || "",
    [clinicAddressError?.data?.message, patientAddressError?.data?.message, shippingError?.data?.message],
  );

  const findShippingMethod = useCallback(
    methodName => findKey(shippingMethods, method => includes(method, methodName)),
    [shippingMethods],
  );

  const getShippingMethodOption = useCallback(
    (method, isRushOrder = false, isColdShipped = false) => {
      const emptyOption = [
        {
          value: "",
          label: "No data found",
          disabled: true,
        },
      ];
      if (shippingFetching) {
        return [
          {
            value: "",
            label: "Loading",
            disabled: true,
          },
        ];
      } else if (shippingMethods?.shippingMethods?.length < 1) {
        return emptyOption;
      }
      let optionList = shippingMethods?.[method]?.map(shipping => find(SHIP_METHODS, { value: shipping }));
      if ((isRushOrder || isColdShipped) && optionList) {
        optionList = filter(optionList, option => !includes(EXCLUDE_SHIP_METHOD_FOR_RUSH_ORDER, option?.value));
      }
      return optionList ?? emptyOption;
    },
    [shippingFetching, shippingMethods],
  );

  const getClinicAddressObj = useCallback(() => {
    return {
      addressLine1: clinicAddressData?.addressLine1,
      addressLine2: clinicAddressData?.addressLine2,
      city: clinicAddressData?.city,
      state: clinicAddressData?.state,
      zipcode: clinicAddressData?.zipcode,
    };
  }, [
    clinicAddressData?.addressLine1,
    clinicAddressData?.addressLine2,
    clinicAddressData?.city,
    clinicAddressData?.state,
    clinicAddressData?.zipcode,
  ]);

  const getPatientAddressObj = useCallback(
    (id = "") => {
      const patientAddress = find(patientAddressState, { _id: id });
      return {
        addressLine1: patientAddress?.addressLine1,
        addressLine2: patientAddress?.addressLine2,
        city: patientAddress?.city,
        state: patientAddress?.state,
        zipcode: patientAddress?.zipcode,
      };
    },
    [patientAddressState],
  );

  const findAddressId = useCallback(
    (addressInfo, isClinic = false, prescriptionPatient = []) => {
      let id = "";
      if (isClinic) {
        if (
          addressInfo?.addressLine1 === clinicAddressData?.addressLine1 &&
          addressInfo?.addressLine2 === clinicAddressData?.addressLine2 &&
          addressInfo?.city === clinicAddressData?.city &&
          addressInfo?.state === clinicAddressData?.state &&
          addressInfo?.zipcode === clinicAddressData?.zipcode
        )
          id = clinicAddressData?.id;
      } else {
        const patientAddressObj = find(
          patientAddressState,
          patientAddress =>
            addressInfo?.addressLine1 === patientAddress?.addressLine1 &&
            addressInfo?.addressLine2 === patientAddress?.addressLine2 &&
            addressInfo?.city === patientAddress?.city &&
            addressInfo?.state === patientAddress?.state &&
            addressInfo?.zipcode === patientAddress?.zipcode,
        );
        if (patientAddressObj) {
          id = patientAddressObj?._id;
        } else if (patientAddressState.length > 0 && selectedPatients[0] === prescriptionPatient[0]) {
          // THis will add the deleted address in the dropdown
          id = "DELETED_ADDRESS_ID";
          setPatientAddressState(prev => [
            ...prev,
            {
              _id: "DELETED_ADDRESS_ID",
              addressLine1: addressInfo?.addressLine1 ?? "",
              addressLine2: addressInfo?.addressLine2 ?? "",
              city: addressInfo?.city ?? "",
              state: addressInfo?.state ?? "",
              zipcode: addressInfo?.zipcode ?? "",
            },
          ]);
        }
      }
      return id;
    },
    [
      clinicAddressData?.addressLine1,
      clinicAddressData?.addressLine2,
      clinicAddressData?.city,
      clinicAddressData?.id,
      clinicAddressData?.state,
      clinicAddressData?.zipcode,
      patientAddressState,
      selectedPatients,
    ],
  );

  const clinicAddressOptions = useMemo(() => {
    if (clinicAddressFetching) {
      return [
        {
          value: "",
          label: "Loading",
          disabled: true,
        },
      ];
    } else if (!clinicAddressData) {
      return [
        {
          value: "",
          label: "No data found",
          disabled: true,
        },
      ];
    }
    let clinicAddressDD = map([clinicAddressData], clinicAddress => ({
      value: clinicAddress?.id,
      label: `${clinicAddress.addressLine1 ?? "-"}, ${clinicAddress?.addressLine2 ?? "-"}, ${clinicAddress.city}, ${
        clinicAddress?.state ?? "-"
      }, ${clinicAddress.zipcode ?? "-"}`,
    }));

    return clinicAddressDD;
  }, [clinicAddressData, clinicAddressFetching]);

  const patientAddressOptions = useMemo(() => {
    if (patientAddressFetching) {
      return [
        {
          value: "",
          label: "Loading",
          disabled: true,
        },
      ];
    } else if (patientAddressState.length === 0) {
      return [
        {
          value: "",
          label: "No data found",
          disabled: true,
        },
      ];
    }
    let patientAddressesDD = map(patientAddressState, patientAddress => {
      if (patientAddress.isDefault) {
        setValue("shippingAddress", patientAddress._id);
      }
      return {
        value: patientAddress?._id,
        label: `${patientAddress.addressLine1 ?? "-"}, ${patientAddress?.addressLine2 ?? "-"}, ${
          patientAddress.city
        }, ${patientAddress?.state ?? "-"}, ${patientAddress.zipcode ?? "-"}`,
      };
    });
    return patientAddressesDD;
  }, [patientAddressState, patientAddressFetching, setValue]);

  return {
    apiLoading,
    apiError,
    clinicAddressOptions,
    patientAddressOptions,
    findAddressId,
    getPatientAddressObj,
    getClinicAddressObj,
    getShippingMethodOption,
    findShippingMethod,
  };
}

export default usePrescriptionShippingInfo;
