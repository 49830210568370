import { map } from "lodash";
import { AdminApi } from "../../rtkApi";
import { ADMIN_END_POINTS, API_METHODS, CACHE_TAGS, END_POINTS, providesList } from "../../rtkConstant";

const AdminProductsQuery = AdminApi.injectEndpoints({
  endpoints: builder => ({
    getAllDiscountedProductsByClinicId: builder.query({
      queryFn: async (apiArgs, queryApi, _, baseQuery) => {
        try {
          const user = queryApi.getState().AuthSlice || {};
          if (user?.userData) {
            const organizedBody = {
              ...apiArgs,
              limit: apiArgs?.limit || 10,
              currentPage: apiArgs?.currentPage,
              clinicId: apiArgs?.clinicId,
              userId: user?.userData?.id,
            };
            let url;
            url = END_POINTS.CLINIC_ALL_DISCOUNTED_PRODUCTS;
            const response = await baseQuery({
              url,
              method: API_METHODS.POST,
              body: organizedBody,
            });
            return response;
          } else {
            return {
              error: {
                data: { message: "Unauthenticated user" },
              },
            };
          }
        } catch {}
      },
      providesTags: (result, error, arg) => providesList(result?.products, CACHE_TAGS.PRODUCT),
    }),
  }),
});

export const { useLazyGetAllDiscountedProductsByClinicIdQuery } = AdminProductsQuery;
