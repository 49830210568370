/* eslint-disable react-hooks/exhaustive-deps */
import {
  PrescriptionActionContext,
  PrescriptionApiStateContext,
  PrescriptionFormSateContext,
  PrescriptionStateContext,
} from "context/PrescriptionContext";
import { flatten } from "lodash";
import { useCallback, useContext, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import {
  useGetFamilyCategoriesQuery,
  useGetFavoriteProductsQuery,
  useGetRecentProductsQuery,
  useLazyGetProductByCategoryQuery,
  useLazyGetProductBySearchQuery,
} from "rtk";
import { clearProductSearch } from "rtk/rtkReducers";

function useProductSelection(params) {
  const dispatch = useDispatch();
  const [keywordSearch, setKeywordSearch] = useState("");
  const [searchFilter, setSearchFilter] = useState("commonName");
  const [selectedFamily, setSelectedFamily] = useState(["Categories"]);
  const { patientFormData, selectedProducts } = useContext(PrescriptionFormSateContext);
  const { prescriptionData } = useContext(PrescriptionApiStateContext);
  const { isPrescriptionEdit, preSelectedProducts } = useContext(PrescriptionStateContext);
  const { addProductToCartAction, addToSidebarSummary, handleCustomProductError, handleSelectedProduct } =
    useContext(PrescriptionActionContext);

  const {
    data: productFamilyData,
    error: productFamilyError,
    isFetching: productFamilyFetching,
  } = useGetFamilyCategoriesQuery();
  const [
    fetchSearchProduct,
    { data: searchProductData, error: searchProductError, isFetching: searchProductFetching },
  ] = useLazyGetProductBySearchQuery();

  const {
    data: recentProductData,
    error: recentProductError,
    isFetching: recentProductFetching,
  } = useGetRecentProductsQuery();

  const {
    data: clinicFavoriteProductData,
    error: clinicFavoriteProductError,
    isFetching: clinicFavoriteProductFetching,
  } = useGetFavoriteProductsQuery();

  const [fetchProduct, { data: productData, error: productError, isFetching: productFetching }] =
    useLazyGetProductByCategoryQuery();

  const productSelectionLoading = useMemo(
    () =>
      productFamilyFetching ||
      recentProductFetching ||
      clinicFavoriteProductFetching ||
      productFetching ||
      searchProductFetching,
    [
      clinicFavoriteProductFetching,
      productFamilyFetching,
      productFetching,
      recentProductFetching,
      searchProductFetching,
    ],
  );
  const productSelectionError = useMemo(
    () =>
      productFamilyError?.data?.message ||
      recentProductError?.data?.message ||
      productError?.data?.message ||
      clinicFavoriteProductError?.data?.message ||
      searchProductError?.data?.message ||
      "",
    [
      clinicFavoriteProductError?.data?.message,
      productError?.data?.message,
      productFamilyError?.data?.message,
      recentProductError?.data?.message,
      searchProductError?.data?.message,
    ],
  );

  const isMultiPatient = useMemo(() => patientFormData?.current?.length > 1, [patientFormData?.current?.length]);
  const clearSearchFilter = useCallback(() => {
    dispatch(
      clearProductSearch({
        endpointName: "getProductBySearch",
        // position originalArgs matter otherwise query data will not be clear
        originalArgs: { filter: [searchFilter], keyword: keywordSearch },
      }),
    );
    setKeywordSearch("");
    setSearchFilter("commonName");
  }, [dispatch, keywordSearch, searchFilter]);

  const updateKeyword = useCallback((keyword = "") => {
    setKeywordSearch(keyword);
  }, []);

  const updateFilter = useCallback(e => {
    e.preventDefault();
    setSearchFilter(e.target.value);
    setKeywordSearch("");
  }, []);

  const handleSearch = useCallback(() => {
    fetchSearchProduct({ keyword: keywordSearch, filter: [searchFilter] });
  }, [fetchSearchProduct, keywordSearch, searchFilter]);

  const handleProductSelect = useCallback(
    (product, resetState = false) => {
      setSelectedFamily(prev => {
        const prevState = resetState ? ["Categories"] : prev;
        const newState = [...prevState, ...flatten([product])];
        if (newState.length === 3) {
          fetchProduct(newState, true);
        }
        return newState;
      });
    },
    [fetchProduct],
  );

  const handleBreadcrumbSelect = useCallback(index => {
    setSelectedFamily(prev => prev.slice(0, index + 1));
  }, []);

  return {
    productData,
    searchFilter,
    isMultiPatient,
    selectedFamily,
    keywordSearch,
    selectedProducts,
    prescriptionData,
    searchProductData,
    recentProductData,
    productFamilyData,
    isPrescriptionEdit,
    preSelectedProducts,
    clinicFavoriteProductData,
    productSelectionLoading,
    productSelectionError,
    updateFilter,
    handleSearch,
    updateKeyword,
    clearSearchFilter,
    handleProductSelect,
    addToSidebarSummary,
    handleSelectedProduct,
    addProductToCartAction,
    handleBreadcrumbSelect,
    handleCustomProductError,
  };
}

export default useProductSelection;
