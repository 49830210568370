import { Button, Container, Grid, Typography } from "@mui/material";
import OutlineSelectField from "components/InputField/OutlineSelectField";
import { find, map } from "lodash";
import React, { useCallback, useMemo, useState } from "react";

function ProductCommonNameListing({ commonProduct, addToCartAction }) {
  const [selectedVariant, setSelectedVariant] = useState({});

  const disableButton = useMemo(() => !selectedVariant?.id, [selectedVariant]);
  const commonProductVariantOptions = useMemo(
    () => map(commonProduct?.products, product => ({ value: product?.id, label: product?.name })),
    [commonProduct],
  );

  const handleSelectChange = useCallback(
    event => {
      const getProductObj = find(commonProduct?.products, { id: event.target.value });
      setSelectedVariant(getProductObj);
    },
    [commonProduct?.products],
  );

  return (
    <Grid container item xs={12} sx={{ pl: 0 }}>
      <Container sx={{ p: 2, backgroundColor: "white", boxShadow: 2 }}>
        <Grid item container xs={12} alignItems={"center"} justifyContent={"space-around"}>
          <Grid item>
            <img
              src="https://i.pinimg.com/564x/84/a8/7e/84a87eb13637e32d5fd67ca7fc2cf2b4.jpg"
              width={"100px"}
              height="100px"
              alt={"medication"}
              loading="lazy"
            />
          </Grid>
          <Grid item xs={6}>
            <Typography variant="h6" mb={1} fontWeight={400}>
              {commonProduct?.commonName}
            </Typography>
            <OutlineSelectField
              ref={null}
              error={" "}
              inputField={{ value: selectedVariant?.id ?? "", onChange: handleSelectChange }}
              MuiFieldProps={{
                fullWidth: true,
                label: "Select Variant",
                optionArray: commonProductVariantOptions,
              }}
            />
          </Grid>
          <Grid item>
            <Button
              disabled={disableButton}
              variant="contained"
              color="secondary"
              onClick={addToCartAction({ ...selectedVariant, productId: selectedVariant.id })}
              sx={{
                borderRadius: 50,
              }}>
              ADD TO CART
            </Button>
          </Grid>
        </Grid>
      </Container>
    </Grid>
  );
}

export default React.memo(ProductCommonNameListing);
