import React, { useCallback, useMemo, useRef } from "react";
import { usePrescriptionApi, usePrescriptionProvider } from "./Hook";
import {
  PrescriptionActionContext,
  PrescriptionApiStateContext,
  PrescriptionFormActionContext,
  PrescriptionFormSateContext,
  PrescriptionStateContext,
  ProductCartDialogContext,
} from "./PrescriptionContext";
import { ProductCartModal } from "components";

function PrescriptionProvider({ children }) {
  const productCartDialogRef = useRef(null);
  const {
    isNet30,
    apiError,
    clinicData,
    apiLoading,
    adminClinicId,
    filterClinicId,
    prescriptionData,
    clinicDataFetching,
    isPrescriptionEdit,
    selectedPatients: preSelectedPatients,
    selectedProducts: preSelectedProducts,
    getEditApiParam,
  } = usePrescriptionApi();

  const {
    formRef,
    activeStep,
    customError,
    shippingRate,
    mutateLoading,
    rushOrderCheck,
    creditRequestAmount,
    shippingRateError,
    shippingRateLoading,
    selectedProducts,
    mutateError: providerError,
    patientData: patientFormData,
    providerData: providerFormData,
    productData: productFormData,
    completedStep,
    shippingMethod,
    selectedClinicLocation,
    prescriptionSubTotal,
    prescriptionSideCartRef,
    formData,
    goBack,
    goNext,
    rateMutate,
    reviewButton,
    addUsedCredit,
    getSingleFormData,
    setShippingMethod,
    toggleRushOrder,
    progressBarAction,
    subTotalCalculation,
    addToSidebarSummary,
    handleSelectedProduct,
    removeSelectedProduct,
    addProductToCartAction,
    setCustomShippingRate,
    handleCustomProductError,
  } = usePrescriptionProvider({
    getEditApiParam,
    isPrescriptionEdit,
    filterClinicId,
    adminClinicId,
    productCartDialogRef,
  });

  const mutateError = useMemo(() => apiError || providerError || customError, [apiError, customError, providerError]);
  const spinnerLoading = useMemo(() => apiLoading || clinicDataFetching || mutateLoading, [apiLoading,clinicDataFetching, mutateLoading]);

  const prescriptionStateValue = useMemo(
    () => ({
      mutateError,
      showSpinner: spinnerLoading,
      clinicDataFetching,
      isPrescriptionEdit,
      preSelectedPatients,
      preSelectedProducts,
    }),
    [mutateError, spinnerLoading, clinicDataFetching, isPrescriptionEdit, preSelectedPatients, preSelectedProducts],
  );

  const prescriptionApiStateValue = useMemo(
    () => ({ filterClinicId, clinicData, isNet30, prescriptionData }),
    [clinicData, filterClinicId, isNet30, prescriptionData],
  );
  //...FormData are the data saved after next button.
  const prescriptionFormStateValue = useMemo(
    () => ({
      completedStep,
      formRef,
      activeStep,
      patientFormData,
      productFormData,
      providerFormData,
      selectedProducts,
      shippingRate,
      shippingMethod,
      prescriptionSubTotal,
      creditRequestAmount,
      rushOrderCheck,
      shippingRateError,
      shippingRateLoading,
      prescriptionSideCartRef,
      selectedClinicLocation,
    }),
    [
      completedStep,
      formRef,
      activeStep,
      patientFormData,
      productFormData,
      selectedProducts,
      providerFormData,
      shippingRate,
      shippingMethod,
      prescriptionSubTotal,
      creditRequestAmount,
      rushOrderCheck,
      shippingRateError,
      shippingRateLoading,
      prescriptionSideCartRef,
      selectedClinicLocation,
    ],
  );

  const prescriptionActionValue = useMemo(
    () => ({
      addProductToCartAction,
      addToSidebarSummary,
      handleCustomProductError,
      removeSelectedProduct,
      rateMutate,
      addUsedCredit,
      setCustomShippingRate,
      setShippingMethod,
      toggleRushOrder,
      subTotalCalculation,
      handleSelectedProduct,
    }),
    [
      addProductToCartAction,
      addToSidebarSummary,
      handleCustomProductError,
      removeSelectedProduct,
      rateMutate,
      addUsedCredit,
      setCustomShippingRate,
      setShippingMethod,
      toggleRushOrder,
      subTotalCalculation,
      handleSelectedProduct,
    ],
  );

  const prescriptionFormActionValue = useMemo(
    () => ({ goBack, goNext, reviewButton, formData, getSingleFormData, progressBarAction }),
    [goBack, goNext, reviewButton, formData, getSingleFormData, progressBarAction],
  );

  // Add Product to Cart Modal Action
  const showProductCartDialog = useCallback((showObj, callback) => {
    productCartDialogRef.current?.showProductCartDialog?.(showObj, callback);
  }, []);

  const closeProductCartDialog = useCallback(() => {
    productCartDialogRef.current?.closeProductCartDialog?.();
  }, []);

  const productCartDialogContextValue = useMemo(
    () => ({
      showProductCartDialog,
      closeProductCartDialog,
    }),
    [closeProductCartDialog, showProductCartDialog],
  );

  return (
    <ProductCartDialogContext.Provider value={productCartDialogContextValue}>
      <PrescriptionStateContext.Provider value={prescriptionStateValue}>
        <PrescriptionApiStateContext.Provider value={prescriptionApiStateValue}>
          <PrescriptionActionContext.Provider value={prescriptionActionValue}>
            <PrescriptionFormActionContext.Provider value={prescriptionFormActionValue}>
              <PrescriptionFormSateContext.Provider value={prescriptionFormStateValue}>
                {children}
                {/* Add Product to Cart Modal  */}
                <ProductCartModal ref={productCartDialogRef} />
              </PrescriptionFormSateContext.Provider>
            </PrescriptionFormActionContext.Provider>
          </PrescriptionActionContext.Provider>
        </PrescriptionApiStateContext.Provider>
      </PrescriptionStateContext.Provider>
    </ProductCartDialogContext.Provider>
  );
}

export default React.memo(PrescriptionProvider);
