import { useEffect, useMemo, useState } from "react";
import { Card, Col, FormGroup, Label, Row } from "reactstrap";
import Cookies from "universal-cookie";
import { mapShippingMethod } from "utils/constants";
import axiosConfig, { errorResponse } from "../../utils/axiosConfig";

import fedex from "assets/images/shippingMethods/fedex.png";
import pickup from "assets/images/shippingMethods/pickup.png";
import ups from "assets/images/shippingMethods/ups.png";
import usps from "assets/images/shippingMethods/usps.png";
const ShippingMethodDD = (props) => {
  const [shippingMethods, setShippingMethods] = useState([]);
  const [shipObject, setShipObject] = useState("");
  const [shipMethod, setShipMethod] = useState(
    props.defaultValue ? (props.defaultValue === "FEDEX_GROUND" ? "FEDEX_2_DAY" : props.defaultValue) : ""
  );

  const fastShipment = useMemo(() => props.rushOrder || props.isColdShip, [props.isColdShip, props.rushOrder]);

  useEffect(() => {
    axiosConfig
      .get("portal/getShippingEnums", {
        headers: { Authorization: "Bearer " + new Cookies().get("user_token") },
      })
      .then(async (response) => {
        var values = [];

        setShipObject(response.data.shippingMethods);
      })
      .catch((error) => {
        errorResponse(error);
      });
    if (props.name === "shippingPreference")
      setShipMethod(
        props.defaultValue ? (props.defaultValue === "FEDEX_GROUND" ? "FEDEX_2_DAY" : props.defaultValue) : ""
      );
  }, [props.defaultValue]);

  useEffect(() => {
    let values = [];
    if (shipObject?.fedex?.find((v) => v === shipMethod) || !shipMethod) {
      shipObject?.fedex?.forEach((method) => {
        if (fastShipment === true) {
          if (props.setShippingMethod) props.setShippingMethod(shipMethod ? shipMethod : shipObject?.fedex[3]);
          if (method !== "FEDEX_GROUND" && method !== "FEDEX_2_DAY" && method !== "GROUND_HOME_DELIVERY") {
            values.push(
              <option
                selected={
                  props.defaultValue === method ||
                  (document.getElementsByName("POBox")[0]?.checked && method === "USPS 1-Day (PO BOX ONLY)")
                    ? true
                    : false
                }
                key={method}
                value={method}
              >
                {mapShippingMethod(method)}
              </option>
            );
          }
        } else {
          if (props.setShippingMethod) props.setShippingMethod(shipMethod ? shipMethod : shipObject?.fedex[0]);
          if (method !== "FEDEX_GROUND") {
            values.push(
              <option
                selected={
                  props.defaultValue === method ||
                  (document.getElementsByName("POBox")[0]?.checked && method === "USPS 1-Day (PO BOX ONLY)")
                    ? true
                    : false
                }
                key={method}
                value={method}
              >
                {mapShippingMethod(method)}
              </option>
            );
          }
        }
      });
      if (fastShipment === true) {
        if (shipMethod === "FEDEX_GROUND" || shipMethod === "FEDEX_2_DAY" || shipMethod === "GROUND_HOME_DELIVERY") {
          if (props.toggleRushButton > 0) {
            setShipMethod(shipObject?.fedex[3]);
            if (props.setShippingMethod) props.setShippingMethod(shipObject?.fedex[3]);
          }
        } else {
          if (props.toggleRushButton > 0) {
            if (props.setShippingMethod) props.setShippingMethod(shipMethod);
          }
        }
      } else {
        if (props.toggleRushButton > 0) {
          setShipMethod(shipMethod);
          if (props.setShippingMethod) props.setShippingMethod(shipMethod);
        }
      }
    } else if (shipObject?.ups?.find((v) => v === shipMethod)) {
      shipObject?.ups.forEach((method) => {
        if (fastShipment === true) {
          if (props.setShippingMethod) props.setShippingMethod(shipMethod ? shipMethod : shipObject?.ups[0]);
          if (method !== "UPS 3 Day Select" && method !== "UPS 2nd Day Air" && method !== "UPS Ground") {
            values.push(
              <option
                selected={
                  props.defaultValue === method ||
                  (document.getElementsByName("POBox")[0]?.checked && method === "USPS 1-Day (PO BOX ONLY)")
                    ? true
                    : false
                }
                key={method}
                value={method}
              >
                {mapShippingMethod(method)}
              </option>
            );
          }
        } else {
          if (props.setShippingMethod) props.setShippingMethod(shipMethod ? shipMethod : shipObject?.ups[0]);
          values.push(
            <option
              selected={
                props.defaultValue === method ||
                (document.getElementsByName("POBox")[0]?.checked && method === "USPS 1-Day (PO BOX ONLY)")
                  ? true
                  : false
              }
              key={method}
              value={method}
            >
              {mapShippingMethod(method)}
            </option>
          );
        }
      });
      if (fastShipment === true) {
        if (
          shipMethod === "UPS 3 Day Select" ||
          shipMethod === "UPS 2nd Day Air" ||
          shipMethod === "UPS 2nd Day Air Saver"
        ) {
          if (props.toggleRushButton > 0) {
            setShipMethod(shipObject?.ups[0]);
            if (props.setShippingMethod) props.setShippingMethod(shipObject?.ups[0]);
          }
        } else {
          if (props.toggleRushButton > 0) {
            if (props.setShippingMethod) props.setShippingMethod(shipMethod);
          }
        }
      } else {
        if (props.toggleRushButton > 0) {
          setShipMethod(shipMethod);
          if (props.setShippingMethod) props.setShippingMethod(shipMethod);
        }
      }
    } else if (shipObject?.others?.find((v) => v === shipMethod)) {
      shipObject?.others.forEach((method) => {
        if (method === shipMethod) {
          values.push(
            <option
              selected={
                props.defaultValue === method ||
                (document.getElementsByName("POBox")[0]?.checked && method === "USPS 1-Day (PO BOX ONLY)")
                  ? true
                  : false
              }
              key={method}
              value={method}
            >
              {mapShippingMethod(method)}
            </option>
          );
          if (props.toggleRushButton > 0) {
            setShipMethod(shipMethod);
          }
          if (props.setShippingMethod) props.setShippingMethod(shipMethod);
        }
      });
    }
    setShippingMethods(values);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fastShipment, props.toggleRushButton, shipMethod, shipObject]);

  useEffect(() => {
    if (shipMethod?.toLowerCase()?.includes("pick")) {
      props.setShipOptional(true);
    }
  }, [shipMethod]);

  useEffect(() => {
    if (props.modalView) {
      setShipMethod(props.defaultValue);
      if (props.defaultValue?.toLowerCase()?.includes("pick")) {
        props.setShipOptional(true);
      } else {
        props.setShipOptional(false);
      }
    }
  }, [props.defaultValue]);


  return (
    <>
      <FormGroup>
        <Row style={{ width: "400px" }}>
          <Col md={3} lg={3}>
            <Card>
              <Label check>
                {" "}
                <div style={{ display: "flex", alignItems: "center" }}>
                  <input
                    type="radio"
                    className="mx-2"
                    checked={
                      (!shipMethod?.toLowerCase().includes("ups") &&
                        !shipMethod?.toLowerCase().includes("usps") &&
                        !shipMethod?.toLowerCase().includes("pick")) ||
                      (shipMethod === "" && !props.modalView)
                    }
                    onChange={(e) => {
                      if (e.target?.checked) {
                        if (props.setShipOptional) props.setShipOptional(false);
                        if (props.rushOrder) {
                          setShipMethod(shipObject?.fedex[3]);
                        } else {
                          setShipMethod(shipObject?.fedex[0]);
                        }
                      }
                    }}
                  />
                  <img src={fedex} width="60" height="40" />
                </div>
              </Label>
            </Card>
          </Col>
          <Col md={3} lg={3}>
            {" "}
            <Card>
              <Label check>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <input
                    type="radio"
                    className="mx-2"
                    checked={shipMethod?.toLowerCase().includes("ups")}
                    onChange={(e) => {
                      if (e.target?.checked) {
                        if (props.setShipOptional) props.setShipOptional(false);
                        setShipMethod(shipObject?.ups[0]);
                      }
                    }}
                  />

                  <img src={ups} width="60" height="40" />
                </div>
              </Label>
            </Card>
          </Col>
          <Col md={3} lg={3}>
            {" "}
            <Card>
              <Label check>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <input
                    type="radio"
                    className="mx-2"
                    checked={shipMethod?.toLowerCase().includes("usps")}
                    onChange={(e) => {
                      if (e.target?.checked) {
                        if (props.setShipOptional) props.setShipOptional(false);
                        setShipMethod(shipObject?.others[0]);
                      }
                    }}
                  />{" "}
                  <img src={usps} width="100" height="40" />
                </div>
              </Label>
            </Card>
          </Col>
          <Col md={3} lg={3}>
            <Card>
              <Label check>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <input
                    className="mx-2"
                    type="radio"
                    checked={shipMethod?.toLowerCase().includes("pick")}
                    onChange={(e) => {
                      if (e.target?.checked) {
                        if (props.setShipOptional) props.setShipOptional(true);
                        setShipMethod(shipObject?.others[1]);
                      }
                    }}
                  />
                  <img src={pickup} width="100" height="40" />
                </div>
              </Label>
            </Card>
          </Col>
        </Row>
      </FormGroup>
      <FormGroup>
        <Label className="normalText" for={props.name}>
          {props.title ? props.title : "Shipping Preference"}{" "}
          {props.required ? <span className="requiredStar">*</span> : ""}
        </Label>
        <br></br>
        <select
          style={{ width: "470px", borderColor: "#CDCDCD", color: "#495057" }}
          className="p-1 rounded py-2"
          id={props.name}
          name={props.name}
          required={props.required}
          title={mapShippingMethod(shipMethod)}
          onChange={(e) => {
            setShipMethod(e.target.value);
            if (e.target.options[e.target.selectedIndex].text !== "USPS 1-Day (PO BOX ONLY)") {
              document.getElementsByName("POBox")[0].checked = false;
            }
            if (props.setShippingMethod) {
              props.setShippingMethod(e.target.value);
            }
          }}
        >
          {!shippingMethods.length && (
            <option value="" selected>
              {shippingMethods.length ? props.defaultValue : "Loading ..."}
            </option>
          )}
          {shippingMethods}
        </select>
      </FormGroup>
    </>
  );
};

export default ShippingMethodDD;
