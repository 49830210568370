import { Button, Divider, Grid, Paper, Stack, Typography } from "@mui/material";
import React, { useMemo } from "react";

function ProductSavedMedicationListing({ title = "", medicationList = [], addToCartAction }) {
  const itemArray = useMemo(
    () =>
      medicationList.map(product => (
        <Grid key={product.id} item xs={2}>
          <Stack alignItems={"center"} rowGap={1}>
            <Button
              variant="contained"
              color="secondary"
              onClick={addToCartAction({ ...product, productId: product.id })}
              sx={{
                borderRadius: 50,
                py: 0.5,
                px: 1,
                fontSize: 10,
              }}>
              ADD TO CART
            </Button>
            <img
              src="https://i.pinimg.com/564x/84/a8/7e/84a87eb13637e32d5fd67ca7fc2cf2b4.jpg"
              width={"70px"}
              height="70px"
              alt={"medication"}
              loading="lazy"
            />
            <Typography variant="body2" textAlign={"center"} fontSize={11}>
              {product?.name}
            </Typography>
          </Stack>
        </Grid>
      )),
    [addToCartAction, medicationList],
  );

  return (
    <Paper sx={{ p: 2 }} elevation={3}>
      <Typography variant="h6" color={"primary"}>
        {title}
      </Typography>
      <Divider variant="fullWidth" sx={{ my: 1 }} />
      <Grid container columns={10} rowSpacing={2} columnSpacing={1}>
        {itemArray}
      </Grid>
    </Paper>
  );
}

export default React.memo(ProductSavedMedicationListing);
