const STEPPER_FORMS = {
  PORTAL_INFO: "PortalInfoForm",
  CREDIT_INFO: "PortalCreditForm",
  PERSONAL_INFO: "PersonalInfoForm",
  BILLING_INFO: "PortalBillingForm",
  ADDRESS_INFO: "PortalAddressesForm",
  SHIPPING_INFO: "PortalShippingForm",
  NOTIFICATION_INFO: "PortalNotificationForm",
  // Prescription Stepper
  PATIENT_INFO: "PortalPatientInfoForm",
  PRODUCT_INFO: "PortalProductInfoForm",
  PROVIDER_INFO: "PortalProviderInfoForm",
  LOCATION_INFO: "PortalLocationInfoForm",
};

const PATIENT_STEPPER = [
  "Personal Information",
  "Contact Information",
  "Shipping Information",
  "Billing & Preferences",
];
const STAFF_STEPPER = ["Personal Information", "Contact Information", "Portal Information"];
const CLINIC_LOCATION_STEPPER = ["Location Information", "Credit Card Information", "Notifications & Preferences"];
const PRESCRIPTION_ORDER_STEPPER = [
  "Patients",
  "Products",
  "Verify Provider & Location",
  "Shipping Information",
  "Billing & Preferences",
];

export { STEPPER_FORMS, PATIENT_STEPPER, STAFF_STEPPER, CLINIC_LOCATION_STEPPER, PRESCRIPTION_ORDER_STEPPER };
