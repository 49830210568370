import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from "@mui/material";
import { AlertMessage } from "components";
import { ClinicLocationFormActionContext, ClinicLocationStateContext } from "context";
import React, { useCallback, useContext, useEffect, useMemo, useRef } from "react";
import {
  ClinicLocationCard,
  ClinicLocationContact,
  ClinicLocationNotification,
  ClinicLocationPracticeInfo,
} from "screens";
import { STEPPER_FORMS } from "utils";

function ClinicLocationUpdateModal({ showModal = false, sectionName = "", locationId = "", handleClose }) {
  const modalFormRef = useRef(null);
  const openDialog = useMemo(() => Boolean(locationId) && showModal, [locationId, showModal]);
  const { formData } = useContext(ClinicLocationFormActionContext);
  const { mutateError, showSpinner, clinicLocationInsertSuccessfully = false } = useContext(ClinicLocationStateContext);

  const closeModal = useCallback(() => {
    handleClose?.();
  }, [handleClose]);

  useEffect(() => {
    modalFormRef.current = null;
  }, [sectionName]);

  useEffect(() => {
    if (clinicLocationInsertSuccessfully) {
      closeModal();
    }
  }, [clinicLocationInsertSuccessfully, closeModal]);

  const updateAction = useCallback(e => {
    e.preventDefault();
    modalFormRef.current?.submitForm?.();
  }, []);

  const renderView = useMemo(() => {
    switch (sectionName) {
      case "Location Information":
        return (
          <ClinicLocationPracticeInfo
            ref={modalFormRef}
            formId={STEPPER_FORMS.PERSONAL_INFO}
            formData={formData}
            additionalInfo={true}
          />
        );
      case "Office Contact & Preference Information":
        return (
          <ClinicLocationContact
            ref={modalFormRef}
            formId={STEPPER_FORMS.ADDRESS_INFO}
            formData={formData}
            additionalInfo={true}
          />
        );
      case "Credit Card Information":
        return <ClinicLocationCard ref={modalFormRef} formId={STEPPER_FORMS.CREDIT_INFO} formData={formData} />;
      case "Notifications Information":
        return (
          <ClinicLocationNotification ref={modalFormRef} formId={STEPPER_FORMS.NOTIFICATION_INFO} formData={formData} />
        );
      default:
        return null;
    }
  }, [formData, sectionName]);

  return (
    <Dialog open={openDialog} onClose={closeModal} scroll="paper" fullWidth maxWidth="xl">
      <DialogTitle variant="h5">
        {sectionName}
        <IconButton
          aria-label="close"
          onClick={closeModal}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: theme => theme.palette.grey[500],
          }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Box className="px-5 mt-4">
          <AlertMessage msg={mutateError} isError={mutateError} />
          {renderView}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button disabled={showSpinner} variant="contained" className={"mx-2"} onClick={updateAction}>
          {"Update"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default React.memo(ClinicLocationUpdateModal);
