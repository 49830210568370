import { createApi } from "@reduxjs/toolkit/query/react";
import { CACHE_TAGS } from "rtk/rtkConstant";
import { baseQuery } from "./BaseApi";

const StaffApi = createApi({
  reducerPath: "StaffApi",
  baseQuery: baseQuery,
  endpoints: () => ({}),
  tagTypes: [CACHE_TAGS.STAFF, CACHE_TAGS.PROVIDER],
});

export default StaffApi;
