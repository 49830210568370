import { Button, Card, CardActions, CardContent, Container, Grid, Typography } from "@mui/material";
import { AlertMessage, FormStepper } from "components";
import NavBar from "components/NavBar";
import MyLottie from "components/lottie";
import Spinner from "components/spinner";
import { StaffFormActionContext, StaffFormSateContext, StaffStateContext } from "context";
import React, { useCallback, useContext, useMemo } from "react";
import { MetaTags } from "react-meta-tags";
import { useHistory } from "react-router-dom";
import { TabContent, TabPane } from "reactstrap";
import { STAFF_STEPPER, STEPPER_FORMS } from "utils";
import { StaffAddresses, StaffPersonalInfo, StaffPortalInfo } from "../StaffInfo";

function InsertStaff() {
  const {
    mutateError,
    showSpinner,
    isStaffEdit,
    isEditProfile,
    staffInsertSuccessfully = false,
  } = useContext(StaffStateContext);
  const history = useHistory();
  const { formRef, completedStep, activeStep, staffRole, clinicLocationDea, clinicId } = useContext(StaffFormSateContext);
  const { goNext, goBack, formData, progressBarAction } = useContext(StaffFormActionContext);

  const goBrowserBack = useCallback(e => {
    e?.preventDefault();
    history.goBack();
  }, []);

  const isLastForm = useMemo(() => formRef.current?.length - 1 === activeStep, [activeStep, formRef]);
  const buttonText = useMemo(() => {
    let text = "Next";
    if (isLastForm) {
      text = "Add Staff";
      if (isEditProfile) {
        text = "Update Profile";
      } else if (isStaffEdit) {
        text = "Update Staff";
      }
    }
    return text;
  }, [isEditProfile, isLastForm, isStaffEdit]);

  const backButtonView = useMemo(() => {
    if (activeStep > 0) {
      return (
        <Button
          variant="outlined"
          size="large"
          className="muiLightButton w-25  mx-2"
          style={{ borderColor: "red", borderWidth: 1 }}
          onClick={goBack}>
          Back
        </Button>
      );
    }
  }, [activeStep, goBack]);

  const successMessage = useMemo(() => {
    if (staffInsertSuccessfully) {
      return (
        <Card elevation={2} className="pb-5">
          <CardContent className="justify-content-center">
            <MyLottie name="success-check" />
            <Typography variant="h6" align="center">
              {isEditProfile
                ? "Your profile information has been updated!"
                : "Your staff information has been updated!"}
            </Typography>
          </CardContent>
          <CardActions className="justify-content-center">
            <Button variant="contained" onClick={goBrowserBack} className="pinkButton">
              {isEditProfile ? "Profile" : "Staff"}
            </Button>
          </CardActions>
        </Card>
      );
    }
  }, [isEditProfile, staffInsertSuccessfully]);

  return (
    <>
      <NavBar />
      <div className="page-content">
        <MetaTags>
          <title>
            {`${
              isEditProfile ? "Edit Profile" : isStaffEdit ? "Edit Clinic Staff" : "Add Clinic Staff"
            } | VPI Compounding`}
          </title>
        </MetaTags>
        <Container maxWidth={false} disableGutters>
          <h4 className="p-3 text-muted">
            {isEditProfile ? "EDIT PROFILE" : isStaffEdit ? "EDIT CLINIC STAFF" : "ADD CLINIC STAFF"}
          </h4>
          {successMessage}
          <Card elevation={1} hidden={staffInsertSuccessfully}>
            <Spinner showSpinner={showSpinner} />
            <CardContent>
              <AlertMessage msg={mutateError} isError={mutateError} />
              <FormStepper
                stepper={STAFF_STEPPER}
                completedStep={completedStep}
                activeStep={activeStep}
                clickAction={progressBarAction}
              />
              <Grid container justifyContent={"center"}>
                <Grid item xs={10}>
                  <TabContent activeTab={activeStep}>
                    <TabPane tabId={0}>
                      <StaffPersonalInfo
                        ref={el => (formRef.current[0] = el)}
                        formId={STEPPER_FORMS.PERSONAL_INFO}
                        formData={formData}
                      />
                    </TabPane>
                    <TabPane tabId={1}>
                      <StaffAddresses
                        ref={el => (formRef.current[1] = el)}
                        formId={STEPPER_FORMS.ADDRESS_INFO}
                        formData={formData}
                        staffRole={staffRole}
                        clinicLocationDea={clinicLocationDea}
                      />
                    </TabPane>
                    <TabPane tabId={2}>
                      <StaffPortalInfo
                        ref={el => (formRef.current[2] = el)}
                        formId={STEPPER_FORMS.PORTAL_INFO}
                        formData={formData}
                        clinicId={clinicId}
                      />
                    </TabPane>
                  </TabContent>
                </Grid>
              </Grid>
            </CardContent>
            <CardActions className="justify-content-center my-5">
              {backButtonView}
              <Button color="secondary" variant="contained" size="large" className="w-25 mx-2" onClick={goNext}>
                {buttonText}
              </Button>
            </CardActions>
          </Card>
        </Container>
      </div>
    </>
  );
}

export default React.memo(InsertStaff);
