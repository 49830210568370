import { ExostarApi } from "rtk";
import { API_METHODS, EXOSTAR_END_POINTS } from "../../rtkConstant";

const ExostarMutation = ExostarApi.injectEndpoints({
  endpoints: builder => ({
    //P12-2
    exostarOnlineAuthSign: builder.mutation({
        queryFn: async (apiArgs, queryApi, _, baseQuery) => {
  
            try {
              const user = queryApi.getState().AuthSlice || {};
              if (user?.userData) {
                const response = await baseQuery({
                  url: EXOSTAR_END_POINTS.ONLINE_AUTH_SIGN,
                  method: API_METHODS.POST,
                  body: apiArgs,
                });
                return response;
              }
            } catch {}
          },
    }),
    //P12-3
    exostarOfflineAuthSign: builder.mutation({
      queryFn: async (apiArgs, queryApi, _, baseQuery) => {
        try {
          const user = queryApi.getState().AuthSlice || {};
          if (user?.userData) {
            const response = await baseQuery({
              url: EXOSTAR_END_POINTS.OFFLINE_AUTH_SIGN,
              method: API_METHODS.POST,
              body: apiArgs,
            });
            return response;
          }
        } catch {}
      },
    }),

    //P12-8
    exostarFinishProofing: builder.mutation({
      queryFn: async (apiArgs, queryApi, _, baseQuery) => {
        try {
          const user = queryApi.getState().AuthSlice || {};
          if (user?.userData) {
            const response = await baseQuery({
              url: EXOSTAR_END_POINTS.FINISH_PROOFING,
              method: API_METHODS.POST,
              body: apiArgs,
            });
            return response;
          }
        } catch {}
      },
    }),

  //P12-1
    exostarRegister: builder.mutation({
      queryFn: async (apiArgs, queryApi, _, baseQuery) => {
        try {
          const user = queryApi.getState().AuthSlice || {};
          if (user?.userData) {
            const response = await baseQuery({
              url: EXOSTAR_END_POINTS.EXOSTAR_REGISTER,
              method: API_METHODS.POST,
              body: apiArgs,
            });
            return response;
          }
        } catch {}
      },
    }),
  }),
});

export const { useExostarOfflineAuthSignMutation, useExostarOnlineAuthSignMutation, useExostarRegisterMutation, useExostarFinishProofingMutation } = ExostarMutation;
