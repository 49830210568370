/* eslint-disable react-hooks/exhaustive-deps */
import CancelIcon from "@mui/icons-material/Cancel";
import { Alert, Button, Grid, IconButton, TextField, Typography } from "@mui/material";
import { AlertMessage, FormCheckBox, FormRadioField, FormSelectField } from "components";
import { AddNewAddress } from "components/AddNewAddress";
import ValidAddressModal from "components/validAddressModal";
import { findIndex, first, isNull } from "lodash";
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useLazyValidateAddressQuery } from "rtk";
import { COMPLETE_SHIPPING_METHOD, SHIPPING_METHOD } from "utils";
import { VPI_ADDRESS } from "utils/StatusConstant";
import usePrescriptionShippingInfo from "./Hook/usePrescriptionShippingInfo";

const isClinicFlow = true;

function PrescriptionShippingInfo(
  {
    isBulkOrder = false,
    shipToClinic = false,
    isRushOrder = false,
    isPrescriptionEdit = false,
    clinicLocationId = "",
    selectedPatients = [],
    prescriptionPatient = [],
    prescriptionShipping = {},
    shippingMethod,
    updateShippingCost,
    setShippingMethod,
    isColdShipped,
    fillPrescriptionData = () => null,
  },
  ref,
) {
  const [customError, setCustomError] = useState("");
  const [typedAddress, setTypedAddress] = useState(null);
  const [addressModal, setAddressModal] = useState(false);
  const [customNewAddress, setCustomNewAddress] = useState(null);
  const [fedexShippingAddress, setFedexShippingAddress] = useState(null);
  const [validateShippingAddress] = useLazyValidateAddressQuery();

  const { control, getValues, setValue, trigger } = useForm({
    defaultValues: {
      shipToClinic: shipToClinic,
      isNewAddressUsed: false,
      shippingMethod: "",
      shippingPreference: "",
      shippingAddress: "",
    },
  });

  const {
    clinicAddressOptions,
    patientAddressOptions,
    findAddressId,
    findShippingMethod,
    getPatientAddressObj,
    getClinicAddressObj,
    getShippingMethodOption,
  } = usePrescriptionShippingInfo({ setValue, isBulkOrder, clinicLocationId, selectedPatients });

  const showValidatedAddress = useMemo(() => fedexShippingAddress !== null, [fedexShippingAddress]);
  const isShipToClinic = useWatch({ control, name: "shipToClinic" });
  const watchShippingMethod = useWatch({ control, name: "shippingMethod" });
  const watchShippingPreference = useWatch({ control, name: "shippingPreference" });

  const isPickupMethod = useMemo(() => watchShippingMethod === "pickup", [watchShippingMethod]);

  const shippingMethodOption = useMemo(
    () => getShippingMethodOption(watchShippingMethod, isRushOrder,isColdShipped),
    [getShippingMethodOption, isRushOrder, watchShippingMethod,isColdShipped],
  );

  const getShippingRate = useCallback(() => {
    const shippingPreferenceMethod = getValues("shippingPreference");
    setShippingMethod(shippingPreferenceMethod);
  }, [getValues, setShippingMethod]);

  useEffect(() => {
    // Get shipping rate on clinicLocation change
    if (clinicLocationId) {
      getShippingRate();
    }
  }, [clinicLocationId]);

  useEffect(() => {
    setValue("shipToClinic", shipToClinic);
    setValue("shippingMethod", findShippingMethod(shippingMethod));
  }, [findShippingMethod, setValue, shipToClinic, shippingMethod]);

  useEffect(() => {
    // useEffect will run for Edit Prescription
    // this useEffect should render before clinic address useEffect
    if (isPrescriptionEdit) {
      setValue("isNewAddressUsed", prescriptionShipping?.isNewAddressUsed);
      setValue("shipToClinic", shipToClinic || prescriptionShipping?.shipTo === "clinic");
      setValue("shippingPreference", prescriptionShipping?.shippingMethod);
      setValue(
        "shippingAddress",
        findAddressId(
          prescriptionShipping?.shippingAddress,
          prescriptionShipping?.shipTo === "clinic",
          prescriptionPatient,
        ),
      );
      if (prescriptionShipping?.isNewAddressUsed) {
        setCustomNewAddress(prescriptionShipping?.shippingAddress);
      }
      fillPrescriptionData?.();
    }
  }, [
    fillPrescriptionData,
    findAddressId,
    isPrescriptionEdit,
    prescriptionShipping?.isNewAddressUsed,
    prescriptionShipping?.shipTo,
    prescriptionShipping?.shippingAddress,
    prescriptionShipping?.shippingMethod,
  ]);

  useEffect(() => {
    // this useEffect set the clinic address for bulk orders
    if (shipToClinic) {
      setValue("shippingAddress", clinicAddressOptions?.[0]?.value);
    } else if (
      // This block run when change the patient in Edit prescription and shippingAddress is empty
      patientAddressOptions?.length > 0 &&
      first(patientAddressOptions)?.value &&
      findIndex(patientAddressOptions, { value: getValues("shippingAddress") }) === -1
    ) {
      setValue("shippingAddress", patientAddressOptions?.[0]?.value);
    }
  }, [clinicAddressOptions, getValues, patientAddressOptions, setValue, shipToClinic]);

  useEffect(() => {
    // Set the shippingPreference with first option if not set before or when option remove on rushOrder
    if (shippingMethodOption?.length > 0 && first(shippingMethodOption)?.value) {
      const selectedOptionIndex = findIndex(shippingMethodOption, { value: watchShippingPreference });
      if (!watchShippingPreference || selectedOptionIndex === -1) {
        const preferenceValue = shippingMethodOption?.[0]?.value;
        setValue("shippingPreference", preferenceValue);
        getShippingRate();
      }
    }
  }, [getShippingRate, setValue, shippingMethodOption, watchShippingPreference]);

  const setSelectedAddress = useCallback(
    (isNewAddress = false) => {
      setValue("isNewAddressUsed", isNewAddress);
      updateShippingCost();
    },
    [setValue, updateShippingCost],
  );

  const getSelectedAddress = useCallback(() => {
    let address = {};
    if (getValues("shippingMethod")?.toString() !== "pickup") {
      if (getValues("isNewAddressUsed")) {
        address = customNewAddress;
      } else if (getValues("shipToClinic")) {
        address = getClinicAddressObj();
      } else {
        const getSelectedAddressID = getValues("shippingAddress");
        address = getPatientAddressObj(getSelectedAddressID);
      }
    }
    if (!address?.addressLine1 && isPrescriptionEdit) {
      address = prescriptionShipping?.shippingAddress;
    }
    return address;
  }, [
    customNewAddress,
    getClinicAddressObj,
    getPatientAddressObj,
    getValues,
    isPrescriptionEdit,
    prescriptionShipping,
  ]);

  const refetchAddress = useCallback(() => {
    setCustomNewAddress(null);
    setFedexShippingAddress(null);
    setSelectedAddress();
  }, [setSelectedAddress]);

  const checkBoxBtn = useCallback(
    (name, index) => {
      if (!isBulkOrder) {
        if (index) {
          setValue("shippingAddress", clinicAddressOptions?.[0]?.value);
        } else {
          setValue("shippingAddress", patientAddressOptions?.[0]?.value);
        }
        setValue(name, index);
        refetchAddress();
      }
    },
    [clinicAddressOptions, isBulkOrder, patientAddressOptions, refetchAddress, setValue],
  );

  const getShippingInfo = useCallback(() => {
    const shippingValues = getValues();
    return {
      shippingMethod: shippingValues?.shippingPreference,
    };
  }, [getValues]);

  const getShippingData = useCallback(async () => {
    const formValidated = await trigger();
    if (formValidated) {
      const shippingValues = getValues();
      const apiParam = {
        shipTo: isBulkOrder || isShipToClinic ? "clinic" : "patient",
        isNewAddressUsed: shippingValues?.isNewAddressUsed,
        shippingMethod: shippingValues?.shippingPreference,
      };
      return apiParam;
    } else {
      return null;
    }
  }, [getValues, isBulkOrder, isShipToClinic, trigger]);

  const checkValidation = useCallback(async () => {
    const formValidated = await trigger();
    return formValidated;
  }, [trigger]);

  useImperativeHandle(ref, () => ({
    getShippingData: getShippingData,
    getShippingInfo: getShippingInfo,
    checkValidation: checkValidation,
    getShippingAddress: getSelectedAddress,
  }));

  const setNewShippingAddress = useCallback(
    (patientAddress = {}) => {
      setCustomNewAddress(patientAddress);
      setTimeout(() => {
        // This delay is added to update the shippingAddress with new address before calling shippingRate api
        setSelectedAddress(true);
      }, 300);
    },
    [setSelectedAddress],
  );

  const setNewAddress = async e => {
    e.preventDefault();
    const serialize = require("form-serialize");
    const obj = serialize(document.getElementById("changeAddressForm"), { hash: true });
    setTypedAddress({
      addressLine1: obj?.addressLine1,
      addressLine2: obj?.addressLine2,
      city: obj?.city,
      state: obj?.state,
      zipcode: obj?.zipcode,
    });
    const body = {
      isUSPS: watchShippingMethod === "usps",
      address: {
        streetLines: {
          addressLine1: obj?.addressLine1 ?? "",
          addressLine2: obj?.addressLine2 ?? "-",
        },
        city: obj?.city,
        state: obj?.state,
        postalCode: obj?.zipcode,
      },
    };
    try {
      const fedexShipping = await validateShippingAddress(body, true).unwrap();
      setFedexShippingAddress({ ...fedexShipping, zipcode: fedexShipping.postalCode });
      setAddressModal(false);
    } catch (e) {
      setCustomError(e.data?.message);
      setAddressModal(false);
    }
  };

  const resetShippingPreference = useCallback(() => {
    setValue("shippingPreference", "");
  }, []);

  const closeValidateAddress = useCallback(() => {
    setFedexShippingAddress(null);
    setNewShippingAddress(typedAddress);
  }, [setNewShippingAddress, typedAddress]);

  const onClickValidate = useCallback(() => {
    setNewShippingAddress(fedexShippingAddress);
    setFedexShippingAddress(null);
  }, [fedexShippingAddress, setNewShippingAddress]);

  const renderNewAddressView = useMemo(() => {
    if (isNull(customNewAddress) && !isPickupMethod) {
      return (
        <Grid item xs={12} textAlign={"center"}>
          <Button
            color="primary"
            variant="contained"
            size="large"
            className="pinkButton"
            onClick={e => {
              e.preventDefault();
              setAddressModal(true);
            }}>
            Add New Address
          </Button>
        </Grid>
      );
    } else {
      const newAddressValue = isPickupMethod
        ? VPI_ADDRESS.address
        : `${customNewAddress?.addressLine1 ?? "-"}, ${customNewAddress?.addressLine2 ?? "-"}, ${
            customNewAddress?.city
          },${customNewAddress?.state}, ${customNewAddress?.zipcode}`;
      return (
        <Grid container item xs={12} justifyContent={"space-between"} alignItems={"center"}>
          <Grid item xs={isPickupMethod ? 12 : 10}>
            <TextField
              id="outlined-basic"
              label={isPickupMethod ? VPI_ADDRESS.name : "New Address"}
              variant="outlined"
              size="small"
              fullWidth
              contentEditable={false}
              InputProps={{ readOnly: true }}
              value={newAddressValue}
            />
          </Grid>
          {!isPickupMethod && (
            <IconButton aria-label="delete" size="small" style={{ width: 25, height: 25 }} onClick={refetchAddress}>
              <CancelIcon color="error" style={{ width: 20, height: 20 }} />
            </IconButton>
          )}
        </Grid>
      );
    }
  }, [customNewAddress, isPickupMethod, refetchAddress]);

  return (
    <>
      <Grid container>
        <AlertMessage msg={customError} isError={true} />
        {isBulkOrder && (
          <Alert severity="info">“Ship to clinic” and/or “Pick Up” are the only options for bulk orders</Alert>
        )}
        <Grid container item xs={12}>
          <Grid item xs={12}>
            <FormCheckBox
              control={control}
              name="shipToClinic"
              checkBoxBtn={checkBoxBtn}
              MuiFieldProps={{
                disabled: !isNull(customNewAddress) || isBulkOrder || isPickupMethod,
                label: "Ship To Clinic",
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <FormSelectField
              name={"shippingAddress"}
              control={control}
              rules={{ required: "Address is required." }}
              dependentChange={refetchAddress}
              MuiFieldProps={{
                size: "small",
                disabled: !isNull(customNewAddress) || isPickupMethod,
                label: isShipToClinic ? "Clinic Address" : "Patient Address",
                optionArray: isShipToClinic ? clinicAddressOptions : patientAddressOptions,
              }}
            />
          </Grid>
          {renderNewAddressView}

          <Grid item xs={12} my={2}>
            <Typography variant="h6">Shipping Method</Typography>
          </Grid>
          <Grid item xs={12}>
            <FormRadioField
              control={control}
              name={"shippingMethod"}
              radioOptions={isClinicFlow ? SHIPPING_METHOD : COMPLETE_SHIPPING_METHOD}
              dependentChange={resetShippingPreference}
            />
          </Grid>
          <Grid item xs={12} mt={2}>
            <FormSelectField
              name={"shippingPreference"}
              control={control}
              rules={{ required: "Shipping preference is required." }}
              dependentChange={getShippingRate}
              MuiFieldProps={{ label: "Shipping Preference", size: "small", optionArray: shippingMethodOption }}
            />
          </Grid>
        </Grid>
      </Grid>
      <AddNewAddress modalOpen={addressModal} closeModal={() => setAddressModal(false)} setNewAddress={setNewAddress} />
      <ValidAddressModal
        isUSPS={watchShippingMethod === "usps"}
        showValidAddressModal={showValidatedAddress}
        fedexShippingAddress={fedexShippingAddress}
        clinicAddress={typedAddress}
        onClickNotValidate={closeValidateAddress}
        onClickValidate={onClickValidate}
        closeModal={closeValidateAddress}
      />
    </>
  );
}

export default React.memo(forwardRef(PrescriptionShippingInfo));
