import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Alert } from "reactstrap";

function AlertMessage({ msg = "", isError = false, info = false, onClose }) {
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    setSuccess(Boolean(msg));
  }, [msg]);

  const toggleAlert = useCallback(() => {
    setSuccess(false);
    onClose?.();
  }, []);

  const alertColor = useMemo(() => {
    let alertColor = "success";
    if (isError) {
      alertColor = "danger";
    } else if (info) {
      alertColor = "info";
    }
    return alertColor;
  }, [info, isError]);

  if (!success) {
    return null;
  }

  return (
    <Alert color={alertColor} className="text-center" isOpen={success} toggle={toggleAlert}>
      {msg}
    </Alert>
  );
}
export default React.memo(AlertMessage);
