import RefreshIcon from "@mui/icons-material/Refresh";
import {
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { flexRender, getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { OrderViewModal, PatientSummaryTableCollapse } from "components";
import { filter, includes, map } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import {
  useLazyGetClinicPrescriptionDetailQuery,
  useLazyGetPatientPrescriptionQuery,
  useSaveNewPrescriptionMutation,
} from "rtk";
import { userInfoSelector } from "selectors";
import { CLINIC_PATIENT_SUMMARY_COLUMNS, LISTING_NAME, ROWS_LIMIT, columnHelper } from "utils";
import Spinner from "../../../components/spinner";
import AlertMessage from "../Prescriptions/components/alert_message";

function PatientSummaryPrescription({ patientId = "", scrollToRx = () => null }) {
  const [rowLimit, setRowLimit] = useState(ROWS_LIMIT[1]);
  const [refillPrescriptionId, setRefillPrescriptionId] = useState("");
  const [mainPrescriptionId, setMainPrescriptionId] = useState("");
  const userInfo = useSelector(userInfoSelector);
  const [fetchPrescriptionDetail, { isFetching: reviewFetching, error: reviewError }] =
    useLazyGetClinicPrescriptionDetailQuery();
  const [
    fetchList,
    { data: { pagination, prescriptions } = { pagination: {}, prescriptions: [] }, error, isFetching },
  ] = useLazyGetPatientPrescriptionQuery();
  const [
    rxSaveMutate,
    { data: saveResponseData, isLoading: saveLoading, error: saveError, isSuccess: isSaveSuccess, reset: resetMutate },
  ] = useSaveNewPrescriptionMutation();
  const isClinicSide = useMemo(() => userInfo?.clinicId ?? "", [userInfo?.clinicId]);
  const showLoading = useMemo(
    () => saveLoading || isFetching || reviewFetching,
    [isFetching, saveLoading, reviewFetching],
  );
  const tablePage = useMemo(() => (pagination?.currentPage ?? 1) - 1, [pagination?.currentPage]);
  const totalRecords = useMemo(() => rowLimit * (pagination?.totalPages ?? 0), [pagination?.totalPages, rowLimit]);
  const errorMessage = useMemo(
    () => error?.data?.message || saveError?.data?.message || reviewError?.data?.message || "",
    [error?.data?.message, reviewError?.data?.message, saveError?.data?.message],
  );

  const toggleReviewModal = useCallback((id = "", mainPrescriptionId = "") => {
    setRefillPrescriptionId(id);
    setMainPrescriptionId(mainPrescriptionId);
  }, []);

  useEffect(() => {
    if (!isFetching && patientId) {
      fetchList({ patientId, currentPage: 1, limit: rowLimit }, true);
      scrollToRx();
    }
  }, [patientId]);

  useEffect(() => {
    if (isSaveSuccess && saveResponseData) {
      toggleReviewModal(saveResponseData?.prescriptionId, saveResponseData?.refillFromPrescriptionId);
      resetMutate();
    }
  }, [isSaveSuccess, saveResponseData]);

  const refetchList = useCallback(() => {
    fetchList({ patientId, currentPage: tablePage + 1, limit: rowLimit });
  }, [fetchList, patientId, rowLimit, tablePage]);

  const changeRowLimit = useCallback(
    e => {
      const size = Number(e.target.value);
      setRowLimit(size);
      fetchList({ patientId, currentPage: 1, limit: size }, true);
    },
    [fetchList, patientId],
  );

  const handleChangePage = useCallback(
    (_, newPage) => {
      if (!isFetching) {
        fetchList({ patientId, currentPage: newPage + 1, limit: rowLimit }, true);
      }
    },
    [isFetching, fetchList, patientId, rowLimit],
  );

  const refillAction = useCallback(
    async (selectedProducts, patientPrescription) => {
      try {
        const prescriptionDetail = await fetchPrescriptionDetail({ prescriptionId: patientPrescription.id }).unwrap();
        const refillPrescription = {
          creditRequested: 0,
          isRefillRequest: true,
          refillFromPrescriptionId: prescriptionDetail?.id,
          clinicId: prescriptionDetail?.clinicId,
          clinicLocationId: prescriptionDetail?.clinicLocationId,
          providerId: prescriptionDetail?.providerId,
          patientIds: [patientId],
          products: map(
            filter(prescriptionDetail?.products, productRow => includes(selectedProducts, productRow?._id)),
            product => ({ ...product, refills: product.refills - 1 }),
          ),
          rxPadProducts: map(
            filter(prescriptionDetail?.rxPadProducts, productRow => includes(selectedProducts, productRow?._id)),
            product => ({ ...product, refills: product.refills - 1 }),
          ),
          shippingInfo: prescriptionDetail?.shippingInfo,
          encryptedBillingInfo: prescriptionDetail?.encryptedBillingInfo,
        };
        rxSaveMutate({ prescription: refillPrescription });
      } catch (e) {
        console.error(e);
      }
    },
    [fetchPrescriptionDetail, patientId, rxSaveMutate],
  );

  const columnAction = useMemo(() => {
    if (isClinicSide) {
      return columnHelper.accessor("id", {
        id: "actions",
        header: "Action",
        headerStyle: { textAlign: "left" },
      });
    }
  }, [isClinicSide]);

  const clinicPatientTableHeader = useMemo(
    () => [...CLINIC_PATIENT_SUMMARY_COLUMNS, columnAction].filter(Boolean),
    [columnAction],
  );

  const reactTable = useReactTable({
    data: prescriptions,
    columns: clinicPatientTableHeader,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <>
      {showLoading && <Spinner />}
      <AlertMessage msg={errorMessage} isError={true} />
      <Grid container justifyContent={"flex-end"}>
        <Tooltip title="Refresh">
          <Button variant="contained" onClick={refetchList}>
            <RefreshIcon style={{ width: 20, height: 20 }} />
          </Button>
        </Tooltip>
      </Grid>
      <TableContainer>
        <Table size="small" stickyHeader>
          <TableHead>
            {reactTable.getHeaderGroups().map(headerGroup => (
              <TableRow key={headerGroup.id}>
                <TableCell sx={{ width: 50 }} />
                {headerGroup.headers.map(header => {
                  return (
                    <TableCell key={header.id} style={header.column.columnDef.headerStyle ?? { minWidth: 150 }}>
                      <Typography variant="tableHeader" noWrap>
                        {flexRender(header.column.columnDef.header, header.getContext())}
                      </Typography>
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableHead>
          <TableBody>
            {reactTable.getRowModel().rows.map(row => (
              <PatientSummaryTableCollapse
                key={row.id}
                row={row}
                refillAction={refillAction}
                allowRefill={isClinicSide}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        showFirstButton
        showLastButton
        page={tablePage}
        count={totalRecords}
        rowsPerPage={rowLimit}
        rowsPerPageOptions={ROWS_LIMIT}
        onPageChange={handleChangePage}
        onRowsPerPageChange={changeRowLimit}
        style={{ alignItems: "center", marginBottom: 0 }}
      />
      <OrderViewModal
        isReview
        patientProductRefill
        listingName={LISTING_NAME.PATIENT_SUMMARY}
        // Important to hide the Detail view on editing
        prescriptionId={refillPrescriptionId}
        mainPrescriptionId={mainPrescriptionId}
        handleClose={toggleReviewModal}
      />
    </>
  );
}
export default React.memo(PatientSummaryPrescription);
