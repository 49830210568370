import { Dialog, DialogTitle, DialogContent, IconButton } from "@mui/material";
import React, { useEffect, useState, useMemo, useRef } from "react";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import { Spinner } from "reactstrap";
import {
  useExostarOfflineAuthSignMutation,
  useExostarOnlineAuthSignMutation,
  useLazyGetExostarPollStatusQuery,
} from "rtk/rtkEndpoints/Exostar";
import "./ExostarSign.scss";
import icon from "../../assets/images/exostar-icon.svg";
import Lottie from "../../../src/components/lottie";
import { set } from "lodash";

const ExostarSign = props => {
  const { setSuccess, prescriptionData } = props;
  const [otpToken, setOtpToken] = useState("");
  const [isOnline, setIsOnline] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  return (
    <div>
      <div className="d-flex align-items-center  flex-column">
        <OfflineWay
          setOpenModal={setOpenModal}
          setIsOnline={setIsOnline}
          setOtpToken={setOtpToken}
          otpToken={otpToken}
        />
        <div className="mx-2">
          <span>
            <strong>OR</strong>
          </span>
        </div>
        <OnlineWay setOpenModal={setOpenModal} setIsOnline={setIsOnline} />
      </div>
      <ActionModal
        setOpenModal={setOpenModal}
        openModal={openModal}
        otpToken={otpToken}
        setSuccess={setSuccess}
        isOnline={isOnline}
        prescriptionData={prescriptionData}
      />
    </div>
  );
};

const OfflineWay = ({ otpToken, setOtpToken, setIsOnline, setOpenModal }) => {
  return (
    <form
      onSubmit={e => {
        e.preventDefault();
        setIsOnline(false);
        setOpenModal(true);
      }}>
      <p style={{ color: "black" }}>
        <strong>Enter the Authy App Token Code</strong>
      </p>
      <div className="d-flex align-items-center">
        <input
          type="text"
          style={{ width: "150px" }}
          className="form-control"
          placeholder=""
          value={otpToken}
          required
          onChange={e => {
            setOtpToken(e.target.value);
          }}></input>
        <span>
          <Button type="submit" className="mx-1 offline-button">
            <i className="fa fa-check" />
          </Button>
        </span>
      </div>
    </form>
  );
};

const OnlineWay = ({ setIsOnline, setOpenModal }) => {
  return (
    <Button
      className="online-button"
      onClick={() => {
        setIsOnline(true);
        setOpenModal(true);
      }}
      variant="contained"
      startIcon={<img src={icon} alt="icon" style={{ width: "24px", height: "24px" }} />}>
      App
    </Button>
  );
};

const ActionModal = props => {
  const { openModal, setOpenModal, otpToken, setSuccess, isOnline, prescriptionData } = props;
  const [showSpinner, setShowSpinner] = useState(false);
  const openModalRef = useRef(openModal); // Reference to track openModal state
  const [error, setError] = useState("");

  const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));
  const [fetchPollStatus, { data: pollStatusData, isLoading: fetchPollStatusLoading, error: fetchPollStatusError }] =
    useLazyGetExostarPollStatusQuery();
  const [
    submitOfflineSign,
    {
      data: offlineSignData,
      isLoading: submitOfflineSignLoading,
      error: submitOfflineSignError,
      isSuccess: isSubmitOfflineSuccess,
    },
  ] = useExostarOfflineAuthSignMutation();
  const [
    submitOnlineSign,
    {
      data: onlineSignData,
      isLoading: submitOnlineSignLoading,
      error: submitOnlineSignError,
      isSuccess: isSubmitOnlineSuccess,
    },
  ] = useExostarOnlineAuthSignMutation();

  const errorMessage = useMemo(
    () =>
      fetchPollStatusError?.data?.message ||
      submitOfflineSignError?.data?.message ||
      submitOnlineSignError?.data?.message,
    [submitOfflineSignError, submitOnlineSignError, fetchPollStatusError],
  );
  const showLoading = useMemo(
    () => submitOfflineSignLoading || submitOnlineSignLoading || showSpinner,
    [submitOfflineSignLoading, submitOnlineSignLoading, fetchPollStatusLoading, showSpinner],
  );

  useEffect(() => {
    openModalRef.current = openModal; // Update the reference when openModal changes
    if (openModal) {
      (async () => {
        let body = {
          prescription_id: prescriptionData.id,
          products: prescriptionData?.products,
        };
        if (isOnline) {
          setShowSpinner(true);
          const res = await submitOnlineSign(body);
          if (res?.data) {
            startPolling();
          } else if (res?.error?.data?.message) {
            setError(res?.error?.data?.message);
            setShowSpinner(false);
          }
        } else {
          setShowSpinner(true);
          body.otpCode = otpToken;
          const res = await submitOfflineSign(body);
          if (res?.data) {
            setSuccess(res?.data?.msg);
            setShowSpinner(false);
            setError("");
          } else if (res?.error?.data?.message) {
            setError(res?.error?.data?.message);
            setShowSpinner(false);
          }
        }
      })();
    }
  }, [isOnline, openModal]);

  async function startPolling() {
    let isChecking = true;
    while (isChecking) {
      if (!openModalRef.current) {
        isChecking = false;
        break;
      }
      const response = await fetchPollStatus();
      if (response?.data?.msg === "Prescription approved") {
        isChecking = false;
        setSuccess(response?.data?.msg);
        setShowSpinner(false);
        setError("");
        break;
      } else if (response?.error?.data?.message) {
        isChecking = false;
        setSuccess("");
        setShowSpinner(false);
        setError(response?.error?.data?.message);
        break;
      }
      await sleep(3000);
    }
    // Sleep for 3 seconds before next poll
  }
  console.log({ error });
  return (
    <Dialog
      sx={{
        "& .MuiDialog-paper": {
          width: "400px", // Set custom width
          height: "80vh", // Set custom height
          maxHeight: "80vh", // Ensure height is within viewport limits
        },
      }}
      open={Boolean(openModal)}
      onClose={() => setOpenModal(false)}
      scroll="paper"
      fullWidth
      maxWidth="xl">
      <DialogTitle variant="h3">
        <IconButton
          aria-label="close"
          onClick={() => setOpenModal(false)}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: theme => theme.palette.grey[500],
          }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        {showSpinner ? (
          <div className="text-center">
            <Spinner size="lg">...</Spinner>
            <p> {isOnline && "Please use your authy app to continue"}</p>
          </div>
        ) : (
          <div>
            {error ? (
              <div className="text-center">
                <Lottie name="sad-lottie" />
                <p className="redText">{error}</p>
              </div>
            ) : (
              <div className="text-center">
                <Lottie name="success-check" />
                <p className="my-4">
                  {isSubmitOnlineSuccess
                    ? "The push notification has been approved"
                    : "The Authy app code token has been approved"}
                </p>
              </div>
            )}
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default ExostarSign;
