import CloseIcon from "@mui/icons-material/Close";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import Tab from "@mui/material/Tab";
import { AlertMessage, FormOutlineField, FormSelectField } from "components";
import Spinner from "components/spinner";
import dayjs from "dayjs";
import { map, trim } from "lodash";
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo } from "react";
import { useForm, useWatch } from "react-hook-form";
import { COMPOUND_MEDICATION_REASONS, get_day_supply } from "utils";
import { useProductCartModal } from "./Hooks";

function ProductCartModal(_, ref) {
  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    clearErrors,
    setError,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      _id: "",
      quantity: 1,
      sig: "",
      medicalAccessory: "",
      productType: "categorized",
    },
  });

  const {
    isRxPad,
    unitPrice,
    activeTab,
    infoMessage,
    favLoading,
    showLoading,
    errorMessage,
    productDetail,
    discountDetail,
    patientFormData,
    openProductCartDialog,
    medicalAccessoryOption,
    medicalAccessoryDiscountDetail,
    submitAction,
    handleTabChange,
    favoriteCheckbox,
    showProductCartDialog,
    closeProductCartDialog,
    onMedicalAccessoryDependentChange,
  } = useProductCartModal({ getValues, setValue, setError, reset });

  const watchQuantity = useWatch({ control, name: "quantity" });
  const watchSigOption = useWatch({ control, name: "sig" });
  const medicalAccessoryValue = useWatch({ control, name: "medicalAccessory" });
  const watchMedicalQuantity = useWatch({ control, name: "medicalAccessoryQuantity" });

  useEffect(() => {
    if (productDetail?.productSize) {
      setValue("daySupply", get_day_supply(productDetail?.productSize, watchSigOption, watchQuantity));
    }
  }, [productDetail?.productSize, setValue, watchQuantity, watchSigOption]);

  const radioBtnClick = useCallback(
    fieldName => async event => {
      setValue(fieldName, event.target.value);
      clearErrors(fieldName);
    },
    [clearErrors, setValue],
  );

  useImperativeHandle(ref, () => ({
    showProductCartDialog,
    closeProductCartDialog,
  }));

  const recommendSig = useMemo(
    () =>
      map(productDetail?.sigOptions, sigObj => (
        <FormControlLabel
          key={sigObj?._id}
          value={sigObj.description}
          control={<Radio style={{ fontSize: 20 }} onClick={radioBtnClick("sig")} />}
          sx={{ alignItems: "flex-start", width: 1 }}
          label={
            <>
              <Typography variant="body1" fontWeight={"500"}>
                {sigObj?.title}
              </Typography>
              <Typography variant="body2">{sigObj?.description}</Typography>
            </>
          }
        />
      )),
    [productDetail?.sigOptions, radioBtnClick],
  );

  const allowedMedicalAccessories = useMemo(() => {
    if (!(patientFormData?.current?.length > 1 || isRxPad) && productDetail?.medicalAccessories === "1") {
      return (
        <>
          <Divider my={1} />
          <Grid container columnSpacing={1}>
            <Grid item xs={12} md={8}>
              <FormSelectField
                name="medicalAccessory"
                control={control}
                dependentChange={onMedicalAccessoryDependentChange}
                MuiFieldProps={{
                  label: "Medical Accessories",
                  optionArray: medicalAccessoryOption,
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <FormOutlineField
                name="medicalAccessoryQuantity"
                control={control}
                rules={{
                  required: medicalAccessoryValue ? "Unit Quantity is required." : false,
                  min: { value: 1, message: "Quantity must be greater than 0" },
                }}
                MuiFieldProps={{ label: "Unit Quantity", type: "number" }}
                MuiInputProps={{ min: 1 }}
              />
            </Grid>
          </Grid>
          <Divider my={1} />
        </>
      );
    }
  }, [
    control,
    isRxPad,
    medicalAccessoryOption,
    medicalAccessoryValue,
    onMedicalAccessoryDependentChange,
    patientFormData,
    productDetail?.medicalAccessories,
  ]);

  const medicalAccessoryPriceView = useMemo(() => {
    if (medicalAccessoryValue) {
      return (
        <>
          <Typography variant="body1" fontWeight={"500"} color={"primary"}>
            Pricing Information (Medical Accessory)
          </Typography>
          <Grid container justifyContent={"space-between"}>
            <Grid item>
              <Typography variant="body1">Unit Price</Typography>
            </Grid>
            <Grid item>
              <Typography variant="body1">{medicalAccessoryDiscountDetail?.[0]?.unitPrice || "-"}</Typography>
            </Grid>
          </Grid>
          <Grid container justifyContent={"space-between"}>
            <Grid item>
              <Typography variant="body1">Discounted Price</Typography>
            </Grid>
            <Grid item>
              <Typography variant="body1">{medicalAccessoryDiscountDetail?.[0]?.discountedPrice ?? "-"}</Typography>
            </Grid>
          </Grid>
          <Grid container justifyContent={"space-between"}>
            <Grid item>
              <Typography variant="body1">Unit Quantity</Typography>
            </Grid>
            <Grid item>
              <Typography variant="body1">{watchMedicalQuantity ?? "-"}</Typography>
            </Grid>
          </Grid>
        </>
      );
    }
  }, [medicalAccessoryDiscountDetail, medicalAccessoryValue, watchMedicalQuantity]);

  return (
    <Dialog
      id="PRODUCT_CART-DIALOG"
      open={openProductCartDialog}
      scroll="paper"
      maxWidth="xl"
      fullWidth
      disableEscapeKeyDown>
      {showLoading && <Spinner />}
      <DialogTitle variant="h5">
        Add Product to Cart
        <IconButton
          aria-label="close"
          onClick={closeProductCartDialog}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: theme => theme.palette.grey[500],
          }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <AlertMessage isError msg={errorMessage} />
        <AlertMessage info msg={infoMessage} />
        <Grid container justifyContent={"space-between"}>
          <Grid container item xs={8.5} columnSpacing={3} rowSpacing={1}>
            <Grid item xs={12}>
              <FormOutlineField
                name="name"
                control={control}
                rules={{ required: "Product name is required." }}
                MuiFieldProps={{ label: "Product Name", readOnly: isRxPad ? false : true }}
              />
            </Grid>
            <Grid item xs={6}>
              <FormOutlineField
                name="quantity"
                control={control}
                rules={{
                  required: "Unit Quantity is required.",
                  min: { value: 1, message: "Quantity must be greater than 0" },
                }}
                MuiFieldProps={{ label: "Unit Quantity", type: "number" }}
                MuiInputProps={{ min: 1 }}
              />
            </Grid>
            <Grid item xs={6}>
              {isRxPad ? (
                <FormOutlineField
                  name="strength"
                  control={control}
                  rules={{
                    required: "strength is required.",
                  }}
                  MuiFieldProps={{ label: "Strength" }}
                />
              ) : (
                <>
                  <Typography fontWeight={"bold"}>Dispense Type</Typography>
                  <Typography>{productDetail?.dispenseType}</Typography>
                </>
              )}
            </Grid>
            <Grid item xs={6}>
              <FormOutlineField
                name="daySupply"
                control={control}
                rules={{
                  min: { value: 1, message: "Supply must be greater than 0" },
                }}
                MuiFieldProps={{ label: "Day Supply", type: "number" }}
                MuiInputProps={{ min: 1 }}
              />
            </Grid>
            <Grid item xs={6}>
              <FormOutlineField
                name="refills"
                control={control}
                rules={{
                  required: "Refills is required.",
                  min: { value: 0, message: "Refills can not be negative" },
                }}
                MuiFieldProps={{ label: "Refills", type: "number" }}
                MuiInputProps={{ min: 0 }}
              />
            </Grid>
            <Grid item xs={12}>
              <FormOutlineField
                name="last_office_visit_timestamp"
                control={control}
                rules={{
                  required: productDetail?.controlledSubstance === "1" ? "Last visit is required." : false,
                }}
                MuiInputProps={{ max: dayjs().format("YYYY-MM-DD") }}
                MuiFieldProps={{ label: "Last Office Visit", type: "date", shrink: true }}
              />
            </Grid>
            <Grid item xs={12}>
              <FormSelectField
                name="reasonForCompoundedMedication"
                control={control}
                MuiFieldProps={{
                  label: "Reason for compounded medication",
                  optionArray: COMPOUND_MEDICATION_REASONS,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <FormOutlineField
                name="patientDiagnosis"
                control={control}
                MuiFieldProps={{ label: "Patient Diagnosis", multiline: true, rows: 2 }}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth error={Boolean(trim(errors?.sig))}>
                <FormLabel>
                  Written Instruction <span className="requiredStar"> *</span>
                </FormLabel>
                <RadioGroup name="sig" value={watchSigOption}>
                  <TabContext value={activeTab}>
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                      <TabList variant="fullWidth" onChange={handleTabChange}>
                        <Tab label="Recommended or Common Dosing" value="1" disabled={isRxPad} />
                        <Tab label="Custom Written Instructions" value="2" />
                      </TabList>
                    </Box>
                    <TabPanel value="1">
                      <FormLabel>
                        <Typography variant="h6" color={trim(errors?.sig) && "error"}>
                          Dosing Recommendations
                        </Typography>
                      </FormLabel>
                      {recommendSig}
                    </TabPanel>
                    <TabPanel value="2">
                      <FormOutlineField
                        name="sig"
                        control={control}
                        rules={{
                          required: "Instruction is missing.",
                        }}
                        MuiFieldProps={{ label: "Custom Instruction", multiline: true, rows: 2 }}
                      />
                    </TabPanel>
                  </TabContext>
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} py={1}>
              <Typography color={"primary"}>Generated Sig</Typography>
              <Typography>{watchSigOption ?? "-"}</Typography>
            </Grid>
          </Grid>
          <Grid container item xs={3} rowGap={2} alignContent="flex-start">
            <Grid item xs={12}>
              <FormGroup row sx={{ alignItems: "center" }}>
                <FormControlLabel
                  disabled={isRxPad}
                  control={<Checkbox checked={productDetail?.isFavorite ?? false} onChange={favoriteCheckbox} />}
                  label="Favorite"
                />
                {favLoading && <CircularProgress size={15} />}
              </FormGroup>
            </Grid>

            {allowedMedicalAccessories}

            <Typography variant="body1" fontWeight={"500"} color={"primary"}>
              Pricing Information
            </Typography>
            <Grid container justifyContent={"space-between"}>
              <Grid item>
                <Typography variant="body1">Unit Price</Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1">{unitPrice}</Typography>
              </Grid>
            </Grid>
            <Grid container justifyContent={"space-between"}>
              <Grid item>
                <Typography variant="body1">Discounted Price</Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1">{discountDetail?.[0]?.discountedPrice ?? "-"}</Typography>
              </Grid>
            </Grid>
            <Grid container justifyContent={"space-between"}>
              <Grid item>
                <Typography variant="body1">Unit Quantity</Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1">{watchQuantity}</Typography>
              </Grid>
            </Grid>
            {medicalAccessoryPriceView}
            <Divider variant="fullWidth" />
            <Grid container justifyContent={"space-between"}>
              <Grid item>
                <Typography variant="body1" fontWeight={"500"}>
                  Total
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1" fontWeight={"500"}>
                  {(
                    (discountDetail?.[0]?.discountedPrice ?? 0) * watchQuantity +
                    (medicalAccessoryDiscountDetail?.[0]?.discountedPrice ?? 0) * (watchMedicalQuantity ?? 0)
                  ).toFixed(2)}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" className="pinkButton" onClick={handleSubmit(submitAction)}>
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default React.memo(forwardRef(ProductCartModal));
