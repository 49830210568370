/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Grid, Typography } from "@mui/material";
import { FormSelectField, MuiAlertMessage } from "components";
import Spinner from "components/spinner";
import { DialogContext } from "context/Dialog/DialogContext";
import {
  PrescriptionApiStateContext,
  PrescriptionFormActionContext,
  PrescriptionFormSateContext,
  PrescriptionStateContext,
} from "context/PrescriptionContext";
import { find, unset } from "lodash";
import React, { forwardRef, useCallback, useContext, useEffect, useImperativeHandle, useMemo, useRef } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useLazyGetAllProviderListQuery, useLazyGetClinicLocationAddressQuery } from "rtk";
import { STEPPER_FORMS, matchAddressDea } from "utils";

function PrescriptionLocationProviderSelection({ formId, formData }, parentRef) {
  const formRef = useRef();
  const { closeDialog, showDialog } = useContext(DialogContext);
  const { patientFormData } = useContext(PrescriptionFormSateContext);
  const { clinicData, prescriptionData } = useContext(PrescriptionApiStateContext);
  const { getSingleFormData } = useContext(PrescriptionFormActionContext);
  const { isPrescriptionEdit, clinicDataFetching } = useContext(PrescriptionStateContext);
  const { control, handleSubmit, getValues, setValue, trigger } = useForm();

  const [fetchProvider, { data: providerData, isFetching: providerFetching, error: providerError }] =
    useLazyGetAllProviderListQuery();

  const [fetchClinicLocationAddress, { error: addressError, isFetching: addressFetching }] =
    useLazyGetClinicLocationAddressQuery();

  const watchClinicLocationId = useWatch({ control, name: "clinicLocationId" });
  const watchProviderId = useWatch({ control, name: "providerId" });

  useEffect(() => {
    // useEffect will not run for Edit Prescription
    if (!isPrescriptionEdit && patientFormData.current?.length) {
      const isBulkOrder = patientFormData.current?.length > 1;
      setValue("clinicLocationId", isBulkOrder ? "" : patientFormData.current?.[0]?.clinicLocationId);
      setValue("providerId", isBulkOrder ? "" : patientFormData.current?.[0]?.physicianId);
      if (!isBulkOrder) {
        fetchProvider({ clinicLocationId: patientFormData.current?.[0]?.clinicLocationId });
      }
    }
  }, [
    isPrescriptionEdit,
    patientFormData.current.length,
    patientFormData.current?.[0]?.clinicLocationId,
    patientFormData.current?.[0]?.physicianId,
  ]);

  useEffect(() => {
    // useEffect will run for Edit Prescription
    if (!clinicDataFetching && isPrescriptionEdit && prescriptionData?.id) {
      setValue("clinicLocationId", prescriptionData?.clinicLocationId);
      setValue("providerId", prescriptionData?.providerId);
      fetchProvider({ clinicLocationId: prescriptionData?.clinicLocationId });
      submitAction(getValues(), null, false, prescriptionData?.providerDetails);
    }
  }, [
    isPrescriptionEdit,
    clinicDataFetching,
    prescriptionData?.clinicLocationId,
    prescriptionData?.id,
    prescriptionData?.providerId,
  ]);

  const providerSelectionError = useMemo(
    () => providerError?.data?.message || addressError?.data?.message || "",
    [addressError?.data?.message, providerError?.data?.message],
  );

  const clinicLocationOption = useMemo(() => {
    if (clinicData?.clinicLocations?.length === 0) {
      return [
        {
          value: "",
          label: "No clinic locations found",
          disabled: true,
        },
      ];
    }
    return clinicData?.clinicLocations?.map(location => ({
      value: location?.id,
      label: location?.locationName ?? "",
    }));
  }, [clinicData?.clinicLocations]);

  const providerOption = useMemo(() => {
    if (providerFetching) {
      return [
        {
          value: "",
          label: "Loading",
          disabled: true,
        },
      ];
    } else if (providerData?.length === 0) {
      return [
        {
          value: "",
          label: "No providers found!",
          disabled: true,
        },
      ];
    }
    return providerData?.map(provider => ({
      value: provider?.id,
      label: `${provider?.firstName ?? ""} ${provider?.lastName ?? ""}`,
    }));
  }, [providerData, providerFetching]);

  const providerDetail = useMemo(() => {
    if (watchProviderId) {
      return find(providerData, { id: watchProviderId });
    }
  }, [providerData, watchProviderId]);

  const providerDEA = useMemo(() => {
    if (providerDetail) {
      return find(providerDetail?.deaInfo, { clinicLocationId: watchClinicLocationId });
    }
  }, [providerDetail, watchClinicLocationId]);

  const submitAction = useCallback(
    async (data, progressBarIndex = null, allowNavigation = true, editPrescriptionProviderDetail = {}) => {
      const selectedProducts = getSingleFormData(STEPPER_FORMS.PRODUCT_INFO);
      const isControlledSubstance = selectedProducts?.some(product => product.controlledSubstance === "1");
      const selectedClinicLocationDetail = find(clinicData?.clinicLocations, {
        id: data?.clinicLocationId,
      });

      const filterProviderDetail = providerDetail ?? editPrescriptionProviderDetail;
      unset(filterProviderDetail, "clinicLocationName");
      const filterProviderDea =
        providerDEA ??
        find(editPrescriptionProviderDetail?.deaInfo, { clinicLocationId: selectedClinicLocationDetail?.id });

      const hasProviderDetail =
        filterProviderDea?.dea &&
        filterProviderDea?.deaLicenseUrl &&
        filterProviderDea?.providerDeaLicenseExpirationDate;

      if (isControlledSubstance && !hasProviderDetail) {
        showDialog({
          question:
            "Provider doesn't have DEA credentials on file. Please edit provider profile or select alternative provider.",
          cancelText: "OK",
          onClickNo: closeDialog,
        });
      } else {
        const clinicLocationAddressResponse = await fetchClinicLocationAddress({
          locationId: data?.clinicLocationId,
        }).unwrap();
        const clinicLocationAddress = {
          addressLine1: clinicLocationAddressResponse?.addressLine1,
          addressLine2: clinicLocationAddressResponse?.addressLine2,
          city: clinicLocationAddressResponse?.city,
          state: clinicLocationAddressResponse?.state,
          zipcode: clinicLocationAddressResponse?.zipcode,
        };

        const selectedPatient = getSingleFormData(STEPPER_FORMS.PATIENT_INFO);
        if (selectedPatient?.length <= 1 && !editPrescriptionProviderDetail?.id) {
          const isAddressMatch = matchAddressDea(filterProviderDetail, clinicLocationAddress, data?.clinicLocationId);
          if (!isAddressMatch && isControlledSubstance) {
            showDialog({
              question:
                "This prescription order must be shipped directly to the patient due to the selected provider's DEA information.",
              actionText: "OK",
              onClickNo: closeDialog,
              onClickYes: () => {
                formData?.(
                  {
                    info: {
                      ...data,
                      ...filterProviderDetail,
                      clinicLocationAddress,
                      clinicLocation: {
                        id: selectedClinicLocationDetail?.id,
                        credit: selectedClinicLocationDetail?.credit,
                        encryptedCreditCards: selectedClinicLocationDetail?.encryptedCreditCards,
                      },
                    },
                  },
                  formId,
                  progressBarIndex,
                  allowNavigation,
                );
                closeDialog();
              },
            });
            return;
          }
        }
        formData?.(
          {
            info: {
              ...data,
              ...filterProviderDetail,
              clinicLocation: {
                id: selectedClinicLocationDetail?.id,
                credit: selectedClinicLocationDetail?.credit,
                encryptedCreditCards: selectedClinicLocationDetail?.encryptedCreditCards,
              },
            },
          },
          formId,
          progressBarIndex,
          allowNavigation,
        );
      }
    },
    [
      clinicData?.clinicLocations,
      closeDialog,
      fetchClinicLocationAddress,
      formData,
      formId,
      getSingleFormData,
      providerDEA?.dea,
      providerDEA?.deaLicenseUrl,
      providerDEA?.providerDeaLicenseExpirationDate,
      providerDetail,
      showDialog,
    ],
  );

  const canNavigate = useCallback(
    progressBarIndex => {
      handleSubmit(data => submitAction(data, progressBarIndex))();
    },
    [handleSubmit, submitAction],
  );

  const checkValidation = useCallback(async () => {
    const formValidated = await trigger();
    return formValidated;
  }, [trigger]);

  const requestFormSubmit = useCallback(() => formRef.current?.requestSubmit?.(), []);

  useImperativeHandle(parentRef, () => ({
    submitForm: requestFormSubmit,
    canNavigate: canNavigate,
    checkFilledForm: checkValidation,
  }));

  const onClinicLocationChange = useCallback(() => {
    const clinicLocationId = getValues("clinicLocationId");
    setValue("providerId", "");
    fetchProvider({ clinicLocationId }, true);
  }, [fetchProvider, getValues, setValue]);

  return (
    <Box id={formId} ref={formRef} component={"form"} onSubmit={handleSubmit(data => submitAction(data, null))}>
      <Grid container justifyContent={"center"}>
        <Spinner showSpinner={addressFetching} />
        <MuiAlertMessage message={providerSelectionError} isError />
        <Grid container justifyContent={"space-around"}>
          <Grid item md={3} xl={2} display={{ xs: "none", md: "flex" }} />
          <Grid item container xs={12} md={9} lg={9} px={2} rowGap={2}>
            <Grid item xs={12}>
              <Typography fontWeight={"600"}>
                Clinic Name{" "}
                <Typography component={"span"} sx={{ px: 2 }} color={"grey.700"}>
                  {clinicData?.businessName}
                </Typography>
              </Typography>
            </Grid>
            <Grid container rowSpacing={2} mt={1}>
              <Grid item xs={12} md={10}>
                <FormSelectField
                  name="clinicLocationId"
                  control={control}
                  rules={{ required: "Clinic Location is required." }}
                  dependentChange={onClinicLocationChange}
                  MuiFieldProps={{
                    label: "Clinic Location",
                    optionArray: clinicLocationOption,
                  }}
                />
              </Grid>
              <Grid item xs={12} md={10}>
                <FormSelectField
                  name="providerId"
                  control={control}
                  rules={{ required: "Provider is required." }}
                  MuiFieldProps={{
                    label: "Provider",
                    optionArray: providerOption,
                    disabled: !watchClinicLocationId,
                  }}
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Typography fontWeight={"600"}>
                NPI Number{" "}
                <Typography component={"span"} sx={{ px: 2 }} color={"grey.700"}>
                  {providerDetail?.npi}
                </Typography>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography fontWeight={"600"}>
                DEA Number{" "}
                <Typography component={"span"} sx={{ px: 2 }} color={"grey.700"}>
                  {providerDEA?.dea}
                </Typography>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}

export default React.memo(forwardRef(PrescriptionLocationProviderSelection));
