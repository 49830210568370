const COMPOUND_MEDICATION_REASONS = [
  { label: "Hypo-allergenic Formulation, Sensitivity", value: "Hypo-allergenic Formulation, Sensitivity" },
  { label: "Modified-Release, Decrease side-effects", value: "Modified-Release, Decrease side-effects" },
  { label: "Excipient Ingredient Sensitivity", value: "Excipient Ingredient Sensitivity" },
  { label: "Inactive Ingredient Sensitivity", value: "Inactive Ingredient Sensitivity" },
  {
    label: "Higher Concentration, Decrease post-injection pain",
    value: "Higher Concentration, Decrease post-injection pain",
  },
  { label: "Alternate administration route", value: "Alternate administration route" },
  { label: "Microdosing", value: "Microdosing" },
  { label: "Sustained Released", value: "Sustained Released" },
  { label: "Unavailable Commercially", value: "Unavailable Commercially" },
];

export { COMPOUND_MEDICATION_REASONS };
