/* eslint-disable react-hooks/exhaustive-deps */
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import { FormFileField, FormOutlineField, FormSelectField } from "components";
import { ProfileImageStyled } from "components/StyledComponent";
import { PatientActionContext, PatientApiActionContext, PatientApiStateContext, PatientStateContext } from "context";
import dayjs from "dayjs";
import { filter, includes } from "lodash";
import React, { forwardRef, useCallback, useContext, useEffect, useImperativeHandle, useMemo, useRef } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useSelector } from "react-redux";
import { userInfoSelector, userRoleSelector } from "selectors";
import { ETHNICITY, HIDE_PATIENT_LOCATION, MARTIAL_STATUS, RACE, SEX, USA_STATES, getFileNames } from "utils";

const PatientPersonalInfo = forwardRef(({ formId, formData }, parentRef) => {
  const formRef = useRef(null);
  const uploadButtonRef = useRef(null);
  const userInfo = useSelector(userInfoSelector);
  const userRole = useSelector(userRoleSelector);
  const { isPatientEdit, profilePic, imageUploading } = useContext(PatientStateContext);
  const { patientData, clinicLocationOption, physicianData, physicianFetching } = useContext(PatientApiStateContext);
  const { fetchPhysician } = useContext(PatientApiActionContext);
  const { removeProfileImage, profileImageUploading } = useContext(PatientActionContext);
  const { control, handleSubmit, setValue, getValues, trigger } = useForm({
    defaultValues: {
      clinicLocationId: userInfo?.clinicLocationIds?.[0] ?? "",
      isEmployee: false,
      labWork: [],
      physicals: [],
      soapNotes: [],
      otherDocuments: [],
    },
  });
  const watchClinicLocationId = useWatch({ control, name: "clinicLocationId" });
  const licenseDocWork = useWatch({ control, name: "driverLicenseFileUrl" });
  const watchLabWork = useWatch({ control, name: "labWork" });
  const watchPhysical = useWatch({ control, name: "physicals" });
  const watchSoapNotes = useWatch({ control, name: "soapNotes" });
  const watchOtherDoc = useWatch({ control, name: "otherDocuments" });

  const removeFile = useCallback((keyName, index, removeAble = true) => {
    if (!removeAble) {
      return;
    }
    if (keyName === "driverLicenseFileUrl") {
      setValue(keyName, "");
    } else {
      const keyValues = getValues(keyName);
      keyValues.splice(index, 1);
      setValue(keyName, keyValues);
    }
  }, []);

  const getFileListItems = useCallback(
    (fileArray, docName) => {
      return fileArray.map(({ name, removeAble }, index) => (
        <ListItem
          key={`lab-${name}`}
          disablePadding
          disableGutters
          secondaryAction={
            <IconButton
              edge="end"
              aria-label="delete"
              disabled={!removeAble}
              onClick={() => removeFile(docName, index, removeAble)}>
              <DeleteIcon color={removeAble ? "error" : "disabled"} style={{ width: 18, height: 18 }} />
            </IconButton>
          }>
          <ListItemText
            primaryTypographyProps={{ color: "grey", noWrap: true }}
            style={{ marginRight: 20 }}
            primary={name}
          />
        </ListItem>
      ));
    },
    [removeFile],
  );

  const licenseDocList = useMemo(() => {
    if (licenseDocWork) {
      const fileNames = getFileNames([licenseDocWork]);
      return getFileListItems(fileNames, "driverLicenseFileUrl");
    }
  }, [getFileListItems, licenseDocWork]);

  const labDocList = useMemo(() => {
    const fileNames = getFileNames(watchLabWork);
    return getFileListItems(fileNames, "labWork");
  }, [getFileListItems, watchLabWork]);

  const physicalDocList = useMemo(() => {
    const fileNames = getFileNames(watchPhysical);

    return getFileListItems(fileNames, "physicals");
  }, [getFileListItems, watchPhysical]);

  const soapNotesDocList = useMemo(() => {
    const fileNames = getFileNames(watchSoapNotes);
    return getFileListItems(fileNames, "soapNotes");
  }, [getFileListItems, watchSoapNotes]);

  const otherDocList = useMemo(() => {
    const fileNames = getFileNames(watchOtherDoc);
    return getFileListItems(fileNames, "otherDocuments");
  }, [getFileListItems, watchOtherDoc]);

  const submitAction = useCallback(
    (data, progressBarIndex = null, allowNavigation = true) => {
      const formattedDate = dayjs(data.dateOfBirth).format("MM/DD/YYYY");

      // Update the data with the formatted date
      const formattedData = {
        ...data,
        dateOfBirth: formattedDate,
      };
      formData?.({ info: formattedData }, formId, progressBarIndex, allowNavigation);
    },
    [formData, formId],
  );

  useEffect(() => {
    if (watchClinicLocationId) {
      fetchPhysician();
    }
  }, [fetchPhysician, watchClinicLocationId]);

  useEffect(() => {
    if (isPatientEdit && patientData?.id && !physicianFetching) {
      const formFields = getValues();
      for (const field in formFields) {
        let fieldValue = patientData?.[field] ?? "";
        if (field === "dateOfBirth" && fieldValue) {
          // moment is deprecated now. So we use dayjs
          fieldValue = dayjs(fieldValue).format("YYYY-MM-DD");
        } else if (field === "isEmployee") {
          fieldValue = Boolean(fieldValue);
        }
        setValue(field, fieldValue);
      }
      submitAction(getValues(), null, false);
    }
  }, [isPatientEdit, patientData?.clinicLocationIds, patientData?.id, physicianFetching]);

  const uploadClick = useCallback(
    e => {
      e.preventDefault();
      if (!imageUploading) {
        uploadButtonRef.current?.click();
      }
    },
    [imageUploading],
  );

  const canNavigate = useCallback(
    progressBarIndex => {
      handleSubmit(data => submitAction(data, progressBarIndex))();
    },
    [handleSubmit, submitAction],
  );

  const resetProvider = useCallback(() => setValue("physicianId", ""), []);
  const requestFormSubmit = useCallback(() => formRef.current?.requestSubmit?.(), []);
  const checkValidation = useCallback(async () => {
    const formValidated = await trigger();
    return formValidated;
  }, []);

  useImperativeHandle(parentRef, () => ({
    submitForm: requestFormSubmit,
    canNavigate: canNavigate,
    checkFilledForm: checkValidation,
  }));

  const imageComponent = useMemo(() => {
    if (profilePic) {
      return <ProfileImageStyled src={profilePic} alt={"Profile"} />;
    }
    return <CloudUploadIcon style={{ width: 30, height: 30, color: "#495057" }} />;
  }, [profilePic]);

  const physicianOption = useMemo(() => {
    if (physicianFetching) {
      return [
        {
          value: "",
          label: "Loading",
          disabled: true,
        },
      ];
    } else if (!(watchClinicLocationId || userInfo?.clinicLocationIds?.length > 0)) {
      return [
        {
          value: "",
          label: "No data found",
          disabled: true,
        },
      ];
    }
    return filter(physicianData, physicianObj =>
      physicianObj.clinicLocationIds.includes(watchClinicLocationId || userInfo?.clinicLocationIds?.[0]),
    )?.map(physician => ({
      value: physician?.id,
      label: `${physician?.firstName ?? ""} ${physician.lastName ?? ""}`,
    }));
  }, [physicianData, physicianFetching, userInfo?.clinicLocationIds, watchClinicLocationId]);

  const clinicLocation = useMemo(() => {
    //Filter Clinic Locations for Provider
    let filteredClinicLocationOption = clinicLocationOption;
    if (includes(HIDE_PATIENT_LOCATION, userRole)) {
      filteredClinicLocationOption = clinicLocationOption.filter(a => userInfo?.clinicLocationIds?.includes(a.value));
    }
    return (
      <Grid item xs={12} sm={6} md={4}>
        <FormSelectField
          name="clinicLocationId"
          control={control}
          rules={{ required: "Clinic Location is required." }}
          MuiFieldProps={{ label: "Clinic Location", optionArray: filteredClinicLocationOption }}
          dependentChange={resetProvider}
        />
      </Grid>
    );
  }, [clinicLocationOption, control, userRole]);

  return (
    <>
      <Grid container paddingY={5} flexDirection={"column"} alignItems={"center"}>
        <div onClick={uploadClick} className="staffProfileImgCircle">
          {imageUploading ? <CircularProgress size={20} /> : imageComponent}
        </div>
        <span>
          <Button
            disabled={imageUploading}
            component="label"
            variant="contained"
            className="pinkButton mx-2"
            startIcon={<CloudUploadIcon />}
            style={{ textTransform: "capitalize" }}
            href="#file-upload">
            {`${profilePic ? "Change" : "Upload"} Image`}
            <input
              ref={uploadButtonRef}
              accept=".jpg, .png, .jpeg"
              onChange={profileImageUploading}
              name="profile_photo"
              className="d-none"
              type="file"
              id="profilePhoto"
            />
          </Button>
          {Boolean(profilePic) && (
            <IconButton
              disabled={imageUploading}
              aria-label="delete"
              style={{ width: 35, height: 35 }}
              onClick={removeProfileImage}>
              <DeleteIcon color="error" style={{ width: 30, height: 30 }} />
            </IconButton>
          )}
        </span>
      </Grid>
      <Box id={formId} ref={formRef} component={"form"} onSubmit={handleSubmit(data => submitAction(data, null))}>
        <Grid container marginTop={2} columnSpacing={3} rowSpacing={1}>
          {clinicLocation}
        </Grid>
        <Grid container columnSpacing={3} rowSpacing={1}>
          <Grid item xs={12} sm={6} md={4}>
            <FormOutlineField
              name="firstName"
              control={control}
              rules={{ required: "First name is required." }}
              MuiFieldProps={{ label: "First name" }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <FormOutlineField name="middleName" control={control} MuiFieldProps={{ label: "Middle name" }} />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <FormOutlineField
              name="lastName"
              control={control}
              rules={{ required: "Last name is required." }}
              MuiFieldProps={{ label: "Last name" }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <FormOutlineField name="guardianName" control={control} MuiFieldProps={{ label: "Guardian's name" }} />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <FormOutlineField name="currentOccupation" control={control} MuiFieldProps={{ label: "Occupation" }} />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <FormOutlineField name="ssn" control={control} MuiFieldProps={{ label: "SSN" }} />
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <FormOutlineField
              name="dateOfBirth"
              control={control}
              rules={{ required: "Date is required." }}
              MuiInputProps={{ max: dayjs().format("YYYY-MM-DD") }}
              MuiFieldProps={{ label: "Date of Birth", type: "date", shrink: true }}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <FormSelectField
              name="physicianId"
              control={control}
              rules={{ required: "Provider is required." }}
              MuiFieldProps={{ label: "Provider", optionArray: physicianOption }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <FormSelectField
              name="martialStatus"
              control={control}
              MuiFieldProps={{ label: "Marital State", optionArray: MARTIAL_STATUS }}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <FormSelectField
              name="sex"
              control={control}
              rules={{ required: "Sex is required." }}
              MuiFieldProps={{ label: "Sex", optionArray: SEX }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <FormSelectField
              name="ethnicity"
              control={control}
              MuiFieldProps={{ label: "Ethnicity", optionArray: ETHNICITY }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <FormSelectField name="race" control={control} MuiFieldProps={{ label: "Race", optionArray: RACE }} />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <FormOutlineField name="height" control={control} MuiFieldProps={{ label: "Height" }} />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <FormOutlineField
              name="weight"
              control={control}
              MuiFieldProps={{
                label: "Weight",
                type: "number",
                endAdornment: <InputAdornment position="end">lbs</InputAdornment>,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <FormOutlineField name="currentPlan" control={control} MuiFieldProps={{ label: "Current Plan" }} />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <FormOutlineField name="driverLicenseId" control={control} MuiFieldProps={{ label: "Driver License ID" }} />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <FormSelectField
              name="driverLicenseState"
              control={control}
              MuiFieldProps={{ label: "Driver License State", optionArray: USA_STATES }}
            />
          </Grid>
        </Grid>
        <Grid container marginY={2} columnSpacing={3} rowSpacing={1}>
          <Grid item xs={12} marginBottom={3}>
            <Typography variant="h5" fontWeight={"500"}>
              Documents
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <FormFileField
              name="driverLicenseFileUrl"
              control={control}
              showError={false}
              MuiInputProps={{ accept: ".jpg, .png, .jpeg, .pdf" }}
              MuiFieldProps={{ label: "Driver License" }}
            />
            <List dense={true}>{licenseDocList}</List>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <FormFileField
              name="labWork"
              control={control}
              showError={false}
              appendFiles={true}
              MuiInputProps={{ multiple: true, accept: ".jpg, .png, .jpeg, .pdf" }}
              MuiFieldProps={{ label: "Lab Work" }}
            />
            <List dense={true}>{labDocList}</List>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <FormFileField
              name="physicals"
              control={control}
              showError={false}
              appendFiles={true}
              MuiInputProps={{ multiple: true, accept: ".jpg, .png, .jpeg, .pdf" }}
              MuiFieldProps={{ label: "Physicals" }}
            />
            <List dense={true}>{physicalDocList}</List>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <FormFileField
              name="soapNotes"
              control={control}
              showError={false}
              appendFiles={true}
              MuiInputProps={{ multiple: true, accept: ".jpg, .png, .jpeg, .pdf" }}
              MuiFieldProps={{ label: "Soap Notes" }}
            />
            <List dense={true}>{soapNotesDocList}</List>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <FormFileField
              name="otherDocuments"
              control={control}
              showError={false}
              appendFiles={true}
              MuiInputProps={{ multiple: true, accept: ".jpg, .png, .jpeg, .pdf" }}
              MuiFieldProps={{ label: "Other Documents" }}
            />
            <List dense={true}>{otherDocList}</List>
          </Grid>
        </Grid>
      </Box>
    </>
  );
});

export default React.memo(PatientPersonalInfo);
