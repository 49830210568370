import { ExostarApi } from "rtk";
import { API_METHODS, EXOSTAR_END_POINTS } from "rtk/rtkConstant";

const ExostarQuery = ExostarApi.injectEndpoints({
  endpoints: builder => ({
    //P12-2
    getExostarPollStatus: builder.query({
      queryFn: async (_, queryApi, __, baseQuery) => {
        try {
          const userLogin = queryApi.getState().AuthSlice || {};
          if (userLogin) {
            const response = await baseQuery({
              url: EXOSTAR_END_POINTS.GET_POLL_STATUS,
              method: API_METHODS.GET,
            });
            return response;
          } else {
            return {
              error: {
                data: { message: "Unauthenticated user" },
              },
            };
          }
        } catch {}
      },
    }),

    //P12-4
    getExostarManageCredentials: builder.query({
      queryFn: async (_, queryApi, __, baseQuery) => {
        try {
          const userLogin = queryApi.getState().AuthSlice || {};
          if (userLogin) {
            const response = await baseQuery({
              url: EXOSTAR_END_POINTS.MANAGE_CREDENTIALS,
              method: API_METHODS.GET,
            });
            return response;
          } else {
            return {
              error: {
                data: { message: "Unauthenticated user" },
              },
            };
          }
        } catch {}
      },
    }),
 
    //P12-6
    getExostarProviderStatus: builder.query({
      queryFn: async (_, queryApi, __, baseQuery) => {
        try {
          const userLogin = queryApi.getState().AuthSlice || {};
          if (userLogin) {
            const response = await baseQuery({
              url: EXOSTAR_END_POINTS.GET_PROVIDER_STATUS,
              method: API_METHODS.GET,
            });
            return response;
          } else {
            return {
              error: {
                data: { message: "Unauthenticated user" },
              },
            };
          }
        } catch {}
      },
    }),
  }),
});

export const { useLazyGetExostarManageCredentialsQuery, useLazyGetExostarPollStatusQuery, useLazyGetExostarProviderStatusQuery } = ExostarQuery;
