import { createApi } from "@reduxjs/toolkit/query/react";
import { CACHE_TAGS } from "rtk/rtkConstant";
import { baseQuery } from "./BaseApi";

const ProductApi = createApi({
  reducerPath: "ProductApi",
  baseQuery: baseQuery,
  endpoints: () => ({}),
  tagTypes: [CACHE_TAGS.PRODUCT],
});

export default ProductApi;
