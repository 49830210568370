import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import StarIcon from "@mui/icons-material/Star";
import {
  AccordionSummary,
  Box,
  Button,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import { AccordionStyled } from "components/StyledComponent";
import { last, map } from "lodash";
import React, { useCallback, useEffect, useState } from "react";

function ProductFamilyAccordion({ selectedFamily, accordionData = [], onSelect, addToCartAction }) {
  const [expanded, setExpanded] = useState("");

  useEffect(() => {
    if (selectedFamily.length > 1) {
      setExpanded(selectedFamily[1]);
    }
  }, [selectedFamily]);

  const handleAccordionChange = panel => (_, isExpanded) => {
    setExpanded(isExpanded ? panel : "");
  };

  const onSelectCallback = useCallback(
    (...familyHierarchy) =>
      () => {
        onSelect(familyHierarchy, true);
      },
    [onSelect],
  );

  return (
    <>
      <Box>
        {map(accordionData, (familyObj, index) => (
          <AccordionStyled
            key={`${familyObj?.family}_${index}`}
            expanded={expanded === familyObj?.family}
            onChange={handleAccordionChange(familyObj?.family)}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ backgroundColor: "#FBFDFE", px: 1 }}>
              <Stack alignItems="center" direction="row" gap={1} sx={{ color: "#2c4b76" }}>
                <i className="fas fa-capsules" />
                <Typography fontWeight={"medium"} color={"#2c4b76"}>
                  {familyObj.family}
                </Typography>
              </Stack>
            </AccordionSummary>
            {map(familyObj.categories, (category, categoryIndex) => (
              <List dense disablePadding key={`${category}_${index}_${categoryIndex}`}>
                <ListItem disablePadding divider>
                  <ListItemButton
                    selected={expanded === familyObj?.family && last(selectedFamily) === category}
                    onClick={onSelectCallback(familyObj.family, category)}>
                    <ListItemIcon sx={{ minWidth: "25px" }}>
                      <StarIcon />
                    </ListItemIcon>
                    <ListItemText
                      primaryTypographyProps={{
                        variant: "body2",
                        color: "grey.700",
                        fontSize: 11,
                      }}
                      primary={category}
                    />
                  </ListItemButton>
                </ListItem>
              </List>
            ))}
          </AccordionStyled>
        ))}
      </Box>
      <Box component={"div"} mt={1} textAlign={"center"}>
        <Typography variant="subtitle2">Cannot find what you're looking for?</Typography>
        <Button variant="contained" color="warning" fullWidth onClick={addToCartAction()}>
          Rx Pad
        </Button>
      </Box>
    </>
  );
}

export default React.memo(ProductFamilyAccordion);
