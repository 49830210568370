import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import DoneIcon from "@mui/icons-material/Done";
import VaccinesIcon from "@mui/icons-material/Vaccines";
import {
  Box,
  Button,
  Divider,
  Drawer,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import { DrawerHeaderStyled } from "components/StyledComponent";
import { DialogContext } from "context/Dialog/DialogContext";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { map } from "lodash";
import React, { forwardRef, useCallback, useContext, useImperativeHandle, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { ProductCartAction } from "rtk";
import { cartProductSelector } from "selectors";

dayjs.extend(relativeTime);

function CartDrawer({ getCartProduct, drawerVariant = "temporary", hideSelect = false }, ref) {
  const dispatch = useDispatch();
  const [openCartDrawer, setOpenCartDrawer] = useState(() => drawerVariant === "persistent");
  const productsSummary = useSelector(cartProductSelector);
  const { pathname } = useLocation();
  const history = useHistory();
  const { showDialog, closeDialog } = useContext(DialogContext);

  const isPrescriptionJourney = useMemo(() => {
    const pathArray = pathname?.split("/");
    return (
      pathArray.includes("new-prescription") ||
      pathArray.includes("product-search") ||
      pathArray.includes("edit-prescription") ||
      pathArray.includes("new-prescription-flow") ||
      pathArray.includes("edit-prescription-flow")
    );
  }, [pathname]);

  const isEmpty = useMemo(() => !productsSummary.length, [productsSummary.length]);
  const toggleCartDrawer = () => {
    setOpenCartDrawer(prev => !prev);
  };

  const getDrawerState = useCallback(() => openCartDrawer, [openCartDrawer]);

  const navigatePrescription = useCallback(
    productCart => {
      const clinicId = productCart?.clinicId ?? "";
      history.push({
        pathname: "/new-prescription-flow",
        state: {
          clinicId: clinicId,
          selectedPatients: productCart?.productSummary?.patient,
          selectedProducts: productCart?.productSummary?.product,
        },
      });
      toggleCartDrawer();
    },
    [history],
  );

  const productSelection = useCallback(
    productCart => () => {
      closeDialog();
      if (getCartProduct) {
        getCartProduct?.(productCart?.productSummary?.product, productCart?.productSummary?.patient);
      } else {
        navigatePrescription(productCart);
      }
      dispatch(ProductCartAction.removeProductSummary(productCart?.id));
    },
    [closeDialog, dispatch, getCartProduct, navigatePrescription],
  );

  const selectionDialog = useCallback(
    productCart => () => {
      if (productCart?.id) {
        showDialog({
          question: "Are you sure you want to select this Product Summary?",
          actionText: "Yes",
          cancelText: "No",
          closeAble: false,
          onClickYes: productSelection(productCart),
          onClickNo: closeDialog,
        });
      }
    },
    [closeDialog, productSelection, showDialog],
  );

  useImperativeHandle(ref, () => ({
    toggleCartDrawer,
    getDrawerState,
  }));

  const removeProductSummary = useCallback(
    summaryId => () => {
      closeDialog();
      dispatch(ProductCartAction.removeProductSummary(summaryId));
    },
    [dispatch],
  );

  const deletionModal = useCallback(
    summaryId => () => {
      if (summaryId) {
        showDialog({
          question: "Are you sure you want to delete this Product Summary?",
          actionText: "Yes",
          cancelText: "No",
          closeAble: false,
          onClickYes: removeProductSummary(summaryId),
          onClickNo: closeDialog,
        });
      }
    },
    [closeDialog, removeProductSummary, showDialog],
  );

  const drawerPaperStyle = useMemo(() => {
    if (isPrescriptionJourney) {
      return { minWidth: "16%", width: "16%", height: "83%", marginTop: 8, zIndex: 1 };
    }
    return {};
  }, [isPrescriptionJourney]);

  const emptyView = useMemo(() => {
    if (isEmpty) {
      return (
        <Box sx={{ display: "flex", flexDirection: "column", flex: 1, justifyContent: "center", alignItems: "center" }}>
          <AddShoppingCartIcon style={{ fontSize: 40 }} color="secondary" />
          <Typography textAlign={"center"} color="secondary" variant="body1" fontWeight={"500"} mt={2}>
            No Product Saved
          </Typography>
        </Box>
      );
    }
  }, [isEmpty]);

  const closeButtonView = useMemo(() => {
    if (!isPrescriptionJourney) {
      return (
        <IconButton
          aria-label="close"
          onClick={toggleCartDrawer}
          sx={{
            color: theme => theme.palette.grey[600],
          }}
          size="large">
          <CloseIcon style={{ fontSize: 28 }} />
        </IconButton>
      );
    }
  }, [isPrescriptionJourney]);

  const selectButtonView = useCallback(
    productCart => {
      if (!hideSelect) {
        return (
          <Button
            size="small"
            variant="contained"
            color="secondary"
            startIcon={<DoneIcon />}
            onClick={selectionDialog(productCart)}>
            Select
          </Button>
        );
      }
    },
    [hideSelect, selectionDialog],
  );

  const productSummaryView = useCallback(cartProduct => {
    return (
      <Box sx={{ backgroundColor: "grey.100", borderRadius: 5, p: 1, my: 1 }}>
        {cartProduct?.productSummary?.patient?.map(patient => {
          return cartProduct?.productSummary?.product?.map(product => (
            <React.Fragment key={product?.productId}>
              <ListItem sx={{ p: 0.5 }}>
                <ListItemIcon sx={{ minWidth: "30px" }}>
                  <VaccinesIcon sx={{ fontSize: 20 }} color="info" />
                </ListItemIcon>
                <ListItemText primary={product?.name} primaryTypographyProps={{ fontSize: 12 }} />
              </ListItem>
              <Stack justifyContent={"flex-end"} alignItems="center" direction="row" gap={0.5}>
                <AccountCircleIcon color="secondary" sx={{ fontSize: 20 }} />
                <Typography variant="body2" color={"#6a7187"} fontSize={12}>
                  {(patient?.firstName ?? "-") + " " + (patient?.lastName ?? "")}
                </Typography>
              </Stack>
            </React.Fragment>
          ));
        })}
      </Box>
    );
  }, []);

  const cartProduct = useMemo(() => {
    if (productsSummary.length) {
      return map(productsSummary, (productCart, index) => {
        const productView = productSummaryView(productCart);
        return (
          <React.Fragment key={productCart?.id}>
            {index > 0 && <Divider variant="fullWidth" sx={{ my: 1 }} />}
            <Box m={1}>
              <Typography color={"primary"} fontWeight={"600"} variant="subtitle2" display={"inline"} fontSize={14}>
                Saved:
                <Typography color={"#6a7187"} variant="body2" display={"inline"} fontSize={12} ml={1}>
                  {dayjs(productCart?.createdAt)?.fromNow()}
                </Typography>
              </Typography>
              {productView}
              <Stack justifyContent={"flex-end"} alignItems="center" direction="row" gap={0.5}>
                {selectButtonView(productCart)}
                <Button
                  size="small"
                  variant={"contained"}
                  color="error"
                  startIcon={<DeleteIcon />}
                  onClick={deletionModal(productCart?.id)}>
                  Delete
                </Button>
              </Stack>
            </Box>
          </React.Fragment>
        );
      });
    }
  }, [productsSummary, productSummaryView, selectButtonView, deletionModal]);

  return (
    <Drawer
      id="PRODUCT-SUMMARY"
      anchor={"right"}
      open={openCartDrawer}
      variant={drawerVariant}
      onClose={toggleCartDrawer}
      PaperProps={{
        sx: [{ minWidth: "25%", p: 1 }, drawerPaperStyle],
      }}>
      <DrawerHeaderStyled>
        <Typography variant="h5" color={"#50a5f1"} fontWeight={"bold"}>
          Product Summary
        </Typography>
        {closeButtonView}
      </DrawerHeaderStyled>
      <Divider sx={{ mb: 1 }} />
      {emptyView}
      {cartProduct}
    </Drawer>
  );
}

export default React.memo(forwardRef(CartDrawer));
