import React, { useEffect, useState, useMemo } from "react";
import { Modal, Row, Input, Label, Spinner, Col, Alert } from "reactstrap";

import Lottie from "components/lottie";
import ExostarRegisterModal from "./ExostarRegisterModal";
const ExostarAuthToggle = (props) => {
  const [showAuthModal, setShowAuthModal] = useState(false);
  const [authActionType, setAuthActionType] = useState(false);

  const closeAuthModal = (mess = "") => {
    setShowAuthModal(false);
  };

  return (
    <>
      <div>
        <label className="switch mb-0">
          <input
          checked={false}
            type="checkbox"
            id={"enable-2fa"}
            onChange={(e) => {
              setShowAuthModal(true);
            }}
          />
          <span className="slider round"></span>
        </label>
        <ExostarRegisterModal showModal={showAuthModal}  closeModal={closeAuthModal} />
      </div>
    </>
  );
};

export default ExostarAuthToggle;
