/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Divider, Grid, Typography } from "@mui/material";
import { FormOutlineField, FromAutoComplete } from "components";
import { StaffApiStateContext, StaffStateContext } from "context";
import { find, map } from "lodash";
import React, { forwardRef, useCallback, useContext, useEffect, useImperativeHandle, useMemo, useRef } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { userRoleSelector } from "selectors";
import { COUNTRIES, USA_STATES } from "utils";

const StaffAddresses = forwardRef(({ formId, formData, staffRole, clinicLocationDea = [] }, parentRef) => {
  const formRef = useRef(null);
  const { isStaffEdit } = useContext(StaffStateContext);
  const { staffData } = useContext(StaffApiStateContext);
  const userRole = useSelector(userRoleSelector);
  const clinicSide = useMemo(() => userRole?.toLowerCase().includes("clinic"), [userRole]);
  const showPrescriberData = useMemo(
    () => staffRole === "ClinicProvider" || staffData?.isSuperUserSameAsProvider,
    [staffData?.isSuperUserSameAsProvider, staffRole],
  );

  const { control, handleSubmit, getValues, setValue, trigger } = useForm({
    defaultValues: {
      country: "United States",
      deaInfo: [],
    },
  });

  const { fields, replace } = useFieldArray({
    control,
    name: "deaInfo",
  });

  const submitAction = useCallback(
    (data, progressBarIndex = null, allowNavigation = true) => {
      formData?.({ info: data }, formId, progressBarIndex, allowNavigation);
    },
    [formData, formId],
  );

  useEffect(() => {
    if (isStaffEdit && staffData?.id) {
      const formFields = getValues();
      for (const field in formFields) {
        let fieldValue = staffData?.[field] ?? "";
        if (field === "deaInfo") {
          fieldValue = map(clinicLocationDea, dea => {
            const findDea = find(fieldValue, { _id: dea._id });
            return {
              ...findDea,
              ...dea,
            };
          });
        }
        setValue(field, fieldValue);
      }
      submitAction(getValues(), null, false);
    } else if (clinicLocationDea.length > 0) {
      replace(
        map(clinicLocationDea, dea => ({
          ...dea,
          deaAddressInfo: {
            addressLine1: "",
            addressLine2: "",
            city: "",
            state: "",
            zipcode: "",
          },
        })),
      );
    }
  }, [clinicLocationDea, isStaffEdit, staffData]);

  const canNavigate = useCallback(
    progressBarIndex => {
      handleSubmit(data => submitAction(data, progressBarIndex))();
    },
    [handleSubmit, submitAction],
  );

  const checkValidation = useCallback(async () => {
    const formValidated = await trigger();
    return formValidated;
  }, []);

  const requestFormSubmit = useCallback(() => formRef.current?.requestSubmit?.(), []);

  useImperativeHandle(parentRef, () => ({
    submitForm: requestFormSubmit,
    canNavigate: canNavigate,
    checkFilledForm: checkValidation,
  }));

  return (
    <Box id={formId} ref={formRef} component={"form"} onSubmit={handleSubmit(data => submitAction(data, null))}>
      <Grid container marginY={2} columnSpacing={3} rowSpacing={1}>
        <Grid item xs={12} sm={6} md={4}>
          <FormOutlineField
            name="email"
            control={control}
            rules={{
              required: "Email address is required.",
              pattern: { value: /\S+@\S+\.\S+/, message: "Invalid email address" },
            }}
            MuiFieldProps={{ label: "Email address", type: "email", placeholder: "abc@abc.com" }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <FormOutlineField
            name="phoneNumber"
            control={control}
            rules={{
              required: clinicSide ? "Mobile Phone is required." : false,
              pattern: { value: /^(\d{10})?$/, message: "Invalid Mobile Phone" },
            }}
            MuiInputProps={{ format: "(###) ###-####" }}
            MuiFieldProps={{ label: "Mobile Phone", type: "tel", placeholder: "(123) 456-7890", isMasked: true }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <FormOutlineField
            name="mobileNumber"
            control={control}
            rules={{ pattern: { value: /^(\d{10})?$/, message: "Invalid Phone" } }}
            MuiInputProps={{ format: "(###) ###-####" }}
            MuiFieldProps={{ label: "Other Phone", type: "tel", placeholder: "(123) 456-7890", isMasked: true }}
          />
        </Grid>
        {/* Address */}
        <Grid item xs={12} sm={6}>
          <FormOutlineField
            name={"addressLine1"}
            control={control}
            rules={{ required: clinicSide ? "Address is required." : false }}
            MuiFieldProps={{ label: "Address line 1", multiline: true, rows: 2 }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormOutlineField
            name={"addressLine2"}
            control={control}
            MuiFieldProps={{ label: "Address line 2", multiline: true, rows: 2 }}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={4} lg={3}>
          <FromAutoComplete
            control={control}
            name={"country"}
            rules={{ required: clinicSide ? "Country is required." : false }}
            MuiFieldProps={{ label: "Country", optionArray: COUNTRIES }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <FormOutlineField
            name={"city"}
            control={control}
            rules={{ required: clinicSide ? "City is required." : false }}
            MuiFieldProps={{ label: "City" }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <FromAutoComplete
            control={control}
            name={"state"}
            rules={{ required: clinicSide ? "State is required." : false }}
            MuiFieldProps={{ label: "State", optionArray: USA_STATES }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <FormOutlineField
            name={"zipcode"}
            control={control}
            rules={{
              required: clinicSide ? "Zip Code is required." : false,
              pattern: { value: /^\d{5}(-\d{4})?$/, message: "Invalid ZipCode" },
            }}
            MuiFieldProps={{ label: "Zip Code", placeholder: "12345-1234" }}
          />
        </Grid>
      </Grid>
      {showPrescriberData && fields.length > 0 && (
        <Grid container marginY={2} columnSpacing={3} rowSpacing={1}>
          <Grid item xs={12} marginBottom={3}>
            <Typography variant="h5" fontWeight={"500"}>
              DEA Address
            </Typography>
          </Grid>
          {/* Clinic Location wise Dea Information  */}
          {fields.map((field, index) => (
            <React.Fragment key={field.id}>
              <Divider className="my-3">{`Clinic Location: ${field.clinicLocationName}`}</Divider>
              <Grid item xs={12} sm={6}>
                <FormOutlineField
                  name={`deaInfo.${index}.deaAddressInfo.addressLine1`}
                  control={control}
                  MuiFieldProps={{ label: "Address line 1", multiline: true, rows: 2 }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormOutlineField
                  name={`deaInfo.${index}.deaAddressInfo.addressLine2`}
                  control={control}
                  MuiFieldProps={{ label: "Address line 2", multiline: true, rows: 2 }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FormOutlineField
                  name={`deaInfo.${index}.deaAddressInfo.city`}
                  control={control}
                  MuiFieldProps={{ label: "City" }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FromAutoComplete
                  control={control}
                  name={`deaInfo.${index}.deaAddressInfo.state`}
                  MuiFieldProps={{ label: "State", optionArray: USA_STATES }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FormOutlineField
                  name={`deaInfo.${index}.deaAddressInfo.zipcode`}
                  control={control}
                  rules={{
                    pattern: { value: /^\d{5}(-\d{4})?$/, message: "Invalid ZipCode" },
                  }}
                  MuiFieldProps={{ label: "Zip Code", placeholder: "12345-1234" }}
                />
              </Grid>
            </React.Fragment>
          ))}
        </Grid>
      )}
    </Box>
  );
});

export default React.memo(StaffAddresses);
