import { createContext } from "react";

const PrescriptionApiStateContext = createContext({
  filterClinicId: "",
  clinicData: {},
  isNet30: false,
  prescriptionData: null,
});
const ProductCartDialogContext = createContext({
  showProductCartDialog: ({ id = "", ...rest }, callback) => null,
  closeProductCartDialog: () => null,
});

const PrescriptionStateContext = createContext({
  showSpinner: false,
  clinicDataFetching: false,
  isPrescriptionEdit: false,
  preSelectedPatients: [],
  preSelectedProducts: [],
  mutateError: "",
});
const PrescriptionActionContext = createContext({
  addProductToCartAction: () => () => null,
  addToSidebarSummary: () => null,
  handleCustomProductError: () => null,
  removeSelectedProduct: () => null,
  handleSelectedProduct: (product, replaceProducts = false) => null,
  rateMutate: () => null,
  setCustomShippingRate: () => null,
  setShippingMethod: () => null,
  toggleRushOrder: () => null,
  addUsedCredit: () => null,
  subTotalCalculation: () => null,
});

const PrescriptionFormSateContext = createContext({
  formRef: { current: [] },
  activeStep: 0,
  selectedProducts: [],
  prescriptionSideCartRef: null,
  completedStep: { 0: true },
  patientFormData: { current: null },
  providerFormData: { current: null },
  productFormData: { current: null },
  selectedClinicLocation: {},
  shippingRate: {},
  shippingRateError: "",
  shippingRateLoading: false,
  shippingMethod: "",
  rushOrderCheck: false,
  creditRequestAmount: 0,
  prescriptionSubTotal: 0,
});

const PrescriptionFormActionContext = createContext({
  goBack: () => null,
  goNext: () => null,
  formData: () => null,
  reviewButton: () => null,
  progressBarAction: () => null,
  getSingleFormData: () => null,
  getCartProduct: () => null,
});

export {
  ProductCartDialogContext,
  PrescriptionActionContext,
  PrescriptionStateContext,
  PrescriptionApiStateContext,
  PrescriptionFormSateContext,
  PrescriptionFormActionContext,
};
