import { Grid } from "@mui/material";
import { CartDrawer } from "components";
import NavBar from "components/NavBar";
import { DialogContext } from "context";
import { isEqual, map } from "lodash";
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import MetaTags from "react-meta-tags";
import { useDispatch } from "react-redux";
import { Link, useLocation, useParams } from "react-router-dom";
import { Alert, Card, CardBody, Col, Row } from "reactstrap";
import AddBillingInfo from "screens/common/add-billing-info";
import AddShippingInfo from "screens/common/add-shipping-info";
import CartContainer from "screens/common/cartContainer";
import SelectProducts from "screens/common/select-products";
import VerifyProviderAndLocation from "screens/common/verify-provider-and-location";
import { matchAddressDea } from "utils";
import { RUSH_ORDER_INFO } from "utils/StatusConstant";
import Lottie from "../../../components/lottie";
import Spinner from "../../../components/spinner";
import { decrypt, encrypt } from "../../../cryptofunc";
import {
  ProductCartAction,
  useEditClinicPrescriptionMutation,
  useLazyClinicDetailQuery,
  useLazyGetClinicLocationAddressQuery,
  useLazyGetClinicPrescriptionDetailQuery,
  useLazyGetShippingRateQuery,
} from "../../../rtk";
import NavigationSteps from "./NavigationSteps";
import PrescriptionPatient from "./PrescriptionPatient";

function EditPrescription() {
  const savedRxCalled = useRef(false);
  const cartRef = useRef();
  const dispatch = useDispatch();
  const { showDialog, closeDialog } = useContext(DialogContext);
  const [pageStep, setPageStep] = useState(1);
  const [creditUsed, setCreditUsed] = useState(false);
  const [renderView, setRenderView] = useState(false);
  const [shipToClinic, setShipToClinic] = useState(true);
  const [billTo, setBillTo] = useState("");
  const [creditCard, setCreditCard] = useState();
  const [isNewCardUsed, setIsNewCardUsed] = useState(false);
  const [creditApplied, setCreditApplied] = useState(0);
  const [creditRequested, setCreditRequested] = useState(0);
  const [signatureRequired, setSignatureRequired] = useState();
  const [customError, setCustomError] = useState("");
  const [rushOrderCost, setRushOrderCost] = useState(0);
  const [rushOrder, setRushOrder] = useState(false);
  const [rushOrderMethod, setRushOrderMethod] = useState();
  const [isNewAddressUsed, setIsNewAddressUsed] = useState(false);
  const [defaultShippingInfo, setDefaultShippingInfo] = useState();
  const [clinicLocationId, setClinicLocationId] = useState("");
  const [selectedPatient, setSelectedPatient] = useState([]);
  const [shippingAddress, setShippingAddress] = useState({});
  const [shippingMethod, setShippingMethod] = useState("");
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedProductToEdit, setSelectedProductToEdit] = useState("");
  const [selectedProvider, setSelectedProvider] = useState({});
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [deleteCartCredit, setDeleteCartCredit] = useState(false);
  const [subtotal, setSubtotal] = useState(0);
  const reorder = window?.location?.pathname.includes("reorder");
  const {
    state: {
      isReview = false,
      prescriptionStatus = "",
      paramClinicId = "",
      fromRxWorkFlow = false,
      side = "",
      isRefillPrescription = "",
    } = {},
  } = useLocation();
  const { id: prescriptionId = "" } = useParams();
  const [fetchList, { isFetching: prescriptionFetching, error: prescriptionError, data: prescriptionData }] =
    useLazyGetClinicPrescriptionDetailQuery();
  const [editMutate, { isLoading: editLoading, data: editData, isSuccess: editSuccess, error: editError }] =
    useEditClinicPrescriptionMutation();
  const [fetchClinicData, { data: clinicData, error: clinicError }] = useLazyClinicDetailQuery();
  const [rateMutate, { data: shippingRateData, isLoading: shippingRateLoading, error: shippingErrorMessage }] =
    useLazyGetShippingRateQuery();
  const [clinicAddressRefetch, { data: clinicAddressData, error: addressError, isFetching: addressFetching }] =
    useLazyGetClinicLocationAddressQuery();

  const selectedClinicLocationId = useMemo(
    () => clinicLocationId || prescriptionData?.clinicLocationId,
    [clinicLocationId, prescriptionData?.clinicLocationId],
  );

  const selectedClinicLocationProviderId = useMemo(
    () => selectedProvider?.id || prescriptionData?.providerId || "",
    [prescriptionData?.providerId, selectedProvider],
  );
  const credit = useMemo(
    () => clinicData?.clinicLocations.filter(v => v.id === selectedClinicLocationId)[0]?.credit,
    [clinicData?.clinicLocations, selectedClinicLocationId],
  );
  const decryptedBillingInfo = useMemo(
    () => decrypt(prescriptionData?.encryptedBillingInfo),
    [prescriptionData?.encryptedBillingInfo],
  );

  const selectedPatientIds = useMemo(() => map(selectedPatient, "id"), [selectedPatient]);
  const isNet30 = useMemo(() => clinicData?.paymentTermDays > 0, [clinicData?.paymentTermDays]);
  const emptyCart = useMemo(() => selectedProducts.length < 1, [selectedProducts.length]);
  const isBulkOrder = useMemo(() => selectedPatient.length > 1, [selectedPatient.length]);
  const renderComponents = useMemo(() => renderView && !prescriptionFetching, [prescriptionFetching, renderView]);
  const disableNextAction = useMemo(() => {
    if (selectedPatient?.length < 1) {
      return true;
    } else if (selectedProducts?.length < 1) {
      return true;
    } else {
      return false;
    }
  }, [selectedPatient.length, selectedProducts.length]);

  const errorMessage = useMemo(
    () =>
      prescriptionError?.data?.message ||
      editError?.data?.message ||
      clinicError?.data?.message ||
      shippingRateData?.data?.message ||
      shippingErrorMessage?.data?.message ||
      customError,
    [
      clinicError?.data?.message,
      customError,
      editError?.data?.message,
      prescriptionError?.data?.message,
      shippingRateData?.data?.message,
      shippingErrorMessage?.data?.message,
    ],
  );
  const showLoading = useMemo(
    () => prescriptionFetching || editLoading || shippingRateLoading || addressFetching,
    [prescriptionFetching, editLoading, shippingRateLoading, addressFetching],
  );
  useEffect(() => {
    fetchList({ prescriptionId });
  }, []);

  useEffect(() => {
    if (prescriptionData?.clinicId) fetchClinicData({ clinicId: prescriptionData?.clinicId });
  }, [prescriptionData?.clinicId]);

  useEffect(() => {
    if (pageStep === 3) clinicAddressRefetch({ locationId: selectedClinicLocationId }, false);
  }, [pageStep]);

  // useEffect(() => {
  //   if (editSuccess) {
  //     if (reorder) {
  //       history.push({
  //         pathname: "/reorder-prescription/" + prescriptionData?.id,
  //         state: {
  //           shipTo: shipToClinic,
  //         },
  //       });
  //     } else if (editData?.reviewCheck) {
  //       history.push({
  //         pathname: paramClinicId
  //           ? "/review-prescription/" + paramClinicId + "/" + prescriptionData?.id
  //           : "/review-prescription/" + prescriptionData?.id,
  //         state: {
  //           shipTo: shipToClinic,
  //         },
  //       });
  //     }
  //   }
  // }, [editData?.reviewCheck, editSuccess, history, prescriptionData?.id, shipToClinic, paramClinicId]);

  useEffect(() => {
    if (prescriptionData?.id) {
      const decryptedBillingInfo = decrypt(prescriptionData?.encryptedBillingInfo);
      setSelectedPatient(prescriptionData?.patients);
      setSelectedProducts(prescriptionData?.products.concat(prescriptionData?.rxPadProducts));
      setShippingAddress(prescriptionData?.shippingInfo.shippingAddress);
      setShippingMethod(prescriptionData?.shippingInfo.shippingMethod);
      setCreditApplied(prescriptionData?.creditRequested);
      setCreditRequested(prescriptionData?.creditRequested);
      setBillTo(decryptedBillingInfo.creditCardOwner);
      setCreditCard(decryptedBillingInfo.creditCard);
      setIsNewAddressUsed(prescriptionData?.shippingInfo.isNewAddressUsed);
      setIsNewCardUsed(decryptedBillingInfo.isNewCardUsed);
      setRushOrder(prescriptionData?.shippingInfo.isRushOrder);
      setShipToClinic(
        prescriptionData?.patientIds.length > 1
          ? true
          : prescriptionData?.shippingInfo?.shipTo === "clinic"
          ? true
          : false,
      );
    }
    setRenderView(true);
  }, [
    prescriptionData?.creditRequested,
    prescriptionData?.encryptedBillingInfo,
    prescriptionData?.id,
    prescriptionData?.patients,
    prescriptionData?.products,
    prescriptionData?.rxPadProducts,
    prescriptionData?.shippingInfo.isNewAddressUsed,
    prescriptionData?.shippingInfo.isRushOrder,
    prescriptionData?.shippingInfo?.shipTo,
    prescriptionData?.shippingInfo.shippingAddress,
    prescriptionData?.shippingInfo.shippingMethod,
  ]);

  const editProduct = useCallback(product => {
    setShowEditModal(true);
    setSelectedProductToEdit(product);
  }, []);

  const removeProduct = useCallback(product => {
    setSelectedProducts(prev =>
      prev.filter((e, i) => {
        return (
          e.productId?.toString() !== product?.productId?.toString() &&
          e.name?.toString() !== product?.productName?.toString()
        );
      }),
    );
  }, []);

  const goBack = useCallback(e => {
    e.preventDefault();
    setPageStep(prev => {
      if (prev === 3) {
        dispatch(ProductCartAction.removeLastProductSummary());
      }
      return prev - 1;
    });
  }, []);

  const getPrescriptionObject = useCallback(() => {
    var serialize = require("form-serialize");
    var form = document.getElementById("newPrescriptionForm");
    var obj = serialize(form, {
      hash: true,
      empty: true,
    });

    var rxPadProd = [];
    var storedProducts = [];

    selectedProducts.forEach(prod => {
      if (prod?.productType === "custom" || prod?.rxStatus?.includes("CUSTOM")) {
        rxPadProd.push({
          name: prod.name,
          quantity: prod.quantity,
          family: prod.family,
          strength: prod.strength,
          refills: prod.refills,
          sig: prod.sig,
          reasonForCompoundedMedication: prod.reasonForCompoundedMedication,
        });
      } else {
        storedProducts.push(prod);
      }
    });

    var prescription = {
      prescriptionId: prescriptionData?.id,
      clinicId: prescriptionData?.clinicId,
      clinicLocationId: obj.locationId,
      providerId: obj.providerId,
      patientIds: selectedPatientIds,
      products: storedProducts,
      rxPadProducts: rxPadProd,
      //Credit
      creditRequested: creditRequested,
      shippingInfo: {
        //SHIP TO
        shipTo: isBulkOrder ? "clinic" : shipToClinic ? "clinic" : "patient",
        isNewAddressUsed: isNewAddressUsed,
        shippingAddress: shippingAddress,
        shippingMethod: shippingMethod,
        isPoBox: obj.POBox === "on" ? true : false,
        orderNotes: obj.orderNotes,
        isSignatureRequired: obj.signatureRequired === "on" ? true : false,
        isRushOrder: obj.isRushOrder === "on" ? true : false,
        rushOrderMethod: rushOrder ? RUSH_ORDER_INFO.name : "",
        rushOrderCost: rushOrderCost,
      },
      encryptedBillingInfo: encrypt({
        creditCardOwner: billTo,
        isNewCardUsed: isNewCardUsed,
        creditCard: creditCard,
      }),
      signatureFileUrl: prescriptionData?.signatureFileUrl,
    };

    return prescription;
  }, [
    billTo,
    creditCard,
    creditRequested,
    isBulkOrder,
    isNewAddressUsed,
    isNewCardUsed,
    prescriptionData?.clinicId,
    prescriptionData?.id,
    prescriptionData?.signatureFileUrl,
    rushOrderCost,
    selectedPatientIds,
    selectedProducts,
    shipToClinic,
    shippingAddress,
    shippingMethod,
  ]);

  const savePrescription = (reviewCheck = false) => {
    const newObj = {
      prescriptionStatus,
      isReview,
      adminClinicSide: paramClinicId,
      reviewCheck,
      side: side,
      ...getPrescriptionObject(),
    };
    if (!isEqual(savedRxCalled.current, newObj)) {
      savedRxCalled.current = {
        prescriptionStatus,
        isReview,
        adminClinicSide: paramClinicId,
        reviewCheck,
        side: side,
        ...getPrescriptionObject(),
      };
      editMutate({
        prescriptionStatus,
        isReview,
        adminClinicSide: paramClinicId,
        reviewCheck,
        side: side,
        ...getPrescriptionObject(),
      });
    }
  };

  const updateShippingCost = useCallback(
    (address = "", method = "") => {
      selectedProducts?.forEach(prod => {
        if (prod.unitPrice !== 0) {
          if ((address || shippingAddress) && (method || shippingMethod)) {
            var body = {
              recipientAddress: {
                streetLines: {
                  addressLine1: address ? address.addressLine1 : shippingAddress.addressLine1,
                  addressLine2: address ? address.addressLine2 : shippingAddress.addressLine2,
                },
                city: address ? address.city : shippingAddress.city,
                state: address ? address.state : shippingAddress.state,
                postalCode: address ? address.zipcode : shippingAddress.zipcode,
                residential: method === "GROUND_HOME_DELIVERY" ? true : false,
              },
              shippingMethod: method ? method : shippingMethod,
            };

            if (
              localStorage.getItem("rxJourney") === "clinicEdit" &&
              body?.recipientAddress?.streetLines?.addressLine1 !== "" &&
              localStorage.getItem("shipAddress1")?.toLowerCase() ===
                body?.recipientAddress?.streetLines?.addressLine1?.toLowerCase() &&
              localStorage.getItem("shipMethod")?.toLowerCase() === body?.shippingMethod?.toLowerCase()
            ) {
            } else {
              if (body?.recipientAddress?.streetLines?.addressLine1 !== "") {
                localStorage.setItem("rxJourney", "clinicEdit");
                localStorage.setItem("shipAddress1", body?.recipientAddress?.streetLines?.addressLine1);
                localStorage.setItem("shipMethod", body.shippingMethod);
                rateMutate(body);
              } else {
                setCustomError("Shipping Address Line 1 is invalid");
              }
            }
          }
        }
      });
    },
    [rateMutate, selectedProducts, shippingAddress, shippingMethod],
  );

  const handleValidSubmit = e => {
    e.preventDefault();
    if (pageStep === 2) {
      dispatch(
        ProductCartAction.addNewProductSummary({
          clinicId: paramClinicId,
          clinicLocationId: selectedClinicLocationId,
          product: selectedProducts,
          patient: selectedPatient,
        }),
      );
    }
    setPageStep(prev => {
      if (prev === 1) {
        updateShippingCost(shippingAddress, shippingMethod);
      } else if (prev === 5) {
        savePrescription(true);
      }
      if (prev < 5) {
        if (prev === 3) {
          const isControlledSubstance = selectedProducts.some(product => product.controlledSubstance === "1");
          const selectedProviderLocationSpecificDeaInfo = selectedProvider?.deaInfo?.filter(
            a => a.clinicLocationId === clinicLocationId,
          )[0];
          const hasProviderDetail =
            selectedProviderLocationSpecificDeaInfo?.dea &&
            selectedProviderLocationSpecificDeaInfo?.deaLicenseUrl &&
            selectedProviderLocationSpecificDeaInfo?.providerDeaLicenseExpirationDate;
          if (isControlledSubstance && !hasProviderDetail) {
            showDialog({
              question:
                "Provider doesn't have DEA credentials on file. Please edit provider profile or select alternative provider.",
              cancelText: "OK",
              onClickNo: closeDialog,
            });
            return prev;
          } else {
            if (clinicAddressData) {
              var clinicLocationAddress = {
                addressLine1: clinicAddressData.addressLine1,
                addressLine2: clinicAddressData.addressLine2,
                city: clinicAddressData.city,
                state: clinicAddressData.state,
                zipcode: clinicAddressData.zipcode,
              };
              if (!isBulkOrder) {
                const isAddressMatch = matchAddressDea(
                  selectedProvider,
                  clinicLocationAddress,
                  selectedClinicLocationId,
                );
                if (!isAddressMatch && isControlledSubstance) {
                  showDialog({
                    question:
                      "This prescription order must be shipped directly to the patient due to the selected provider's DEA information.",
                    actionText: "OK",
                    onClickNo: closeDialog,
                    onClickYes: () => {
                      closeDialog();
                      setPageStep(4);
                    },
                  });
                  return prev;
                }
              }
            }
          }
        }
        if (prev === 4) {
          const isControlledSubstance = selectedProducts.some(product => product.controlledSubstance === "1");
          //Address for DEA controlled Substance
          const isAddressMatch = matchAddressDea(selectedProvider, shippingAddress, clinicLocationId);
          if (isControlledSubstance && !isAddressMatch && shipToClinic) {
            if (isBulkOrder) {
              showDialog({
                question: "DEA Address is not matching with the selected Clinic Address.",
                cancelText: "OK",
                onClickNo: closeDialog,
              });
              return prev;
            } else {
              showDialog({
                question: "Update your provider profile to allow clinic shipments for controlled substances.",
                cancelText: "OK",
                onClickNo: closeDialog,
              });
              return prev;
            }
          }
        }
        return prev + 1;
      } else return prev;
    });
  };

  const setCreditForLocation = useCallback(locId => {
    setClinicLocationId(locId);
  }, []);

  const setProvider = useCallback(providerObj => {
    setSelectedProvider(providerObj);
  }, []);

  const onSuccessView = useMemo(() => {
    return (
      <div className="m-sm-5 m-4 p-sm-5 p-4 text-center">
        <Lottie name="success-check" />
        <p className="mb-5">
          {editSuccess
            ? "Your prescription has been saved successfully!"
            : "Your order for a new prescription has been placed. You will be notified once it gets approved!"}
        </p>

        {!isReview && !fromRxWorkFlow ? (
          <Link to={{ pathname: "/pending-prescriptions", state: "2" }} className="pinkButton">
            {editSuccess ? "Saved Prescriptions" : "Pending Prescriptions"}
          </Link>
        ) : fromRxWorkFlow ? (
          <Link to={{ pathname: "/prescription-history", state: "2" }} className="pinkButton">
            {"RX Workflow"}
          </Link>
        ) : (
          <Link
            to={{
              pathname: reorder
                ? `/reorder-prescription/${editData?.prescriptionId}`
                : paramClinicId
                ? "/review-prescription/" + paramClinicId + "/" + prescriptionData?.id
                : `/review-prescription/${prescriptionData?.id}`,
              state: { isReview: true, shipTo: shipToClinic, flow: "EDIT", side: side, isRefillPrescription },
            }}
            className="pinkButton">
            {"Review Prescriptions"}
          </Link>
        )}
      </div>
    );
  }, [editSuccess, isReview, prescriptionData?.id, shipToClinic]);

  const bottomView = useMemo(() => {
    const buttonArray = [];
    if (pageStep > 1) {
      buttonArray.push(
        <button
          key="BackBtn"
          className="lightButton mx-2"
          style={{ width: "200px" }}
          disabled={emptyCart && pageStep === 2}
          onClick={goBack}>
          Back
        </button>,
      );
    }
    if (pageStep === 5) {
      buttonArray.push(
        <React.Fragment key="Review&Submit">
          <button
            style={{ width: "200px" }}
            className="yellowButton"
            type="submit"
            // onClick={(e) => {
            //   // console.log("Event: ", e);
            //   // savePrescription();
            // }}
          >
            Save Prescription
          </button>
        </React.Fragment>,
      );
    } else if (pageStep < 5) {
      buttonArray.push(
        <button
          key="NextBtn"
          type="submit"
          style={{ width: "200px" }}
          disabled={disableNextAction || (selectedProducts?.length > 1 && selectedPatient?.length > 1)}
          className="blueButton">
          Next
        </button>,
      );
    }

    return (
      <div
        className="p-4 flexJCAC"
        style={{
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
          background: "white",
          textAlign: "center",
          zIndex: 3,
        }}>
        {buttonArray}
      </div>
    );
  }, [disableNextAction, goBack, handleValidSubmit, isReview, pageStep]);

  const updateShippingMethod = useCallback(
    shippingMethod => {
      setShippingMethod(shippingMethod);
      updateShippingCost(shippingAddress, shippingMethod);
    },
    [shippingAddress, updateShippingCost],
  );

  const updateShippingAddress = useCallback(
    (address, newAddressCheck) => {
      setShippingAddress(address);
      setIsNewAddressUsed(newAddressCheck);
      updateShippingCost(address, shippingMethod);
    },
    [shippingMethod, updateShippingCost],
  );

  const updateCreditRequested = useCallback(am => {
    setCreditRequested(parseFloat(am));
  }, []);

  const resetCreditApplied = useCallback(() => {
    setCreditApplied(0);
  }, []);

  const updateCreditCard = useCallback((card, newCardCheck, billTo) => {
    setBillTo(billTo);
    setCreditCard(card);
    setIsNewCardUsed(newCardCheck);
  }, []);

  const updateRushOrder = useCallback((check, method) => {
    setRushOrder(check);
    setRushOrderMethod(method);
  }, []);

  const getCartProduct = useCallback((product, patient) => {
    setSelectedProducts(product);
    setSelectedPatient(patient);
    if (patient.length === 1) {
      setClinicLocationId(patient?.[0]?.clinicLocationId);
      setSelectedProvider({ id: patient?.[0].physicianId });
    } else {
      setClinicLocationId("");
      setSelectedProvider({});
    }
  }, []);

  return (
    <Grid container>
      <Grid item xs={12} lg={10} style={{ position: "relative" }}>
        <NavBar />
        <div className="page-content">
          <MetaTags>
            <title>Edit Prescription | VPI Compounding</title>
          </MetaTags>
          {showLoading ? <Spinner /> : null}
          <h4 className="p-3 text-muted">EDIT PRESCRIPTION</h4>
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  {errorMessage ? (
                    <Alert color="danger" className="text-center">
                      {errorMessage}
                    </Alert>
                  ) : null}
                  {editSuccess ? (
                    onSuccessView
                  ) : (
                    <>
                      <NavigationSteps currentStep={pageStep} />
                      {renderComponents ? (
                        <CardBody className="pt-0">
                          <div className="p-2">
                            {emptyCart ? (
                              <Alert color="danger" className="text-center">
                                Cart is Empty! Please select some products to continue!
                              </Alert>
                            ) : null}
                            <form
                              id="newPrescriptionForm"
                              className="form-horizontal px-md-4 px-0"
                              onSubmit={handleValidSubmit}>
                              <div className="mb-5 px-md-5 px-0">
                                <Row className="d-flex justify-content-around">
                                  <Col
                                    className={`${
                                      pageStep === 1
                                        ? "col-sm-12 newPrescriptionFormStep1"
                                        : "col-lg-9 newPrescriptionForm"
                                    } col-12`}>
                                    <div className={`mt-4 ${pageStep === 1 ? "d-block" : "d-none"}`}>
                                      <p className="bold medText">Select Patients</p>
                                      <PrescriptionPatient
                                        editPrescription={true}
                                        selectedPatients={selectedPatientIds}
                                        updateSelectedPatients={setSelectedPatient}
                                        defaultLocationId={selectedClinicLocationId}
                                        clinicId={prescriptionData?.clinicId}
                                        selectedProducts={selectedProducts}
                                      />
                                    </div>
                                    <div className={`mt-4 ${pageStep === 2 ? "d-block" : "d-none"}`}>
                                      <SelectProducts
                                        selectedProductToEdit={selectedProductToEdit}
                                        isEditModal={setShowEditModal}
                                        showEditModal={showEditModal}
                                        isBulkOrder={selectedPatient?.length > 1}
                                        selectedProducts={selectedProducts}
                                        updateSelectedProducts={setSelectedProducts}
                                        selectedPatients={selectedPatientIds}
                                        clinicId={prescriptionData?.clinicId}
                                        clinicLocationId={selectedClinicLocationId}
                                      />
                                    </div>
                                    <div
                                      className={`flexJCAC mx-sm-5 mx-0 mt-4 ${pageStep === 3 ? "d-block" : "d-none"}`}>
                                      <VerifyProviderAndLocation
                                        setCreditForLocation={setCreditForLocation}
                                        step={pageStep}
                                        setProvider={setProvider}
                                        businessName={clinicData?.businessName ?? ""}
                                        defaultClinicId={prescriptionData?.clinicId}
                                        defaultLocationId={selectedClinicLocationId}
                                        defaultProviderId={selectedClinicLocationProviderId}
                                        defaultProviderNPI={prescriptionData?.providerNpi}
                                        defaultProviderDEA={prescriptionData?.providerDetails?.deaInfo}
                                        clinicId={prescriptionData?.clinicId}
                                        edit={true}
                                      />
                                    </div>
                                    <div
                                      className={`flexJCAC mx-sm-5 mx-0 mt-4 ${pageStep === 4 ? "d-block" : "d-none"}`}>
                                      <AddShippingInfo
                                        resetCreditApplied={resetCreditApplied}
                                        credit={credit}
                                        setCreditUsed={setCreditUsed}
                                        shipToClinicFunc={setShipToClinic}
                                        updateShippingCost={updateShippingCost}
                                        shippingMethod={shippingMethod}
                                        setShippingMethod={updateShippingMethod}
                                        updateDefaultShippingOnChange={setDefaultShippingInfo}
                                        defaultShippingInfo={prescriptionData?.shippingInfo}
                                        setShippingAddress={updateShippingAddress}
                                        selectedPatients={selectedPatientIds}
                                        setRushOrder={updateRushOrder}
                                        setSignatureRequired={setSignatureRequired}
                                        isBulkOrder={isBulkOrder}
                                        clinicLocationId={selectedClinicLocationId}
                                        step={pageStep}
                                        shipToClinic={shipToClinic}
                                        clinicId={prescriptionData?.clinicId}
                                        isNet30={isNet30}
                                        selectedProducts={selectedProducts}
                                      />
                                    </div>
                                    <div
                                      className={`flexJCAC mx-sm-5 mx-0 mt-4 ${pageStep === 5 ? "d-block" : "d-none"}`}>
                                      {errorMessage ? (
                                        <Alert color="danger" className="text-center">
                                          {errorMessage}
                                        </Alert>
                                      ) : null}
                                      <AddBillingInfo
                                        resetCreditApplied={resetCreditApplied}
                                        credit={credit}
                                        setCreditUsed={setCreditUsed}
                                        setCreditRequested={updateCreditRequested}
                                        creditRequested={creditRequested}
                                        clinic={clinicData ?? ""}
                                        isNet30={isNet30}
                                        defaultBillingInfo={decryptedBillingInfo}
                                        billTo={billTo}
                                        clinicLocationId={selectedClinicLocationId}
                                        setCreditCard={updateCreditCard}
                                        selectedPatients={selectedPatientIds}
                                        isBulkOrder={isBulkOrder}
                                        step={pageStep}
                                        clinicId={prescriptionData?.clinicId}
                                        subtotal={subtotal}
                                        setDeleteCartCredit={setDeleteCartCredit}
                                        deleteCartCredit={deleteCartCredit}
                                      />
                                    </div>
                                  </Col>
                                  {pageStep > 1 ? (
                                    <div className="col-lg-3 col-12">
                                      <CartContainer
                                        setCreditRequested={updateCreditRequested}
                                        credit={credit}
                                        creditRequested={creditRequested}
                                        creditUsed={creditUsed}
                                        creditApplied={creditApplied}
                                        setRushOrderCost={setRushOrderCost}
                                        editProduct={editProduct}
                                        showEditModal={showEditModal}
                                        shippingCost={selectedPatientIds ? shippingRateData?.shippingCost : "0"}
                                        shippingMethod={shippingMethod}
                                        rushOrder={rushOrder}
                                        rushOrderMethod={rushOrderMethod}
                                        selectedPatients={selectedPatient}
                                        selectedProducts={selectedProducts}
                                        emptyCart={emptyCart}
                                        removeProduct={removeProduct}
                                        clinicId={prescriptionData?.clinicId}
                                        setDeleteCartCredit={setDeleteCartCredit}
                                        setSubTotal={setSubtotal}
                                      />
                                    </div>
                                  ) : null}
                                </Row>
                              </div>
                              {bottomView}
                            </form>
                          </div>
                        </CardBody>
                      ) : null}
                    </>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </Grid>
      <Grid
        item
        xs={0}
        lg={2}
        sx={{
          display: { xs: "none", lg: "flex" },
        }}>
        <CartDrawer
          ref={cartRef}
          drawerVariant={"persistent"}
          getCartProduct={getCartProduct}
          hideSelect={pageStep > 2}
        />
      </Grid>
    </Grid>
  );
}

export default React.memo(EditPrescription);
