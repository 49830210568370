/* eslint-disable react-hooks/exhaustive-deps */
import AddCardIcon from "@mui/icons-material/AddCard";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  Box,
  Button,
  Chip,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  Radio,
  Tooltip,
  Typography,
} from "@mui/material";
import { FormCheckBox, FormOutlineField, FormSelectField } from "components";
import { PatientApiStateContext, PatientStateContext } from "context";
import { decrypt, encrypt } from "cryptofunc";
import { includes, map } from "lodash";
import React, {
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { useFieldArray, useForm, useWatch } from "react-hook-form";
import { useSelector } from "react-redux";
import { userRoleSelector } from "selectors";
import { CONTACT_MEDIUM, NOTIFICATION_MEDIUM, USER_ROLES } from "utils";
import CreditCardRow from "./CreditCardRow";

const booleanFields = [
  "isToGivePortalAccess",
  "isToNotifyWhenOrderReceived",
  "isToNotifyWhenOrderOnHold",
  "isToNotifyWhenOrderInProcess",
  "isToNotifyWhenOrderIsCompleted",
];

const cardFieldFormat = {
  cardHolderCity: "",
  cardHolderName: "",
  creditCardNumber: "",
  cardExpirationDate: "",
  cardHolderState: "",
  cardHolderZipcode: "",
  cardHolderAddressLine1: "",
  cardHolderAddressLine2: "",
};

const PatientBilling = forwardRef(({ formId, formData }, parentRef) => {
  const formRef = useRef(null);
  const userRole = useSelector(userRoleSelector);
  const [isReadyOnly, setReadyOnly] = useState(false);
  const { isPatientEdit } = useContext(PatientStateContext);
  const { patientData } = useContext(PatientApiStateContext);
  const { control, handleSubmit, getValues, setValue, trigger } = useForm({
    defaultValues: {
      creditCards: [],
      default_card_index: 0,
      isToGivePortalAccess: false,
      isToNotifyWhenOrderReceived: false,
      isToNotifyWhenOrderOnHold: false,
      isToNotifyWhenOrderInProcess: false,
      isToNotifyWhenOrderIsCompleted: false,
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "creditCards",
  });

  const isEmptyCardList = useMemo(() => fields.length < 1, [fields.length]);
  const defaultAddressWatch = useWatch({ control, name: "default_card_index" });

  const submitAction = useCallback(
    ({ default_card_index, creditCards = [], ...data }, progressBarIndex = null, allowNavigation = true) => {
      const organizedCards = map(creditCards ?? [], (card, index) =>
        encrypt({
          ...card,
          creditCardNumber: card?.creditCardNumber?.replace(/\s/g, ""),
          isCardDefault: index === default_card_index,
        }),
      );
      const info = { ...data, encryptedCreditCards: organizedCards };
      formData?.({ info }, formId, progressBarIndex, allowNavigation);
    },
    [formData, formId],
  );

  useEffect(() => {
    if (isPatientEdit && patientData?.id) {
      let default_card_index = 0;
      const addresses = map(patientData?.encryptedCreditCards, (encryptedCard, index) => {
        const { isCardDefault, ...card } = decrypt(encryptedCard);
        if (isCardDefault) {
          default_card_index = index;
        }
        return card;
      });

      const formFields = getValues();
      for (const field in formFields) {
        let fieldValue = patientData?.[field] ?? "";
        if (field === "creditCards") {
          fieldValue = addresses;
        } else if (field === "default_card_index") {
          fieldValue = default_card_index;
        } else if (includes(booleanFields, field)) {
          fieldValue = Boolean(fieldValue);
        }
        setValue(field, fieldValue);
      }

      submitAction(getValues(), null, false);
    }
  }, [isPatientEdit, patientData?.id]);

  useEffect(() => {
    if (USER_ROLES.CLINIC_PROVIDER === userRole || USER_ROLES.CLINIC_SUPPORT === userRole) {
      setReadyOnly(true);
    } else {
      setReadyOnly(false);
    }
  }, [USER_ROLES]);

  const radioBtn = useCallback(index => () => setValue("default_card_index", index), []);

  const checkBoxBtn = (name, index) => {
    setValue(name, index);
  };
  const appendAddress = useCallback(e => {
    e.preventDefault?.();
    append({
      ...cardFieldFormat,
    });
    setValue("default_card_index", getValues("creditCards").length - 1);
  }, []);

  const removeAddress = useCallback(
    index => () => {
      if (index >= 0) {
        remove(index);
      }
    },
    [],
  );

  const canNavigate = useCallback(
    progressBarIndex => {
      handleSubmit(data => submitAction(data, progressBarIndex))();
    },
    [handleSubmit, submitAction],
  );

  const checkValidation = useCallback(async () => {
    const formValidated = await trigger();
    return formValidated;
  }, []);
  const requestFormSubmit = useCallback(() => formRef.current?.requestSubmit?.(), []);

  useImperativeHandle(parentRef, () => ({
    submitForm: requestFormSubmit,
    canNavigate: canNavigate,
    checkFilledForm: checkValidation,
  }));

  const addressDivider = useCallback(
    index => {
      return (
        <Divider className="mb-3">
          <Tooltip title="Remove the card below">
            <Chip
              color="secondary"
              label={`Credit Card ${index + 1}`}
              deleteIcon={<CancelIcon style={{ color: "#f44336" }} />}
              onClick={removeAddress(index)}
              onDelete={removeAddress(index)}
            />
          </Tooltip>
        </Divider>
      );
    },
    [removeAddress],
  );

  return (
    <Box id={formId} ref={formRef} component={"form"} onSubmit={handleSubmit(data => submitAction(data, null))}>
      <Grid
        style={isReadyOnly ? { pointerEvents: "none", opacity: 0.6 } : {}}
        container
        marginY={2}
        columnSpacing={3}
        rowSpacing={1}>
        {/* Credit Cards */}
        {fields.map((field, index) => (
          <React.Fragment key={field.id}>
            {addressDivider(index)}
            <CreditCardRow name={`creditCards.${index}`} control={control} trigger={trigger} />
            <Grid item xs={12}>
              <FormControlLabel
                control={<Radio style={{ fontSize: 20 }} />}
                label="Default Credit Card"
                value={index}
                checked={index === defaultAddressWatch}
                onChange={radioBtn(index)}
              />
            </Grid>
          </React.Fragment>
        ))}
      </Grid>
      <Grid container justifyContent={"center"}>
        <Button
          color="primary"
          variant="contained"
          size="large"
          className="pinkButton"
          onClick={appendAddress}
          disabled={isReadyOnly ? true : false}
          startIcon={<AddCardIcon />}>
          {`Add ${isEmptyCardList ? "Credit" : "Another"} Card`}
        </Button>
      </Grid>
      <Grid container marginY={2} columnSpacing={3} rowSpacing={1}>
        <Grid item xs={12} marginBottom={3}>
          <Typography variant="h5" fontWeight={"500"}>
            Preferred Information
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <FormOutlineField
            name="preferredFirstName"
            control={control}
            MuiFieldProps={{ label: "Preferred First Name" }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <FormOutlineField
            name="preferredLastName"
            control={control}
            MuiFieldProps={{ label: "Preferred Last Name" }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <FormOutlineField
            name="preferredLanguage"
            control={control}
            MuiFieldProps={{ label: "Preferred Language" }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <FormSelectField
            name={"preferredContactMethod"}
            control={control}
            MuiFieldProps={{ label: "Contact Method", optionArray: CONTACT_MEDIUM }}
          />
        </Grid>
        <Divider variant="fullWidth" className="my-3" />
        <Grid item xs={12} sm={6} md={4}>
          <FormSelectField
            name={"notificationPreferences"}
            control={control}
            MuiFieldProps={{ label: "Notification Method", optionArray: NOTIFICATION_MEDIUM }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <FormOutlineField
            name="notificationEmail"
            control={control}
            rules={{ pattern: { value: /\S+@\S+\.\S+/, message: "Invalid email address" } }}
            MuiFieldProps={{ label: "Notification Email", type: "email", placeholder: "abc@abc.com" }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <FormOutlineField
            name="notificationText"
            control={control}
            rules={{ pattern: { value: /^(\d{10})?$/, message: "Invalid Phone" } }}
            MuiInputProps={{ format: "(###) ###-####" }}
            MuiFieldProps={{
              type: "tel",
              isMasked: true,
              label: "Notifications Text",
              placeholder: "(123) 456-7890",
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <FormOutlineField
            name="preferredName"
            control={control}
            MuiFieldProps={{ label: "Preferred Emergency Contact Name" }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <FormOutlineField
            name="preferredEmergencyContact"
            control={control}
            rules={{ pattern: { value: /^(\d{10})?$/, message: "Invalid Phone" } }}
            MuiInputProps={{ format: "(###) ###-####" }}
            MuiFieldProps={{
              label: "Preferred Emergency Contact Phone",
              type: "tel",
              placeholder: "(123) 456-7890",
              isMasked: true,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <FormOutlineField
            name="comments"
            control={control}
            MuiFieldProps={{ label: "Comments", multiline: true, rows: 2 }}
          />
        </Grid>
      </Grid>
      <Grid container marginY={2} columnSpacing={3} rowSpacing={1}>
        <Grid item xs={12} marginBottom={3}>
          <Typography variant="h5" fontWeight={"500"}>
            Portal Information
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <FormCheckBox
            control={control}
            name="isToGivePortalAccess"
            checkBoxBtn={checkBoxBtn}
            MuiFieldProps={{ label: "Portal Access" }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <FormOutlineField
            name="password"
            control={control}
            //autoComplete:"new-password" is necessary, other wise browse autofill the password field by browser Password Manager
            MuiFieldProps={{ isPassword: true, label: "Password", type: "password", autoComplete: "new-password" }}
          />
        </Grid>
      </Grid>
      <Grid container item marginY={2}>
        <FormControl component="fieldset" variant="standard">
          <FormLabel component="legend" className="my-3">
            <Typography variant="h5" fontWeight={"500"}>
              Preferred Notifications
            </Typography>
          </FormLabel>
          <FormGroup row>
            <FormCheckBox
              control={control}
              name="isToNotifyWhenOrderReceived"
              checkBoxBtn={checkBoxBtn}
              MuiFieldProps={{ label: "Order Received" }}
            />
            <FormCheckBox
              control={control}
              name="isToNotifyWhenOrderOnHold"
              checkBoxBtn={checkBoxBtn}
              MuiFieldProps={{ label: "Order on Hold" }}
            />
            <FormCheckBox
              control={control}
              name="isToNotifyWhenOrderInProcess"
              checkBoxBtn={checkBoxBtn}
              MuiFieldProps={{ label: "Order in Process" }}
            />
            <FormCheckBox
              control={control}
              name="isToNotifyWhenOrderIsCompleted"
              checkBoxBtn={checkBoxBtn}
              MuiFieldProps={{ label: "Order Completed" }}
            />
          </FormGroup>
        </FormControl>
      </Grid>
    </Box>
  );
});
export default React.memo(PatientBilling);
