import { Checkbox, FormControlLabel } from "@mui/material";
import React, { useContext, useEffect, useState, useCallback } from "react";
import { useController } from "react-hook-form";
import { DialogContext } from "context";
import { useSelector } from "react-redux";
import { userRoleSelector } from "selectors";

function FormCheckBox({
  control,
  name,
  rules,
  checkBoxBtn,
  MuiFieldProps: { label = "", disabled = false },
  forceChecked = false,
}) {
  const userRole = useSelector(userRoleSelector);
  const clinicSide = userRole?.toLowerCase().includes("clinic");

  const {
    field: { ref, value, ...inputField },
  } = useController({ name, rules, control, defaultValue: "" });
  const { showDialog, closeDialog } = useContext(DialogContext);
  const [val, setVal] = useState(false);

  useEffect(() => {
    // P11-15 Requirement
    setVal(Boolean(value));
  }, [value]);

  const dialogYesAction = useCallback(() => {
    closeDialog();
    setVal(true);
    checkBoxBtn(name, true);
  }, [closeDialog]);

  const handleCheckboxChange = event => {
    if (name === "isToGivePortalAccess" && event.target.checked === true && clinicSide) {
      showDialog({
        question: "You are about to send an activation link to the patient's email.",
        actionText: "OK",
        onClickYes: dialogYesAction,
        onClickNo: closeDialog,
      });
    } else {
      setVal(event.target.checked);
      checkBoxBtn(name, event.target.checked);
    }
  };

  const CustomCheckBox = () => {
    return (
      <Checkbox
        checked={forceChecked ? true : val}
        {...inputField}
        style={{ fontSize: 20 }}
        onChange={handleCheckboxChange}
        disabled={forceChecked || disabled}
      />
    );
  };

  return <FormControlLabel ref={ref} disabled={disabled} control={<CustomCheckBox />} label={label} />;
}

export default React.memo(FormCheckBox);
