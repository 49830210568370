import MetaTags from "react-meta-tags";
import React, { useEffect, useState } from "react";
import { Row, Col, Card, CardBody, Container, FormGroup, Input, Label, Alert } from "reactstrap";
import LoggedOutNavbar from "../../components/loggedOutNavbar";
import Lottie from "../../components/lottie";
import Spinner from "../../components/spinner";
import { Link } from "react-router-dom";

import { useExostarFinishProofingMutation } from "rtk/rtkEndpoints/Exostar";
import NavBar from "components/NavBar";
const ExostarTokenVerification = ({ token }) => {
  const [verifyToken, { isFetching }] = useExostarFinishProofingMutation();
  const [error, setError] = useState("");
  const [showSpinner, setShowSpinner] = useState(true);
  const [success, setSuccess] = useState('');
  const [isVerified, setVerification] = useState(false);

  useEffect(async () => {
    setShowSpinner(true);
    if (token) {
      const body = {
        jwtStr: token,
      };
      const res = await verifyToken(body);
      if (res?.data) {
        setSuccess(res?.data?.msg);
        setError("");
        setShowSpinner(false);
      } else if (res?.error?.data?.message) {
        setSuccess('');
        setError(res?.error?.data?.message);
        setShowSpinner(false);
      }
    }
  }, [token]);

  return (
    <React.Fragment>
        <NavBar/>
      <div className="page-content">
        <MetaTags>
          <title>Finish Exostar Verification | VPI Compounding</title>
        </MetaTags>

        <Container fluid>
          {showSpinner ? (
            <Spinner />
          ) : (
            <div className="account-pages p-sm-3 p-1 pt-5 mt-5">
              <Container>
                <Row className="justify-content-center">
                  <Col md={8} lg={6} xl={5}>
                    <Card className="overflow-hidden">
                      <CardBody className="pt-0">
                        {success ? (
                          <div className="m-sm-5 m-4 p-sm-5 p-4 text-center">
                            <Lottie name="success-check" />
                            <p className="my-4">{success}</p>
                            <Link to="/profile" className="pinkButton">
                              Go to Profile
                            </Link>
                          </div>
                        ) : (
                          <div className="m-sm-5 m-4 p-sm-5 p-4 text-center">
                            <p className="my-4 redText">{error}</p>
                          </div>
                        )}
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Container>
            </div>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ExostarTokenVerification;
