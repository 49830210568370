import { useCallback, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useClinicDetailQuery, useGetClinicPrescriptionDetailQuery } from "rtk";
import { userInfoSelector } from "selectors";

function usePrescriptionApi() {
  const { prescriptionId = "" } = useParams();
  const userInfo = useSelector(userInfoSelector);
  const history = useHistory();

  // Find the usage of these location state.
  const {
    pathname,
    state: {
      side = "",
      clinicId = "",
      selectedPatients = [],
      selectedProducts = [],
      isReview = false,
      prescriptionStatus = "",
      fromRxWorkFlow = false,
      isRefillPrescription = "",
    } = {},
  } = useLocation();

  const {
    isFetching: prescriptionFetching,
    error: prescriptionError,
    data: prescriptionData,
  } = useGetClinicPrescriptionDetailQuery({ prescriptionId }, { skip: !prescriptionId });

  const isPrescriptionEdit = useMemo(
    () => pathname.includes("edit-prescription-flow") || prescriptionId,
    [pathname, prescriptionId],
  );
  const filterClinicId = useMemo(
    () => clinicId || userInfo?.clinicId || prescriptionData?.clinicId || "",
    [clinicId, prescriptionData?.clinicId, userInfo?.clinicId],
  );

  const adminClinicId = useMemo(
    () => (userInfo?.clinicId ? "" : clinicId || prescriptionData?.clinicId),
    [clinicId, prescriptionData?.clinicId, userInfo?.clinicId],
  );

  const {
    data: clinicData,
    isFetching: clinicDataFetching,
    error: clinicError,
  } = useClinicDetailQuery({ clinicId: filterClinicId }, { skip: !filterClinicId });

  const isNet30 = useMemo(() => clinicData?.paymentTermDays > 0, [clinicData?.paymentTermDays]);
  const apiLoading = useMemo(() => prescriptionFetching, [prescriptionFetching]);

  const apiError = useMemo(
    () => clinicError?.data?.message || prescriptionError?.data?.message || "",
    [clinicError?.data?.message, prescriptionError?.data?.message],
  );

  useEffect(() => {
    if (!filterClinicId && !apiLoading) {
      // Page not found when user enter url in browser.
      history.replace("/404");
    }
  }, [apiLoading, filterClinicId, history]);

  const getEditApiParam = useCallback(() => {
    const apiParam = { clinicId: filterClinicId || "" };
    if (isPrescriptionEdit) {
      apiParam["prescriptionId"] = prescriptionData?.id;
      apiParam["adminClinicSide"] = clinicId;
      apiParam["side"] = side;
      apiParam["signatureFileUrl"] = prescriptionData?.signatureFileUrl;
    }
    return apiParam;
  }, [filterClinicId, isPrescriptionEdit, prescriptionData?.id, prescriptionData?.signatureFileUrl, clinicId, side]);

  return {
    isNet30,
    apiError,
    clinicData,
    apiLoading,
    adminClinicId,
    clinicDataFetching,
    filterClinicId,
    selectedProducts,
    selectedPatients,
    prescriptionData,
    isPrescriptionEdit,
    getEditApiParam,
  };
}

export default usePrescriptionApi;
