import { createAction, createReducer } from "@reduxjs/toolkit";

const clearProductSearch = createAction("clearProductSearch");

const ProductOrderReducer = createReducer([], builder => {
  builder.addCase(clearProductSearch, (state = {}, action) => {
    const { endpointName, originalArgs } = action.payload;
    const endpoint = state.queries[`${endpointName}(${JSON.stringify(originalArgs)})`];
    if (endpoint) {
      return {
        ...state,
        queries: {
          ...state.queries,
          [`${endpointName}(${JSON.stringify(originalArgs)})`]: undefined,
        },
      };
    }
    return state;
  });
});

export default ProductOrderReducer;
export { clearProductSearch };
