import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  FormHelperText,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import classNames from "classnames";
import { FormCheckBox, FormFileField, FormOutlineField, FormSelectField } from "components";
import { SignaturePad } from "components/SignaturePad";
import { ProfileImageStyled } from "components/StyledComponent";
import { StaffActionContext, StaffApiStateContext, StaffStateContext } from "context";
import dayjs from "dayjs";
import { find, findIndex, flatten, forEach, includes, last, map, trim } from "lodash";
import React, {
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { useFieldArray, useForm, useWatch } from "react-hook-form";
import { useSelector } from "react-redux";
import { userInfoSelector, userRoleSelector } from "selectors";
import { CLINIC_STAFF_TYPE, CREDENTIALS, USA_STATES, SEX, getFileNames } from "utils";

const ClinicLocationsDeaFormat = {
  dea: "",
  deaLicenseUrl: "",
  clinicLocationId: "",
  clinicLocationName: "",
  providerDeaLicenseExpirationDate: "",
};

const StaffPersonalInfo = forwardRef(({ formId, formData }, parentRef) => {
  const formRef = useRef(null);
  const uploadButtonRef = useRef(null);
  const userInfo = useSelector(userInfoSelector);
  const userRole = useSelector(userRoleSelector);
  const [signatureModal, setSignatureModal] = useState(false);
  const { isStaffEdit, profilePic, profileImageUploading } = useContext(StaffStateContext);
  const { staffData, clinicLocationOption } = useContext(StaffApiStateContext);
  const { removeProfileImage, uploadFile } = useContext(StaffActionContext);
  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    trigger,
    register,
    unregister,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      role: isStaffEdit ? "ClinicProvider" : "",
      clinicLocationIds: [],
      shouldAccessOnlyOwnPatients: false,
      clinicLocationsDea: [],
    },
  });
  const { fields, append, remove, replace } = useFieldArray({
    control,
    name: "clinicLocationsDea",
  });
  const missingSignature = useMemo(() => errors?.signatureImageUrl?.message, [errors?.signatureImageUrl?.message]);
  const clinicSide = useMemo(() => userRole?.toLowerCase().includes("clinic"), [userRole]);
  const ownUser = useMemo(() => staffData?.id === userInfo?.id, [userInfo?.id, staffData?.id]);
  const watchLocationDeFields = useWatch({ control, name: "clinicLocationsDea" });
  const staffRole = useWatch({ control, name: "role" });
  const watchStateLicenseUrl = useWatch({ control, name: "stateLicenseUrl" });
  const signatureFileUrl = useWatch({ control, name: "signatureImageUrl" });

  const showPrescriberData = useMemo(
    () => staffRole === "ClinicProvider" || staffData?.isSuperUserSameAsProvider,
    [staffData?.isSuperUserSameAsProvider, staffRole],
  );

  const resetForm = useCallback(() => {
    const formValues = getValues();
    reset({
      role: formValues.role,
      shouldAccessOnlyOwnPatients: false,
      clinicLocationsDea: [],
      clinicLocationIds: formValues.clinicLocationIds ?? [],
    });
    if (formValues.role === "ClinicProvider") {
      register("signatureImageUrl", { required: clinicSide ? "Signature is required." : false });
    } else {
      unregister("signatureImageUrl");
    }
  }, [clinicSide]);

  const removeFile = useCallback((keyName, index, removeAble = true) => {
    if (!removeAble) {
      return;
    }
    setValue(keyName, "");
  }, []);

  const getFileListItems = useCallback(
    (fileArray, docName) => {
      const hrefArray = flatten([getValues(docName)]);
      return fileArray.map(({ name, removeAble }, index) => (
        <ListItem
          key={`lab-${name}`}
          disablePadding
          disableGutters
          secondaryAction={
            <IconButton
              edge="end"
              aria-label="delete"
              disabled={!removeAble}
              onClick={() => removeFile(docName, index, removeAble)}>
              <DeleteIcon color={removeAble ? "error" : "disabled"} style={{ width: 18, height: 18 }} />
            </IconButton>
          }>
          <ListItemText
            role={!removeAble ? "button" : undefined}
            primaryTypographyProps={{ color: "grey", noWrap: true, className: classNames({ medText: !removeAble }) }}
            style={{ marginRight: 20 }}
            onClick={e => {
              e.preventDefault();
              if (!removeAble) {
                const newWindow = window.open(hrefArray[index], "_blank", "noopener,noreferrer");
                if (newWindow) newWindow.opener = null;
              }
            }}
            primary={name}
          />
        </ListItem>
      ));
    },
    [removeFile],
  );

  const stateLicenseDocList = useMemo(() => {
    if (watchStateLicenseUrl) {
      const fileNames = getFileNames([watchStateLicenseUrl]);
      return getFileListItems(fileNames, "stateLicenseUrl");
    }
  }, [getFileListItems, watchStateLicenseUrl]);

  const submitAction = useCallback(
    (data, progressBarIndex = null, allowNavigation = true) => {
      formData?.({ info: data }, formId, progressBarIndex, allowNavigation);
    },
    [formData, formId],
  );

  useEffect(() => {
    if (isStaffEdit && staffData?.id) {
      const formFields = getValues();
      for (const field in formFields) {
        let fieldValue = staffData?.[field] ?? "";
        if (includes(["providerStateLicenseExpirationDate", "dateOfBirth"], field) && fieldValue) {
          fieldValue = dayjs(fieldValue).format("YYYY-MM-DD");
        } else if (
          field === "clinicLocationsDea" &&
          staffData?.deaInfo &&
          (staffData.role === "ClinicProvider" || staffData?.isSuperUserSameAsProvider)
        ) {
          fieldValue = map(staffData?.deaInfo, ({ deaAddressInfo: _, ...restDea }) => ({
            ...restDea,
            providerDeaLicenseExpirationDate: restDea?.providerDeaLicenseExpirationDate
              ? dayjs(restDea.providerDeaLicenseExpirationDate).format("YYYY-MM-DD")
              : "",
          }));
          if (staffData?.deaInfo !== staffData?.clinicLocationIds) {
            forEach(staffData?.clinicLocationIds, (locationId, locationIndex) => {
              const index = findIndex(staffData?.deaInfo, dea => dea?.clinicLocationId === locationId);
              if (index === -1) {
                fieldValue.push({
                  ...ClinicLocationsDeaFormat,
                  clinicLocationId: locationId,
                  clinicLocationName: staffData?.clinicLocationName[locationIndex],
                });
              }
            });
          }
        }
        setValue(field, fieldValue);
      }
      // Set Signature file
      setValue("signatureImageUrl", staffData.signatureImageUrl);

      submitAction(getValues(), null, false);
    }
  }, [isStaffEdit, staffData]);

  const uploadSignature = useCallback(
    async (e, person = "", signPad = "") => {
      e?.preventDefault?.();
      const fileURL = await uploadFile("signatureImageUrl")(e, signPad);
      if (fileURL) {
        setValue("signatureImageUrl", fileURL);
      }
    },
    [uploadFile],
  );

  const uploadClick = useCallback(
    e => {
      e.preventDefault();
      if (!profileImageUploading) {
        uploadButtonRef.current?.click();
      }
    },
    [profileImageUploading],
  );

  const canNavigate = useCallback(
    progressBarIndex => {
      handleSubmit(data => submitAction(data, progressBarIndex))();
    },
    [handleSubmit, submitAction],
  );

  const requestFormSubmit = useCallback(() => {
    formRef.current?.requestSubmit?.();
  }, []);

  const checkBoxBtn = (name, index) => {
    setValue(name, index);
  };

  const checkValidation = useCallback(async () => {
    const formValidated = await trigger();
    return formValidated;
  }, []);

  const onClickSave = (person = "signatureImageUrl", sig) => {
    uploadSignature("", person, sig);
  };

  const closeSignatureModal = () => {
    setSignatureModal(false);
  };

  const getClinicLocationDeaLicenseList = useCallback(
    index => {
      const locationDeaLicense = getValues(`clinicLocationsDea.${index}.deaLicenseUrl`);
      if (locationDeaLicense) {
        const fileNames = getFileNames([locationDeaLicense]);
        return getFileListItems(fileNames, `clinicLocationsDea.${index}.deaLicenseUrl`);
      }
    },
    [getFileListItems, watchLocationDeFields],
  );

  useImperativeHandle(parentRef, () => ({
    submitForm: requestFormSubmit,
    canNavigate: canNavigate,
    checkFilledForm: checkValidation,
  }));

  const imageComponent = useMemo(() => {
    if (profilePic) {
      return <ProfileImageStyled src={profilePic} alt={"Profile"} />;
    }
    return <CloudUploadIcon style={{ width: 30, height: 30, color: "#495057" }} />;
  }, [profilePic]);

  const onRemoveSelection = useCallback(
    fieldName => removedValue => {
      const fieldValue = getValues(fieldName);
      if (fieldName === "clinicLocationIds" && showPrescriberData) {
        const locationFieldArray = getValues("clinicLocationsDea");
        const index = findIndex(locationFieldArray, { clinicLocationId: removedValue });
        remove(index);
      }
      setValue(
        fieldName,
        fieldValue.filter(value => value !== removedValue),
      );
    },
    [showPrescriberData],
  );

  const clinicLocationsUpdate = useCallback(() => {
    if (showPrescriberData) {
      const clinicLocations = getValues("clinicLocationIds");
      const locationFieldArray = getValues("clinicLocationsDea");
      if ((clinicLocations?.length ?? 0) === 0) {
        replace([]);
      } else if (clinicLocations?.length < locationFieldArray?.length) {
        const index = findIndex(
          locationFieldArray,
          locationObj => !includes(clinicLocations, locationObj.clinicLocationId),
        );
        remove(index);
      } else {
        const lastLocationId = last(clinicLocations);
        const selectedObj = find(clinicLocationOption, { value: lastLocationId });
        append(
          {
            ...ClinicLocationsDeaFormat,
            clinicLocationId: lastLocationId,
            clinicLocationName: selectedObj?.label,
          },
          { shouldFocus: false },
        );
      }
    }
  }, [clinicLocationOption, showPrescriberData]);

  return (
    <>
      <Grid container paddingY={5} flexDirection={"column"} alignItems={"center"}>
        <div onClick={uploadClick} className="staffProfileImgCircle">
          {profileImageUploading ? <CircularProgress size={20} /> : imageComponent}
        </div>
        <span>
          <Button
            disabled={profileImageUploading}
            component="label"
            variant="contained"
            className="pinkButton mx-2"
            startIcon={<CloudUploadIcon />}
            style={{ textTransform: "capitalize" }}
            href="#file-upload">
            {`${profilePic ? "Change" : "Upload"} Image`}
            <input
              ref={uploadButtonRef}
              accept=".jpg, .png, .jpeg"
              onChange={uploadFile("profileImage")}
              name="profile_photo"
              className="d-none"
              type="file"
              id="profilePhoto"
            />
          </Button>
          {Boolean(profilePic) && (
            <IconButton
              disabled={profileImageUploading}
              aria-label="delete"
              style={{ width: 35, height: 35 }}
              onClick={removeProfileImage}>
              <DeleteIcon color="error" style={{ width: 30, height: 30 }} />
            </IconButton>
          )}
        </span>
      </Grid>
      <Box id={formId} ref={formRef} component={"form"} onSubmit={handleSubmit(data => submitAction(data, null))}>
        <Grid container marginTop={2} columnSpacing={3} rowSpacing={1}>
          <Grid item xs={12} sm={6} md={4}>
            <FormSelectField
              name="role"
              control={control}
              rules={{ required: "Staff Type is required." }}
              MuiFieldProps={{ label: "Staff Type", optionArray: CLINIC_STAFF_TYPE, disabled: ownUser }}
              dependentChange={resetForm}
            />
          </Grid>
        </Grid>
        <Divider className="my-3" textAlign="center">
          {showPrescriberData ? "Prescriber Name" : "Clinic Representative for all Customer Care Needs"}
        </Divider>
        <Grid container columnSpacing={3} rowSpacing={1}>
          <Grid item xs={12} sm={6} md={4}>
            <FormOutlineField
              name="firstName"
              control={control}
              rules={{ required: "First name is required." }}
              MuiFieldProps={{ label: "First name" }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <FormOutlineField name="middleName" control={control} MuiFieldProps={{ label: "Middle name" }} />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <FormOutlineField
              name="lastName"
              control={control}
              rules={{ required: "Last name is required." }}
              MuiFieldProps={{ label: "Last name" }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <FormSelectField
              name="sex"
              control={control}
              rules={{ required: clinicSide ? "Sex is required." : false }}
              MuiFieldProps={{ label: "Sex", optionArray: SEX }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <FormOutlineField
              name="dateOfBirth"
              control={control}
              rules={{ required: clinicSide ? "Date is required." : false }}
              MuiInputProps={{ max: dayjs().format("YYYY-MM-DD") }}
              MuiFieldProps={{ label: "Date of Birth", type: "date", shrink: true }}
            />
          </Grid>
        </Grid>

        <Grid container marginY={2} columnSpacing={3} rowSpacing={1}>
          <Grid item xs={12} marginBottom={3}>
            <Typography variant="h5" fontWeight={"500"}>
              Additional Information
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <FormSelectField
              name="clinicLocationIds"
              control={control}
              rules={{ required: "Clinic Location is required." }}
              MuiFieldProps={{
                label: "Clinic Location",
                optionArray: clinicLocationOption,
                multiple: true,
                onRemoveSelection: onRemoveSelection("clinicLocationIds"),
              }}
              dependentChange={clinicLocationsUpdate}
            />
          </Grid>
          {staffRole !== "ClinicSupport" && (
            <Grid item xs={12} sm={6} md={4}>
              <FormCheckBox
                control={control}
                checkBoxBtn={checkBoxBtn}
                name="shouldAccessOnlyOwnPatients"
                MuiFieldProps={{ label: "Can Access Own Patients Only" }}
              />
            </Grid>
          )}
          {showPrescriberData && (
            <>
              <Grid item xs={12} sm={6} md={4}>
                <FormOutlineField
                  name="npi"
                  control={control}
                  rules={{ required: "Prescriber NPI # is required." }}
                  MuiFieldProps={{ label: "Prescriber NPI #" }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FormSelectField
                  name="providerLicensingState"
                  control={control}
                  rules={{ required: "Provider License State is required." }}
                  MuiFieldProps={{ label: "Provider License State", optionArray: USA_STATES }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FormOutlineField
                  name="providerStateLicenseExpirationDate"
                  control={control}
                  rules={{ required: "State License Expiration Date is required." }}
                  MuiFieldProps={{ label: "State License Expiration Date", type: "date", shrink: true }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FormOutlineField
                  name="medicalLicenseNumber"
                  control={control}
                  rules={{ required: "Prescriber State License # is required." }}
                  MuiFieldProps={{ label: "Prescriber State License #" }}
                />
              </Grid>

              {/* Clinic Location wise Dea Information  */}
              {fields.map((field, index) => (
                <React.Fragment key={field.id}>
                  <Divider className="my-3">{`Clinic Location: ${field.clinicLocationName}`}</Divider>
                  <Grid item xs={12} sm={6} md={4}>
                    <FormOutlineField
                      name={`clinicLocationsDea.${index}.dea`}
                      control={control}
                      MuiFieldProps={{ label: "Prescriber DEA #" }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <FormOutlineField
                      name={`clinicLocationsDea.${index}.providerDeaLicenseExpirationDate`}
                      control={control}
                      MuiFieldProps={{ label: "DEA License Expiration Date", type: "date", shrink: true }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} style={{ paddingTop: 0 }}>
                    <FormFileField
                      name={`clinicLocationsDea.${index}.deaLicenseUrl`}
                      control={control}
                      showError={false}
                      MuiInputProps={{ accept: ".jpg, .png, .jpeg, .pdf" }}
                      MuiFieldProps={{ label: "Copy of DEA license" }}
                    />
                    <List dense={true}>{getClinicLocationDeaLicenseList(index)}</List>
                  </Grid>
                </React.Fragment>
              ))}
              <Divider className="my-3" />
              <Grid item xs={12} sm={6} md={4}>
                <FormSelectField
                  name="providerCredentials"
                  control={control}
                  rules={{ required: "Prescriber Credentials is required." }}
                  MuiFieldProps={{ label: "Prescriber Credentials", optionArray: CREDENTIALS }}
                />
              </Grid>
              <Grid item xs={12} sm={6} />
              <Grid item xs={12} sm={6} md={4}>
                <FormFileField
                  name="stateLicenseUrl"
                  control={control}
                  rules={{ required: "Copy of State license is required." }}
                  MuiInputProps={{ accept: ".jpg, .png, .jpeg, .pdf" }}
                  MuiFieldProps={{ label: "Copy of State license" }}
                />
                <List dense={true}>{stateLicenseDocList}</List>
              </Grid>
              <Grid item xs={12} sm={6} />

              <div className="my-4 mx-1">
                <p className="bold medText">Signature{clinicSide ? <span className="requiredStar">*</span> : ""}</p>
                <br></br>
                <small>
                  <i>(Provide Signature using the signature pad.)</i>
                </small>
                <br></br>
                <div className="flexJCAC justify-content-start">
                  {signatureFileUrl && (
                    <>
                      <img alt="provider_signature" src={signatureFileUrl} width={180} />
                      <p className="mx-3 mb-0">OR </p>
                    </>
                  )}
                  <span
                    className="pointer form-control mt-1 mx-2"
                    style={{ width: "250px" }}
                    onClick={() => setSignatureModal(true)}>
                    <i className="fas fa-pen mx-2" />
                    Signature Pad
                  </span>
                </div>
                <FormHelperText error={Boolean(trim(missingSignature))} variant="outlined" style={{ fontSize: 10 }}>
                  {missingSignature}
                </FormHelperText>
              </div>
            </>
          )}
        </Grid>
      </Box>
      <SignaturePad
        actionText="Save"
        onClickSave={onClickSave}
        closeModal={closeSignatureModal}
        showModal={signatureModal}
      />
    </>
  );
});

export default React.memo(StaffPersonalInfo);
