import React, { useState, useEffect } from "react";
import MetaTags from "react-meta-tags";
import { Card, CardBody, CardTitle, Col, Container, Row, Alert } from "reactstrap";
import ClinicNavbar from "../../../components/ClinicNavbar";
import { Link } from "react-router-dom";
import axiosConfig, { errorResponse } from "../../../utils/axiosConfig";
import Spinner from "../../../components/spinner";
import Cookies from "universal-cookie";
import PrimaryModal from "components/primaryModal";
import { USER_ROLES } from "../../../utils";
import moment from "moment";
import GoogleAuthModal from "components/GoogleAuthModal/GoogleAuthModal";
import { GoogleAuthToggle } from "components/GoogleAuthModal";
import { useClinicStaff } from "../Hook";
import { Button, Tooltip } from "@mui/material";
import { useLazyGetExostarProviderStatusQuery } from "rtk/rtkEndpoints/Exostar";
import { Verified } from "@mui/icons-material";

const StaffProfile = props => {
  const [error, setError] = useState("");
  const [showSpinner, setShowSpinner] = useState(true);
  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);
  const staffID = props.match.params.id;

  const {
    fetchStaff,
    user: staff,
    showLoading,
    showError,
    errorMessage,
    userInfo,
    userRole,
    editStaffUrl,
  } = useClinicStaff(props);
  useEffect(() => {
    fetchStaff({ staffId: staffID });
  }, []);
  const clinicSide = userRole.toLowerCase().includes("clinic");
  const deleteStaff = () => {
    setShowSpinner(true);
    setConfirmDeleteModal(false);
    axiosConfig
      .delete("staffs/deleteStaffByStaffId", {
        headers: {
          Authorization: "Bearer " + new Cookies().get("user_token"),
        },
        data: {
          staffId: staffID,
          userId: userInfo.id,
        },
      })
      .then(async response => {
        setError("Staff Profile deleted succesfully! Redirecting to staff list!");
        setShowSpinner(false);
        setTimeout(() => {
          props.history.push("/clinic-staff");
        }, 3000);
      })
      .catch(error => {
        setShowSpinner(false);
        setError("An error occurred while trying to delete staff?. " + error?.response?.data?.message);
        errorResponse(error);
      });
  };

  const closeConfirmDeleteModal = () => {
    setConfirmDeleteModal(false);
  };

  const [fetchProviderExostarStatus, { data: providerStatus }] = useLazyGetExostarProviderStatusQuery();

  useEffect(() => {
    fetchProviderExostarStatus();
  }, []);

  return (
    <React.Fragment>
      <ClinicNavbar />
      <div className="page-content">
        <MetaTags>
          <title>Staff Profile | VPI Compounding</title>
        </MetaTags>
        <Container fluid>
          <h4 className="p-3 text-muted">STAFF PROFILE</h4>
          {showLoading ? <Spinner /> : null}
          {showError ? (
            <Alert color="danger" className="text-center">
              {errorMessage}
            </Alert>
          ) : null}
          <Row>
            <Col xl="4">
              <Card className="overflow-hidden">
                <div
                  style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
                  className="bg-primary bg-soft text-center py-5">
                  {!staff?.profileImageUrl && (
                    <h1>
                      <i className="fas fa-user text-dark"></i>
                    </h1>
                  )}
                  {staff?.profileImageUrl && (
                    <img className="staffProfileImgCircle mb-4" src={staff?.profileImageUrl} alt="" />
                  )}
                  <h2 className="text-dark">
                    {staff?.firstName} {staff?.lastName}
                    {staff?.isVerified && (
                      <Tooltip title="Verified">
                        <span className="blueText mx-1">
                          <Verified color="green" />
                        </span>
                      </Tooltip>
                    )}
                    {staff?.status === "Verified" ||
                      (providerStatus?.status === "Verified" && (
                        <Tooltip title=" Exostar Verified">
                          <span className="greenText">
                            <Verified color="green" />
                          </span>
                        </Tooltip>
                      ))}
                  </h2>
                  <p>{staff?.role}</p>
                  {staff?.isSuperUserSameAsProvider ? <p>"Provider is Super User"</p> : null}
                  <div className="d-flex align-items-center">
                    <Tooltip title="Edit Profile">
                      <span onClick={editStaffUrl} className="blueText mx-2 fs-2 pointer">
                        <i className="fas fa-edit" />
                      </span>
                    </Tooltip>
                    {staffID !== userInfo?.id &&
                    (userInfo.role === USER_ROLES.CLINIC_SUPER_USER || userInfo?.isSuperUserSameAsProvider) ? (
                      <Tooltip title="Delete Staff">
                        <span onClick={() => setConfirmDeleteModal(true)} className="redText mx-2 fs-2 pointer">
                          <i className="fas fa-trash" />
                        </span>
                      </Tooltip>
                    ) : null}
                  </div>
                </div>
              </Card>
            </Col>
            <Col xl="8">
              <Card>
                <CardBody>
                  <CardTitle>Personal Information</CardTitle>
                  <Row className="my-4 px-3">
                    <Col lg={12} xl={6}>
                      <div className="InfoRow">
                        <p>First Name</p>
                        <p>{staff?.firstName}</p>
                      </div>
                      {staff?.middleName && (
                        <div className="InfoRow">
                          <p>Middle Name</p>
                          <p>{staff?.middleName}</p>
                        </div>
                      )}
                      <div className="InfoRow">
                        <p>Last Name</p>
                        <p>{staff?.lastName}</p>
                      </div>
                      <div className="InfoRow">
                        <p>Date of Birth</p>
                        <p>{staff?.dateOfBirth ? moment(staff?.dateOfBirth).format("MM/DD/YYYY") : "-"}</p>
                      </div>
                    </Col>
                    <Col lg={12} xl={6}>
                      {staff?.role == "ClinicProvider" ? (
                        <div className="InfoRow">
                          <p>Signature</p>
                          {staff?.signatureImageUrl?.length > 10 ? (
                            <img
                              src={staff?.signatureImageUrl}
                              alt="file"
                              width={110}
                              style={{ marginTop: "-30px", marginLeft: "-10px" }}
                            />
                          ) : (
                            <p>-</p>
                          )}
                        </div>
                      ) : (
                        <p></p>
                      )}
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <CardTitle>Contact Information</CardTitle>
                  <Row className="my-4 px-3">
                    <Col lg={12} xl={6}>
                      <div className="InfoRow">
                        <p>Email</p>
                        <p>{staff?.email}</p>
                      </div>
                      <div className="InfoRow">
                        <p>Mobile Number</p>
                        <p>{staff?.phoneNumber}</p>
                      </div>
                      <div className="InfoRow">
                        <p>Other Phone</p>
                        <p>{staff?.mobileNumber ? staff?.mobileNumber : "-"}</p>
                      </div>
                    </Col>
                    <Col lg={12} xl={6}>
                      <div className="InfoRow">
                        <p>Address Line 1</p>
                        <p>{staff?.addressLine1 ? staff?.addressLine1 : "-"}</p>
                      </div>
                      <div className="InfoRow">
                        <p>Address Line 2</p>
                        <p>{staff?.addressLine2 ? staff?.addressLine2 : "-"}</p>
                      </div>
                      <div className="InfoRow">
                        <p>City</p>
                        <p>{staff?.city}</p>
                      </div>
                      <div className="InfoRow">
                        <p>State</p>
                        <p>{staff?.state}</p>
                      </div>
                      <div className="InfoRow">
                        <p>Country</p>
                        <p>{staff?.country}</p>
                      </div>
                      <div className="InfoRow">
                        <p>Zipcode</p>
                        <p>{staff?.zipcode}</p>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <CardTitle>Portal Information</CardTitle>
                  <Row className="my-4 px-3">
                    <Col lg={12} xl={6}>
                      <div className="InfoRow">
                        <p>Role</p>
                        <p>{staff?.role}</p>
                      </div>
                      {/* <div className="InfoRow">
                        <p>Should Access Only Own Patients</p>
                        <p>{staff?.shouldAccessOnlyOwnPatients ? "Yes" : "No"}</p>
                      </div> */}
                      <div className="InfoRow">
                        <p>Verified</p>
                        <p>{staff?.isVerified ? "Yes" : "No"}</p>
                      </div>
                      <div className="InfoRow">
                        <p>Give Portal Access</p>
                        <p>{staff?.isToGivePortalAccess ? "Yes" : "No"}</p>
                      </div>

                      {/* <div className="InfoRow">
                        <p>Auto Approve Order</p>
                        <p>{staff?.isToAutoApproveOrder ? "Yes" : "No"}</p>
                      </div> */}

                      <div className="InfoRow">
                        <p>Hippa Compliance</p>
                        <p>{staff?.isHippaCompliance ? "Yes" : "No"}</p>
                      </div>
                    </Col>
                    <Col lg={12} xl={6}>
                      {/* <div className="InfoRow">
                        <p>Medical License Status</p>
                        <p>{staff?.medicalLicenseStatus?.includes("@") ? "-" : staff?.medicalLicenseStatus}</p>
                      </div> */}
                      {staff?.role === "ClinicProvider" && (
                        <>
                          {" "}
                          <div className="InfoRow">
                            <p>State License #</p>
                            <p>{staff?.medicalLicenseNumber?.includes("@") ? "-" : staff?.medicalLicenseNumber}</p>
                          </div>
                          <div className="InfoRow">
                            <p>Licensing State</p>
                            <p>{staff?.providerLicensingState}</p>
                          </div>
                          <div className="InfoRow">
                            <p>State License Expiration Date</p>
                            <p>
                              {" "}
                              {staff?.providerStateLicenseExpirationDate
                                ? moment(staff?.providerStateLicenseExpirationDate).format("MM/DD/YYYY")
                                : "-"}
                            </p>
                          </div>
                          <div className="InfoRow">
                            <p>Copy of State License</p>
                            {staff?.stateLicenseUrl?.length > 0 ? (
                              <a href={staff?.stateLicenseUrl} rel="noreferrer" target="_blank">
                                State License
                              </a>
                            ) : (
                              <p>License not found</p>
                            )}
                          </div>
                        </>
                      )}
                    </Col>
                  </Row>

                  <Row className="my-4">
                    {" "}
                    <CardTitle>DEA Information</CardTitle>{" "}
                    {staff?.deaInfo?.map(a => {
                      return (
                        <div>
                          <Row className="my-4 px-3">
                            <Col lg={12} xl={6} md={12}>
                              <p>
                                <strong>Clinic Location: </strong>
                                {a?.clinicLocationName}
                              </p>
                              <div className="InfoRow">
                                <p>DEA License #</p>
                                <p>{a?.dea ?? "-"}</p>
                              </div>
                              <div className="InfoRow">
                                <p>DEA License Expiration Date</p>
                                <p>
                                  {a?.providerDeaLicenseExpirationDate
                                    ? moment(a?.providerDeaLicenseExpirationDate).format("MM/DD/YYYY")
                                    : "-"}
                                </p>
                              </div>
                              <div className="InfoRow">
                                <p>Copy of DEA License</p>
                                {a?.deaLicenseUrl ? (
                                  <a href={a?.deaLicenseUrl} rel="noreferrer" target="_blank">
                                    DEA License
                                  </a>
                                ) : (
                                  <p>License not found</p>
                                )}
                              </div>

                              {a?.deaAddressInfo?.addressLine1 && (
                                <>
                                  <p>
                                    <b>DEA Address</b>
                                  </p>
                                  <p>
                                    {a?.deaAddressInfo?.addressLine2
                                      ? a?.deaAddressInfo?.addressLine1 +
                                        " " +
                                        a?.deaAddressInfo?.addressLine2 +
                                        " " +
                                        a?.deaAddressInfo?.city +
                                        "  " +
                                        a?.deaAddressInfo?.state +
                                        "  " +
                                        a?.deaAddressInfo?.zipcode
                                      : a?.deaAddressInfo?.addressLine1 +
                                        " " +
                                        a?.deaAddressInfo?.city +
                                        "  " +
                                        a?.deaAddressInfo?.state +
                                        "  " +
                                        a?.deaAddressInfo?.zipcode}
                                  </p>
                                </>
                              )}

                              <hr></hr>
                            </Col>
                          </Row>
                        </div>
                      );
                    })}
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <PrimaryModal
        question="Are you sure you want to delete this staff?"
        showModal={confirmDeleteModal}
        closeModal={() => closeConfirmDeleteModal()}
        onClickYes={() => deleteStaff()}
      />
    </React.Fragment>
  );
};

export default StaffProfile;
