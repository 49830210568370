const NET30_TERMS = {
  infoHeading: "Your clinic is currently under the terms of Net 15/30.",
  list: [
    {
      id: 11,
      text: "Payment Obligations: You are responsible for making all payments due under the net 15/30 payment terms in full by the payment due date. Failure to make timely payments may result in late fees and interest charges",
    },
    {
      id: 12,
      text: "Credit Card: The card selected above will automatically be charged 30 days after the order is compounded and left our facility, unless payment is manually made via the Invoices Page before the 30 days concludes",
    },
    {
      id: 13,
      text: "Late Payments: If you fail to make a payment on time, your account may be considered delinquent, and additional fees and charges may be assessed. We reserve the right to suspend or terminate your access to the net 15/30 payment terms if you fail to make timely payments",
    },
    {
      id: 14,
      text: "Collection: In the event that we need to take legal action to collect any amounts due under the net 30 payment terms, you agree to pay all costs and expenses, including reasonable attorney's fees, incurred in connection with such action.",
    },
  ],
};

const COMMON_TERMS = {
  infoHeading: "Your clinic is currently under credit card terms",
  list: [
    {
      id: 1,
      text: "Payment Obligations: The credit card selected above will be used to pay for this order once the order has been compounded and left our facility. If the wrong credit card is selected, VPI will not be responsible for issuing a refund and charging the correct card.",
    },
  ],
};

export { COMMON_TERMS, NET30_TERMS };
