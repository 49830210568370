import { flatten, get, map, sortBy } from "lodash";
import { ProductApi } from "../../rtkApi";
import { API_METHODS, CACHE_TAGS, PRODUCT_END_POINTS, providesList } from "../../rtkConstant";

const ProductQuery = ProductApi.injectEndpoints({
  endpoints: builder => ({
    getSuggestionSearchProducts: builder.query({
      query: body => ({
        url: PRODUCT_END_POINTS.SUGGESTION_PRODUCT_SEARCH,
        method: API_METHODS.POST,
        body: body,
      }),
      transformResponse: (response, _, arg) => Array.from(new Set(map(response, arg.filter[0]))),
    }),
    getMedicalAccessoriesList: builder.query({
      query: () => PRODUCT_END_POINTS.GET_MEDICAL_ACCESSORIES_LIST,
    }),
    getFavoriteProducts: builder.query({
      query: () => ({
        url: PRODUCT_END_POINTS.GET_CLINIC_FAVORITE_PRODUCTS,
        method: API_METHODS.POST,
        body: {
          limit: 5,
          currentPage: 1,
        },
      }),
      transformResponse: response => response?.products?.slice(0, 4),
      providesTags: result => providesList(result, CACHE_TAGS.PRODUCT),
    }),

    getFamilyCategories: builder.query({
      query: () => PRODUCT_END_POINTS.GET_FAMILIES_CATEGORIES,
      transformResponse: response => sortBy(response, ["family"]),
    }),
    getProductByCategory: builder.query({
      query: body => ({
        method: API_METHODS.POST,
        body: { category: body[1], subCategory1: body[2] },
        url: PRODUCT_END_POINTS.GET_PRODUCT_BY_CATEGORIES,
      }),
      transformResponse: response => flatten(map(response, product => get(product, "commonNames"))),
    }),
    getProductBySearch: builder.query({
      query: body => ({
        method: API_METHODS.POST,
        body,
        url: PRODUCT_END_POINTS.GET_PRODUCT_BY_SEARCH,
      }),
    }),
    getProductDetailById: builder.query({
      query: productId => `${PRODUCT_END_POINTS.GET_PRODUCT_DETAIL_BY_ID}/${productId}`,
      providesTags: result => (result ? providesList([result], CACHE_TAGS.PRODUCT) : [CACHE_TAGS.PRODUCT]),
    }),
    getProductDiscountByIds: builder.query({
      queryFn: async ({ clinicId = "", ...apiArgs } = {}, queryApi, _, baseQuery) => {
        try {
          const user = queryApi.getState().AuthSlice || {};
          if (user?.userData) {
            const organizedBody = {
              ...apiArgs,
              clinicId: clinicId || user?.userData?.clinicId,
            };
            const response = await baseQuery({
              url: PRODUCT_END_POINTS.GET_PRODUCT_DISCOUNT,
              method: API_METHODS.POST,
              body: organizedBody,
            });
            return response;
          } else {
            return {
              error: {
                data: { message: "Unauthenticated user" },
              },
            };
          }
        } catch {}
      },
    }),
    getProductCustomSig: builder.query({
      queryFn: async ({ clinicId = "", ...apiArgs } = {}, queryApi, _, baseQuery) => {
        try {
          const user = queryApi.getState().AuthSlice || {};
          if (user?.userData) {
            const organizedBody = {
              ...apiArgs,
              clinicId: clinicId || user?.userData?.clinicId,
              providerId: user?.userData?.id,
            };
            const response = await baseQuery({
              url: PRODUCT_END_POINTS.GET_PRODUCT_CUSTOM_SIG,
              method: API_METHODS.POST,
              body: organizedBody,
            });
            return response;
          } else {
            return {
              error: {
                data: { message: "Unauthenticated user" },
              },
            };
          }
        } catch {}
      },
    }),
  }),
});
export const {
  useGetFamilyCategoriesQuery,
  useGetFavoriteProductsQuery,
  useLazyGetProductCustomSigQuery,
  useLazyGetProductDetailByIdQuery,
  useLazyGetProductBySearchQuery,
  useLazyGetProductDiscountByIdsQuery,
  useLazyGetMedicalAccessoriesListQuery,
  useLazyGetSuggestionSearchProductsQuery,
  useLazyGetProductByCategoryQuery,
} = ProductQuery;
