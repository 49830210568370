/* eslint-disable react-hooks/exhaustive-deps */
import { Grid, Typography } from "@mui/material";
import { FormCheckBox, FormOutlineField, MuiAlertMessage } from "components";
import Spinner from "components/spinner";
import { DialogContext } from "context";
import {
  PrescriptionActionContext,
  PrescriptionApiStateContext,
  PrescriptionFormActionContext,
  PrescriptionFormSateContext,
  PrescriptionStateContext,
} from "context/PrescriptionContext";
import { map, some, startsWith } from "lodash";
import React, {
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { useForm, useWatch } from "react-hook-form";
import { useGetPatientsDetailQuery } from "rtk";
import { PrescriptionShippingInfo } from "screens";
import { STEPPER_FORMS, matchAddressDea } from "utils";

function PrescriptionShipping({ formId, formData }, parentRef) {
  const changeShippingRef = useRef(null);
  const [customError, setCustomError] = useState("");
  const [customInfo, setCustomInfo] = useState("");
  const { closeDialog, showDialog } = useContext(DialogContext);
  const { getSingleFormData } = useContext(PrescriptionFormActionContext);
  const { prescriptionData } = useContext(PrescriptionApiStateContext);
  const { isPrescriptionEdit } = useContext(PrescriptionStateContext);
  const {
    shippingMethod,
    patientFormData,
    productFormData,
    shippingRateError,
    shippingRateLoading,
    selectedClinicLocation,
  } = useContext(PrescriptionFormSateContext);
  const { rateMutate, setShippingMethod, toggleRushOrder, subTotalCalculation } = useContext(PrescriptionActionContext);

  const { control, handleSubmit, setValue, getValues, trigger } = useForm({
    defaultValues: {
      isRushOrder: false,
      isSignatureRequired: false,
    },
  });

  const watchRushOrder = useWatch({ control, name: "isRushOrder" });
  const hasProducts = useMemo(() => productFormData.current?.length > 0, [productFormData.current?.length]);
  const isBulkOrder = useMemo(() => patientFormData?.current?.length > 1, [patientFormData?.current?.length]);
  const selectedPatientIds = useMemo(() => map(patientFormData?.current, "id"), [patientFormData?.current]);
  const shipToClinic = useMemo(
    () => isBulkOrder || prescriptionData?.shippingInfo?.shipTo === "clinic",
    [isBulkOrder, prescriptionData?.shippingInfo?.shipTo],
  );

  const {
    data: selectedPatientData,
    error: selectedPatientError,
    isFetching: selectedPatientFetching,
  } = useGetPatientsDetailQuery({ patientId: selectedPatientIds?.[0] }, { skip: selectedPatientIds?.length < 1 });

  const shippingLoading = useMemo(
    () => shippingRateLoading || selectedPatientFetching,
    [shippingRateLoading, selectedPatientFetching],
  );

  const shippingSelectionError = useMemo(
    () => selectedPatientError?.data?.message || shippingRateError?.data?.message || customError,
    [customError, selectedPatientError?.data?.message, shippingRateError?.data?.message],
  );

  useEffect(() => {
    // This useEffect run when product is selected (after Step 2)
    if (!isPrescriptionEdit && selectedPatientData && hasProducts) {
      setShippingMethod(selectedPatientData?.shippingPreference);
    }
  }, [selectedPatientData, hasProducts]);

  useEffect(() => {
    //Cold Shipped products can only use Rush order logic
    const selectedProducts = getSingleFormData(STEPPER_FORMS.PRODUCT_INFO);
    const isColdShipped = selectedProducts.some(product => product.coldShipped === "1");
    if (isColdShipped && hasProducts) {
      // setValue("isRushOrder", true);
      // toggleRushOrder(true);
      setCustomInfo("This order has a cold shipping product product which can only be shipped overnight.");
    }
  }, [hasProducts,getSingleFormData]);

  const fillPrescriptionData = useCallback(() => {
    // will run for Edit Prescription after getting patient and clinic address (API call)
    if (isPrescriptionEdit && prescriptionData?.id) {
      setShippingMethod(prescriptionData?.shippingInfo?.shippingMethod);
      setValue("isRushOrder", prescriptionData?.shippingInfo?.isRushOrder);
      setValue("orderNotes", prescriptionData?.shippingInfo?.orderNotes);
      setValue("isSignatureRequired", prescriptionData?.shippingInfo?.isSignatureRequired);
      if (prescriptionData?.shippingInfo?.isRushOrder) {
        toggleRushOrder(prescriptionData?.shippingInfo?.isRushOrder);
      }
      submitAction(
        {
          ...getValues(),
          shipTo: prescriptionData?.shippingInfo?.shipTo,
          isNewAddressUsed: prescriptionData?.shippingInfo?.isNewAddressUsed,
          shippingMethod: prescriptionData?.shippingInfo?.shippingMethod,
          shippingAddress: changeShippingRef.current?.getShippingAddress?.(),
        },
        null,
        false,
      );
    }
  }, [
    isPrescriptionEdit,
    prescriptionData?.id,
    prescriptionData?.shippingInfo?.isRushOrder,
    prescriptionData?.shippingInfo?.isSignatureRequired,
    prescriptionData?.shippingInfo?.shippingMethod,
  ]);

  const submitAction = useCallback(
    (data, progressBarIndex = null, allowNavigation = true) => {
      formData?.({ info: data }, formId, progressBarIndex, allowNavigation);
    },
    [formData, formId],
  );

  const canNavigate = useCallback(
    async progressBarIndex => {
      setCustomError("");
      const selectedProducts = getSingleFormData(STEPPER_FORMS.PRODUCT_INFO);
      const isControlledSubstance = selectedProducts.some(product => product.controlledSubstance === "1");
      const selectedProvider = getSingleFormData(STEPPER_FORMS.PROVIDER_INFO);
      const selectedPatients = getSingleFormData(STEPPER_FORMS.PATIENT_INFO);

      const isAddressMatch = matchAddressDea(
        selectedProvider,
        selectedProvider?.clinicLocationAddress,
        selectedProvider?.clinicLocationId,
      );
      const selectedShippingData = await changeShippingRef.current?.getShippingData();

      if (selectedShippingData) {
        if (
          !isAddressMatch &&
          isControlledSubstance &&
          selectedShippingData?.shipTo === "clinic" &&
          selectedShippingData.shippingMethod !== "PickUp"
        ) {
          if (selectedPatients?.length > 1) {
            showDialog({
              question: "DEA Address is not matching with the selected Clinic Address.",
              cancelText: "OK",
              onClickNo: closeDialog,
            });
          } else {
            showDialog({
              question: "Update your provider profile to allow clinic shipments for controlled substances.",
              cancelText: "OK",
              onClickNo: closeDialog,
            });
          }
        } else {
          const selectedShippingAddress = changeShippingRef.current?.getShippingAddress?.();
          subTotalCalculation();
          handleSubmit(data =>
            submitAction(
              { ...data, ...selectedShippingData, shippingAddress: selectedShippingAddress },
              progressBarIndex,
            ),
          )();
        }
      }
    },
    [closeDialog, getSingleFormData, handleSubmit, showDialog, subTotalCalculation, submitAction],
  );

  const requestFormSubmit = useCallback(() => {
    canNavigate(null);
  }, [canNavigate]);

  const checkValidation = useCallback(async () => {
    const shippingDataFormValidated = await changeShippingRef.current?.checkValidation();
    const orderDataFormValidated = await trigger();
    return orderDataFormValidated && shippingDataFormValidated;
  }, [trigger]);

  useImperativeHandle(parentRef, () => ({
    submitForm: requestFormSubmit,
    canNavigate: canNavigate,
    checkFilledForm: checkValidation,
  }));

  const updateShippingCost = useCallback(
    async (method = shippingMethod) => {
      setCustomError("");
      const selectedProducts = getSingleFormData(STEPPER_FORMS.PRODUCT_INFO);
      const findProduct = some(
        selectedProducts,
        product => product?.productType !== "rxPad" && !startsWith(product?.rxStatus, "CUSTOM"),
      );
      let filterAddress = changeShippingRef.current?.getShippingAddress?.();

      const body = {
        recipientAddress: {
          streetLines: {
            addressLine1: filterAddress?.addressLine1 ?? "",
            addressLine2: filterAddress?.addressLine2 ?? "",
          },
          city: filterAddress?.city ?? "",
          state: filterAddress?.state ?? "",
          postalCode: filterAddress?.zipcode ?? "",
          residential: method === "GROUND_HOME_DELIVERY",
        },
        shippingMethod: method,
      };
      if (findProduct) {
        if (body?.shippingMethod !== "PickUp" && body?.shippingMethod !== "CustomRate") {
          if (body?.recipientAddress?.streetLines?.addressLine1 !== "") {
            const shippingApiParam = changeShippingRef.current?.getShippingInfo();
            rateMutate({ ...body, ...shippingApiParam });
          } else {
            setCustomError("Invalid Shipping Address");
          }
        }
      } else {
        setCustomInfo("Shipping rate has not been calculated as all selected products are RxPad.");
      }
    },
    [getSingleFormData, rateMutate, shippingMethod],
  );

  const updateShippingMethod = useCallback(
    shippingMethod => {
      setShippingMethod(shippingMethod);
      if (shippingMethod !== "CustomRate" && shippingMethod !== "PickUp") {
        updateShippingCost(shippingMethod);
      }
    },
    [setShippingMethod, updateShippingCost],
  );

  const checkBoxBtn = useCallback(
    (name, index) => {
      setValue(name, index);
      if (name === "isRushOrder") {
        toggleRushOrder(index);
      }
    },
    [toggleRushOrder, setValue],
  );

  const noticeView = useMemo(() => {
    if (watchRushOrder) {
      return (
        <Typography>
          <strong>Please Note:</strong> Selecting this fee moves this order to the top of the list for your clinic's
          orders. Expected turnaround time is 2-3 business days.
        </Typography>
      );
    }
  }, [watchRushOrder]);

  return (
    <Grid container justifyContent={"center"}>
      <Spinner showSpinner={shippingLoading} />
      <Grid container justifyContent={"space-around"}>
        <Grid item md={3} xl={2} display={{ xs: "none", md: "flex" }} />
        <Grid item container xs={12} md={9} lg={9} px={2} rowGap={2}>
          <Grid item xs={12}>
            <MuiAlertMessage
              message={shippingSelectionError || customInfo}
              isError={shippingSelectionError}
              info={customInfo}
            />
          </Grid>

          <PrescriptionShippingInfo
            ref={changeShippingRef}
            isBulkOrder={isBulkOrder}
            shipToClinic={shipToClinic}
            isRushOrder={watchRushOrder}
            shippingMethod={shippingMethod}
            selectedPatients={selectedPatientIds}
            isPrescriptionEdit={isPrescriptionEdit}
            prescriptionPatient={prescriptionData?.patientIds}
            prescriptionShipping={prescriptionData?.shippingInfo}
            clinicLocationId={selectedClinicLocation?.id}
            fillPrescriptionData={fillPrescriptionData}
            updateShippingCost={updateShippingCost}
            setShippingMethod={updateShippingMethod}
            isColdShipped={getSingleFormData(STEPPER_FORMS.PRODUCT_INFO)?.some(product => product.coldShipped === "1")}
          />
          <Grid item xs={12}>
            <FormOutlineField
              name="orderNotes"
              control={control}
              MuiFieldProps={{ label: "Order Notes", multiline: true, rows: 2 }}
            />
          </Grid>
          <Grid item xs={12}>
            <FormCheckBox
              control={control}
              name="isSignatureRequired"
              checkBoxBtn={checkBoxBtn}
              MuiFieldProps={{ label: "Signature Required" }}
            />
          </Grid>
          <Grid item xs={12}>
            <FormCheckBox
              control={control}
              name="isRushOrder"
              checkBoxBtn={checkBoxBtn}
              MuiFieldProps={{ label: "Priority Order Fee" }}
            />
          </Grid>
          {noticeView}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default React.memo(forwardRef(PrescriptionShipping));
