/* eslint-disable react-hooks/exhaustive-deps */
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Grid } from "@mui/material";
import {
  AutoCompleteSearch,
  MuiAlertMessage,
  ProductCategoryBox,
  ProductFamilyAccordion,
  ProductSavedMedicationListing,
} from "components";
import { ProductSubcategoryListing } from "components/ProductCommonNameListing";
import { BreadcrumbsStyled, LinkButtonStyled } from "components/StyledComponent";
import Spinner from "components/spinner";
import { delay, find, isNull, last, map } from "lodash";
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo } from "react";
import { PRODUCT_FILER_OPTIONS } from "utils";
import { useProductSelection } from "../Hook";

function PrescriptionProductSelection({ formId, formData }, parentRef) {
  const {
    productData,
    searchFilter,
    selectedFamily,
    keywordSearch,
    isMultiPatient,
    prescriptionData,
    selectedProducts,
    searchProductData,
    recentProductData,
    productFamilyData,
    isPrescriptionEdit,
    preSelectedProducts,
    clinicFavoriteProductData,
    productSelectionLoading,
    productSelectionError,
    updateFilter,
    handleSearch,
    updateKeyword,
    clearSearchFilter,
    handleProductSelect,
    addToSidebarSummary,
    handleSelectedProduct,
    addProductToCartAction,
    handleBreadcrumbSelect,
    handleCustomProductError,
  } = useProductSelection();

  const submitAction = useCallback(
    (data, progressBarIndex = null, allowNavigation = true) => {
      formData?.({ info: data }, formId, progressBarIndex, allowNavigation);
      if (allowNavigation && (isNull(progressBarIndex) || (progressBarIndex && progressBarIndex > 1))) {
        addToSidebarSummary();
      }
    },
    [addToSidebarSummary, formData, formId],
  );

  useEffect(() => {
    if (isPrescriptionEdit && prescriptionData?.id) {
      const prescriptionProducts = [...prescriptionData?.products, ...prescriptionData?.rxPadProducts];
      handleSelectedProduct(prescriptionProducts, true);
      submitAction(prescriptionProducts, null, false);
    }
  }, [isPrescriptionEdit, prescriptionData?.id, prescriptionData?.products, prescriptionData?.rxPadProducts]);

  useEffect(() => {
    // Handle the pre selected Product. When start ordering from side cart
    if (!isPrescriptionEdit && preSelectedProducts.length > 0 && selectedProducts.length === 0) {
      handleSelectedProduct(preSelectedProducts, true);
      submitAction(preSelectedProducts, null, false);
    }
  }, [isPrescriptionEdit, preSelectedProducts]);

  const canNavigate = useCallback(
    progressBarIndex => {
      handleCustomProductError("");
      if ((selectedProducts.length > 1) & isMultiPatient) {
        delay(() => handleCustomProductError("You can not select more than one product for Bulk Orders!"), 350);
        window.scrollTo(0, 0);
      } else if (selectedProducts.length) {
        submitAction(selectedProducts, progressBarIndex);
      } else {
        delay(() => handleCustomProductError("Please select one or more product"), 350);
        window.scrollTo(0, 0);
      }
    },
    [handleCustomProductError, isMultiPatient, selectedProducts, submitAction],
  );

  const requestFormSubmit = useCallback(() => {
    canNavigate(null);
  }, [canNavigate]);

  const checkValidation = useCallback(() => Boolean(selectedProducts?.length), [selectedProducts?.length]);

  useImperativeHandle(parentRef, () => ({
    submitForm: requestFormSubmit,
    canNavigate: canNavigate,
    checkFilledForm: checkValidation,
  }));

  const renderProductCatalogue = useMemo(() => {
    if (searchProductData) {
      return <ProductSubcategoryListing productData={searchProductData} addToCartAction={addProductToCartAction} />;
    } else if (selectedFamily.length === 1) {
      return map(productFamilyData, ({ family }, index) => (
        <ProductCategoryBox key={`${family}_${index}`} itemTitle={family} onSelect={handleProductSelect} />
      ));
    } else if (selectedFamily.length === 2) {
      const filteredFamily = find(productFamilyData, { family: last(selectedFamily) });
      if (filteredFamily?.categories) {
        return map(filteredFamily?.categories, (category, index) => (
          <ProductCategoryBox key={`${category}_${index}`} itemTitle={category} onSelect={handleProductSelect} />
        ));
      }
    } else if (selectedFamily.length === 3) {
      return <ProductSubcategoryListing productData={productData} addToCartAction={addProductToCartAction} />;
    }
  }, [addProductToCartAction, handleProductSelect, productData, productFamilyData, searchProductData, selectedFamily]);

  return (
    <Grid container justifyContent={"center"} rowGap={2}>
      <Spinner showSpinner={productSelectionLoading} />
      <MuiAlertMessage message={productSelectionError} isError />
      <Grid container justifyContent={"center"}>
        <Grid item md={3} xl={2} display={{ xs: "none", md: "flex" }} />
        <Grid item xs={10} sm={8} md={6.5} lg={6}>
          <AutoCompleteSearch
            searchKeyword={keywordSearch}
            applySearch={handleSearch}
            clearFilter={clearSearchFilter}
            selectedFilter={searchFilter}
            updateKeyword={updateKeyword}
            updateFilterType={updateFilter}
            filterOptions={PRODUCT_FILER_OPTIONS}
          />
        </Grid>
        <Grid item md={3} display={{ xs: "none", md: "flex" }} />
      </Grid>
      <Grid container justifyContent={"center"} rowGap={{ xs: 2, md: 0 }}>
        <Grid item xs={10} md={3} xl={2}>
          <ProductFamilyAccordion
            selectedFamily={selectedFamily}
            accordionData={productFamilyData}
            onSelect={handleProductSelect}
            addToCartAction={addProductToCartAction}
          />
        </Grid>
        <Grid
          container
          item
          xs={12}
          md={9}
          lg={9}
          xl={10}
          rowGap={4}
          alignContent="flex-start"
          justifyContent={"center"}>
          <Grid item xs={12} md={11} justifyContent={"center"}>
            <BreadcrumbsStyled separator={<NavigateNextIcon fontSize="small" />} sx={{ mb: 2 }}>
              {selectedFamily.map((family, index) => (
                <LinkButtonStyled
                  key={`breadcrumbs_${family}`}
                  variant="text"
                  disableRipple
                  onClick={() => handleBreadcrumbSelect(index)}>
                  {family}
                </LinkButtonStyled>
              ))}
            </BreadcrumbsStyled>
            <Grid container rowSpacing={2} columnSpacing={{ xs: 2, lg: 2, xl: 3 }}>
              {renderProductCatalogue}
            </Grid>
          </Grid>
          <Grid item xs={11}>
            <ProductSavedMedicationListing
              title="Recently Order"
              medicationList={recentProductData}
              addToCartAction={addProductToCartAction}
            />
          </Grid>
          <Grid item xs={11}>
            <ProductSavedMedicationListing
              title="Favorites"
              medicationList={clinicFavoriteProductData}
              addToCartAction={addProductToCartAction}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default React.memo(forwardRef(PrescriptionProductSelection));
